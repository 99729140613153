import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import AddClubMemberController, { Props } from "./AddClubMemberController.web";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares, { MembersContributionTable } from "./MembersContributionTable.web";
import { VerticalSpacer, DezinerButton, DezinerSpinner } from "../../../components/src/design-system";
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";
import {
  useClubMembers,
  useDecodedToken,
  useNavigate,
  useRemoveClubMember,
  useRemoveClubMemberAdminPermission,
  useSingleClub
} from "../../../components/src/hooks";
import { useParams } from "../../../components/src/utils";
import { DeleteMemberDialog } from "./DeleteMemberDialog.web";
import { defaultToEmptyArray, defaultToEmptyString } from "../../../components/src/default-values";
import { ClubMember } from "../../../components/src/api-types";
import { GroupClubNonRespondingMembers } from "./non-responding-members";
import { AddMemberForm } from "./add-member-form";



class AddClubMember extends AddClubMemberController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (<AddClubMemberContent />);
  }
}







function AddClubMemberContent() {

  const params = useParams();
  const clubId = Number(params.clubId);

  const membersQuery = useClubMembers({ clubId });
  const members = defaultToEmptyArray<ClubMember>(membersQuery?.data?.data);

  const clubQuery = useSingleClub({ clubId });

  const { mutate: removeAdminPermission } = useRemoveClubMemberAdminPermission({ clubId });
  const { mutate: removeMember } = useRemoveClubMember({ clubId });

  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [deletedMember, setDeleteMember] = useState({ deleteType: '', memberId: 0, memberName: '' });
  const [currentTab, setCurrentTab] = useState(0);

  const { id: myProfileId } = useDecodedToken();

  const myMember = members.find(member => member.attributes.account_id == myProfileId);

  const currentRole = defaultToEmptyString(myMember?.attributes?.user_type);
  const isAdmin = currentRole == "Admin";
  const isOwner = currentRole == "Owner";

  const navigate = useNavigate();

  const showUpdateButton = isAdmin || isOwner;


  if (clubQuery.isLoading)
    return <DezinerSpinner />;

  return (
    <div>



      <DeleteMemberDialog
        deleteType={"remove_member"}
        open={deleteMemberOpen}
        memberName={deletedMember.memberName}
        onAccept={() => {
          const { deleteType, memberId } = deletedMember;

          if (deleteType == "remove_as_admin")
            removeAdminPermission({ memberId, clubId });
          else if (deleteType == "remove_member")
            removeMember({ memberId, clubId });

          setDeleteMemberOpen(false);
          setDeleteMember({ deleteType: '', memberId: 0, memberName: '' });
        }}
        onClose={() => setDeleteMemberOpen(false)}
      />





      <div style={{ padding: '50px 0' }}>
        <Grid container>
          <Grid item sm={2}></Grid>
          <Grid item xs={12} sm>


            <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
              style={{
                borderBottom: '1px solid #ddd'
              }}
            >
              <GroupAndClubHorizontalCard
                item={clubQuery?.data?.data}
                hideButton hideMembers hideBorder
                onNameClick={() => {
                  navigate(`ClubHomePage/${clubId}`);
                }}
              />
              <DezinerTabs
                value={currentTab}
                onChange={(_e, tab) => setCurrentTab(tab)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                <DezinerTab label="Member" {...a11yProps(0)} />
                <DezinerTab label="Role" {...a11yProps(1)} />
              </DezinerTabs>
            </Grid>

            <DezinerTabPanel value={currentTab} index={0}>

              <VerticalSpacer />

              <AddMemberForm />

              <VerticalSpacer />

              <MembersContributionTable
                members={members}
                currentRole={currentRole}
                isEditable={false}
                isEqual={false}
                revenu={{}}
                setRevenu={() => { }}
                onDelete={({ memberId, memberName }) => {
                  setDeleteMember({
                    memberId,
                    memberName,
                    deleteType: "remove_member"
                  });
                  setDeleteMemberOpen(true);
                }}
                isViewOnly={true}
                workContributionHeaderTitle="Work Contribution %"
              />

              <VerticalSpacer />
              <Grid container justifyContent="flex-end">
                {
                  showUpdateButton ?
                    <DezinerButton
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setCurrentTab(1)
                      }}
                    >
                      {'Update'}
                    </DezinerButton>
                    :
                    null
                }
              </Grid>

            </DezinerTabPanel>
            <DezinerTabPanel value={currentTab} index={1}>

              <MembersContributionTableWithShares
                members={members}
                onDelete={({ memberName, memberId, }) => {
                  setDeleteMember({
                    memberName,
                    memberId,
                    deleteType: "remove_member",
                  })
                  setDeleteMemberOpen(true);
                }}
                onAdd={() => {
                  setCurrentTab(0)
                }}
                onSave={(revenu) => {
                  console.log({ revenu });
                }}
                currentRole={currentRole}
                workContributionHeaderTitle="Work Contribution %"
              />

            </DezinerTabPanel>

          </Grid>
          <Grid item sm={2}></Grid>
        </Grid>
      </div>

      <GroupClubNonRespondingMembers />


    </div>
  );
}

export default AddClubMember;
