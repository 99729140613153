import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setCurrentVideoId } from '../../store/ui/ui.actions';
import { HideContainer } from '../hide';


type DezinerMediaProps = React.HTMLProps<HTMLImageElement> & React.HTMLProps<HTMLVideoElement> & {
  id: string,
  watermarkURL?: string,
  watermarkWidth?: number,
  watermarkHeight?: number,
  containerClassName?: string,
  containerStyles?: CSSProperties,
  mediaType?: 'image' | 'video',
  crossOrigin?: any,
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'inline-block',
  },
  watermark: {
    opacity: 0.2,
    // @ts-ignore
    objectFit: 'unset!important',
    zIndex: 1,
  },
  watermarkContainer: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  watermarkContainerForVideos: {
    height: '90%',
  },
  img: {
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export function DezinerMedia(props: DezinerMediaProps) {

  const {
    id,
    watermarkURL,
    watermarkWidth,
    watermarkHeight,
    containerClassName,
    containerStyles,
    mediaType,
    ...mediaProps
  } = props;


  const classes = useStyles();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [offsetWidth, setOffsetWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);

  const currentVideoId = useSelector((state: RootState) => state.ui.videoPlayer.currentVideoId);
  const dispatch = useDispatch();

  function playHandler() {
    dispatch(setCurrentVideoId({ videoId: id }));
  }

  function onLoad({ target }: any) {

    setOffsetWidth(target.offsetWidth);
    setOffsetHeight(target.offsetHeight);
  }


  let _width = watermarkWidth && offsetWidth ? `${Math.min(offsetWidth, watermarkWidth * offsetWidth)}px` : 'auto';
  let _height = watermarkHeight && offsetHeight ? `${Math.min(offsetHeight, watermarkHeight * offsetHeight)}px` : 'auto';

  useEffect(() => {
    if (videoRef && videoRef.current) {
      let options = {
        rootMargin: "0px",
        threshold: [0.25, 0.75]
      };

      let handlePlay = (entries: any, _observer: any) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting && currentVideoId == id) {
            videoRef?.current?.play();
          } else {
            videoRef?.current?.pause();
          }
        });
      };

      let observer = new IntersectionObserver(handlePlay, options);

      observer.observe(videoRef.current);

      return () => {
        if (videoRef.current)
          observer.unobserve(videoRef.current);
      }
    }
  }, [videoRef.current, currentVideoId]);

  useEffect(function stopWhenAnotherPlay() {
    if (videoRef && videoRef.current && id != currentVideoId)
      videoRef.current.pause();
  }, [currentVideoId]);


  return (
    <span
      style={containerStyles}
      className={clsx(classes.root, containerClassName)}>
      {
        watermarkURL ?
          <span className={clsx(classes.watermarkContainer, {
            [classes.watermarkContainerForVideos]: mediaType == 'video',
          })}>
            <img
              src={watermarkURL}
              style={{
                width: _width,
                height: _height,
                maxWidth: '100%',
                maxHeight: '100%',
                minWidth: _width,
                minHeight: _height,
              }}
              className={classes.watermark} />
          </span>
          :
          null
      }
      <HideContainer hideWhen={!mediaProps.src}>
        {
          mediaType == 'video' ?
            <video
              muted={true}
              autoPlay={false}
              {...mediaProps}
              ref={videoRef}
              crossOrigin={props.crossOrigin}
              onLoadedData={onLoad}
              className={clsx(classes.img, mediaProps.className)}
              onPlay={playHandler}
            >
              <source src={mediaProps?.src} type="video/mp4" />
            </video>
            :
            <img
              {...mediaProps}
              crossOrigin={props.crossOrigin}
              onLoad={onLoad}
              className={clsx(classes.img, mediaProps.className)}
            />
        }
      </HideContainer>
    </span>
  );
}
