import React from "react";
// Customizable Area Start
import "./style.css";
import CreateAccountForm from './CreateAccounForm.web';
import AddInfoForm from './AddInfoForm.web';
import EmailVerificationDialog from "./EmailVerificationDialog.web";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import LoginTypeForm from "./LoginTypeForm.web";
import RegisterationLayout from "./RegisterationLayout.web";


class EmailAccountRegistration extends EmailAccountRegistrationController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.callGetValidationApi();
    // Customizable Area End
  }

  render() {
    const { step } = this.state;

    const signupForm = (
      <CreateAccountForm
        onSubmit={(values) => {
          //if (this.state.numberIsVerified)
            this.onCreateAccount(values);
        }}
        googleHandlers={{
          onSuccess: this.onGoogleLoginSuccess,
          onFailure: this.onGoogleLoginFailure
        }}
        microsoftAuthHandler={this.microsoftAuthHandler}
        loading={this.state.createAccountLoading}
        serverSideValidationsMessages={{
          user_name: this.state.inputValidations1?.user_name,
          email: this.state.inputValidations1?.email,
          full_phone_number: this.state.inputValidations1.full_phone_number,
          phoneOtpIsInvalid:'', // this.state.phoneOtpIsInvalid,
          otpError: '', //this.state.otpError,
        }}
        booleansState={{
          openOTPVerify: false, //this.state.openOTPVerify,
          otpVerifyLoading: false, //this.state.otpVerifyLoading,
          numberIsVerified: true, //this.state.numberIsVerified,
          otpSent: this.state.otpSent,
          otpSendLoading: this.state.otpSendLoading,
        }}
        onOtpClose={() => {
          this.setState({ openOTPVerify: false });
        }}
        onOtpSubmit={(otp: number) => {
          this.verifyOtpCode({ otp: otp as any });
        }}
        sendOtpCode={({ phone }: { phone: number }) => {
          this.sendOtpCode({ phone })
        }}
        otpSeconds={this.state.otpSeconds}
        openOTPVerify={() => {
            this.setState({ openOTPVerify: true })
        }}
        signInHandler={() => {
          this.onSigninSubmit();
        }}
      />
    );

    const addInfoForm = (
      <AddInfoForm
        onSubmit={(values) => {
          this.setState({
            step: this.state.step + 1,
            profilePictureFile: values.image,
            location: values.location,
            color: values.color
          });
        }}
        onSkip={() => {
          this.setState({
            isSkippedClicked: true,
            step: this.state.step + 1
          });
        }}
      />
    );

    const loginTypeForm = (
      <LoginTypeForm
        onSubmit={(values) => this.onFinishSetup(values)}
        onBackHandler={() => {
          this.setState({
            step: this.state.step - 1
          });
        }}
        loading={this.state.createAccountLoading2}
      />
    );

    return (
      <>

        <EmailVerificationDialog
          email={this.state.email}
          open={this.state.displayConfirmPopup}
          onClose={() => {
            this.setState({ displayConfirmPopup: false });
            this.props.navigation.navigate("EmailAccountLoginBlockWeb");
          }}
        />

        <RegisterationLayout>
          {
            step == 1 ?
              signupForm
              :
              step == 2 ?
                addInfoForm
                :
                step == 3 ?
                  loginTypeForm
                  :
                  null
          }
        </RegisterationLayout>
      </>
    );
  }
}



export default EmailAccountRegistration;
