import React, { useState } from 'react';
import {
  likeGray,
  starGray,
  shareGray,
  privateMarkIcon,
  inspireBlackIcon,
} from "../../../blocks/landingpage/src/assets";
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Design, LandingPageDesign, DesignHome } from '../api-types';
import { DEFAULT_PROFILE_IMAGE } from '../defaults';
import { useDecodedToken, useDesignShareLinks, useInspireDesign, useInspiredUsers, useIsLoggedIn, useIsUser, useNavigate } from '../hooks';
import { ShareDesignDialog } from '../common';
import { HorizontalSpacer, DezinerMedia, HideContainer, DezinerSpinner } from '../design-system';
import { SaveDesignDialog } from '../../../blocks/landingpage/src/common';


const useStyles = makeStyles({
  designCard: {
    borderRadius: "5%",
    cursor: "pointer",
    transition: "all 0.5 ease-in-out",
  },
  designCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "5%",
    position: "relative",
    "&:hover>div": { opacity: 1 },
  },
  media: {
    objectFit: "cover",
    maxWidth: "100%",
    height: "182px",
    borderRadius: "5%"
  },
  designCardOverlay: {
    borderRadius: "5%",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "#0000005e",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    opacity: 0,
    zIndex: 100,
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "white",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": { background: "#ddd" },
      "&  img": {
        maxWidth: "20px",
        width: "20px",
        height: "20px"
      },
    },
  },
  designCardDetails: {
    display: 'grid',
    gridTemplateColumns: '1.6rem 80px 58px 1fr 28px 28px',
    overflow: 'hidden',
    alignItems: "center", gridGap: "8px", paddingTop: "5px"
  },
  designCardIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&>div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
      "&>span": { padding: "0 4px", color: "#929292" },
    },
    "& img": { maxWidth: "1rem" }
  },
  priceTag: {
    background: "#1a1b1c",
    color: "white",
    borderRadius: "30px",
    fontSize: "0.9rem",
    marginLeft: "4px",
    padding: "1px 12px",
    textAlign: "center",
    whiteSpace: 'nowrap',
  },
  imageDescription: {
    fontSize: '1rem',
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '8rem',
    textTransform: 'capitalize',
  },
  iconsButtonsContainer: {
    minWidth: '70px'
  },
  addIcon: {
    color: '#929292'
  },
  overlayGrid: {
    height: '100%',
  },
  overlayContentGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 62px 64px",
    gridGap: "10px",
    alignItems: 'center',
  },
  accountProfileImg: {
    borderRadius: '40%',
    maxWidth: '100%',
    width: '25px',
    height: '25px',
    objectFit: 'cover'
  },
  accountName: {
    color: "#2A2B2D",
    fontWeight: "bold",
    fontSize: "1rem",
    maxWidth: "80px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: '1.2'
  },
  tagItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 4px"
  },
  groupTag: {
    color: "black",
    background: "white",
    border: "1px solid black",
  },
  clubTag: {
    color: "white",
    background: "black",
  },
  designerTag: {
    color: "black",
  },
  tagText: {
    fontSize: '0.8rem',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  detailsIconContainer: {
    justifyContent: "flex-end", flexWrap: "nowrap",
    '& img': {
      maxWidth: '14px',
    },
  },
  detailsIconText: {
    padding: "0 4px", color: "#929292"
  },
});


function ProfileAvatar({
  profilePicture,
  color,
  name,
}: {
  profilePicture?: string,
  color: string | null,
  name?: string,
}) {

  const classes = useStyles();

  if (profilePicture)
    return (
      <img
        src={profilePicture}
        alt={name}
        className={classes.accountProfileImg}
      />
    );

  if (color)
    return (
      <div
        className={classes.accountProfileImg}
        style={{ background: color }}
      />
    );

  return (
    <img
      src={DEFAULT_PROFILE_IMAGE}
      alt={name}
      className={classes.accountProfileImg}
    />
  );

}

type DesignItem = LandingPageDesign | Design | DesignHome;


export function DesignCardWithProfileContainer(props: {
  item: DesignItem,
  imgHeight: number,
  className?: string,
}) {
  const { item } = props;
  const isUser = useIsUser();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const [mouseEnter, setMouseEnter] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [saveDesignOpen, setSeaveDesignOpen] = useState(false);

  const { mutate: share } = useDesignShareLinks({ designId: item.attributes.id });

  const { mutate: inspireDesign, isLoading: inspireLoading } = useInspireDesign({ designId: item.attributes.id });
  const inspiredUsers = useInspiredUsers({ designId: item.attributes.id }, {
    enabled: isLoggedIn && mouseEnter,
  });

  const { id } = useDecodedToken();
  const isInspired = Boolean(inspiredUsers?.data?.data?.find(x => (x?.attributes?.id ?? -1) == id) ?? false);
  const isInspiredLoading = inspiredUsers.isLoading;

  function onClickHandler() {
    if (isLoggedIn)
      navigate(`DesignView/${item?.attributes?.id}`);
    else
      navigate(`EmailAccountLoginBlock`);
  }

  function nameClickHandler() {

    if (!isLoggedIn) {
      navigate(`EmailAccountLoginBlock`);
      return;
    }

    let accountType = "Designer";
    let accountId = 0;

    switch (item.type) {
      case "landing_design": {
        accountType = item?.attributes?.account_type;
        accountId = item?.attributes?.account_id ?? 0;
        break;
      }
      case "design_home": {
        accountType = item?.attributes?.account?.data?.attributes?.role?.name;
        accountId = item?.attributes?.account?.data?.attributes?.id;
        break;
      }
      case "design": {
        accountType = item?.attributes?.designer?.data?.attributes?.role;
        accountId = item?.attributes?.designer?.data?.attributes?.id;
        break;
      }
    }


    const isDesigner = accountType == 'Designer';
    const isUser = accountType == 'User';
    const isClub = accountType == 'Club';
    const isGroup = accountType == 'Group';

    if (accountId) {
      if (isDesigner)
        navigate(`DesignerHome/${accountId}`);
      else if (isUser)
        navigate(`userDesignList/${accountId}`);
      else if (isClub)
        navigate(`ClubHomePage/${accountId}`);
      else if (isGroup)
        navigate(`GroupHomePage/${accountId}`);
    }

  }

  function shareClickHandler() {
    setOpenShare(true);
    share({ designId: item.attributes.id });
  }

  return (
    <div
      onMouseEnter={() => { setMouseEnter(true); }}
    >

      <SaveDesignDialog
        open={saveDesignOpen}
        onClose={() => setSeaveDesignOpen(false)}
        designId={item?.attributes?.id}
        mediaProps={{
          id: item?.id,
          src: item?.attributes?.image_url,
          watermarkURL: item?.attributes?.watermark_url,
        }}
      />

      <ShareDesignDialog
        open={openShare}
        shareLink={`${location.origin}/DesignView/${item?.attributes?.id}`}
        onClose={() => setOpenShare(false)}
        onCopy={() => { }}
        mediaProps={{
          id: item?.id,
          src: item?.attributes?.image_url ?? '',
          mediaType: item?.attributes?.main_image_type?.includes('video') ? 'video' : 'image',
          watermarkURL: item?.attributes?.watermark_url,
          autoPlay: true
        }}
      />


      <DesignCardWithProfile
        {...props}
        onClick={onClickHandler}
        onShareClick={shareClickHandler}
        onSaveClick={() => { setSeaveDesignOpen(true); }}
        onInspireClick={(item) => {
          if (isLoggedIn)
            inspireDesign({ designId: item.attributes.id })
        }}
        inspireLoading={inspireLoading || isInspiredLoading}
        onAccountNameClick={nameClickHandler}
        hideSave={!isLoggedIn || !isUser}
        hideInspire={!isLoggedIn}
        isInspired={isInspired}
      />
    </div>
  );
}

export function DesignCardWithProfile(props: {
  item: DesignItem,
  onClick: (item: DesignItem) => void,
  onShareClick: (item: DesignItem) => void,
  onSaveClick: (item: DesignItem) => void,
  onInspireClick: (item: DesignItem) => void,
  inspireLoading: boolean,
  onAccountNameClick: (item: DesignItem) => void,

  className?: string,
  imgHeight: number,
  hideInspire?: boolean,
  hideShare?: boolean,
  hideSave?: boolean,
  isInspired?: boolean,
}) {

  const {
    item,
    onClick,
    onShareClick,
    onSaveClick,
    onInspireClick,
    onAccountNameClick,
    inspireLoading,
    ...restProps
  } = props;


  let sub_category_ids = '';
  let account_type = '';
  let account_name = '';
  let profilePicture = '';
  let profileColor = '';

  if (item.type == "landing_design") {

    sub_category_ids = item?.attributes?.sub_category_ids?.join(',') ?? '';
    account_type = item?.attributes?.account_type ?? '';
    account_name = item?.attributes?.account_name;
    profilePicture = item?.attributes?.account_profile;
    profileColor = item?.attributes?.account_color ?? '';

  } else if (item.type == "design") {

    account_name = item?.attributes?.designer?.data?.attributes?.first_name;
    profilePicture = item?.attributes?.designer?.data?.attributes?.profile_url;
    profileColor = item?.attributes?.designer?.data?.attributes?.color;

  } else if (item.type == "design_home") {
    account_name = item?.attributes?.account?.data?.attributes?.first_name;
    profilePicture = item?.attributes?.account?.data?.attributes?.profile_url;
    profileColor = item?.attributes?.account?.data?.attributes?.color ?? '';
  }



  return (
    <DesignCardWithProfileBase
      {...restProps}
      id={item?.id}
      onClick={() => onClick(item)}
      onShareClick={() => onShareClick(item)}
      onSaveClick={() => onSaveClick(item)}
      onInspireClick={() => onInspireClick(item)}
      inspireLoading={inspireLoading}
      onAccountNameClick={() => onAccountNameClick(item)}
      title={item?.attributes?.title}
      subCategoryIds={sub_category_ids}
      accountType={account_type}
      mainImageType={item?.attributes?.main_image_type?.includes('video') ? 'video' : 'image'}
      imageUrl={item?.attributes?.image_url}
      watermarkUrl={item?.attributes?.watermark_url ?? ''}
      privacy={item?.attributes?.privacy ?? ''}
      price={item?.attributes?.price ?? 0}
      profilePicture={profilePicture}
      profileColor={profileColor}
      profileName={account_name}
      likeCount={item?.attributes?.like_count}
      ratingCount={item?.attributes?.rating_count}
    />
  );
}

function DesignCardWithProfileBase({
  id,
  onClick,
  onShareClick,
  onInspireClick,
  inspireLoading,
  onSaveClick,
  imgHeight,
  onAccountNameClick,
  hideInspire,
  hideShare,
  hideSave,
  isInspired,

  title,
  subCategoryIds,
  accountType,
  mainImageType,
  imageUrl,
  watermarkUrl,
  privacy,
  profilePicture,
  profileColor,
  profileName,
  ratingCount,
  likeCount,
  price,

  className,
}: {
  id: string,
  onClick: () => void,
  onShareClick: () => void,
  onSaveClick: () => void,
  onInspireClick: () => void,
  inspireLoading: boolean,
  onAccountNameClick: () => void,
  imgHeight: number,
  hideInspire?: boolean,
  hideShare?: boolean,
  hideSave?: boolean,
  isInspired?: boolean,
  title: string,
  subCategoryIds: string,
  accountType: string,
  mainImageType: string,
  imageUrl: string,
  watermarkUrl: string,
  privacy: string,
  profilePicture: string,
  profileColor: string,
  profileName: string,
  ratingCount: number,
  likeCount: number,
  price: number,
  className?: string,
}) {


  const classes = useStyles();

  return (
    <div
      className={clsx(classes.designCard, className)}
      onClick={() => onClick()}
      data-testid="design-card-with-profile"
      tabIndex={0}
      aria-label={title}
      role="button"
      data-categories={subCategoryIds}
      data-account-type={accountType}
    >
      <div className={classes.designCardContent}>

        <DezinerMedia
          id={id}
          mediaType={mainImageType as any}
          style={{ height: `${imgHeight}px` }}
          src={imageUrl}
          className={clsx(classes.media, "deziner-design-home-img")}
          watermarkURL={watermarkUrl}
          watermarkWidth={100}
          watermarkHeight={100}
        />

        <div className={classes.designCardOverlay}>

          <Grid
            container
            alignItems='stretch'
            justifyContent='space-between'
            direction='column'
            className={classes.overlayGrid}
            wrap="nowrap"
          >
            <Grid container justifyContent='space-between'>
              {
                privacy == "Private" ?
                  <img src={privateMarkIcon} alt="private" style={{ width: '20px', height: '20px' }} />
                  :
                  <span />
              }
              <HideContainer hideWhen={hideInspire}>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onInspireClick();
                  }}
                >
                  {
                    inspireLoading ?
                      <DezinerSpinner noHeight size={14} />
                      :
                      <img
                        src={inspireBlackIcon}
                        alt="inspire"
                        className={clsx({
                          'black-svg': isInspired,
                          'gray-svg': !isInspired,
                        })} />
                  }
                </button>
              </HideContainer>
            </Grid>

            <div className={classes.overlayContentGrid}>
              <Typography variant='caption' className={classes.imageDescription}>
                {title}
              </Typography>

              {
                privacy === "Private" ?
                  <Typography variant="caption" className={classes.priceTag}>
                    {`₹ ${price}`}
                  </Typography>
                  :
                  <span />
              }
              <Grid container justifyContent="flex-end">
                {
                  hideShare ? null :
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        onShareClick();
                      }}
                    >
                      <img src={shareGray} alt="share" />
                    </button>
                }
                <HorizontalSpacer value={4} />
                {
                  hideSave ? null :
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        onSaveClick();
                      }}
                    >
                      <AddIcon className={classes.addIcon} />
                    </button>
                }
              </Grid>
            </div>

          </Grid>

        </div>

      </div>


      <div className={classes.designCardDetails}>

        <ProfileAvatar
          profilePicture={profilePicture}
          color={profileColor}
          name={profileName}
        />

        <Typography
          className={classes.accountName}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onAccountNameClick();
          }}
        >
          {profileName}
        </Typography>

        <div className={clsx(classes.tagItem, {
          [classes.clubTag]: accountType?.toLowerCase() == 'club',
          [classes.groupTag]: accountType?.toLowerCase() == 'group',
        })}>
          <Typography className={classes.tagText}>
            {accountType}
          </Typography>
        </div>

        <span aria-describedby='spacer' />

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="rating" src={starGray} />
          <Typography className={classes.detailsIconText}> {ratingCount} </Typography>
        </Grid>

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="liked" src={likeGray} />
          <Typography className={classes.detailsIconText}> {likeCount} </Typography>
        </Grid>

      </div>
    </div>
  );
}


