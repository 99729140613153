export const forumsKeys = {
  fetchForums: () => ['forums'] as const,
  createForum: () => ['create-forum'] as const,

  likeForum: (params: { id: number }) => ['like-forum', params] as const,
  dislikeForum: (params: { id: number }) => ['dislike-forum', params] as const,

  fetchSingleForum: (params: { id: number }) => ['single-forum', params] as const,
  fetchSingleForumComments: (params: { id: number }) => ['single-forum-comment', params] as const,

  postCommentForum: (params: { forumId: number }) => ['comment-forum', params] as const,

}
