export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const UserBackground = require('../assets/UserBackground.png');
export const camera = require("../assets/camera.png");
export const close = require("../assets/close.svg");
export const darklogo = require("../assets/darkLogo.png");
export const col = require("../assets/col.png");
export const designs = require("../assets/designs.png");
export const inspire = require("../assets/inspire.png");
export const promote = require("../assets/promote.png");
export const wishlist = require("../assets/wishlist.png");
export const cameraPlusIcon = require("../assets/camera-plus.svg");
export const searchIcon = require("../assets/search.svg");
export const infoIcon = require("../assets/info.svg");
