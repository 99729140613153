import React from 'react';
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";





export interface Props {
  navigation: any;
  id: string;
}

interface S { }

interface SS { }

export default class DesignerDesignEditPageController extends BlockComponent<
  Props,
  S,
  SS
  >  {



  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);


    this.state = {}

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

}
