import React, { useState } from "react";
import DesignerGroupManageController, {
  Props
} from "./DesignerGroupManageController.web";
import Grid from "@material-ui/core/Grid";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares from "./MembersContributionTable.web";
import DesignerProfileWithOptions from "./DesignerProfileWithOptions.web";
import AddIcon from '@material-ui/icons/Add';
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";
import { useParams } from "../../../components/src/utils";
import { useAddGroupMemberAsAdmin, useGroupsMembers, useGroupsNonRespondingMembers, useRemoveGroupMember, useRemoveGroupMemberAdminPermission, useSingleGroup, useAcceptGroupMemberInvitation, useDeclineGroupMemberInvitation } from "../../../components/src/hooks/groups";
import { useDecodedToken, useNavigate } from "../../../components/src/hooks";
import { DeleteMemberDialog } from "./DeleteMemberDialog.web";
import { HideContainer, DezinerSpinner, DezinerButton } from "../../../components/src/design-system";
import MemberInvitationPopup from "./MemberInvitation";
import { defaultToEmptyArray, defaultToEmptyString } from "../../../components/src/default-values";
import { GroupMember } from "../../../components/src/api-types";
import { GroupClubNonRespondingMembers } from './non-responding-members';


class DesignerGroupManage extends DesignerGroupManageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (<DesignerGroupManageContent />);
  }
}




function DesignerGroupManageContent() {

  const [currentTab, setCurrentTab] = useState(0);
  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [deletedMember, setDeleteMember] = useState({ deleteType: '', memberId: 0, memberName: '' });

  const [approveCondition, setApprovedCondition] = useState(false);

  const params = useParams();
  const groupId = Number(params.groupId);

  const membersQuery = useGroupsMembers({ groupId });
  const members = defaultToEmptyArray<GroupMember>(membersQuery?.data?.data);

  const groupQuery = useSingleGroup({ groupId });

  const { id: myProfileId } = useDecodedToken();

  const nonRespondingMembersQuery = useGroupsNonRespondingMembers({ groupId });
  const nonRespondingMembers = defaultToEmptyArray<GroupMember>(nonRespondingMembersQuery?.data?.data);

  const allMembers = members?.concat(nonRespondingMembers);

  const myMember = allMembers.find(member => member.attributes.account_id == myProfileId);

  const currentRole = defaultToEmptyString(myMember?.attributes?.role);
  const isAdmin = currentRole == "Admin";
  const isOwner = currentRole == "Owner";

  const navigate = useNavigate();

  const adminName = allMembers?.find((member) => member.attributes?.role == "Admin")?.attributes?.first_name;

  const { mutate: removeAdminPermission } = useRemoveGroupMemberAdminPermission({ groupId });
  const { mutate: removeMember } = useRemoveGroupMember({ groupId });

  const { mutate: acceptInvitation, isLoading: acceptLoading } = useAcceptGroupMemberInvitation({ groupId });
  const { mutate: declineInvitation, isLoading: declineLoading } = useDeclineGroupMemberInvitation({ groupId });

  const showInvitation = myMember && !membersQuery.isLoading && !myMember?.attributes?.accepted && !myMember?.attributes?.declined;


  if (membersQuery.isLoading)
    return <DezinerSpinner />


  return (
    <>


      <DeleteMemberDialog
        deleteType={deletedMember.deleteType as any || "remove_member"}
        open={deleteMemberOpen}
        onClose={() => { setDeleteMemberOpen(false); }}
        memberName={deletedMember.memberName}
        onAccept={() => {

          const { deleteType, memberId } = deletedMember;

          if (deleteType == "remove_as_admin")
            removeAdminPermission({ memberId, groupId });
          else if (deleteType == "remove_member")
            removeMember({ memberId, groupId });

          setDeleteMemberOpen(false);
          setDeleteMember({ deleteType: '', memberId: 0, memberName: '' });
        }}
      />




      <div style={{ padding: '50px 0' }}>
        <Grid container>
          <Grid item sm={2}></Grid>
          <Grid item xs={12} sm>


            <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
              style={{
                borderBottom: '1px solid #ddd'
              }}
            >
              <GroupAndClubHorizontalCard
                item={groupQuery?.data?.data}
                hideButton
                hideMembers
                hideBorder
              />
              <DezinerTabs
                value={currentTab}
                onChange={(_e, tab) => setCurrentTab(tab)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                <DezinerTab label="Member" {...a11yProps(0)} />
                <DezinerTab label="Role" {...a11yProps(1)} />
              </DezinerTabs>
            </Grid>

            <DezinerTabPanel value={currentTab} index={0}>

              <Members
                onDelete={(params) => {
                  setDeleteMember(params);
                  setDeleteMemberOpen(true);
                }}
              />


              <Grid container justifyContent="center">
                <HideContainer hideWhen={!(isAdmin || isOwner)}>
                  <DezinerButton
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate(`AddGroupMember/${groupId}`)}
                    startIcon={
                      <AddIcon />
                    }
                  >
                    {'Add Member'}
                  </DezinerButton>
                </HideContainer>
              </Grid>

              <GroupClubNonRespondingMembers />


            </DezinerTabPanel>
            <DezinerTabPanel value={currentTab} index={1}>

              <MembersContributionTableWithShares
                members={members}
                onDelete={({ memberName, memberId }) => {
                  setDeleteMember({
                    memberName,
                    memberId,
                    deleteType: "remove_member",
                  })
                  setDeleteMemberOpen(true);
                }}
                onAdd={() => navigate(`AddGroupMember/${groupId}`)}
                onSave={(revenu) => {
                  console.log({ revenu });
                }}
                currentRole={currentRole}
                workContributionHeaderTitle="Reveneu %"
              />

              <GroupClubNonRespondingMembers />

            </DezinerTabPanel>

          </Grid>
          <Grid item sm={2}></Grid>
        </Grid>
      </div>


      <HideContainer hideWhen={!showInvitation}>
        <MemberInvitationPopup
          ownerName={defaultToEmptyString(adminName)}
          contribution={myMember?.attributes?.contribution || "0%"}
          conditionCheck={approveCondition}
          changeConditionCheck={(e: any) => setApprovedCondition(e.target.checked)}
          onAccept={() => acceptInvitation({ groupId, accountId: myProfileId })}
          onDecline={() => declineInvitation({ groupId, accountId: myProfileId }, {
            onSuccess: () => navigate('DesignerGroupPageView')
          })}
          orgType={'Group'}
          orgName={defaultToEmptyString(groupQuery?.data?.data?.attributes.name)}
          role={myMember?.attributes?.role || ''}
          currentTab={currentTab}
          acceptLoading={acceptLoading}
          declineLoading={declineLoading}
        />
      </HideContainer>

    </>
  );

}




function Members({
  onDelete,
}: {
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
}) {

  const params = useParams();
  const groupId = Number(params.groupId);

  const membersQuery = useGroupsMembers({ groupId });
  const members = defaultToEmptyArray<GroupMember>(membersQuery?.data?.data);

  const { id: myProfileId } = useDecodedToken();

  const nonRespondingMembersQuery = useGroupsNonRespondingMembers({ groupId });
  const nonRespondingMembers = defaultToEmptyArray<GroupMember>(nonRespondingMembersQuery?.data?.data);

  const allMembers = members?.concat(nonRespondingMembers);

  const myMember = allMembers.find(member => member.attributes.account_id == myProfileId);

  const currentRole = defaultToEmptyString(myMember?.attributes?.role);

  const { mutate: addAsAdmin } = useAddGroupMemberAsAdmin({ groupId });


  return (
    <Grid container style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))' }}>
      {
        members.map((member) => (
          <div key={member?.id} style={{ padding: '30px 0', }}>
            <DesignerProfileWithOptions
              currentRole={currentRole}
              member={member}
              onDelete={onDelete}
              addAsAdmin={({ memberId }) => {
                addAsAdmin({ memberId, groupId });
              }}
            />
          </div>
        ))
      }
    </Grid>
  );
}



export default DesignerGroupManage;
