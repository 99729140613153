import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClubMember, GroupMember, Profile } from '../api-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { VerticalSpacer } from '../design-system';
import { useNavigate } from '../hooks';
import { DEFAULT_PROFILE_IMAGE } from '../defaults';

const useGroupListStyles = makeStyles({
  groupContainer: {
    padding: '10px',
    marginBottom: '10px',
  },
  groupItemTitle: {
    color: '#444444',
    fontWeight: 600,
    cursor: 'pointer',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  profileImg: {
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px'
  },
});

type GroupOrClubListData = (
  Profile['attributes']['groups']['data'][number]
  |
  Profile['attributes']['clubs']['data'][number]
  |
  GroupMember
  |
  ClubMember
)[];

export function GroupOrClubList({
  groups,
  title,
}: {
  groups: GroupOrClubListData,
  title: string,
}) {

  const classes = useGroupListStyles();
  const navigate = useNavigate();


  function clickHandler(item: GroupOrClubListData[0]) {
    const isClub = item.type == 'club_info';
    const isGroup = item.type == 'group_info';
    const isGroupMember = item.type == 'account_group';
    const isClubMember = item.type == 'account_club';

    if (isGroupMember || isClubMember)
      navigate(`DesignerHome/${item.id}`);

    if (isClub)
      navigate(`ClubHomePage/${item.id}`);

    if (isGroup)
      navigate(`GroupHomePage/${item.id}`);
  }

  return (
    <div className={classes.groupContainer}>
      <Typography variant="body1" className={classes.sectionTitle}>
        {title}
      </Typography>

      <VerticalSpacer />

      <Grid container spacing={2}>
        {
          groups?.map((item) => {
            let name = '', src = DEFAULT_PROFILE_IMAGE;

            if (item.type == 'account_group' || item.type == 'account_club') {
              name = item.attributes.first_name;
              src = item.attributes.profile_url;
            } else {
              name = item.attributes.name;
              src = item.attributes.image_url;
            }


            return (
              <Grid key={name} item xs={6} container alignItems="center" wrap="nowrap">
                <img
                  onClick={() => clickHandler(item)}
                  src={src}
                  alt={name}
                  className={classes.profileImg} />
                <Typography
                  onClick={() => clickHandler(item)}
                  variant="body2"
                  className={classes.groupItemTitle}>
                  {name}
                </Typography>
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}

