import React from 'react';
import { useFloating, shift, offset } from '@floating-ui/react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import {HideContainer} from '../../../components/src/design-system';
import EmojiPicker from './EmojiPicker';



export default function ChatEmojiButton({
  open,
  setOpen,
  onChange,
}: {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onChange: (text: string) => void,
}) {
  let { x, y, reference, floating, strategy } = useFloating({
    strategy: 'fixed',
    placement: 'top-start',
    open,
    onOpenChange: setOpen,
    middleware: [shift(), offset({ mainAxis: 10, crossAxis: -20 })],
  });

  x = x || 0;
  y = y || 0;

  return (
    <>
      <HideContainer hideWhen={!open}>
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y,
            left: x,
          }}
        >
          <EmojiPicker onChange={onChange} />
        </div>
      </HideContainer>

      <InputAdornment position="end" style={{ position: 'absolute', bottom: 24, left: 2 }}>
        <IconButton
          aria-label="emoji-picker"
          onClick={() => setOpen(x => !x)}
          ref={reference}
          size="small">
          <InsertEmoticonIcon />
        </IconButton>
      </InputAdornment>
    </>
  );
}
