
import { Message } from "../../../framework/src/Message";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const skillsMessages = {
  fetchSkills() {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: configJSON.skillsAPI,
    });
  },
}
