import React  from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';


const useCommentRecordingIconStyles = makeStyles({
  recordIconBtn: {
    position: 'relative'
  },
  recordIcon: {
    color: 'red',
  },
  pulse: {
    margin: "0px",
    display: "block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: "rgba(221, 15, 15, 0.6)",
    cursor: "pointer",
    boxShadow: "0 0 0 rgba(221, 15, 15, 0.6)",
    animation: "pulse 2s infinite",
    position: 'absolute',
    top: 0,
    right: '2px'
  },
});

export function CommentRecordingIcon({
  onClick,
  hide,
  isRecording,
}: {
  onClick: () => void,
  hide?: boolean,
  isRecording: boolean,
}) {
  const classes = useCommentRecordingIconStyles();

  if (hide)
    return null;

  return (
    <IconButton data-testid="record-btn" onClick={onClick} size="small" className={classes.recordIconBtn}>
      {
        isRecording ?
          <>
            <MicIcon className={classes.recordIcon} fontSize="small" />
            <span className={classes.pulse}></span>
          </>
          :
          <MicNoneIcon fontSize="small" />
      }
    </IconButton>
  );

}

