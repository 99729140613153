import React, { ComponentProps, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { useSaveDesign, useMyUserCollections } from '../../../../components/src/hooks';
import {
  DezinerButton,
  DezinerAlert,
  DezinerMedia,
  AutocompleteDropdown,
  AutocompleteSelectorItem,
  HideContainer,
} from '../../../../components/src/design-system';
import CreateUserCollectionForm from '../user/design-list/CreateUserCollectionForm';
import { defaultToEmptyArray } from '../../../../components/src/default-values';
import { CollectionHome } from '../../../../components/src/api-types';


const useDialogStyles = makeStyles({
  paper: {
    borderRadius: '10px',
    overflow: 'inherit',
  }
});


const useStyles = makeStyles({
  imageContainer: {
    background: "#e7e7e7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  innerImageContainer: {
    maxWidth: "80%",
    position: "relative",
    top: "-50px",
  },
  image: {
    maxHeight: "320px",
    borderRadius: "10px",
    boxShadow: "4px 4px 4px #5e5e5e"
  },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "20px 0",
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: '10px 0',
    }
  },
  saveBtn: {
    alignSelf: 'flex-end',
  },
  closeBtn: {
    position: 'fixed',
    top: '3vh',
    right: '3vw',
  },
  closeIcon: {
    color: 'white'
  },
  titleText: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export function SaveDesignDialog({
  open,
  designId,
  onClose,
  mediaProps,
}: {
  open: boolean,
  designId: number,
  onClose: () => void,
  mediaProps: ComponentProps<typeof DezinerMedia>
}) {

  const classes = useStyles();
  const dialogClasses = useDialogStyles();


  const collectionsQuery = useMyUserCollections();
  const collections = defaultToEmptyArray<CollectionHome>(collectionsQuery?.data?.data);

  const [selectCollection, setSelectedCollection] = useState<AutocompleteSelectorItem | undefined>(undefined);
  const [createCollectionFormOpen, setCreateCollectionFormOpen] = useState(false);

  const { mutate: saveDesign, isLoading: saveLoading } = useSaveDesign({ designId });

  const [saveError, setSaveError] = useState('');
  const [saveDone, setSaveDone] = useState(false);

  function reset() {
    setSaveDone(false);
    setSaveError('');
    setCreateCollectionFormOpen(false);
    setSelectedCollection(undefined);
  }


  function onSaveSuccess() {
    setSaveDone(true);
    setTimeout(() => {
      onClose();
      reset();
    }, 1000);
  }

  function onSaveError(error: any) {
    setSaveError(error?.[0]?.message ?? 'Save Failed');
    console.log('hi here', error?.[0]?.message ?? 'Save Failed');
  }

  const collectionsOptions = collections?.map((option) => ({
    value: option.attributes.id,
    label: option.attributes.name
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      classes={dialogClasses}
    >

      <div className={classes.imageContainer}>
        <DezinerMedia
          className={classes.image}
          containerClassName={classes.innerImageContainer}
          {...mediaProps}
        />
      </div>

      <IconButton className={classes.closeBtn} onClick={onClose}>
        <CloseIcon className={classes.closeIcon} fontSize="large" />
      </IconButton>

      <DialogContent className={classes.contentContainer}>


        <Typography className={classes.titleText}>
          {'Add this design to a collection'}
        </Typography>


        <AutocompleteDropdown
          options={collectionsOptions}
          placeholder="Select collection"
          inputValue={selectCollection}
          onChange={setSelectedCollection}
        />

        <HideContainer hideWhen={!saveError}>
          <DezinerAlert>
            {saveError}
          </DezinerAlert>
        </HideContainer>

        <DezinerButton
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          onClick={() => {
            if (selectCollection?.value) {
              setSaveError('');
              saveDesign({
                designId,
                collectionId: Number(selectCollection?.value)
              }, {
                onSuccess: onSaveSuccess,
                onError: onSaveError,
              });
            } else
              setSaveError('You need to select a collection name first');
          }}
          loading={saveLoading && !createCollectionFormOpen}
        >
          {'Save'}
        </DezinerButton>

        <Divider />

        {
          createCollectionFormOpen ?
            <CreateUserCollectionForm
              loading={saveLoading}
              createButtonText="Create and Save"
              onSuccess={(response) => {
                saveDesign({ collectionId: response?.data?.attributes?.id, designId }, {
                  onSuccess: onSaveSuccess,
                  onError: onSaveError,
                });
              }}
            />
            :
            <DezinerButton
              disabled={saveLoading}
              variant="contained"
              color="default"
              style={{ alignSelf: 'flex-end' }}
              onClick={() => { setCreateCollectionFormOpen(true) }}>
              {'Create new collection'}
            </DezinerButton>
        }

        <HideContainer hideWhen={!saveDone}>
          <DezinerAlert severity="success">
            {'Design Saved successfully'}
          </DezinerAlert>
        </HideContainer>

      </DialogContent>

    </Dialog>
  );
}

