import { DezinerActionCreator } from "../store";

export const NAVIGATE_TO = 'NAVIGATE_TO';

export interface NavigateToAction {
  type: typeof NAVIGATE_TO,
  url: string,
}

export const navigateTo: DezinerActionCreator<NavigateToAction> =
  (params) => ({
    type: NAVIGATE_TO,
    ...params,
  });
