export const FAQData: {
  [key: string]: { title: string, description: string }[]
} = {
  'The basics': [
    {
      title: "How Deziner's Knot works for a designer",
      description: `

The platform allows artists, designers and illustrators to delve into their creative 
selves and come up with exquisite designs. 

It does not just make a place for masters in designing but also encourages 
pioneers to set a mark in the designing industry. Designers can post, discuss, share 
and sell their fine collections through Deziner’s Knot. 

        `,
    },
  ],
  'Your Account': [
    {
      title: "Creating a designer account",
      description: `
-   Once you visit the Deziner’s Knot website, you will find the Sign In | Designer option at the top left corner of the page. Click on it and a Sign In column appears.
    
-   For a new user, click on Sign Up Now option at the bottom next to “Not a member?”.
    
-   A Sign Up column appears. You can either create account with your existing Google or Microsoft account or fill details in the fields (Name, Username, Email, Phone and Password). Remember to agree to the Terms of Service and Privacy Policy by ticking the checkbox before clicking on the Create Account button.

Your account will be registered in Deziner’s Knot and you will be redirected to set up your profile as a designer.

`,
    },
    {
      title: "Signing in as a designer",
      description: `
-   Click on Sign In | Designer at the top left of the Deziner’s Knot’s home page.
    
-   In the Sign In column that appears, you can choose to log in to your account either via Google or Microsoft accounts or by entering your registered Email and Password.
    
-   Another way of logging in is entering your registered mobile number and clicking on Get OTP. Now, enter the OTP received and click on Sign In before the validity of the OTP expires.
    

You will be signed in and will be taken to the Home Page of your designer account.
`,
    },
    {
      title: "How do I reset password?",
      description: `

If you do not remember your password for Deziner’s Knot, you can follow the steps below:

-   In the Sign in page, click on Forgot Password option  below the Email and Password fields. Enter the mail ID that you had registered with Deziner’s Knot before hitting the Send reset Instructions button.
    
-   You will be mailed a link for resetting your password. Click on the link to enter a new password and confirm it so that you can use that password for logging in to your account.
    

If you would like to change your existing password while you are signed-in, do as follows:

-   Go to your profile and you will find the Password section next to Edit Profile, Account Settings, Group and Club.
    
-   Fill in the fields with your Old Password and your New Password and click on Change.
    
`,
    },
    {
      title: "How do I set up my profile?",
      description: `
-   Once you create your own designer account in Deziner’s Knot, a page for setting up your profile appears.
    
-   You can Add an Avatar,  Choose an Image and Add your Location. Click Next to choose whom you are logging in as, a designer or a user. After choosing, press Finish.
`,
    },
    {
      title: "Navigating the Home Page of a designer",
      description: `
The Home Page of a designer has the following sections and features:

-   The top center of the page will display – your name, image and title with your followers and following count below.
    
-   Each icon below your image is a separate tab.

    1.  Collections – view, create or add collections of designs
        
    2.  Designs – high rated, recent and popular designs appear here
        
    3.  Liked designs – displays designs that other users have liked
        
    4.  Promoted designs – your designs that have been promoted
        
    5.  Inspired designs – designs that other users have marked as inspirations
        
    6.  Rated designs – designs that have been given rating by the users
        
    7.  Free designs – designs that are posted for free downloading by users
        
    8.  Paid designs – designs that are available for paid purchase
        

-   Below these icons, you can find the ‘+’ mark. On clicking you can create new collections and assign names. All the names of your created collections are displayed along.
    

-   The cover image is displayed in the background of all the aforementioned features.
    
-   The designs as categorised under each collection appear.
    
-   The top right corner of the page has the Search, Notification and Chat features and your profile where you can configure your account settings.
`,
    },
    {
      title: " Where can I edit my profile?",
      description: `
-   From the home page of your designer account, go to your profile at the top right corner of the page. Below your name, tabs like Edit Profile, Account Settings, Group Club, Password, Social Profiles and Log out will be shown.
    
-   Go to Edit Profile where you can update your Profile Image, Name, Location and Bio. Click on Save Profile button after the desired changes are done.
`,
    },
    {
      title: "Where can I see the notifications?",
      description: `
The notification icon ‘ ![](https://lh3.googleusercontent.com/xXEEPNlLhcSA8MDvdbz98wy-lDQJx2BQqLBhlu3UKm3im3Bb6mWG9fBeJ64Ly0NCOEyyfaUjkQ-wtxqQ4yTpzcTu3w9FgfP25vGyNVP4sVRc8jEBQwqwVNyoqpNeU2o8rBp1wPzhJYQtvbWbKmYbaTw9nJJ-uvm8ArEphW5uQDR4yUi3SXrkzWmS-HLzeFYv-n6MMQ) ’ is present at the top right corner of the home page next to the Search icon. Click to view any notifications regarding posting, selling, getting hired and so on.
`,
    },
    {
      title: "How do I upload my social profile details?",
      description: `

-   Go to your profile from your designer account’s home page.
    
-   Click on the Social Profiles tab that is present next to the following tabs– Edit profile, Account Settings, Group, Club and Password.
    
-   You will find fields named Microsoft, Facebook, Twitter and Instagram. Fill in details or IDs of the respective accounts and click Update Social Profiles.
    
`,
    },
    {
      title: "How to log out of my designer account?",
      description: `
-   Go to your profile from your designer account’s home page.
    
-   Click on the Log out tab that is present next to the following tabs– Edit profile, Account Settings, Group, Club, Password and Social Profiles.
    

You will be logged out of your account.
`,
    },
    {
      title: "How do I update my account settings?",
      description: `
-   Go to your profile from the home page of your account.
    
-   Click on the Account Settings tab next to the Edit profile tab below your name.
    
-   You can update your Username and Email ID by filling in the respective fields.
    
-   Hit the Save Changes button.
    
`,
    },
    {
      title: "Closing my designer’s account",
      description: `
-   Go to your profile from the home page of your account.
    
-   Click on the Account Settings tab next to the Edit profile tab below your name.
    
-   Below the Username and Password fields, click on the Close Account button to delete all your account data and exit Deziner’s Knot.
`,
    },
  ],
  'Posting': [
    {
      title: "How do I upload designs?",
      description: `
From your designer account’s home page, you can upload designs to an already existing collection or create a new collection and upload designs to it.

-   Click on the ‘+’ mark below a collection and a page for publishing your designs appears.
    
-   Drag and drop your design file or browse from your device in the box with the words Drag and drop an image.
    
-   Enter a title for your design. Type in tags that could be used to find your design.
    
-   Choose whether your design has to be kept private or shown in public.
    
-   Quote a price for your design.
    
-   Give a short description about your design.
    
-   Under Settings, you can set that design as collection thumbnail by ticking the checkbox.
    
-   Choose a watermark and click on the Publish button.
    
        `
    },
    {
      title: " How to create collections?",
      description: `
To create a new collection, click on the ‘+’ mark below your name from your designer account’s home page. A dialog pops up for creating a label. Assign a name to your collection based on the type of designs it is going to take in and click Create. A new collection in the specified name will be created.
        `
    },
    {
      title: "How do I view my posted designs?",
      description: `
The collections will appear in the names that you created below your name. To view a design classified under a collection, click on a collection name and it will show you all the designs. Click on the design that you would like to view.

-   The posted design will be displayed at the centre.
    
-   To the left of the image, you will have Share option.
    
-   Below the design, the Edit and Delete buttons are present followed by the number of views, likes, comments, inspirations and its rating.
    
-   The lower section of the design contains a textbox for typing a new comment and other comments received for the design.
    
        `
    },
    {
      title: "How do I keep my collections private?",
      description: `
From your home page of your designer’s account, click on a collection’s name below your name and the designs that are posted in the collection will be displayed.

You will find a checkbox with the title ‘Keep this collection private’. By ticking, you can change the view status of the selected collection from public to private.
        `
    },
  ],
  'Promotions': [
    {
      title: "How do I promote my design?",
      description: `

-   When you view a design from a collection, you can see the Promote symbol to the left of a design.
    
-   On clicking, a window for Budget and Duration appears. Set the impressions and duration to the desired levels and the promotion amount will be displayed.
    
-   Click next to review your Boost in which Button link, Total views and Total price will be shown.
    
-   Click next to see the payment details. Enter your card details and click Finish.
    

The promotion process for your design is done.
        `,
    },
  ],
  'Features': [
    {
      title: "How can I use the chat feature?",
      description: `
The Chat feature is present at the top right corner of the designer’s home page. Click on the ‘ ![](https://lh3.googleusercontent.com/3rrHK2uoAS9Hh-MIli13wjodjSJPijbScBfRY7JLYLnxjVhdNQcvBaPmvlmPMvjQIfx7YqcYOaowmQKRA7YAbYr9rWpEDtiwtYwR4nUAZq3h2bABFPP8kj1FOqCrwKghTczJQrxh4fH44hMfVQtLMa3pcbbVHat9R-IAtR9mKsXhGBfsLEUySRcAiNivlqGMqOYxdA) ‘ icon and a chat window similar to that in mail opens. It shows the list of people whom you had conversations with and also their statuses as online or offline. On clicking a person’s name, a separate chat window opens in which you can start discussions.
        `,
    },
    {
      title: "Search feature",
      description: `
The Search icon ‘ ![](https://lh5.googleusercontent.com/R1DXNwIrGS-mEUm4qO0d4rGhdS3GqVQDklE4zqaPp73wJ8gKxBXibNxsolEqzlWJjJ0RCODVmqw76wAErh6c7mRzrr7v4jZx6ecaxLvs1K_AlbQrCAnwp072DDtwwNuftE2DxHq9-eWIiNFC9Nu7QhenEBV7B3egfqvHkwdIIhsCGNu6ZmVfMliv6o3XfOdreR9cvQ) ‘ is present at the top right corner of the page. Click on it and a seach box appears. Enter keywords based on your search criteria, say, “pattern design for t-shirts” and the corresponding results will be displayed.
        `,
    },
    {
      title: "Sort and filter features",
      description: `
Once you search for designs and get the results, you can find the Sort and Filter features.

-   Using the sort feature, you can organize your designs based on alphabetical order or bring the most recent or popular designs forward.
    

-   Using the filter feature, you can pick out the required designs based on certain criteria such as colour, title or the type of designs such as web design logo design, etc.
    
        `,
    },
  ],
  'VI Groups': [
    {
      title: "How do I create a group?",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Groups tab next to Edit profile and Account Settings.
    
-   You will find the Create Group option. By clicking, a window opens where you will be required to fill in fields like name and description. You can add categories and group members by clicking the Add buttons. The group members will be notified and they will have to agree to the terms and conditions of the group before becoming a member. Also, choose the Group Admin from the list of group members.
    
-   Click on the Create button. The group will be created successfully and will appear under Groups tab in your profile.
    
        `,
    },
    {
      title: "Navigating Home Page of a group",
      description: `
The Home Page of a group is similar to the home page of an individual designer.

-   The name of the group and its profile image are displayed along with its followers and following counts.
    
-   Followed by the Liked, Inspired, Rated, Free and Paid designs, the collections are present.
    
-   The cover image set for the group appears in the background.
    
-   The Members option is present to the right of collection names.
    
-   Each collection is displayed with its label and designs.
    
-   The top of the page has features like Chat, Notifications, Search and a dropdown for Profile.
        `,
    },
    {
      title: "Viewing the members of a group",
      description: `
-   Go to the group profile from your home page and you can find the Members option next to the collections.
    
-   Click on it and a window appears with the list of members.
    
-   The Group Admin for the group is highlighted.
    
-   You can add new members to the group with the Add a member option.
        `,
    },
    {
      title: "How do I manage groups and their members?",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Groups tab next to Edit profile and Account Settings. The groups in which you are a member will be displayed.
    
-   The Manage Group option is present alongside each group name.
    
-   If you would like to make changes to a particular group, click on the Manage Group option and its members’ names will appear.
    
-   Place the curser on a designer’s image and you will have options to add/remove a member as Admin and add/remove a designer as a member.
        `,
    },
    {
      title: "Updating revenues of group members",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Groups tab next to Edit profile and Account Settings. The groups in which you are a member will be displayed.
    
-   The Manage Group option is present alongside each group name.
    
-   If you would like to make changes to a particular group, click on the Manage Group option.
    
-   Next to the group name, you will find the Members and Revenue section. Click on Revenue. The members with their designations and revenue percentage will appear. Click Edit.
    
-   You can change the revenue by typing in the desired number in case the revenues are different for each member. Note that the contribution that you set for members should sum up to 100%. If you would like to divide equally among the members, then tick the Equal contribution checkbox. Finally click Update to save the changes. The change in revenue will be notified to the group member. The member will have to accept the share.
    
        `,
    },
  ],
  'V Clubs': [
    {
      title: "How do I create a club?",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Clubs tab next to Edit profile and Account Settings.
    
-   You will find the Create Club option. By clicking, a window opens where you will be required to fill in fields like name and description. You can add categories and club members by clicking the Add buttons. The club members will be notified and they will have to agree to the terms and conditions of the club before becoming a member. The person who creates the club will be the Owner of the club. Also, choose the Club Admin from the list of club members.
    
-   Click on the Create button. The club will be created successfully and will appear under Clubs tab in your profile.
      `,
    },
    {
      title: "Navigating Home Page of a club",
      description: `
The Home Page of a club is similar to the home page of an individual designer.

-   The name of the club and its profile image are displayed along with its followers and following counts.
    
-   Followed by the Liked, Inspired, Rated, Free and Paid designs, the collections are present.
    
-   The cover image set for the club appears in the background.
    
-   The Members option is present to the right of collection names.
    
-   Each collection is displayed with its label and designs.
    
-   The top of the page has features like Chat, Notifications, Search and a dropdown for Profile.
    
      `,
    },
    {
      title: "Viewing the members of a club",
      description: `
-   Go to the club profile from your home page and you can find the Members option next to the collections.
    
-   Click on it and a window appears with the list of members.
    
-   The Club Admin for the group is highlighted.
    
-   You can add new members to the club with the Add a member option.
      `,
    },
    {
      title: "How do I manage clubs and their members?",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Clubs tab next to Edit profile and Account Settings. The clubs in which you are a member will be displayed.
    
-   The Manage Club option is present alongside each club name.
    
-   If you would like to make changes to a particular club, click on the Manage Club option and its members’ names will appear.
    
-   Place the cursor on a designer’s image and you will have options to add/remove a member as Admin and add/remove a designer as a member. The Owner of the club can perform this action.
      `,
    },
    {
      title: "Updating revenues of club members",
      description: `
-   Click on the profile dropdown at the top right corner of your designer account’s home page.
    
-   Go to the Clubs tab next to Edit profile and Account Settings. The clubs in which you are a member will be displayed.
    
-   The Manage Clubs option is present alongside each club name.
    
-   If you would like to make changes to a particular club, click on the Manage Club option.
    
-   Next to the name of a club, you will find the Members and Revenue section. Click on Revenue. The members with their designations and revenue percentage will appear. Click Edit.
    
-   You can change the revenue by typing in the desired number in case the revenues are different for each member. Note that the contribution that you set for members should sum up to 100%. If you would like to divide equally among the members, then tick the Equal contribution checkbox. Finally click Update to save the changes. The change in revenue will be notified to the club member. The member will have to accept the share.
    
      `,
    },
  ],
  'Payment and Billing': [
    {
      title: "How should I charge for my designs?",
      description: `
Things that you should keep in mind before quoting a price for your design:

-   Time spent on creating the design
    
-   The research work that you did for the idea or the concept of the design
    
-   The software subscriptions that you used for the design
    
-   Your preference - more designs sold with less pricing or limited designs with high pricing
    

You can either charge on a hourly basis or based on the effort that you have invested in the work.
        `,
    },
    {
      title: "Earnings and levels",
      description: `
The level that a designer reaches is mainly determined based on the number of subscribers gained. The three levels of designers are as follows:

-   Premium – state level designers with more than 100 subscribers
    
-   VIP - national level designers with more than 200 subscribers
    
-   Celebrity – worldwide known subscribers with more than 300 subscribers
        `,
    },
    {
      title: "What are the Modes of Payments?",
      description: `
One can make payment through Net banking, wallets, Credit and Debit card.
        `,
    },
  ],
};
