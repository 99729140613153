import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');


export const commentsMessages = {


  likeComment({
    commentId,
  }: {
    commentId: number,
  }) {

    const url = configJSON.likeCommentAPI.replace(':id', commentId);

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.PostMethod,
      url
    });
  },

  postComment({
    designId,
    commentText,
    commentAudio,
    commentAttachement,
  }: {
    designId: number,
    commentText: string,
    commentAudio: string,
    commentAttachement: string,
  }) {

    const httpBody = {
      data: {
        attributes: {
          comment: commentText,
          commentable_type: "BxBlockAttachment::Design",
          commentable_id: designId,
          audios: commentAudio ? [{ data: commentAudio }] : [],
          attachments: commentAttachement ? [{ data: commentAttachement }] : []
        },
      },
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.postCommentAPI,
    });
  },

  postReplyComment({
    reply, replyAudio, replyAttachment, parentCommentId
  }: {
    reply: string,
    replyAudio: string,
    replyAttachment: string,
    parentCommentId: number,
  }) {

    const httpBody = {
      data: {
        attributes: {
          comment: reply,
          audios: replyAudio ? [{ data: replyAudio }] : [],
          attachments: replyAttachment ? [{ data: replyAttachment }] : []
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.postCommentAPI}/${parentCommentId}/reply`,
    });
  },

  fetchComments({ designId }: { designId: number }) {
    const httpBody = {};

    const url = configJSON.fetchCommentsAPI.replace(':id', designId);

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.GetMethod,
      url: url,
    });
  },

  fetchRepliesToReply({ replyId }: { replyId: number }) {

    const url = configJSON.fetchRepliesToReplyAPI.replace(':id', replyId);

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });
  },
}
