import React from 'react';
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useRef } from "react";
import {DezinerButton} from "../design-system";
import { blobToBase64 } from "../utils";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  headerFloatingBtn: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
});



export function DezinerChangeCoverButton({ onChange, loading }: { onChange: (base64: string) => void, loading: boolean }) {

  const inputFileRef = useRef<any>();
  const classes = useStyles();

  return (
    <div>
      <input
        ref={inputFileRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={async (event) => {

          let file = event?.target?.files?.[0];
          if (file)
            onChange(await blobToBase64(file));

        }} />

      <DezinerButton
        variant="contained"
        onClick={() => {
          if (inputFileRef && inputFileRef.current) {
            inputFileRef.current.click();
          }
        }}
        startIcon={<CameraAltIcon />}
        className={classes.headerFloatingBtn}
        loading={loading}
      >
        {'Change Cover'}
      </DezinerButton>
    </div>
  );
}
