import React, { useState } from 'react';
import { promote, } from '../../assets';
import { useSingleDesign, } from '../../../../../components/src/hooks';
import { PromoteDialog } from '../../../../../components/src/common';
import { DezinerButton, } from '../../../../../components/src/design-system';

export function PromoteButton({ designId }: { designId: number }) {

  const [open, setOpen] = useState(false);

  const designQuery = useSingleDesign({ designId });
  const design = designQuery?.data?.design?.data?.attributes;

  const isPromoted = design?.prmoted ?? false;

  return (
    <>
      <PromoteDialog
        open={open}
        onClose={() => setOpen(false)}
        mediaProps={{
          id: String(design?.id ?? ''),
          src: design?.image_url ?? '',
          mediaType: design?.main_image_type?.includes('video') ? 'video' : 'image',
          watermarkURL: design?.watermark_url ?? '',
          watermarkHeight: parseFloat(design?.height ?? '0'),
          watermarkWidth: parseFloat(design?.width ?? '0'),
        }}
        id={String(design?.id ?? '')}
        promoteType={'Design'}
      />

      <DezinerButton
        color="primary"
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={isPromoted}
        startIcon={<img
          src={promote}
          alt="promote"
          className="white-svg"
          style={{ maxWidth: '20px', marginRight: '10px' }}
        />}
      >
        {isPromoted ? 'Promoted' : 'Promote'}
      </DezinerButton>
    </>
  );
}
