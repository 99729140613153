import React from 'react';
import { DezinerSpinner } from '../spinner';


export function LoadingSpinnerContainer({
  loading,
  children
}: {
  loading: boolean,
  children?: any
}) {

  if (loading)
    return <DezinerSpinner />

  return <>{children}</>;
}

