import { Profile } from "../api-types";

export const DEFAULT_PROFILE_ATTRIBUTES: Profile['attributes'] = {
  id: 0,
  first_name: '',
  user_name: '',
  email: '',
  full_phone_number: '',
  country_code: '',
  phone_number: '',
  location: '',
  activated: false,
  device_id: '',
  unique_auth_id: '',
  role: '',
  type: '',
  bio: '',
  about: '',
  profile_info: '',
  color: '',
  profile_url: '',
  profile_thumbnail_url: '',
  cover: '',
  facebook_url: '',
  twitter_url: '',
  instagram_url: '',
  user_name_days: 0,
  follower_count: 0,
  following_count: 0,
  totel_collections: 0,
  totel_designs: 0,
  totel_like_designs: 0,
  totel_promoted_designs: 0,
  totel_inspired_designs: 0,
  totel_rated_designs: 0,
  totel_free_designs: 0,
  totel_paid_designs: 0,
  followed: false,
  subscribed: false,
  kyc_updated: false,
  user_name_at: '',
  created_at: '',
  updated_at: '',
  uid: '',
  comet_chat_token: '',
  clubs: { data: [] },
  groups: { data: [] },
  kyc_updated_at: '',
  skills: {
    data: []
  }
};

export function defaultProfile(data?: Profile['attributes']): Profile['attributes'] {

  if (data) 
    return data;
  else
    return DEFAULT_PROFILE_ATTRIBUTES;
}
