
export function defaultToEmptyArray<T>(data: any): T[] {

  if (data && data.length)
    return data;
  else
    return [];
}
export function defaultToZero(v?: any) {
  if (typeof v == 'number' && !Object.is(v, NaN))
    return v;
  return 0;
}
export function defaultToEmptyString(data: any): string {

  if (data && data.length)
    return data;
  else
    return '';
}
