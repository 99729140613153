export const buyDesignStaticContent = `
 


The digital world is growing fast. But you can be faster! That’s why we are here. Be it a commercial logo, a poster for an advertisement, or a graphic design for your college project, Deziner’s Knot has it all to impress you along with its powerful features.

A huge opportunity to explore and get in touch with illustrious designers who have made their way through this field. Fall in love with the best designs our designers are ready to offer you!

They say, “If you think a good design is expensive, think about what a bad design is going to cost you”. But what if you get top-rated designs without breaking your bank? Doesn’t it sound nice? You have landed in a platform that allows mutual growth of designers as well as customers and hence the pricings are set accordingly. 

A good design that enthralls you will have the following attributes:  

  - Timeless
  - Emotionally resonant
  - Innovative
  - Aesthetic
  - Unobstrusive

Deziner’s Knot and its passionate designers work as a team and share a synergy to deliver you designs that represent ultimate class with all the above- mentioned qualities irrespective of the purpose it serves. 

Because we think our customers deserve only the best, we collaborate with alluring creatives and let the fireworks happen. Deziner’s Knot always believes that customer satisfaction reflects directly upon any company’s as well as its employees’ growth and we try to give our fullest potential.

`;

