import React from 'react';
import { useFormikContext } from "formik";
import { UplodaDesignInitValues } from "./UploadDesignForm.web";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export function IsThumbnailInput() {

  const formik = useFormikContext<UplodaDesignInitValues>();

  return (<FormControlLabel
    control={
      <Checkbox
        checked={formik.values.isThumbnail}
        onChange={(event) => { formik.setFieldValue('isThumbnail', event.target.checked) }}
        name="isThumbnail"
        color="primary"
      />
    }
    label="Set this image as collection thumbnail"
  />);

}
