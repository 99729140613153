import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { VerticalSpacer, DezinerCard } from '../../../../components/src/design-system';
import { DEFAULT_PROFILE_IMAGE } from '../../../../components/src/defaults';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";

interface Testimonial {
  author: string,
  job: string,
  testimonial: string,
  image: string,
}

const testimonials: Testimonial[] = [
  {
    testimonial: 'Deziner’s Knot has been very resourceful for gaining knowledge about the recent trends in designing. I’ve learned a lot by following great artists’ profiles. It has in fact helped me decide my career in designing.',
    author: 'ohn Kenneth',
    job: 'Web Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    testimonial: 'I have been using Deziner’s Knot for the past two weeks and I must say, for a beginner who wishes to learn illustrations and graphic designing, this platform serves its fullest purpose.',
    author: 'Smrithi Ravi',
    job: 'Graphic Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    testimonial: 'I am an architect and when I happened to visit Deziner’s Knot website, I was interested by these unique designs and started to follow artists. I realized there could not be a better platform for beginners to start their career since it offers much support and exposure.',
    author: 'Akshay Srivatsav ',
    job: 'Fashion Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    testimonial: 'Deziner’s Knot has been very resourceful for gaining knowledge about the recent trends in designing. I’ve learned a lot by following great artists’ profiles. It has in fact helped me decide my career in designing.',
    author: 'ohn Kenneth',
    job: 'Web Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    testimonial: 'I have been using Deziner’s Knot for the past two weeks and I must say, for a beginner who wishes to learn illustrations and graphic designing, this platform serves its fullest purpose.',
    author: 'Smrithi Ravi',
    job: 'Graphic Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    testimonial: 'I am an architect and when I happened to visit Deziner’s Knot website, I was interested by these unique designs and started to follow artists. I realized there could not be a better platform for beginners to start their career since it offers much support and exposure.',
    author: 'Akshay Srivatsav ',
    job: 'Fashion Designer',
    image: DEFAULT_PROFILE_IMAGE,
  },
];


const useStyles = makeStyles({
  content: {
    maxWidth: '1200px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});

const SLIDER_SETTINGS: Settings = {
  dots: true,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
}

////////////////
declare let window: any;


function itemsPerScreenWidth(screenWidth: number) {
  if (screenWidth > 1000)
    return 3;
  if (screenWidth > 650)
    return 2;
  return 1;
}


export default function TestimonialsStaticPage() {

  const classes = useStyles();
  const containerRef = useRef<any>();
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {

    const resizeObserver = new window.ResizeObserver((entries: any) => {
      for (const entry of entries) {
        let screenWidth = entry.contentRect.width;
        if (containerRef && containerRef.current && containerRef.current?.offsetWidth) {
          let containerWidth = containerRef.current?.offsetWidth;
          setScreenWidth(containerWidth - 60);
        }
        else
          setScreenWidth(screenWidth - 60);
      }
    });

    resizeObserver.observe(window.document.body);
  }, [containerRef]);



  return (
    <div ref={containerRef}>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'TESTIMONIALS'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {'See what people are saying.'}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {''}
          </Typography>
        </Grid>
        <hr />

        <Slider {...SLIDER_SETTINGS} slidesToShow={itemsPerScreenWidth(screenWidth)}>
          {
            testimonials?.map((item) => (
              <TestimonialCard
                key={item.author}
                testimonial={item}
              />
            ))
          }
        </Slider>


      </div>
    </div>
  );
}

const useTestimonialCardStyles = makeStyles({
  card: {
    minHeight: '300px',
    overflow: 'inherit',
    margin: '80px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 0 10px #c1c1c178',
    border: 'none',
  },
  img: {
    borderRadius: '40%',
    width: '100px',
    height: '100px',
  },
  testimonial: {
    textAlign: 'center',
    color: '#9F9F9F',
  },
  author: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  job: {
    textAlign: 'center',
    color: '#9F9F9F',
    fontSize: '1.1rem',
    fontStyle: 'italic',
  },
  seperator: {
    flexGrow: 1,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-50px',
    height: '60px',
  },
});


function TestimonialCard(props: { testimonial: Testimonial }) {

  const { testimonial, image, author, job } = props.testimonial;
  const classes = useTestimonialCardStyles();

  return (
    <DezinerCard className={classes.card}>

      <div className={classes.imgContainer}>
        <img src={image} alt={author}
          className={classes.img}
        />
      </div>

      <Typography className={classes.testimonial}>
        {testimonial}
      </Typography>

      <div className={classes.seperator} />
      <VerticalSpacer />

      <Typography className={classes.author}>
        {author}
      </Typography>

      <Typography className={classes.job}>
        {job}
      </Typography>

    </DezinerCard>
  );
}
