import { appendJsonContentType, appendToken, apiCall } from "../utils";


const configJSON = require('../config');


export const designerMessages = {

  fetchDesignerFollowers({ designerId }:{ designerId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.fetchDesignerFollowersAPI.replace(':id', designerId),
    });
  },


  fetchDesignerList() {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.designerListAPI,
    });
  },

  unfollowDesigner({ designerId }: { designerId: number }) {

    const url = configJSON.unfollowDesignerAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "data": {
          "attributes": {
            "account_id": designerId
          }
        }
      },
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },

  followDesigner({ designerId }: { designerId: number }) {

    const url = configJSON.followDesignerAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "data": {
          "attributes": {
            "account_id": designerId
          }
        }
      },
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },

  fetchSubscriptionPackages({ subscriptionType }:{ subscriptionType: string }) {
    let url = configJSON.subscriptionPackages;
    if (subscriptionType === 'Designs') {
      url = configJSON.designSubscriptionPlans;
    } else if (subscriptionType === 'Topup') {
      url = configJSON.topupPlans;
    }
    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url,
    });
  },

  subscribePackage({
    designerId,
    planId,
    subscribeType,
    cardDetails,
  }: {
    designerId: number,
    planId: number,
    subscribeType: string,
    cardDetails: any
  }) {

    let url = configJSON.applySubscriptionPackage;
    let subscriptionObj: any = {
      "subscribe_type": subscribeType,
      "subscribe_id": designerId,
      "plan_id": planId
    };
    if (subscribeType === 'Designs' || subscribeType === 'Topup') {
      url = configJSON.applyGeneralPackage;
      subscriptionObj = {
        "plan_id": planId
      }
    }

    

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "subscription":{
          ...subscriptionObj
        },
        "card": {
          "card_number": cardDetails.card_number,
          "exp_month": cardDetails.exp_month,
          "exp_year": cardDetails.exp_year,
          "cvc": cardDetails.cvc,
        }
      },
      httpMethod: configJSON.PostMethod,
      url,
    });
  },

  fetchNotifications({ filter }:{ filter: string }) {
    const url = configJSON.getNotifications;
    const params = {
      filter: !filter ? 'All' : filter
    };
    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url,
      params,
    });
  },

}
