import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { DesignComment } from "../../../components/src/api-types";
import { Reply } from "./Reply";

const useCommentRepliesStyles = makeStyles({
  repliesContainer: {
  }
});

export function CommentReplies({
  comment,
  designId,
  onAnonymousInteraction,
}: {
  comment: DesignComment,
  designId: number,
  onAnonymousInteraction?: () => void,
}) {
  const classes = useCommentRepliesStyles();

  if (!comment?.attributes?.replies?.data?.length)
    return null;

  return (
    <div className={classes.repliesContainer}>
      {
        comment?.attributes?.replies?.data?.map((reply) => (
          <Reply
            key={reply.id}
            allowReply={true}
            reply={reply}
            designId={designId}
            parentCommentId={comment.attributes.id}
            onAnonymousInteraction={onAnonymousInteraction}
          />
        ))
      }
    </div>
  );
}
