import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { useQueryClient } from '@tanstack/react-query';
import { cometchatKeys } from './query-keys';
import { clearCometChatMessages } from '../../../components/src/store/cometchat/message/message.action';
import {HideContainer} from '../../../components/src/design-system';
import { toggleChatMinimize } from '../../../components/src/store/ui/ui.actions';
import LaunchIcon from '@material-ui/icons/Launch';
import ChatUsername from './ChatUsername';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import PrivateChat from './PrivateChatList';

const useStyles = makeStyles({
  userCardContainer: {
    background: '#CBCCCE',
    borderRadius: '10px 10px 0 0',
  },
  iconsContainer: {
    width: 'max-content',
    padding: '10px',
  },
});


export default function LiveChatMenu({ onClose, }: { onClose: () => void }) {

  const classes = useStyles();

  const uid = useSelector((state: RootState) => state.ui.chatWidget.uid);
  const chatType = useSelector((state: RootState) => state.ui.chatWidget.chatType);
  const minimized = useSelector((state: RootState) => state.ui.chatWidget.minimized);
  const showPrivateChat = chatType === 'group' ? true : false;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  function closeHandler() {
    onClose();
    queryClient.invalidateQueries(cometchatKeys.conversationHistory({ uid, chatType }));
    dispatch(clearCometChatMessages({ uid }));
  }

  function toggleMinimize() {
    dispatch(toggleChatMinimize({}));
  }


  return (
    <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>



      <Grid item className={classes.userCardContainer} container wrap="nowrap" alignItems="center" justifyContent="space-between">

        <ChatUsername />

        <Grid item container wrap="nowrap" className={classes.iconsContainer}>
          {/* <IconButton size="small"><SettingsIcon /></IconButton> */}
          <IconButton size="small" onClick={toggleMinimize}>
            {
              minimized ?
                <LaunchIcon /> :
                <RemoveIcon />
            }
          </IconButton>
          <IconButton size="small" onClick={closeHandler} ><CloseIcon /></IconButton>
        </Grid>

      </Grid>

      <HideContainer hideWhen={minimized}>

        <ChatMessages />

        <ChatInput />

        {showPrivateChat ? <PrivateChat /> : null}

      </HideContainer>
      

    </Grid>
  );
}

