import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DesignComment, DesignReply } from "../../../components/src/api-types";
import { useCommentNameClickHandler } from "./CommentUtils";
import { Grid, Hidden, Typography, } from '@material-ui/core';
import { CommentProfilePicture } from "./CommentProfilePicture";


const useCommentHeaderStyles = makeStyles({
  firstName: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  duration: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  dot: {
    width: '6px',
    height: '6px',
    background: '#515151',
    margin: '0 10px 0 20px',
    borderRadius: '50%'
  },
});

export function CommentHeader({
  comment,
  size,
}: {
  comment: DesignComment | DesignReply,
  size?: "small",
}) {

  const commentAttr = comment.attributes;

  const classes = useCommentHeaderStyles();

  const nameClickHandler = useCommentNameClickHandler(comment);

  let firstName = '';
  if (comment.type == 'comment')
    firstName = comment.attributes?.comment_by?.data?.attributes?.first_name;
  else
    firstName = comment?.attributes?.repled_by?.data?.attributes?.first_name;

  const duration = commentAttr?.created_at ?
    moment.duration(moment().diff(moment(commentAttr?.created_at))).humanize()
    :
    '';

  return (
    <Grid container>

      <Hidden mdUp>
        <Grid item xs={'auto'} >
          <CommentProfilePicture size={size} comment={comment} />
        </Grid>
      </Hidden>

      <Grid item xs>
        <Grid container alignItems="center">

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.firstName} onClick={nameClickHandler}>
              {firstName}
            </Typography>
          </Grid>

          <Hidden xsDown>
            <div className={classes.dot} />
          </Hidden>

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.duration}>
              {duration ? `${duration} ago` : ''}
            </Typography>
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );
}
