import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';

export function SlickSliderNextArrow(props: any) {

  const { style, onClick, size } = props;
  const isSmall = size == "small";

  return (
    <IconButton
      onClick={onClick}
      size={size || "medium"}
      style={{
        ...style,
        position: 'absolute',
        top: isSmall ? '45%' : '36%',
        right: isSmall ? '-20px' : '-50px',
      }}>
      <NavigateNextIcon fontSize={size || "large"} />
    </IconButton>
  );
}

export function SlickSliderPrevArrow(props: any) {

  const { style, onClick, size } = props;
  const isSmall = size == "small";

  return (
    <IconButton
      size={size || "medium"}
      onClick={onClick}
      style={{
        ...style,
        position: 'absolute',
        top: isSmall ? '45%' : '36%',
        left: isSmall ? '-20px' : '-50px'
      }}>
      <NavigatePrevIcon fontSize={size || "large"} />
    </IconButton>
  );
}
