export const copyrightPolicyContent = `


# Reporting Claims of Copyright Infringement

We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from this site (the “Site”) infringe your copyright, you may request removal of those materials (or access to them) from the Site by submitting Written Copyright Infringement Notice notification to our copyright team designated below. In accordance with the Copyright Act, 1957 (“WCIN”), the written notice (the “WCIN Notice”) must include substantially the following:

  

-   Your physical or electronic signature.
    
-   Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Site, a representative list of such works.
    
-   Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.
    
-   Adequate information by which we can contact you (including your name, postal address, telephone number, and email address).
    
-   A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.
    
-   A statement that the information in the written notice is accurate.
    
-   A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
    

Our designated copyright team to receive WCIN Notices is:

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copyright/Legal Team,

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Deziner's Knot Product Private Limited

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 77C, New Market Street,

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tiruppur – 641604

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tamil Nadu, India

  

If you fail to comply with all of the requirements with respect to the WCIN Notice as stated above your Notice may not be effective.

Please be aware that if you knowingly materially misrepresent that material or activity on the Site is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) as per law.

We provide a form for submission of claims, which you may optionally use to submit a claim.

# Counter-Notification Procedures

If you believe that material you posted on the Site was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (a “Counter-Notice”) by submitting written notification to our copyright team designated above. The Counter-Notice must include substantially the following:

  

-   Your physical or electronic signature.
    
-   An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.
    
-   Adequate information by which we can contact you (including your name, postal address, telephone number, and email address).
    
-   A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.
    
-   A statement that you will consent to the jurisdiction of the appropriate Court in which your address is located (or if you reside outside the India for appropriate courts in Tiruppur, Tamil Nadu, India) and that you will accept service from the person (or an agent of that person) who provided the Site with the complaint at issue.
    

  

The law allows us to restore the removed content if the party filing the original WCIN Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice. Please be aware that if you knowingly materially misrepresent that material or activity on the Site was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorneys’ fees) under law.

# Repeat Infringers

It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.

`;
