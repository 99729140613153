import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { Label } from "../api-types";
import { labelsMessages } from "../messages-generator";
import { labelsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";
import { useIsMyProfile } from "./utils";

export function useLabelsByOther({ designerId }: { designerId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Label[] }>({
    message: labelsMessages.fetchLabelsByOthers({
      designerId,
    }),
    queryKey: labelsKeys.fetchLabels({ designerId }),
    options: {
      enabled: (options?.enabled ?? true) && Boolean(designerId),
      placeholderData: { data: [] as any },
      ...options,
    }
  });
}

export function useLabels({ designerId }: { designerId: number }) {

  const isMyProfile = useIsMyProfile({ id: designerId });

  const myLabelsQuery = useMyLabels({ designerId }, { enabled: isMyProfile });
  const labelsByOthersQuery = useLabelsByOther({ designerId }, { enabled: !isMyProfile });

  return isMyProfile ? myLabelsQuery : labelsByOthersQuery;
}


export function useMyLabels({ designerId }: { designerId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Label[] }>({
    message: labelsMessages.fetchLabels({}),
    queryKey: labelsKeys.fetchLabels({ designerId }),
    options: {
      ...options,
      placeholderData: { data: [] as any }
    },
  });
}


export function useCreateLabel({ designerId, }: { designerId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof labelsMessages.createLabel>[0]>({
    queryKey: labelsKeys.createLabel({ designerId }),
    messageGenerator: labelsMessages.createLabel,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(labelsKeys.fetchLabels({ designerId }));
      },
    },
  });

}
