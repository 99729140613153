import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { passwordValidate } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  email: string;
  enterOtpScreen: boolean;
  passwordScreen: boolean;
  newPassword: string;
  confirmNewPassword: string;
  otp: any;
  passwordError: boolean;
  passwordErrorText: string;
  errors: any;
  modalSuccessMessage: any;
  changePasswordOpen: boolean;
  loading: boolean,
  changePasswordDialogMessage: string,
  changePasswordIsSuccessful: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start

  postLoginApiId: any;
  emailReg: RegExp;
  otpVerificationId: any;
  submitPasswordId: any;

  //Properties from config

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    this.goToSignUpPage = this.goToSignUpPage.bind(this);
    this.onPasswordChangeHandler = this.onPasswordChangeHandler.bind(this);
    this.onConfirmPasswordChangeHandler = this.onConfirmPasswordChangeHandler.bind(
      this
    );
    this.forgotpasswordSubmit = this.forgotpasswordSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onOtpVerification = this.onOtpVerification.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      countryCodeSelected: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      email: "",
      enterOtpScreen: false,
      passwordScreen: false,
      newPassword: "",
      confirmNewPassword: "",
      otp: "",
      passwordError: false,
      passwordErrorText: "",
      errors: {},
      modalSuccessMessage: {
        text: "",
        type: null,
      },
      changePasswordOpen: false,
      loading: false,
      changePasswordDialogMessage: '',
      changePasswordIsSuccessful: false,
    };
    this.emailReg = new RegExp("");

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.postLoginApiId) {
        this.setState({ loading: false });
        console.log("ID Match");
        if (responseJson != null) {
          console.log("RES===>", responseJson);
          if (!responseJson.errors) {
            console.log("Error Free");
            localStorage.setItem(
              "forgotPasswordOtpToken",
              responseJson.meta.token
            );
            this.setState({
              enterOtpScreen: true,
            });
          } else {
            const errors = {
              email: responseJson.errors[0].otp,
            };
            this.setState({
              errors,
            });
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (apiRequestCallId === this.otpVerificationId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          if (!responseJson.errors) {
            console.log("Error Free");
            localStorage.setItem(
              "otptoken",
              responseJson.messages[1].meta.token
            );
            this.setState({
              passwordScreen: true,
            });
          } else {
            /*
                        const errors = {
                          otp: responseJson.errors[0].otp,
                        };
            */
            let errors = responseJson.errors.reduce((all: any, e: any) => ({ ...all, ...e }), {});
            this.setState({
              errors,
            });
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (apiRequestCallId === this.submitPasswordId) {
        this.setState({ loading: false });
        if (responseJson != null) {
          console.log(
            "🚀 ~ file: ForgotPasswordControllerWeb.tsx ~ line 176 ~ receive ~ responseJson",
            responseJson
          );
          if (!responseJson.errors) {
            console.log(responseJson);
            const modalSuccessMessage = {
              text: "Password Changed Successfully",
              type: 1,
            };
            this.setState({
              modalSuccessMessage,
              changePasswordOpen: true,
              changePasswordDialogMessage: 'Password Changed Successfully',
              changePasswordIsSuccessful: true,
            });
            // this.props.navigation.navigate("EmailAccountLoginBlockWeb")
          } else {
            // this.parseApiErrorResponse(responseJson);
            const modalSuccessMessage = {
              text: "Password change failed",
              type: 0,
            };
            this.setState({
              modalSuccessMessage,
              changePasswordOpen: true,
              changePasswordDialogMessage: 'Password Changed Failed',
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  onPasswordReset = ({ password, confirmPassword }: any) => {
    this.setState({ loading: true });
    /*
        e.preventDefault();
        const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
        const { newPassword, confirmNewPassword } = this.state;
        const password = newPassword;
        const confirmPassword = confirmNewPassword;
        if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
          const errors = {
            password: "Password cannot be empty",
          };
          this.setState({
            errors,
          });
          return;
        } else if (password.length < 8) {
          const errors = {
            password: "Password must be at least 8 characters long",
          };
          this.setState({
            errors,
          });
          return;
        } else if (!pswdspaceReg.test(password)) {
          const errors = {
            password: "Password must not contain spaces",
          };
          this.setState({
            errors,
          });
          return;
        } else if (password !== confirmPassword) {
          const errors = {
            password: "Password does not match",
          };
          this.setState({
            errors,
          });
          return;
        }
    */
    const data = {
      data: {
        attributes: {
          new_password: password,
          password_confirmation: confirmPassword,
          token: localStorage.getItem("otptoken"),
        },
      },
    };
    console.log(
      "🚀 ~ file: ForgotPasswordControllerWeb.tsx ~ line 238 ~ data",
      data
    );

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitPasswordId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitForgotPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onPasswordChangeHandler = (e: any) => {
    this.setState({
      newPassword: e.target.value,
    });
  };

  onConfirmPasswordChangeHandler = (e: any) => {
    this.setState({
      confirmNewPassword: e.target.value,
    });
  };

  onOtpVerification = ({ otp }: any) => {
    this.setState({ loading: true });
    /*
        e.preventDefault();
        const { otp } = this.state;
        if (otp.length !== 4) {
          const errors = {
            otp: "Please enter correct OTP",
          };
          this.setState({
            errors,
          });
        }
    */
    const data = {
      data: {
        attributes: {
          otp_code: otp,
          token: localStorage.getItem("forgotPasswordOtpToken"),
        },
      },
    };
    console.log(data);
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpVerificationId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerificationEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  goToSignUpPage = () => {
    this.props.navigation.navigate("EmailAccountRegistrationWeb");
  };

  forgotpasswordSubmit = ({ email }: { email: string }) => {
    this.setState({ loading: true });
    /*
    this.setState({
      errors: {},
    });
    e.preventDefault();
    const { email } = this.state;
    console.log(email);
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email === null || this.state.email.length === 0) {
      const errors = {
        email: "Email is required",
      };
      this.setState({
        errors,
      });
      return false;
    }
    if (!this.emailReg.test(this.state.email)) {
      const errors = {
        email: "Please enter a valid email address",
      };
      this.setState({
        errors,
      });
      return false;
    }
*/
    const data = {
      data: {
        attributes: {
          email: email,
        },
      },
    };

    console.log(data);
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLoginApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordByEmailEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleModalClose() {
    this.setState({
      changePasswordOpen: false,
    });
    this.props.navigation.navigate("EmailAccountLoginBlockWeb");
  }

}
