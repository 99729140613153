import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config.js');

export const forumMessages = {


  postCommentForum({
    commentText,
    forumId,
    audio,
    attachement,
  }: {
    forumId: number,
    commentText: string,
    audio: string,
    attachement: string,
  }) {

    const httpBody = {
      data: {
        attributes: {
          comment: commentText,
          commentable_type: "BxBlockCommunityforum::Forum",
          commentable_id: forumId,
          audios: audio ? [{ data: audio }] : [],
          attachments: attachement ? [{ data: attachement }] : []
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.postCommentAPI}`,
    });

  },

  fetchForumComments({ id }: { id: number }) {


    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.fetchForumsAPI}/${id}/forum_comments`,
    });

  },

  fetchSingleForum({ id }: { id: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.fetchForumsAPI}/${id}`,
    });

  },

  likeForum({ id }: { id: number }) {


    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.createForumAPI}/${id}/like`,
    });
  },

  dislikeForum({ id }: { id: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.createForumAPI}/${id}/unlike`,
    });
  },


  createForum({ title, description, media }: { title: string, description: string, media: string }) {

    const httpBody = {
      data: {
        "attributes": {
          title,
          description,
          media: media ? { data: media } : ""
        },
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.createForumAPI,
    });

  },


  fetchForums() {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: configJSON.fetchForumsAPI,
    });

  },

}
