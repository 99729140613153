import React from 'react';
import DesignerEditSocialProfilesPageController, { Props } from "./DesignerEditSocialProfileController.web";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import { VerticalSpacer, DezinerButton, FormikTextInput } from '../../../../../components/src/design-system';
import CircularProgress from '@material-ui/core/CircularProgress';
import { socialMediaLinksValidationSchema } from '../../../../../components/src/validation/social-media-validation';
import { useDecodedToken, useMyProfile, useUpdateSocialMedia } from '../../../../../components/src/hooks';
import { defaultToEmptyString } from '../../../../../components/src/default-values';


class DesignerEditSocialProfilesPage extends DesignerEditSocialProfilesPageController {


  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {


    return (
      <div>

        <EditSocialMedialLinksForm />

      </div>
    )
  }
}

const useStyles: any = makeStyles({
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '40%',
    objectFit: 'cover',
  },
  profileImagePlaceholder: {
    width: '100px',
    height: '100px',
    borderRadius: '40%',
    background: 'lightgray'
  },
  container: {
    margin: '20px auto',
    width: '400px'
  },
});



const initValues = {
  facebook: '',
  twitter: '',
  instagram: '',
}


function EditSocialMedialLinksForm() {

  const profileQuery = useMyProfile();
  const profileData = profileQuery?.data?.data?.attributes;
  const { id } = useDecodedToken();

  const { mutate: updateSocialMediaLinks, isLoading: submitLoading } = useUpdateSocialMedia({
    designerId: id
  });

  const classes = useStyles();

  const currentFormValues = {
    facebook: defaultToEmptyString(profileData?.facebook_url),
    twitter: defaultToEmptyString(profileData?.twitter_url),
    instagram: defaultToEmptyString(profileData?.instagram_url),
  }

  return (
    <Formik
      initialValues={{ ...initValues, ...currentFormValues }}
      validationSchema={socialMediaLinksValidationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        updateSocialMediaLinks(values);
      }}
    >
      {
        formik => <Form translate="yes">

          <Grid direction='column' alignItems="center" className={classes.container}>



            <FormikTextInput
              fullWidth
              variant='outlined'
              name="facebook"
              label="Facebook"
            />

            <VerticalSpacer />


            <FormikTextInput
              fullWidth
              variant='outlined'
              name="twitter"
              label="Twitter"
            />

            <VerticalSpacer />


            <FormikTextInput
              fullWidth
              variant='outlined'
              name="instagram"
              label="Instagram"
            />

            <VerticalSpacer />

            <Grid container justifyContent='flex-end'>
              <DezinerButton
                color="primary"
                variant='contained'
                type='submit'
                disabled={submitLoading}
              >
                {'Update Social Profiles'}
                {
                  submitLoading ?
                    <CircularProgress size={20} style={{ margin: '0 5px' }} color='inherit' />
                    :
                    null
                }
              </DezinerButton>
            </Grid>

          </Grid>

        </Form>
      }
    </Formik>
  );
}


export default DesignerEditSocialProfilesPage;
