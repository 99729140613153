import React from "react";
// Customizable Area Start
import CreateClubForm from "./CreateClubForm.web";
import LogoTitleHeader from "./LogoTitleHeader.web";
// Customizable Area End
import CreateClubController, { Props } from "./CreateClubController.web";
import { useCategories, useCreateClub, useDesignersList, useNavigate } from "../../../components/src/hooks";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { Category, Profile } from "../../../components/src/api-types";



class CreateClub extends CreateClubController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return <CreateClubContent />
  }
}


function CreateClubContent() {

  const navigate = useNavigate();
  const categoriesQuery = useCategories();

  const formattedCategories = defaultToEmptyArray<Category>(categoriesQuery?.data?.data)
  ?.map((category) => {
    const subCategories = category.attributes.sub_categories.data.map((subCategory) => {
      return {
        value: subCategory.attributes.id,
        label: subCategory.attributes.name,
      };
    })
    return {
      label: category.attributes.name,
      options: subCategories,
    }
  })

  let listOfCategories = formattedCategories
    ?.map((item) => (
      item.options.map((option) => ({ ...option, parentLabel: item.label }))
    ))
    ?.flat();



  const { mutate: createClub, isLoading: loading } = useCreateClub();

  const designerListQuery = useDesignersList();
  const designerList = defaultToEmptyArray<Profile>(designerListQuery?.data?.data);


  return (
    <>


      <LogoTitleHeader
        title={"Create your club"}
        onLogoClick={() => {
          navigate("DesignClubPageView");
        }}
      />

      <CreateClubForm
        onSubmit={(values) => {
          createClub({
            name: values.clubName,
            description: values.clubDescription,
            email: values.email,
            organization_name: values.orgName,
            organization_pan_id: values.orgPanId,
            bank_account_name: values.bankAccountName,
            bank_account_number: values.bankAccountNumber,
            ifsc_code: values.bankIFSC,
            pan_photo: values.panPhoto,
            cheque: values.cancelledCheque,
            image: values.profileImage,
            cover: values.coverImage,
            user_type: values.typeofUser == 'Admin' ? 'Admin' : 'Owner',
            account_ids: values.clubMembers.map((x: any) => String(x.value)),
            clubs_sub_categories_attributes: values.categories.map((x: any) => ({ sub_category_id: x.value })),
          }, {
            onSuccess(response) {
              navigate(`ClubHomePage/${response.data.id}`)
            }
          })
        }}
        members={designerList.map((member) => ({
          label: member.attributes.first_name,
          value: member.id,
        }))}
        categories={listOfCategories}
        loading={loading}
      />

    </>
  );
}


// Customizable Area Start
// Customizable Area End
export default CreateClub;
// Customizable Area End
