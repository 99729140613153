import React, { useState } from 'react';
import DesignerDesignEditPageController, { Props } from './EditDesignController.web';
import UploadDesignForm from '../upload-design-form/UploadDesignForm.web';
import { urlToBase64, useParams } from '../../../../../components/src/utils';
import {
  useDecodedToken, useNavigate, useSingleDesign, useSourceFiles, useUpdateDesign, useWatermarks
} from '../../../../../components/src/hooks';
import { UploadDesignPayload } from '../../../../../components/src/messages-generator';
import { DesignUploadHeader } from '../DesignUploadHeader';
import { defaultToEmptyArray, defaultToEmptyString, defaultToZero } from '../../../../../components/src/default-values';
import { Watermark } from '../../../../../components/src/api-types';



class DesignerDesignEditPage extends DesignerDesignEditPageController {

  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <DesignerDesignEditContent />
    );
  }
}



function DesignerDesignEditContent() {

  const { id } = useDecodedToken();
  const navigate = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
      }}
    >

      <DesignUploadHeader
        headerText={'Update Your Design'}
        onLogoClick={() => {
          navigate(`DesignerHome/${id}`);
        }}
      />

      <UploadDesignFormContainer />

    </div>
  );
}


function UploadDesignFormContainer() {

  const params = useParams();
  const designId = parseInt(params.designId);

  const designQuery = useSingleDesign({ designId });
  const design = designQuery?.data?.design?.data?.attributes;

  const watermarksQuery = useWatermarks();
  const watermarks = defaultToEmptyArray<Watermark>(watermarksQuery?.data?.data)


  const sourceFilesQuery = useSourceFiles({
    designId,
  });

  const { id: designerId } = useDecodedToken();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { mutate: updateDesign, isLoading: loading } = useUpdateDesign({
    designerId,
    labelId: defaultToZero(design?.label_id),
    designId,
  });

  return (
    <UploadDesignForm
      initialFormValues={{
        title: defaultToEmptyString(design?.title),
        tags: design?.sub_categories?.map((item: any) => ({ value: item.id, label: item.name, parentLabel: '' })) ?? [],
        description: defaultToEmptyString(design?.description),
        price: defaultToZero(design?.price),
        privacy: design?.privacy ?? 'Public',
        media: [
          {
            data: defaultToEmptyString(design?.image_url),
            design_type: design?.main_image_type,
            file_size: 0,
            name: ''
          },
          ...(defaultToEmptyArray(design?.sub_designs)?.map((item: any) => ({
            data: item?.url, design_type: item?.type, name: '', file_size: 0
          })))
        ],
        watermarkId: watermarks.find(item => item?.attributes?.watermark_url == (design?.watermark_url ?? ''))?.attributes?.id ?? -1,
        isThumbnail: design?.is_thumbnail ?? false,
        waterMarkWidth: parseFloat(design?.width ?? '150'),
        waterMarkHeight: parseFloat(design?.height ?? '150'),
        descriptionAudio: defaultToEmptyString(design?.audio_urls?.[0]?.url),
        sourceLink: defaultToEmptyString(design?.design_url),
        sourceFiles: sourceFilesQuery?.data?.data?.map(x => x.attributes)?.map((item) => ({
          data: defaultToEmptyString(item?.data),
          design_type: defaultToEmptyString(item?.design_type),
          name: defaultToEmptyString(item?.name),
          file_size: defaultToZero(parseFloat(item?.file_size)),
        })) ?? [],
        timePeriod: defaultToEmptyString(design?.time_period_type),
        priceConditions: design?.design_prices?.map(({ year, month, amount }: any) => ({ year, month, price: amount })) ?? [],
      }}
      onSubmit={async (values) => {

        setIsLoading(true);

        const {
          title,
          tags,
          description,
          price,
          privacy,
          media,
          watermarkId,
          isThumbnail,
          descriptionAudio,
          waterMarkWidth,
          waterMarkHeight,
          sourceLink,
          sourceFiles,
          timePeriod,
          priceConditions,
        } = values;

        const labelId = defaultToZero(design?.label_id);
        const collectionId = defaultToZero(design?.collection_id);
        const clubId = defaultToZero(design?.club_id);
        const type = design?.design_type ?? 'Designer';

        let processedMedia = await Promise.all(media.map(({ data }) =>
          data?.startsWith('http') ?
            urlToBase64(data?.replace('http://', 'https://'))
            :
            data
        ));

        const sub_design = processedMedia.slice(1).map((base64) => {
          return {
            data: base64 as string
          };
        });

        const newTags = tags.map((tag) => {
          return { sub_category_id: tag.value };
        });

        const watermark_id_number = watermarkId;

        let clubOnlyParams = {
          club_id: clubId,
        }

        let groupOnlyParams = {
          group_id: clubId,
        }

        let payload: UploadDesignPayload = {
          data: {
            attributes: {
              design_type: type,
              collection_id: collectionId,
              watermark_id: watermark_id_number,
              label_id: labelId,
              title: title,
              designs_sub_categories_attributes: newTags,
              privacy: privacy,
              description: description,
              price: price.toString(),
              width: waterMarkWidth,
              height: waterMarkHeight,
              is_thumbnail: isThumbnail,
              image: {
                data: processedMedia[0] as string
              },
              sub_designs: sub_design,
              audios: descriptionAudio ? [
                {
                  data: (descriptionAudio?.startsWith('http') ?
                    await urlToBase64(descriptionAudio.replace('http://', 'https://'))
                    :
                    descriptionAudio) as string
                }
              ] : [],
              design_url: sourceLink,
              source_designs_attributes: (sourceFiles?.map((item) => ({
                data: item.data,
                design_type: item?.file?.type?.replace('image/', '').replace('video/', '') ?? '',
                name: item.name,
                file_size: item.file_size / 1024,
              })) ?? []) as any,
              design_prices_attributes: priceConditions.map(({ year, month, price }) => ({
                year,
                month,
                amount: price,
              })) as any,
              time_period_type: timePeriod,
              ...(type == 'Club' ? clubOnlyParams : {}),
              ...(type == 'Group' ? groupOnlyParams : {}),
            },
          },
        };

        updateDesign({ payload, designId }, {
          onSuccess: () => {
            navigate(`DesignView/${designId}`);
          },
          onSettled: () => {
            setIsLoading(false);
          }
        });
      }}
      loading={isLoading || loading}
    />
  );
}



export default DesignerDesignEditPage;
