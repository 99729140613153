import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import Dropzone from "react-dropzone";
import { DezinerAlert, HideContainer } from '../../../../../components/src/design-system';
import { blobToBase64 } from '../../../../../components/src/utils';
import { UplodaDesignInitValues, FormMedia } from './UploadDesignForm.web';
import { UploadSourceFilesPlaceholder } from './UploadDesignFormComponents.web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import clsx from 'clsx';
import { localUploadIcon, } from "../../../../catalogue/src/assets";

const MAX_SOURCE_FILE_SIZE = 500 * 1024 * 1024; // 500MB


export function SourceFilesDropzone() {

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();
  const sourceFilesRef = useRef<any>();

  return (
    <>
      <Grid container alignItems="center">
        <img src={localUploadIcon} className={clsx(classes.smallIcon, "gray-svg")} alt="upload" />
        <Typography className={classes.sectionTitleText}>
          {'Upload from local'}
        </Typography>
      </Grid>


      <Dropzone
        multiple
        accept={{
          'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
          'video/*': ['.mp4', '.mkv', '.mov', '.wmv', '.avi'],
          'application/*': ['.zip'],
        }}
        onDrop={async (files: any[]) => {

          try {

            const isBelowLimits = (file: any) => file.size < MAX_SOURCE_FILE_SIZE
            const not = (fn: any) => (...args: any) => !fn(...args);
            let hasExceededLimits = files.some(not(isBelowLimits));

            if (hasExceededLimits)
              formik.setErrors({ 'sourceFiles': 'Can not have files over 500 MB' })
            else
              formik.setErrors({ 'sourceFiles': '' })

            let filesBase64 = await Promise.all(files.filter(isBelowLimits).map(blobToBase64));
            let newFiles: FormMedia[] = files.filter(isBelowLimits).map((file, index) => ({
              file,
              data: filesBase64[index],
              file_size: file?.size,
              name: file?.name,
              design_type: file?.type,
            }));

            formik.setFieldValue('sourceFiles', [...newFiles, ...formik.values.sourceFiles.slice()]);
          } catch (error) {
            console.error(error);
          }
        }}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({ className: "dropzone" })}
            onClick={(event) => {
              event.preventDefault();
              if (sourceFilesRef && sourceFilesRef.current)
                sourceFilesRef.current.click();
            }}
          >

            <UploadSourceFilesPlaceholder />

            {/* @ts-ignore */}
            <input
              id="uploadFile"
              type="file"
              {...getInputProps()}
              ref={sourceFilesRef}
            />
          </div>
        )}
      </Dropzone>

      <HideContainer hideWhen={!formik.errors.sourceFiles}>
        <DezinerAlert>
          {formik.errors.sourceFiles}
        </DezinerAlert>
      </HideContainer>
    </>
  );
}
