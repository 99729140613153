import { put, select } from "redux-saga/effects";
import { SaveCometChatMessageAction } from "../cometchat/message/message.action";
import { RootState } from "../store";
import { openNewChatWidget } from "./ui.actions";

export function* onMessageSaveSaga(action: SaveCometChatMessageAction) {

  const state: RootState = yield select();
  const openState = state.ui.chatWidget.open;

  const { message, uid, chatType } = action;

  if (!openState) {

    let name = '';
    let status: 'online' | 'offline' = 'online';

    if (message.type == 'text') {

      if (message.receiverType == 'group')
        name = message.receiver.name;
      else
        name = message.sender.name;

    } else if (message.type == 'audio') {

      if (message.receiverType == 'group')
        name = message.data.entities.receiver.entity.name;
      else
        name = message.data.entities.sender.entity.name;

    }

    status = message.sender.status;

    yield put(openNewChatWidget({
      uid,
      name,
      status,
      chatType,
    }));

  }
}
