import React, { useRef, useState } from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DezinerLabel } from './label';
import { HideContainer } from '../hide';


interface ExtraProps {
  autoHeight?: boolean,
  label?: string,
  centerLabel?: boolean,
  centerText?: boolean,
  lightGrayBorder?: boolean,
}

const useInputClasses = (props: ExtraProps) => makeStyles({
  root: {
    margin: '5px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: props.lightGrayBorder ? '#e1e1e1' : 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-root': {
      maxHeight: props.autoHeight ? 'unset' : '30px',
      borderRadius: '6px',
    }
  }
})



export function DezinerTextField(props: React.ComponentProps<typeof MaterialTextField> & ExtraProps) {

  const {
    label,
    centerLabel,
    autoHeight,
    centerText,
    lightGrayBorder,
    ...otherProps
  } = props;
  const classes = useInputClasses(props)();
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordInput = Boolean(otherProps.type == 'password');
  

  let id;

  if (label && otherProps.name)
    id = `${otherProps.name}-${label}`;

  let type = otherProps.type;

  if (isPasswordInput)
    if (showPassword)
      type = "text"
    else
      type = "password"


  return (
    <>

      <HideContainer hideWhen={!label}>
        <DezinerLabel htmlFor={id} centerLabel={centerLabel}>{label}</DezinerLabel>
      </HideContainer>

      <MaterialTextField
        id={id}
        classes={{ ...classes, ...props.classes }}
        {...otherProps}
        inputProps={{
          ...otherProps.inputProps,
          style: {
            textAlign: centerText ? 'center' : 'unset',
            ...otherProps.inputProps?.style,
          }
        }}
        type={type}
        InputProps={{
          endAdornment: <HideContainer hideWhen={!isPasswordInput}>
            <IconButton size="small" onClick={() => setShowPassword(x => !x)}>
              {
                !showPassword ?
                  <VisibilityIcon fontSize="small" />
                  :
                  <VisibilityOffIcon fontSize="small" />
              }
            </IconButton>
          </HideContainer>,
          ...otherProps.InputProps
        }}
      />
    </>
  );
}

