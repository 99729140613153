
import { Message } from "../../../framework/src/Message";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const profileMessages = {

  updateSocialMedia({ facebook, twitter, instagram }: {
    facebook: string, twitter: string, instagram: string,
  }) {
    const httpBody = {
      data: {
        attributes: {
          facebook_url: facebook,
          twitter_url: twitter,
          instagram_url: instagram,
        },
      },

    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.updateSocialMedialAPI,
    });
  },


  fetchDesignerProfileByUser({ designerId }: { designerId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchDesignerProfileByUserAPI.replace(":id", designerId),
    });
  },

  editProfile(params: {
    first_name: string,
    location: string,
    bio: string,
  }): Message {

    return apiCall({
      url: configJson.updateProfileAPI,
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.PutMethod,
      httpBody: {
        data: {
          attributes: { ...params },
        },
      },
    });
  },

  uploadProfileImage({
    profileImage,
  }: {
    profileImage: string
  }) {

    return apiCall({
      url: configJson.uploadProfilePictureAPI,
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.PutMethod,
      httpBody: {
        data: {
          attributes: {
            profile: {
              data: profileImage
            },
          },
        },
      },
    });
  },

  updateUsernameAndEmail(params: { username: string, email: string }) {

    return apiCall({
      url: configJson.updateAccountSettingsAPI,
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.PutMethod,
      httpBody: {
        data: {
          attributes: {
            user_name: params.username,
            email: params.email,
          },
        },
      },
    });
  },

  deleteAccount() {

    return apiCall({
      url: configJson.closeAccountAPI,
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.DeleteMethod,
      httpBody: {},
    });
  },

  updatePassword({
    oldPassword,
    newPassword
  }: {
    oldPassword: string,
    newPassword: string
  }) {

    return apiCall({
      url: configJson.changePasswordAPI,
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.PutMethod,
      httpBody: {
        data: {
          attributes: {
            old_password: oldPassword,
            new_password: newPassword
          },
        },
      },
    });
  },



  changeCoverMessage({ image }: { image: string }) {

    const httpBody = {
      data: {
        attributes: {
          cover: {
            data: image
          },
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: configJson.changeCoverImageAPI,
      httpMethod: configJson.PutMethod,
      httpBody,
    });
  },


  fetchProfile() {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: configJson.fetchProfileAPI,
      httpMethod: configJson.GetMethod,
    });
  },


  updateBioAndSkills({
    bio,
    skills,
  }: {
    bio: string,
    skills: { skill_id: number }[],
  }) {

    const httpBody = {
      data: {
        attributes: {
          bio,
          accounts_skills_attributes: skills
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.updateAboutAPI,
    });

  },



  verifyAadhaarNumber({ aadhaar_number }: { aadhaar_number: string }) {

    const httpBody = {
      data: {
        attributes: { aadhaar_number }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.verifyAadharAPI,
    });
  },

  validateAadhaarOtp({ clientId, otp }: { clientId: string, otp: string }) {

    const httpBody = {
      data: {
        attributes: {
          client_id: clientId,
          otp,
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.verifyAadharOtpAPI,
    });
  },

  submitBankAccount({
    bank_name,
    account_number,
    account_name,
    ifsc_code,
    branch,

  }: {
    bank_name: string,
    account_number: string,
    account_name: string,
    ifsc_code: string,
    branch: string,
  }) {

    const httpBody = {
      data: {
        attributes: {
          bank_name,
          account_number,
          account_name,
          ifsc_code,
          branch,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.updateBankDetailsAPI,
    });
  },

  fetchUserProfileByOther({ userId }: { userId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchUserProfileByOther.replace(":id", userId),
    });
  },
}
