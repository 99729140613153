import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useAlertStyles = makeStyles({
  alert: {
    margin: '5px 0',
    padding: '1px 12px',
    borderRadius: '6px',
    width: '100%'
  }
});


export function DezinerAlert(props: React.ComponentProps<typeof Alert>) {
  const classes = useAlertStyles();

  const textContent = String(props?.children);

  if(!props.children || !textContent.length) return null;

  return (
    <Alert
      severity='error'
      aria-label={textContent}
      className={clsx(classes.alert, props.className)}
      {...props} />
  );
}
