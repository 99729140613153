import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useNoDataContainerStyles = makeStyles({
  root: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '40px 0',
    color: 'gray',
  }
});

export function NoDataContainer({
  dataLength, children, message
}: {
  dataLength?: number, children?: any, message?: string
}) {

  const classes = useNoDataContainerStyles();

  if (!dataLength)
    return (
      <Typography className={classes.root}>
        {message || 'No Data'}
      </Typography>
    );

  return <>{children}</>;
}
