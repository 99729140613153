import React, { useState } from 'react';
import {
  TabPanel,
  a11yProps,
  Tab,
  Tabs,
} from './UploadDesignFormComponents.web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormikContext } from 'formik';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import { DezinerAlert, FormikTextInput, HideContainer, VerticalSpacer } from '../../../../../components/src/design-system';
import { SourceFilesDropzone } from './source-files-dropzone';
import { UploadedSourceDesigns } from './uploaded-source-designs';
import { TimePeriodInput } from './time-period-input';
import { PriceConditionsInput } from './price-conditions-input';
import clsx from 'clsx';
import { urlLinkIcon } from '../../../../catalogue/src/assets';


export function UploadDesignFormStep2() {

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Grid container justifyContent="center">

      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={(e, tabIndex) => setTabIndex(tabIndex)}
        aria-label="Vertical tabs example"
      >
        <Tab
          label="Upload your source file here*"
          style={{ color: formik.errors.sourceFiles || formik.errors.sourceLink ? 'red' : 'inherit', }}
          {...a11yProps(0)}
        />
        <Tab
          label={`Price conditions ${formik.values.privacy?.toLowerCase() == 'public' ? '(Private Designs Only)' : ''}`}
          {...a11yProps(1)}
          style={{ color: formik.values.privacy?.toLowerCase() == 'private' && !formik.values.priceConditions.length ? 'red' : 'inherit', }}
          disabled={formik.values.privacy == 'Public'}
        />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>


        <Typography className={classes.titleText}>
          {'Upload Source File'}
        </Typography>

        <VerticalSpacer />

        <Grid container alignItems="center">
          <img src={urlLinkIcon} className={clsx(classes.smallIcon, "gray-svg")} alt="link" />
          <Typography className={classes.sectionTitleText}>
            {'Upload from a URL'}
          </Typography>
        </Grid>

        <FormikTextInput
          autoHeight
          name="sourceLink"
          variant="outlined"
          fullWidth
        />

        <VerticalSpacer />

        <SourceFilesDropzone />

        <VerticalSpacer />

        <UploadedSourceDesigns />

      </TabPanel>
      <TabPanel value={tabIndex} index={1}>

        <Typography className={classes.titleText}>
          {'Price Conditions'}
        </Typography>

        <Typography className={classes.sectionTitleText}>
          {`Lifetime price:       Rs. ${formik.values.price}`}
        </Typography>

        <VerticalSpacer />

        <Typography className={classes.sectionTitleText2}>
          {'Time Period Price'}
        </Typography>

        <VerticalSpacer />

        <TimePeriodInput />

        <PriceConditionsInput />

        <HideContainer hideWhen={formik.values.privacy?.toLowerCase() != 'private' || formik.values.priceConditions.length}>
          <DezinerAlert>
            {'Please add one price condition at least.'}
          </DezinerAlert>
        </HideContainer>

      </TabPanel>

    </Grid>
  );
}
