import { DesignCategory } from "../api-types";

export const groupsKeys = {
  fetchGroups: () => ['groups'] as const,
  fetchSingleGroups: (params: { groupId: number }) => ['single-group', params] as const,

  fetchLabels: (params: { groupId: number }) => ['group-labels', params] as const,

  fetchCollections: (params: { groupId: number, labelId?: number }) => ['group-collections', params] as const,

  changeCover: (params: { groupId: number, }) => ['group-change-cover', params] as const,

  fetchDesignsByCategory: (params: {
    category: DesignCategory,
    labelId?: number,
    groupId: number,
  }) => ['groups-designs', params] as const,


  fetchMembers: (params: { groupId: number }) => ['group-members', params] as const,
  addAsAdmin: (params: { groupId: number }) => ['add-as-admin', params] as const,
  removeAdminPermission: (params: { groupId: number }) => ['remove-admin-permission', params] as const,
  removeMember: (params: { groupId: number }) => ['remove-member', params] as const,

  createLabel: (params: { groupId: number }) => ['create-group-label', params] as const,
  createCollection: (params: { groupId: number }) => ['create-group-collection', params] as const,

  updateInfo: (params: { groupId: number }) => ['update-group-info', params] as const,
  updateSocialProfiles: (params: { groupId: number }) => ['update-group-social-profiles', params] as const,

  addMemberToGroup: (params: { groupId: number }) => ['add-member', params] as const,
  fetchGroupRoles: () => ['group-roles'] as const,
  createNewGroup: () => ['create-new-group'] as const,
  fetchNonRespondingMembers: (params: { groupId: number }) => ['groups-non-responding-members', params] as const,

  followGroup: (params: { groupId: number }) => ['follow-group', params] as const,
  unfollowGroup: (params: { groupId: number }) => ['unfollow-group', params] as const,
  groupFollowers: (params: { groupId: number }) => ['group-followers', params] as const,
  acceptInvitation: (params: { groupId: number }) => ['accept-invitation', params] as const,
  declineInvitation: (params: { groupId: number }) => ['decline-invitation', params] as const,
}
