import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import {
  Reply,
  Info,
} from "@material-ui/icons";
import { promote } from '../../assets';
import DesignInfoDialog from './InfoDialog';
import { DezinerButton } from '../../../../../components/src/design-system';
import { useDesignShareLinks, useIsMyProfile, useSingleDesign } from '../../../../../components/src/hooks';
import { PromoteDialog, ShareDesignDialog } from '../../../../../components/src/common';

const useDesignMetaButtonStyles = makeStyles({
  tooltipButton: {
    margin: '5px', width: '40px', height: '40px', minWidth: '40px', border: '1px solid #e0e0e0', borderRadius: '4px'
  }
});

export default function DesignMetaButtons({
  designId,
}: {
  designId: number,
}) {

  const classes = useDesignMetaButtonStyles();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const { mutate: share } = useDesignShareLinks({ designId });

  const designQuery = useSingleDesign({ designId });
  const isLoading = designQuery.isLoading;

  const [openPromote, setOpenPromote] = useState(false);

  const designAttributes = designQuery?.data?.design?.data?.attributes;

  function onShare() {
    share({ designId });
    setShareDialogOpen(true);
  }

  function onInfo() { setInfoDialogOpen(true); }

  const shareLink = `${location.origin}/DesignView/${designId}`;

  const isMyProfile = useIsMyProfile({ id: designQuery?.data?.design?.data?.attributes?.designer?.data?.attributes?.id ?? 0 });

  function onPromote() { setOpenPromote(true); }



  if (isLoading)
    return null;

  return (
    <>

      <PromoteDialog
        open={openPromote}
        onClose={() => setOpenPromote(false)}
        mediaProps={{
          id: String(designAttributes?.id),
          src: designAttributes?.image_url ?? '',
          mediaType: designAttributes?.main_image_type?.includes('video') ? 'video' : 'image',
          watermarkURL: designAttributes?.watermark_url,
          watermarkWidth: parseFloat(designAttributes?.width ?? '0'),
          watermarkHeight: parseFloat(designAttributes?.height ?? '0'),
          autoPlay: true,
        }}
        id={String(designAttributes?.id ?? '')}
        promoteType={'Design'}
      />


      <DesignInfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        data={designAttributes}
        currentImageUrl={designAttributes?.image_url ?? ''}
      />


      <ShareDesignDialog
        open={shareDialogOpen && Boolean(shareLink)}
        mediaProps={{
          id: String(designAttributes?.id),
          src: designAttributes?.image_url ?? '',
          mediaType: designAttributes?.main_image_type?.includes('video') ? 'video' : 'image',
          watermarkURL: designAttributes?.watermark_url ?? '',
          watermarkWidth: parseFloat(designAttributes?.width ?? '0'),
          watermarkHeight: parseFloat(designAttributes?.height ?? '0'),
        }}
        shareLink={shareLink}
        onClose={() => setShareDialogOpen(false)}
        onCopy={() => { }}
      />

      <Grid container>

        <Grid item xs={4} container justifyContent="flex-end" sm={12}>
          <Tooltip title="Share" arrow placement="left">
            <DezinerButton
              className={classes.tooltipButton}
              onClick={onShare}>
              <Reply style={{ transform: "scaleX(-1)" }} />
            </DezinerButton>
          </Tooltip>
        </Grid>

        <Grid item xs={4} container justifyContent="flex-end" sm={12}>
          <Tooltip title="Info" arrow placement="left">
            <DezinerButton
              className={classes.tooltipButton}
              onClick={onInfo}>
              <Info />
            </DezinerButton>
          </Tooltip>
        </Grid>

        {
          isMyProfile ?
            <Grid item xs={4} container justifyContent="flex-end" sm={12}>
              <Tooltip title="Promote" arrow placement="left">
                <DezinerButton
                  className={classes.tooltipButton}
                  onClick={onPromote}
                >
                  <img
                    src={promote}
                    alt="promote"
                    className="black-svg"
                    style={{
                      maxWidth: '20px'
                    }}
                  />
                </DezinerButton>
              </Tooltip>
            </Grid>
            :
            null
        }

      </Grid>
    </>
  );
}
