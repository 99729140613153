import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useCommentPostButtonsStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },
  cancelBtn: {
    backgroundColor: "#e1e1e1",
    color: "#242424",
    textTransform: 'none',
    margin: '0 20px'
  },
  postBtn: {
    backgroundColor: "black",
    color: "white",
    textTransform: 'none'
  },
});

export function CommentPostButtons({
  singleLine,
  hasNoUserInput,
  onCancel,
  onPost,
}: {
  singleLine?: boolean,
  hasNoUserInput: boolean,
  onCancel: () => void,
  onPost: () => void,
}) {
  const classes = useCommentPostButtonsStyles();

  if (singleLine)
    return null;

  return (
    <div className={classes.buttonsContainer}>
      <Button
        className={classes.cancelBtn}
        onClick={onCancel}
        disabled={hasNoUserInput}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.postBtn}
        variant="contained"
        onClick={onPost}>
        {'Post'}
      </Button>
    </div>
  );
}
