import React from 'react';
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DesignerProfileImage from './profile-image';
import { Member } from '../../../../components/src/api-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const SLIDER_SETTINGS: Settings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  slidesToScroll: 5,
  variableWidth: true
}

const useStyles = makeStyles({
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    overflow: 'auto',
  }
});



export default function DesignerProfilesSlider({ members, onClick }: { members: Member[], onClick: (id: string) => void }) {

  const classes = useStyles();

  const membersUI = members?.map((profile, index: number) =>
    <DesignerProfileImage
      key={profile.id + index}
      fallbackColor={profile?.attributes?.color ?? ''}
      img={profile?.attributes?.profile_url}
      onClick={() => {
        onClick(profile.id);
      }}
      name={profile?.attributes?.first_name ?? ''}
    />
  );

  // because using the <Slider/> component for small list of members will make them appear
  // on the left side of the screen, that's why we're using simple div container
  if (members.length < 10) {
    return (
      <div className={clsx('deziner-scroll-bar', classes.centerContainer)}>
        {membersUI}
      </div>
    );
  }


  return (
    <div style={{ width: '100%' }}>
      <Slider {...SLIDER_SETTINGS}>
        {membersUI}
      </Slider>
    </div>
  );

}

