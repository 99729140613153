
import { Message } from "../../../framework/src/Message";
import { DesignCategory, UserDesignsFilter } from "../api-types";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export type LandingPageAPISortingOptions = 'viewed' | 'liked' | 'inspired' | 'recent';

export interface UploadDesignPayload {
  data: {
    attributes: {
      design_type: "Designer" | 'Group' | 'Club' | 'User',
      collection_id: number,
      watermark_id: number,
      label_id: number,
      title: string,
      designs_sub_categories_attributes: { sub_category_id: number }[],
      privacy: string,
      description: string,
      price: string,
      width: number,
      height: number,
      is_thumbnail: boolean,
      image: { data: string, },
      sub_designs: { data: string }[],
      audios: { data: string }[],
      design_url: string,
      source_designs_attributes: {
        data: string,
        type: string,
        name: string,
        file_size: number,
      }[],
      time_period_type: string,
      design_prices_attributes: { year: number, month: number, amount: number }[],
      club_id?: number,
      group_id?: number,
    },
  },
};





export const designsMessages = {

  fetchDesignsCount() {

    return apiCall({
      header: appendJsonContentType({}),
      httpMethod: configJSON.GetMethod,
      url: configJson.fetchDesignsCountAPI,
    });

  },

  saveDesign({ designId, collectionId }: { designId: number, collectionId: number }) {

    const httpBody = {
      "data": {
        "attributes": {
          "design_id": designId,
          "collection_id": collectionId,
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJson.saveDesignAPI,
    });
  },

  fetchBoughtDesigns() {
    const url = configJson.createDesignPaymentIntentAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });

  },

  createPaymentIntent({
    designId,
    price,
    paidType,
    pricePeriodId
  }: {
    designId: number,
    price: number,
    paidType: 'Limited Period' | 'Lifetime',
    pricePeriodId?: number | null,
  }) {

    let body: any = {
      "buy_design": {
        "design_id": designId,
        "amount": price,
        "paid_type": paidType,
      }
    }

    if (pricePeriodId && paidType == 'Limited Period') {
      body.buy_design.design_price_id = pricePeriodId;
    }

    const url = configJson.createDesignPaymentIntentAPI;


    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: body,
      httpMethod: configJSON.PostMethod,
      url: url,
    });

  },


  inspireDesign({ designId }: { designId: number }) {

    let url = configJSON.inspireDesignAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "data": {
          "attributes": {
            "design_id": designId,
          }
        }
      },
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },

  likeDesign({ designId }: { designId: number }) {

    let url = configJSON.likeDesignAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "data": {
          "attributes": {
            "likeable_type": "BxBlockAttachment::Design", // This is forum type. please send like this.
            "likeable_id": designId,
          }
        }
      },
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },

  fetchSourceFiles({ designId }: { designId: number }) {

    let url = configJSON.fetchSourceFilesAPI.replace(':id', designId);

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });

  },

  updateDesign({ payload, designId }: { designId: number, payload: UploadDesignPayload }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: payload,
      httpMethod: configJSON.PutMethod,
      url: `${configJSON.uploadDesignAPI}/${designId}`,
    });

  },

  uploadDesign({ payload }: { payload: UploadDesignPayload }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: payload,
      httpMethod: configJSON.PostMethod,
      url: configJSON.uploadDesignAPI,
    });

  },

  uploadUserDesign({ payload, collectionId }: {
    payload: { title: string, description: string, media: string[], },
    collectionId: number,
  }) {

    const { media, ...payloadRest } = payload;

    const httpBody = {
      "data": {
        "attributes": {
          "collection_id": collectionId,
          "design_type": "User",
          ...payloadRest,
          "image": { "data": payload?.media?.[0] ?? '' },
          "sub_designs": media.slice(1).map((data) => ({ data })),
        }
      }
    }

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.uploadDesignAPI,
    });

  },

  rateDesign({ designId, rate }: { designId: number, rate: number }) {

    const httpBody = {
      data: {
        attributes: {
          design_id: designId,
          point: rate,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.submitRatingAPI,
    });

  },


  fetchInspiredUsers({ designId }: { designId: number }) {

    const url = configJSON.inspiredUsersAPI.replace(':id', designId);

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });

  },


  fetchMoreDesigns({ designId }: { designId: number }) {

    const url = configJSON.fetchMoreDesignsAPI.replace(':id', designId);

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });
  },


  fetchShareLinks({ designId }: { designId: number }) {

    const httpBody = {
      data: {
        attributes: {
          sharable_type: "BxBlockAttachment::Design",
          sharable_id: designId
        }
      }
    };

    const url = configJSON.fetchDesignShareLinkAPI;

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },



  fetchSingleDesign({ designId }: { designId: number }) {

    let url = `${configJSON.fetchSingleDesignAPI}/${designId}`;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: url,
    });
  },




  fetchDesignerDesigns({ endpoint, url, labelId }: { endpoint: string, url?: string, labelId?: number }) {

    let _url = url || `${configJson.fetchDesignsBaseAPI}/${endpoint}`;

    let params: any = {}
    if (labelId) {
      params.label_id = labelId;
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: _url,
      params,
    });

  },

  fetchUsersDesigns({
    category,
    filter,
  }: {
    category: DesignCategory,
    filter: UserDesignsFilter,
  }): Message {

    let endpoint = '';
    let requestParams: any = {}
    let baseURL = configJson.userDesignsBaseAPI;

    if (filter != 'all') {
      requestParams.filter = filter;
    }

    switch (category) {
      case 'liked': { endpoint = 'user_like_designs'; break; }
      case 'all': { endpoint = 'user_designs'; break; }
      case 'rated': { endpoint = 'user_raterd_designs'; break; }
      case 'inspired': { endpoint = 'user_inspired_designs'; break; }
      case 'saved': { endpoint = 'user_saved_designs'; break; }
      case 'shared': { endpoint = 'user_shared_designs'; break; }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: `${baseURL}/${endpoint}`,
      httpMethod: configJson.GetMethod,
      params: requestParams
    });
  },



  fetchDesignerDesignsByUser({
    designerId,
    endpoint,
    labelId,
    overrideURL,
    filter,
  }: {
    designerId: number,
    endpoint: string,
    labelId?: number,
    overrideURL?: string,
    filter?: UserDesignsFilter,
  }) {

    if (Object.is(NaN, designerId))
      designerId = 0;

    let params: any = {}
    let url = overrideURL || `${configJson.fetchDesignerDesignsByUserBaseAPI.replace(":id", designerId)}${endpoint ? '/' : ''}${endpoint}`;

    if (labelId && labelId > 0)
      params.label_id = labelId;

    if (filter)
      params.filter = filter;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url,
      params
    });
  },





  fetchLandingPageDesigns({
    page = 1,
    filter = 'All',
    selectedCategories = [],
    sorting,
  }: {
    page: number,
    filter: string,
    selectedCategories: number[],
    sorting: LandingPageAPISortingOptions,
  }) {


    let params: any = {
      page,
      design_type: filter,
      sorting,
    }

    if (selectedCategories.length)
      params['sub_categories[]'] = String(selectedCategories);


    return apiCall({
      header: appendJsonContentType({}),
      httpMethod: configJSON.GetMethod,
      url: configJSON.landingPageDesignsAPI,
      params
    });
  },

  fetchSearchResults({
    searchBy,
    selectedCategories = [],
    searchTerm = '',
    chargeFor = 'Free',
    sortBy = '',
  }: {
    searchBy: any,
    selectedCategories: any[],
    searchTerm: string,
    chargeFor: string,
    sortBy: string,
  }) {
    if (!searchBy) {
      searchBy = "All";
    }

    let params: any = {
      ['search_by[]']: searchBy,
      ['charge_for[]']: chargeFor,
      search: searchTerm,
      sorting: sortBy
    }

    if (selectedCategories.length > 0)
      params['category_of[]'] = String(selectedCategories);


    return apiCall({
      header: appendJsonContentType({}),
      httpMethod: configJSON.GetMethod,
      url: configJSON.globalSearchQuery,
      params
    });
  },

  fetchSearchSuggestions({
    searchTerm,
  }: {
    searchTerm: string,
  }) {
    let params: any = {
      search: searchTerm,
    }

    return apiCall({
      header: appendJsonContentType({}),
      httpMethod: configJSON.GetMethod,
      url: configJSON.globalSearchSuggestionQuery,
      params
    });
  }
}
