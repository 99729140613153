import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJson = require('../config.js');

export const watermarkMessages = {

  fetchWaterMarks() {

    return apiCall({
      url: configJson.fetchWatermarksAPI,
      httpMethod: configJson.GetMethod,
      httpBody: {},
      header: appendToken(appendJsonContentType({})),
    });
  }
};
