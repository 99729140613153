import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { ProfileAvatarUI } from "../../../components/src/common";
import { DesignComment, DesignReply, PostedByAttributes, } from "../../../components/src/api-types";
import { useCommentNameClickHandler, } from "./CommentUtils";

const useCommentProfilePictureStyles = makeStyles({
  profilePicture: {
    width: '75px',
    height: '75px',
    borderRadius: '40%',
    marginRight: '20px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
});

export function CommentProfilePicture({
  comment,
  size,
}: {
  comment: DesignComment | DesignReply,
  size?: "small",
}) {
  const classes = useCommentProfilePictureStyles();

  const nameClickHandler = useCommentNameClickHandler(comment);

  let attributes: PostedByAttributes | undefined;

  if (comment.type == 'comment')
    attributes = comment?.attributes?.comment_by?.data?.attributes;
  else
    attributes = comment?.attributes?.repled_by?.data?.attributes;

  return (
    <ProfileAvatarUI
      size={size}
      className={classes.profilePicture}
      image={attributes?.profile_url}
      color={attributes?.color ?? ''}
      alt={attributes?.first_name}
      onClick={nameClickHandler}
    />
  );
}

