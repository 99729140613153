import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  designCollectionGroup: {
    maxWidth: "450px",
    display: "grid",
    gridTemplateAreas:
      "'main main main'\n 'left center right'\n 'text text text'",
    columnGap: "5px",
    rowGap: "8px",
    padding: "10px",
    width: "100%",
    position: "relative",
    cursor: "pointer",
    '&:hover .overlay': {
      opacity: 1,
    }
  },
  designCollectionMainImageContainer: {
    background: "lightgrey",
    height: "200px",
    textAlign: "center",
    width: "200px",
    minWidth: "100%",
    fontWeight: "bold",
    margin: "0 auto",
    cursor: "pointer",
    gridArea: "main",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    '&:hover': {
      cursor: "pointer"
    },
  },
  designCollectionMainImage: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    objectFit: "cover",
  },
  designCollectionMainImageOverlay: {
    gridRow: "1/2",
    gridColumn: "1/4",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.326)",
    opacity: 0,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "20px",
    zIndex: 10,
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "white",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    "& button:hover": { background: "#ddd" },
    "& button img": {
      maxWidth: "20px",
      width: "20px",
      height: "20px"
    },
  },
  collectionSubImage1: {
    gridArea: "left",
    borderBottomLeftRadius: "15px"
  },
  collectionSubImage2: { gridArea: "center" },
  collectionSubImage3: {
    gridArea: "right",
    borderBottomRightRadius: "15px"
  },
  groupTitle: {
    textAlign: "center",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  collectionCounter: {
    textAlign: "center",
    fontSize: "0.8rem",
    color: "#bfbfbf"
  },
  groupText: { gridArea: "text" },
  mainImage: {
    '& img': {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      height: "100%",
      width: "100%"
    }
  },
  collectionSubImage: {
    background: "lightgrey",
    height: "60px",
    textAlign: "center",
    width: "40px",
    minWidth: "100%",
    fontWeight: "bold",
    margin: "0 auto",
    cursor: "pointer",
    objectFit: "cover",
  }
});

