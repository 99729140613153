import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import {GeneralResponsiveCardsContainer} from '../../../../../components/src/common';
import { useSaveDesign, useMyUserCollections } from '../../../../../components/src/hooks';
import { DesignHome } from '../../../../../components/src/api-types';
import { makeStyles } from '@material-ui/core/styles';
import { DezinerMedia, DezinerButton } from '../../../../../components/src/design-system';

export function QuickSaveToUserCollection({
  open,
  onUpload,
  onClose,
  collectionName,
  collectionId,
}: {
  open: boolean,
  onUpload: () => void,
  onClose: () => void,
  collectionName: string,
  collectionId: number,
}) {


  const collectionsQuery = useMyUserCollections();

  let designs = (collectionsQuery?.data?.data?.map(col => col?.attributes?.designs?.data) || []).flat();

  const [savedDesigns, setSavedDesigns] = useState<string[]>([]);

  let filteredDesigns = designs.filter(design => !savedDesigns.includes(design.id));


  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >

      <DialogTitle style={{ position: 'relative' }}>
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          {`Save Designs to your new collection "${collectionName}"`}
        </Typography>
        <IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ padding: '10px 50px' }}>

        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
          <GeneralResponsiveCardsContainer>
            {
              ({ itemHeight, itemWidth }) =>
                filteredDesigns?.map((item) => (
                  <DesignCardForSave
                    key={item.id}
                    itemWidth={itemWidth}
                    itemHeight={itemHeight}
                    item={item}
                    collectionId={collectionId}
                    onSave={() => {
                      setSavedDesigns(currentIds => currentIds.concat(item.id));
                    }}
                  />
                ))
            }
          </GeneralResponsiveCardsContainer>
        </div>

        <Grid container justifyContent="flex-end" style={{ padding: '20px 0' }}>
          <DezinerButton
            color="primary"
            variant="contained"
            onClick={onUpload}
          >
            {'Upload your designs'}
          </DezinerButton>
        </Grid>

      </DialogContent>

    </Dialog>
  );
}

const useDesignCardForSaveStyles = makeStyles({
  container: {
    position: 'relative',
  },
  saveBtn: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
  },
});

function DesignCardForSave({
  item,
  itemHeight,
  itemWidth,
  collectionId,
  onSave,
}: {
  item: DesignHome,
  itemHeight: number,
  itemWidth: number,
  collectionId: number,
  onSave: () => void,
}) {

  const classes = useDesignCardForSaveStyles();
  const { mutate: saveDesign, isLoading } = useSaveDesign({ designId: item.attributes.id });


  return (
    <div className={classes.container}>

      <DezinerMedia
        id={item?.id}
        src={item?.attributes?.image_url}
        style={{ height: `${itemHeight}px`, width: `${itemWidth}px`, borderRadius: '10px' }}
        watermarkURL={item?.attributes?.watermark_url}
        watermarkWidth={Number(item?.attributes?.width ?? '')}
        watermarkHeight={Number(item?.attributes?.height ?? '')}
      />

      <DezinerButton
        loading={isLoading}
        disabled={isLoading || !collectionId}
        className={classes.saveBtn}
        variant="contained"
        onClick={() => saveDesign({ designId: item?.attributes.id, collectionId }, { onSuccess: onSave })}
        color="primary">
        {'Save'}
      </DezinerButton>

      <Typography>
        {item?.attributes?.title}
      </Typography>
    </div>
  );
}

