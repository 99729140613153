import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ProfileAvatarUI } from '../../../components/src/common';
import {
  useMyUID,
  useCometChatLatestConversationsList,
} from './hooks';
import {
  CometChatGroupConversation,
} from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import {  HideContainer ,HorizontalSpacer } from '../../../components/src/design-system';
import { GreenDot, RedDot } from './OnlineOfflineDot';

const useStyles = makeStyles({
  userCard: {
    padding: '10px',
  },
  name: {

  },
  status: {
    color: 'gray',
  },
})

export default function ChatUsernameContainer() {

  const name = useSelector((state: RootState) => state.ui.chatWidget.name);
  const uid = useSelector((state: RootState) => state.ui.chatWidget.uid);
  const chatType = useSelector((state: RootState) => state.ui.chatWidget.chatType);
  const onlineStatus = useSelector((state: RootState) => state.ui.chatWidget.status);
  const realtimeOnlineStatus = useSelector((state: RootState) => state.ui.chatOnlineStatus[uid]);

  const myUid = useMyUID();
  const conversationListQuery = useCometChatLatestConversationsList({ uid: myUid });
  const conversationList = conversationListQuery?.data;

  let onlineMembersCount: any = 0;
  const isGroup = chatType == 'group';
  if (isGroup) {
    let targetGroup = conversationList
      ?.filter(item => item.conversationType == "group")
      ?.find((conv) => (conv as CometChatGroupConversation).conversationWith.guid == uid)

    onlineMembersCount = (targetGroup as CometChatGroupConversation)?.conversationWith.onlineMembersCount;
  }

  const userStatus = realtimeOnlineStatus || onlineStatus;
  const groupStatus = onlineMembersCount > 0 ? 'online' : 'offline';

  const groupDescription = groupStatus == 'online' ?
    `${onlineMembersCount} members online` : 'No Members online';

  const description = isGroup ? groupDescription : userStatus;

  return (
    <ChatUsername
      name={name}
      status={isGroup ? groupStatus : userStatus}
      description={description}
    />
  )
}

export function ChatUsername({
  name,
  status,
  description,
}: {
  name: string,
  status: 'offline' | 'online',
  description: string,
}) {
  const classes = useStyles();

  return (
    <Grid item container wrap="nowrap" className={classes.userCard}>
      <Grid item>
        <ProfileAvatarUI
          image={''}
          alt={name}
          size="small"
        />
      </Grid>

      <Grid item><HorizontalSpacer /></Grid>

      <Grid item container direction="column">
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.status}>
          <HideContainer hideWhen={status == 'online'}>
            <RedDot />
          </HideContainer>
          <HideContainer hideWhen={status == 'offline'}>
            <GreenDot />
          </HideContainer>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}
