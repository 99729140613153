import React, { useState } from 'react';
import NotificationsIcon from "@material-ui/icons/Notifications";
import Hidden from '@material-ui/core/Hidden';
import Select from '@material-ui/core/Select';
import SearchIcon from "@material-ui/icons/Search";
import { dezinerKnotLogo } from '../../../blocks/landingpage/src/assets';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, AppBar, Toolbar, IconButton, MenuItem, Badge } from '@material-ui/core';
import { defaultValuesTo } from '../utils';
import { NavProfileAvatar } from './nav-profile-avatar';
import { NotLoggedInHeader } from './not-authorized-nav';
import { TopNavChatIcon } from './chat-icon';



const useTopNavStyles = makeStyles({
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSelect-icon': {
      color: 'white !important',
    }
  },
});



export interface DesignerInfo {
  currentClubName?: string,
  currentGroupName?: string,
  clubClickHandler?: () => void,
  groupClickHandler?: () => void,
  promoteHandler?: () => void,
  logoutHandler?: () => void,
  editProfileHandler?: () => void,
  onMySelfClickHandler?: () => void,
}


interface DezinerTopNavProps {
  profileImage?: string,
  color?: string,

  onLogoClick?: () => void,
  onNotificationClick?: () => void,
  onSearchClick?: () => void,

  hideAvatarMenu?: boolean,

  userInfo?: {
    onProfileClick?: () => void,
  },

  designerInfo?: DesignerInfo,

  isLoggedIn?: boolean,
  onSignInAsDesigner?: () => void,
  onSignInAsUser?: () => void,

  role?: "User" | "Designer",
}

const EMPTY_FUNC = () => {
  // do nothing
};







const DEFAULT_DEZINER_TOP_NAV_PROPS = {
  profileImage: '',
  color: '',
  onLogoClick: EMPTY_FUNC,
  onNotificationClick: EMPTY_FUNC,
  onChatClick: EMPTY_FUNC,
  onSearchClick: EMPTY_FUNC,
  hideAvatarMenu: false,
  userInfo: {
    onProfileClick: EMPTY_FUNC,
  },
  designerInfo: undefined,
  isLoggedIn: false,
  onSignInAsDesigner: EMPTY_FUNC,
  onSignInAsUser: EMPTY_FUNC,
  role: "Designer",
}

export const DEFAULT_DESIGNER_INFO: DesignerInfo = {
  currentClubName: 'Club',
  currentGroupName: 'Group',
  clubClickHandler: EMPTY_FUNC,
  groupClickHandler: EMPTY_FUNC,
  onMySelfClickHandler: EMPTY_FUNC,
}

export function DezinerTopNav(props: DezinerTopNavProps) {

  const {
    profileImage,
    color,
    onLogoClick,
    userInfo,
    designerInfo,
    onSearchClick,
    hideAvatarMenu,
    isLoggedIn,
    onSignInAsDesigner,
    onSignInAsUser,
    role,
    onNotificationClick,
  } = defaultValuesTo<DezinerTopNavProps>(DEFAULT_DEZINER_TOP_NAV_PROPS)(props);

  const {
    currentClubName,
    currentGroupName,
    clubClickHandler,
    groupClickHandler,
    onMySelfClickHandler,
  } = defaultValuesTo<DesignerInfo>(DEFAULT_DESIGNER_INFO)(designerInfo);

  const classes = useTopNavStyles();

  const [notificationCount, setNotificationCount] = useState(0);

  let selectedValue = "Myself";

  if (props?.designerInfo?.currentClubName)
    selectedValue = "Club";
  if (props?.designerInfo?.currentGroupName)
    selectedValue = "Group";



  if (!isLoggedIn) {
    return (
      <NotLoggedInHeader
        onLogoClick={onLogoClick}
        onSignInAsDesigner={onSignInAsDesigner}
        onSignInAsUser={onSignInAsUser}
      />
    );

  }


  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={onLogoClick}
        />

        <Grid container justifyContent="flex-end">

          <Hidden xsDown>
            <IconButton id='globalAppSearch' onClick={onSearchClick || EMPTY_FUNC} color="inherit">
              <SearchIcon />
            </IconButton>

            <IconButton onClick={onNotificationClick} color="inherit">
              <Badge badgeContent={2} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <TopNavChatIcon/>

          </Hidden>


          {
            role != "User" ?
              <div className={classes.selectContainer}>
                <Select
                  style={{ backgroundColor: "black", color: "white" }}
                  value={selectedValue}
                >
                  <MenuItem
                    value="Myself"
                    onClick={onMySelfClickHandler}
                  >
                    {'Myself'}
                  </MenuItem>
                  <MenuItem
                    value="Club"
                    onClick={clubClickHandler}
                  >
                    {currentClubName}
                  </MenuItem>
                  <MenuItem
                    value="Group"
                    onClick={groupClickHandler}
                  >
                    {currentGroupName}
                  </MenuItem>
                </Select>
              </div>
              :
              null
          }

          <NavProfileAvatar
            image={profileImage}
            color={color}
            designerInfo={designerInfo}
            onClick={role == "User" ? userInfo?.onProfileClick : EMPTY_FUNC}
            hideMenu={hideAvatarMenu}
          />

        </Grid>

      </Toolbar>
    </AppBar>
  );
}

