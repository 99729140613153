import React, { useContext, } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { LandingPageDesign, } from "../../../../components/src/api-types";
import { useLandingPageData, } from "../../../../components/src/hooks";
import { GeneralResponsiveCardsContainer, DesignCardWithProfileContainer } from '../../../../components/src/common';
import { LandingPageContext } from './landing-page-content';


const useDesignsContainerStyles = makeStyles({
  root: {
    padding: '60px 50px',
  },
});




const MemorizedDesignCard = React.memo(DesignCardWithProfileContainer)


export function DesignsContainer() {

  const { selectedFilter, selectedCategories, selectedSortOption } = useContext(LandingPageContext);

  const landingPageQuery = useLandingPageData({
    selectedFilter,
    selectedCategories,
    sorting: selectedSortOption,
  });
  
  const designs = (landingPageQuery?.data?.pages ?? [])
    ?.map(page => page?.data?.designes?.data ?? [])
    ?.flat()
    ?? [];

  return (
    <DesignsListContainer designs={designs} />
  );
}

function DesignsListContainer({ designs }: { designs: LandingPageDesign[] }) {

  const classes = useDesignsContainerStyles();

  return (
    <div className={classes.root}>
      <GeneralResponsiveCardsContainer>
        {
          ({ itemHeight }) =>
            designs 
              .map((item) => (
                <MemorizedDesignCard
                  key={item?.id}
                  imgHeight={itemHeight}
                  item={item}
                />
              ))
        }
      </GeneralResponsiveCardsContainer>
    </div>
  );
}


