import React from 'react';
import { likes } from '../../assets';
import { makeStyles, } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DezinerButton, } from '../../../../../components/src/design-system';

const useStyles = makeStyles({
  floatButton: {
    boxShadow: '0 0 5px #cecaca',
    padding: '6px 18px',
    border: 'none !important',
    marginLeft: '15px',
  },
  icon: {
    maxWidth: '20px',
  },
});

export function LikeButton({
  show,
  onClick,
  liked,
}: {
  show: boolean,
  onClick: () => void,
  liked?: boolean,
}) {

  const classes = useStyles();

  if (!show)
    return null;

  return (
    <DezinerButton
      color="primary"
      variant="contained"
      className={classes.floatButton}
      startIcon={<img src={likes} className={clsx('white-svg', classes.icon)} />}
      onClick={onClick}
    >
      {liked ? 'Liked' : 'Like'}
    </DezinerButton>
  );
}
