Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.getTokenValidationApi = "account_block/accounts/profile"
exports.labelBodyText = "LandingPage Body";
exports.landingPageEndPoint = "account_block/landings";
exports.labelApi = 'bx_block_categories/labels';
exports.collectionsApi = 'account_block/collections';
exports.profileApi = 'account_block/accounts/profile';
exports.addNewLabel = 'bx_block_categories/labels';
exports.designApi = 'bx_block_attachment/designs';
exports.postComment = 'bx_block_comments/comments';
exports.saveDesign = 'bx_block_attachment/save_designs';
exports.submitRating = 'bx_block_catalogue/ratings';
exports.designerGetDesignsApi = "account_block/collections";
exports.otherUserAboutApi = "account_block/accounts"
exports.otherUserDesignsApi = "account_block/accounts"
exports.checkIfPromoted = "bx_block_attachment/promotes/check_promoted"
exports.promoteApi = 'bx_block_attachment/promotes'
exports.userCollectionCreation = 'account_block/collections'
exports.userGetCollectionApi = 'account_block/collections'
exports.userUploadDesignApi = 'bx_block_attachment/designs'
exports.userSavedDesignsApi = 'account_block/accounts/user_saved_designs'
exports.saveDesignAfterCreateCollectionApi = '/bx_block_attachment/save_designs'

// exports.profileApi = 'account_block/accounts/profile';
exports.btnExampleTitle = "CLICK ME";
exports.designerDesigns = 'bx_block_attachment/designs';
exports.designerLikedDesigns = 'account_block/accounts/like_designs';
exports.designerRatedDesigns = 'account_block/accounts/raterd_designs';
exports.designerInspiredDesigns = 'account_block/accounts/inspired_designs';
exports.designerPromotedDesigns = 'account_block/accounts/promoted_designs';
exports.designerFreeDesigns = 'account_block/accounts/free_designs';
exports.designerPaidDesigns = 'account_block/accounts/paid_designs';
exports.inspiredAccs = "bx_block_attachment/designs";
exports.userLandingPageApi = "/account_block/landings/landing_page";
exports.createGroupApi = "bx_block_groups/groups";
exports.createClubApi = "bx_block_groups/clubs";
exports.likeDesign = "bx_block_like/likes";
exports.uploadProfile = "account_block/accounts/upload_profile";
exports.updateProfile = "account_block/accounts/update_profile";
exports.accountSettings = "account_block/accounts/account_settings";
exports.closeAccount = "account_block/accounts/close_account";
exports.changePassword = "account_block/accounts/change_password";
exports.putMethod = "PUT";
exports.delMethod = "DELETE";
exports.userDesings = "account_block/accounts/user_designs";
exports.likedDesings = "account_block/accounts/user_like_designs";
exports.userInspiredDesigns = "account_block/accounts/user_inspired_designs";
exports.userRatedDesigns = "account_block/accounts/user_raterd_designs";
exports.userSharedDesigns = "account_block/accounts/user_shared_designs";
exports.userAbout = "account_block/accounts/about";
exports.otherUserRatedDesigns = "account_block/accounts/";
exports.designSharingLink = "bx_block_share/share";
exports.inspireAcc = "/bx_block_catalogue/inspires";
exports.skillsList = "bx_block_categories/skills";
exports.categoriesList = "bx_block_categories/categories";
exports.categoriesListAPIMethod = "GET";
exports.categoriesListHeaderContentType = "application/json";
exports.updateAbout = "account_block/accounts/update_about";
exports.exampleAPiMethodPut = "PUT";
exports.designerProfileApiUser = "account_block/accounts";
exports.validateCardDetails = "bx_block_attachment/promotes/card_validation";
exports.changeCoverDesigner = "account_block/accounts/change_cover";
exports.verifyAadhar = "account_block/accounts/aadhaar_ekyc";
exports.verifyAadharOtp = "account_block/accounts/aadhaar_otp_varification";
exports.updateBankDetails = "account_block/bank_details";
exports.updateSocialProfiles = "account_block/accounts/social_profile";
exports.pinCollection = "bx_block_cfpindesigns2/pins";
exports.unPinCollection = "bx_block_cfpindesigns2/pins/unpin";
exports.fetchCommentsAPI = "bx_block_attachment/designs/:id/design_comments";
exports.fetchCommentsMethod = "GET";
exports.fetchMoreDesignsAPI = "bx_block_attachment/designs/:id/more_designs";
exports.fetchMoreDesignsMethod = "GET";

exports.GetMethod = 'GET';
exports.PostMethod = 'POST';
exports.PutMethod = 'PUT';
exports.DeleteMethod = 'Delete';
// Customizable Area End
