import React from "react";
import { CollectionHome } from "../../api-types";
import { CollectionGridCard } from "./collection-grid-card";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 370px)",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    gridGap: "20px",
    marginBottom: "50px",
    width: "100%",
    maxWidth: "100%",
    ['@media screen and (max-width: 768px)']: {
      justifyContent: 'center'
    }
  }
});

interface Props {
  collection: CollectionHome[],
  onClick: (collection: CollectionHome) => void,
}

export function CollectionGirdCards(props: Props) {

  const classes = useStyles();
  const { collection, onClick } = props;

  return (
    <div className={classes.root}>
      {
        collection?.length ?
          collection?.map((item: any) => (
            <CollectionGridCard
              key={item.id}
              {...item}
              onClick={() => onClick(item)}
            />
          ))
          :
          null
      }
    </div>
  );
}

