import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DezinerButton} from '../../../components/src/design-system';

export function DeleteMemberDialog({
  open,
  onClose,
  memberName,
  onAccept,
  deleteType,
}: {
  open: boolean,
  onClose: () => void,
  memberName: string,
  onAccept: () => void,
  deleteType: "remove_as_admin" | "remove_member"
}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-member"
    >
      <DialogTitle id="delete-member">{'Delete Member'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            deleteType == 'remove_member' ?
              <>
                {`Are you sure, you want to remove `}
                <b>{memberName}</b>
                {` from this club?`}
              </>
              :
              <>
                {`Are you sure, you want to remove `}
                <b>{memberName}</b>
                {` admin permission?`}
              </>
          }

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DezinerButton onClick={onClose} color="primary">
          {'Cancel'}
        </DezinerButton>
        <DezinerButton
          onClick={onAccept}
          color="primary"
          autoFocus
        >
          {'Remove'}
        </DezinerButton>
      </DialogActions>
    </Dialog>
  );
}
