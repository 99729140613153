import { QueryObserverOptions, useMutation, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import {
  APIData,
  Design,
  DesignCategory,
  LandingPageDesign,
  LandingPageResponse,
  Profile,
  SourceFile,
  UserDesignsFilter,
} from "../api-types";
import { designsMessages, LandingPageAPISortingOptions } from "../messages-generator";
import { clubsKeys, collectionsKeys, designsKeys, groupsKeys, profileKeys } from "../query-keys";
import { useDezinerInfiniteQuery, useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";
import { useIsMyProfile } from "./utils";

export function useDesignsCount() {

  return useDezinerQuery<{ design_count: number }>({
    message: designsMessages.fetchDesignsCount(),
    queryKey: designsKeys.fetchDesignsCount(),
  });
}

export function useBoughtDesigns() {

  return useDezinerQuery({
    message: designsMessages.fetchBoughtDesigns(),
    queryKey: designsKeys.fetchBoughtDesigns(),
  });
}


export function useCreateBuyIntent(
  params: Omit<Parameters<typeof designsMessages.createPaymentIntent>[0], "price">
) {

  return useDezinerMutation<Parameters<typeof designsMessages.createPaymentIntent>[0]>({
    messageGenerator: designsMessages.createPaymentIntent,
    queryKey: designsKeys.createPaymentIntent(params),
  });

}

export function useInspireDesign(
  { designId }: { designId: number },
  options?: Parameters<typeof useMutation>['2'],
) {

  const queryClient = useQueryClient();
  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.inspireDesign>[0]>({
    messageGenerator: designsMessages.inspireDesign,
    queryKey: designsKeys.inspireDesign({ designId }),
    options: {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));
        queryClient.invalidateQueries(designsKeys.fetchInspiredUsers({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }))

        if (options?.onSuccess)
          options.onSuccess(...args);
      }
    }
  });
}

function useDesignerIdByDesignFromQueryClient({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const data: any = queryClient.getQueryData(designsKeys.fetchSingleDesign({ designId }))
  const design = data?.design?.data as Design;
  const designerId = design?.attributes?.designer?.data?.attributes?.id ?? 0;

  return designerId;
}


export function useLikeDesign({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.likeDesign>[0]>({
    messageGenerator: designsMessages.likeDesign,
    queryKey: designsKeys.likeDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      }
    }
  });
}


export function useSourceFiles({ designId }: { designId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: SourceFile[], design_url: string }>({
    message: designsMessages.fetchSourceFiles({ designId }),
    queryKey: designsKeys.fetchSourceFiles({ designId }),
    options,
  });
}


export function useUpdateDesign({ designId, labelId, designerId }: { designId: number, labelId: number, designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.updateDesign>[0]>({
    messageGenerator: designsMessages.updateDesign,
    queryKey: designsKeys.updateDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchCollections({ labelId, designerId }));
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));
      }
    }
  });
}

export function useUserUploadDesign() {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.uploadUserDesign>[0]>({
    messageGenerator: designsMessages.uploadUserDesign,
    queryKey: designsKeys.uploadUserDesign(),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchUserCollections());
      }
    }
  });
}

export function useUploadDesign({
  collectionId,
  labelId,
  designerId,
  clubId,
  groupId,
}: {
  collectionId: number,
  labelId: number,
  designerId: number,
  clubId?: number,
  groupId?: number,
}) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.uploadDesign>[0]>({
    messageGenerator: designsMessages.uploadDesign,
    queryKey: designsKeys.uploadDesign({ labelId, collectionId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchCollections({ labelId, designerId }));

        if (clubId)
          queryClient.invalidateQueries(clubsKeys.fetchCollections({ labelId, clubId }));

        if (groupId)
          queryClient.invalidateQueries(groupsKeys.fetchCollections({ labelId, groupId }));

      }
    }
  });
}


export function useRateDesign({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.rateDesign>[0]>({
    messageGenerator: designsMessages.rateDesign,
    queryKey: designsKeys.rateDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      },
    },
  });
}

export function useInspiredUsers({ designId }: { designId: number, }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Profile[] }>({
    queryKey: designsKeys.fetchInspiredUsers({ designId }),
    message: designsMessages.fetchInspiredUsers({ designId }),
    options,
  });
}


export function useMoreDesigns({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: Design[] }>({
    queryKey: designsKeys.fetchMoreDesigns({ designId }),
    message: designsMessages.fetchMoreDesigns({ designId }),
  });
}


/*
 * it's important to send this request when the user clicks on share button in order 
 * to count the total shared count from the API, even though we are not using the 
 * response right now
 */
export function useDesignShareLinks({ designId }: { designId: number }) {

  return useDezinerMutation<Parameters<typeof designsMessages.fetchShareLinks>[0]>({
    messageGenerator: designsMessages.fetchShareLinks,
    queryKey: designsKeys.fetchShareLinks({ designId }),
  });
}




export function useSingleDesign({ designId }: { designId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ design: APIData<Design> }>({
    message: designsMessages.fetchSingleDesign({ designId }),
    queryKey: designsKeys.fetchSingleDesign({ designId }),
    options: {
      ...options,
      enabled: (options?.enabled ?? true) && Boolean(designId),
    }
  });
}


export function useLandingPageData({
  selectedCategories,
  selectedFilter,
  options,
  sorting,
}: {
  selectedCategories: number[],
  selectedFilter: string,
  options?: Parameters<typeof useInfiniteQuery>['2'],
  sorting: LandingPageAPISortingOptions,
}) {

  return useDezinerInfiniteQuery<LandingPageResponse>({
    messageGenerator: designsMessages.fetchLandingPageDesigns,
    queryKey: designsKeys.fetchLandingPageDesigns({ sorting, selectedCategories, filter: selectedFilter }),
    defaultPageParam: {
      selectedCategories,
      filter: selectedFilter,
      page: 1,
      sorting
    },
    options: {
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage: any, pages: any[]) => {

        if (((lastPage as LandingPageResponse)?.data?.designes?.data?.length ?? 0) < 16)
          return undefined;

        return {
          selectedCategories, 
          filter: selectedFilter, 
          page: pages.length + 1,
          sorting,
        }
      }
    }
  });
}

export function useSearchResults(params: {
  searchBy: any,
  selectedCategories: any[],
  searchTerm: string,
  chargeFor: string,
  sortBy: string,
}) {
  return useDezinerQuery<{ data: { designes: { data: LandingPageDesign[] } } }>({
    message: designsMessages.fetchSearchResults(params),
    queryKey: designsKeys.fetchSearchResults(params),
  });
}

export function useSearchSuggestions({ searchTerm }: {
  searchTerm: string,
}) {
  return useDezinerInfiniteQuery<any>({
    messageGenerator: designsMessages.fetchSearchSuggestions,
    queryKey: designsKeys.fetchSearchSuggestions({
      searchTerm,
    }),
    defaultPageParam: {
      searchTerm
    },
  });
}

export function useDesignerDesignsByUser({
  designerId,
  labelId,
  category,
  filter,
}: {
  designerId: number,
  labelId: number,
  category: DesignCategory,
  filter?: UserDesignsFilter,
}, options?: QueryObserverOptions) {



  const categoryToEndpointMapper: {
    [key in DesignCategory]: string
  } = {
    'collections': '',
    'designs': 'other_user_all_designs',
    'liked': 'other_user_liked_designs',
    'promoted': 'other_user_promoted_designs',
    'rated': 'other_user_rated_designs',
    'inspired': 'other_user_inspired_designs',
    'free': 'other_user_free_designs',
    'paid': 'other_user_paid_designs',
    'all': '',
    saved: 'other_user_saved_designs',
    shared: 'other_user_shared_designs'
  }


  let endpoint = categoryToEndpointMapper?.[category] ?? '';



  return useDezinerQuery<{ data: Design[], meta: any }>({
    message: designsMessages.fetchDesignerDesignsByUser({
      endpoint,
      designerId,
      labelId,
      filter
    }),
    queryKey: designsKeys.fetchDesignerDesignsByUser({ designerId, labelId, category, filter }),
    options: {
      ...options,
      enabled: (options?.enabled ?? true) && Boolean(designerId) && category != 'collections',
    }
  });
}

export function useSaveDesign(params: Parameters<typeof designsKeys.saveDesign>[0]) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.saveDesign>[0]>({
    messageGenerator: designsMessages.saveDesign,
    queryKey: designsKeys.saveDesign(params),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(collectionsKeys.fetchUserCollections());
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId: params.designId }));
      },
    }
  });
}


export function useUserOwnDesigns({
  filter,
  category,
}: {
  filter: UserDesignsFilter,
  category: DesignCategory
}, options?: QueryObserverOptions) {


  return useDezinerQuery<{ data: Design[], meta: any }>({
    message: designsMessages.fetchUsersDesigns({ filter, category }),
    queryKey: designsKeys.fetchUserDesigns({ category, filter }),
    options,
  });

}

export function useUserDesigns({
  filter,
  category,
  userId,
}: {
  filter: UserDesignsFilter,
  category: DesignCategory
  userId: number,
}) {

  userId = Number(userId);

  const isMyProfile = useIsMyProfile({ id: userId });

  const ownerResponse = useUserOwnDesigns({ filter, category }, { enabled: isMyProfile });
  const otherResponse = useDesignerDesignsByUser({
    category: category == 'all' ? 'designs' : category,
    labelId: 0,
    designerId: userId,
    filter,
  }, {
    enabled: !isMyProfile
  });


  if (isMyProfile)
    return ownerResponse;
  else
    return otherResponse;
}

export function useDesigns({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}) {

  const isMyProfile = useIsMyProfile({ id: designerId });

  const designsQuery = useDesignerDesigns({ category, labelId, designerId }, {
    enabled: isMyProfile
  });

  const designsByOthers = useDesignerDesignsByUser({ designerId, labelId, category }, {
    enabled: !isMyProfile
  });

  return isMyProfile ? designsQuery : designsByOthers;
}

export function useDesignerDesigns({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {


  const categoryToEndpointMapper: {
    [key in DesignCategory]: string
  } = {
    'collections': '',
    'designs': '',
    'liked': 'like_designs',
    'promoted': 'promoted_designs',
    'rated': 'raterd_designs',
    'inspired': 'inspired_designs',
    'free': 'free_designs',
    'paid': 'paid_designs',
    'all': '',
    saved: '',
    shared: ''
  }


  return useDezinerQuery<{ data: Design[] }>({
    queryKey: designsKeys.fetchDesigns({ category, labelId, designerId }),
    message: designsMessages.fetchDesignerDesigns({
      endpoint: categoryToEndpointMapper[category],
      url: category == 'designs' ? 'bx_block_attachment/designs' : '',
      labelId,
    }),
    options: {
      ...options,
      enabled: (options?.enabled ?? true) && category != 'collections',
    },
  });
}

