import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useCometChatConverstaionHistoryWithUser,
  useMyUID,
} from './hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import Skeleton from '@material-ui/lab/Skeleton';
import ChatMessageView from './ChatMessageView';


export default function ChatMessages() {

  const messagesContainerRef = useRef<any>();
  const myUid = useMyUID();

  const uid = useSelector((state: RootState) => state.ui.chatWidget.uid);
  const chatType = useSelector((state: RootState) => state.ui.chatWidget.chatType);
  const minimized = useSelector((state: RootState) => state.ui.chatWidget.minimized);


  const prevMessagesQuery = useCometChatConverstaionHistoryWithUser({ uid, chatType });
  const prevMessages = prevMessagesQuery?.data ?? [];
  const loadingPrevMessages = prevMessagesQuery.isLoading;

  let liveMessages = useSelector((state: RootState) => state.messages?.realTime?.[uid] ?? []);

  const previousConversationIds = prevMessages.map(x => x.id);
  liveMessages = liveMessages.filter((message) => !previousConversationIds.includes(message.id));

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current?.scrollTo({ top: messagesContainerRef.current?.scrollHeight, behavior: 'smooth' });
    }
  }, [liveMessages.length, prevMessages.length, minimized]);

  const showSenderName = chatType == 'group';


  return (
    <Grid item container wrap="nowrap" direction="column"
      style={{
        flexGrow: 1,
        padding: '15px 25px',
        height: 0,
        maxWidth: '100%',
        overflow: 'auto',
      }}
      innerRef={messagesContainerRef}
    >
      {
        loadingPrevMessages ?
          Array(8).fill(0).map((_x, index) =>
            <Skeleton
              aria-label="message-loader"
              key={index}
              variant="rect"
              width={'100%'}
              height={40}
              style={{ margin: '5px 0', borderRadius: '10px' }} />
          )
          :
          null
      }
      {
        prevMessages?.map((message, index) => {
          let isFirstMessageInList = prevMessages?.[index - 1]?.sender?.name != message?.sender?.name;
          let isLastMessageInList = prevMessages?.[index + 1]?.sender?.name != message?.sender?.name;
          let _showSenderName = showSenderName && isFirstMessageInList;
          let isLastMessage = index == prevMessages.length - 1;
          let isMyMessage = message?.sender.uid == myUid;
          let isLast = isLastMessage || isLastMessageInList;

          return (
            <ChatMessageView
              key={message?.id}
              message={message}
              isMyMessage={isMyMessage}
              enableReadMarker={false}
              showSenderName={_showSenderName}
              isLast={isLast}
            />
          )
        })
      }
      {
        liveMessages?.map((message, index) => {

          let isFirstMessageInList = liveMessages?.[index - 1]?.sender?.name != message?.sender?.name;
          let isLastMessageInList = liveMessages?.[index + 1]?.sender?.name != message?.sender?.name;
          let _showSenderName = showSenderName && isFirstMessageInList;
          let isLastMessage = index == liveMessages.length - 1;
          let isLast = isLastMessage || isLastMessageInList;
          let isMyMessage = message?.sender.uid == myUid;
          let enableReadMarker = !isMyMessage && isLast;

          return (
            <ChatMessageView
              key={message?.id}
              message={message}
              isMyMessage={isMyMessage}
              enableReadMarker={enableReadMarker}
              showSenderName={_showSenderName}
              isLast={isLast}
            />
          )
        })
      }
    </Grid>
  )
}
