import React from "react";
// Customizable Area Start
import CreateGroupForm from "./CreateGroupForm.web";
import LogoTitleHeader from "./LogoTitleHeader.web";
// Customizable Area End

import CreateGroupController, { Props } from "./CreateGroupController.web";
import { useCategories, useDesignersList, useNavigate } from "../../../components/src/hooks";
import { useCreateGroup } from "../../../components/src/hooks/groups";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { Category, Profile } from "../../../components/src/api-types";

class CreateGroup extends CreateGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (<CreateGroupContent />);
  }
}


function CreateGroupContent() {


  const navigate = useNavigate();
  const categoriesQuery = useCategories();

  const formattedCategories = defaultToEmptyArray<Category>(categoriesQuery?.data?.data)
  ?.map((category) => {
    const subCategories = category.attributes.sub_categories.data.map((subCategory) => {
      return {
        value: subCategory.attributes.id,
        label: subCategory.attributes.name,
      };
    })
    return {
      label: category.attributes.name,
      options: subCategories,
    }
  })

  let listOfCategories = formattedCategories
    ?.map((item) => (
      item.options.map((option) => ({ ...option, parentLabel: item.label }))
    ))
    ?.flat();



  const { mutate: createGroup, isLoading: loading } = useCreateGroup();

  const designerListQuery = useDesignersList();
  const designerList = defaultToEmptyArray<Profile>(designerListQuery?.data?.data);


  return (
    <>
      <LogoTitleHeader
        title="Create your group"
        onLogoClick={() => {
          navigate("DesignerGroupPageView");
        }}
      />

      <CreateGroupForm
        categories={listOfCategories}
        members={designerList.map((member) => ({
          label: member.attributes.first_name,
          value: member.id,
        }))}
        onSubmit={(values) => {
          createGroup({
            name: values.groupName,
            description: values.groupDescription,
            image: values.profileImage,
            cover: values.coverImage,
            account_ids: values.groupMembers.map((x: any) => ({ account_id: x.value })),
            groups_sub_categories_attributes: values.categories.map((x: any) => ({ sub_category_id: x.value })),
          }, {
            onSuccess(response) {
              navigate(`GroupHomePage/${response?.data?.id}`);
            }
          })
        }}
        loading={loading}
      />
    </>
  );

}

// Customizable Area Start
// Customizable Area End
export default CreateGroup;
// Customizable Area End
