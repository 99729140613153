import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../components/src/store/store';
import { useParams } from '../../../../../components/src/utils';
import { useCollections, useIsMyProfile, useNavigate, useSingleCollection } from '../../../../../components/src/hooks';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  editSolidDark,
  trashSolidIcon,
} from "../../assets";
import Divider from '@material-ui/core/Divider';
import { defaultToEmptyArray } from '../../../../../components/src/default-values';
import { HideContainer, VerticalSpacer, DezinerButton, DezinerSpinner, CollectionGirdCards } from '../../../../../components/src/design-system';
import { DesignCardsResponsiveContainer, ProfileAvatar } from '../../../../../components/src/common';


const useStyles = makeStyles({
  root: {
    padding: '40px 20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  moreCollections: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  divider: {
    width: "100%", background: "gray", height: "1px"
  },
  main: {
    padding: '40px 20px',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  designerHeaderContainer: {
  },
  iconButton: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '0'
  },
  iconImg: {
    width: '20px',
    maxWidth: '20px',
  },
  buttonsContainer: {
    '&>*': {
      margin: '4px'
    }
  },
  title: {
    color: "#000002", fontSize: "1.7rem", fontWeight: "bold"
  },
  subtitle: {
    fontSize: "1.1rem",
    fontWeight: 500,
    margin: "2px 0 30px",
    color: "#4a4a4a"
  },
  firstName: {
    fontSize: "1.1rem", fontWeight: "bold", padding: "0 10px"
  },
  createButton: {
    padding: '5px 10px'
  },
  profileImg: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  }
});


export default function CollectionViewPageContent() {

  const classes = useStyles();

  const params = useParams();
  const queryParams = useSelector((state: RootState) => state.router.location.query);

  const collectionId = parseInt(params.collectionId);
  const labelId = parseInt(queryParams.label_id || '0');
  const clubId = parseInt(queryParams.club_id || '0')
  const groupId = parseInt(queryParams.group_id || '0')

  const singleCollectionQuery = useSingleCollection({ collectionId });
  const collectionAttributes = singleCollectionQuery?.data?.data?.attributes;

  const collectionDesignerId = collectionAttributes?.account?.data?.attributes?.id ?? 0;

  const collectionsQuery = useCollections({
    labelId: labelId,
    category: 'collections',
    designerId: collectionDesignerId,
  }, {
    enabled: Boolean(collectionDesignerId)
  });

  const navigate = useNavigate();

  const isMyProfile = useIsMyProfile({ id: collectionDesignerId });

  function createHandler() {
    let url = '';
    let labelString = labelId ? `?label_id=${labelId}` : ''

    if (groupId)
      url = `GroupCreateCollection/${groupId}${labelString}`;
    else if (clubId)
      url = `ClubCreateCollection/${clubId}${labelString}`;
    else
      url = `Catalogue/${collectionAttributes?.account?.data?.id}$${labelString}`;

    navigate(url)
  }

  function placeholderClickHandler() {
    let url = '';

    if (clubId)
      url = `DesignerDesignUpload/${labelId}/${collectionId}/club/${clubId}`;
    else if (groupId)
      url = `DesignerDesignUpload/${labelId}/${collectionId}/group/${groupId}`;
    else if (isMyProfile)
      url = `DesignerDesignUpload/${labelId}/${collectionId}/Designer/0`

    if (url)
      navigate(url);
  }


  if (singleCollectionQuery?.isLoading)
    return <DezinerSpinner />



  return (
    <div>

      <div className={classes.root}>

        <Grid
          className={classes.designerHeaderContainer}
          container justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} sm={6} container direction="column">

            <Typography className={classes.title}>
              {collectionAttributes?.name ?? ''}
            </Typography>
            <Typography className={classes.subtitle}>
              {`${collectionAttributes?.design_count ?? 0} Design`}
            </Typography>

            <Grid container alignItems="center">
              <ProfileAvatar id={collectionAttributes?.account?.data?.attributes?.id ?? 0} />
              <Typography className={classes.firstName}>
                {collectionAttributes?.account?.data?.attributes?.first_name}
              </Typography>
            </Grid>

          </Grid>

          <HideContainer hideWhen={!isMyProfile}>

            <Grid item xs={12} sm={6} container
              justifyContent="flex-end"
              className={classes.buttonsContainer}
            >
              <DezinerButton variant="outlined" color="primary" className={classes.iconButton}>
                <img className={classes.iconImg} src={trashSolidIcon} alt="delete" />
              </DezinerButton>
              <DezinerButton variant="outlined" color="primary" className={classes.iconButton}>
                <img className={classes.iconImg} src={editSolidDark} alt="edit" />
              </DezinerButton>
              <DezinerButton
                onClick={createHandler}
                className={classes.createButton}
                variant="outlined"
                color="primary">
                {'Create Collection'}
              </DezinerButton>
            </Grid>

          </HideContainer>

        </Grid>


        <VerticalSpacer />
        <VerticalSpacer />

        <DesignCardsResponsiveContainer
          designs={collectionAttributes?.designs?.data ?? []}
          onClick={(item) => {
            navigate(`DesignView/${item.id}`);
          }}
          onTrend={() => { }}
          onEdit={(item) => {
            if (isMyProfile)
              navigate(`DesignerDesignEdit/${item.id}`);
          }}
          includeAddDesignPlaceholder={isMyProfile}
          onPlaceholderClick={placeholderClickHandler}
        />


        <VerticalSpacer value={100} />

        <Divider className={classes.divider} />

        <VerticalSpacer value={30} />

        <Typography className={classes.moreCollections}>
          {'More Collections'}
        </Typography>

        <VerticalSpacer value={30} />

        <CollectionGirdCards
          collection={defaultToEmptyArray(collectionsQuery?.data?.data)}
          onClick={(collection) => {
            navigate(`collection/${collection.id}?label_id=${collection.attributes.label_id}`);
          }}
        />

      </div>

    </div>
  );
}


