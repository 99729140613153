import React from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {DezinerButton} from '../../../components/src/design-system';
import DesignerProfileWithOptions from '../../../blocks/Groups/src/DesignerProfileWithOptions.web';
import { ClubMember, GroupMember } from '../../../components/src/api-types';
import { Close as CloseIcon } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import PeopleIcon from "@material-ui/icons/People";
import { makeStyles } from '@material-ui/core/styles';

const useDialogStyles = makeStyles({
	paper: {
		borderRadius: '10px',
    overflow: 'inherit',
	}
});

export function MembersDialog({
  open,
  onClose,
  title,
  profileImage,
  onAddMember,

  members,
  currentRole,
  onDelete,
  addAsAdmin,
}: {
  open: boolean,
  onClose: () => void,
  title: string,
  profileImage: string,
  onAddMember: () => void,

  members: (ClubMember | GroupMember)[],
  currentRole: string,
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
  addAsAdmin: (params: {
    memberId: number,
  }) => void,
}) {

  const dialogClasses = useDialogStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      classes={dialogClasses}
    >


      <DialogTitle style={{}}>
        <div style={{ position: 'relative' }}>
          <Grid container justifyContent="center" style={{ position: 'absolute', top: '-70px' }}>
            {
              profileImage ?
                <img
                  src={profileImage}
                  alt={title}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '40%',
                    objectFit: 'cover',
                  }}
                />
                :
                <div style={{
                  background: '#7A5354',
                  width: '100px',
                  height: '100px',
                  borderRadius: '40%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}>
                  {title?.split(' ')?.map((x: string) => x[0])?.join('')}
                </div>
            }
          </Grid>

          <Typography style={{ textAlign: 'center', paddingTop: '50px', fontSize: '1.2rem', fontWeight: 'bold' }}>
            {title}
          </Typography>

          <IconButton
            style={{ position: 'absolute', right: '-10px', top: '-10px' }}
          >
            <CloseIcon
              onClick={onClose}
            />
          </IconButton>

        </div>
      </DialogTitle>


      <DialogContent style={{ paddingBottom: '30px' }}>

        <Grid container justifyContent="space-between">
          <Typography>
            <PeopleIcon style={{ marginRight: "10px" }} />
            {'Members'}
          </Typography>

          <DezinerButton
            color="primary"
            variant="text"
            style={{ cursor: "pointer" }}
            onClick={onAddMember}
            startIcon={<AddIcon />}
          >
            {'Add Member'}
          </DezinerButton>
        </Grid>

        <hr />

        <Grid container direction="column">
          {
            members?.map((member) => (
              <div key={member?.id}>
                <DesignerProfileWithOptions
                  horizontal
                  imageSize={50}
                  currentRole={currentRole}
                  member={member}
                  onDelete={onDelete}
                  addAsAdmin={addAsAdmin}
                />
              </div>
            ))
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
