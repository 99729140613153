import * as yup from 'yup';


export const socialMediaLinksValidationSchema = yup.object().shape({
  facebook: yup.string().url('URL is not valid')
    .test('test-url', 'Not a valid Facebook URL', (value) => {
      if (!value)
        return true;
      return /https:\/\/(www.)*facebook.com\/.*/g.test(value);
    }),
  twitter: yup.string().url('URL is not valid')
    .test('test-url', 'Not a valid Twitter URL', (value) => {
      if (!value)
        return true;
      return /https:\/\/(www.)*twitter.com\/.*/g.test(value);
    }),
  instagram: yup.string().url('URL is not valid')
    .test('test-url', 'Not a valid Instagram URL', (value) => {
      if (!value)
        return true;
      return /https:\/\/(www.)*instagram.com\/.*/g.test(value);
    }),
});

