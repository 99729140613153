import { useFormikContext } from "formik";
import { UplodaDesignInitValues } from "./UploadDesignForm.web";
import React from 'react';
import { HideContainer } from "../../../../../components/src/design-system";
import { useUploadDesignFormStyles } from "./UploadDesignFormStyle.web";
import Typography from '@material-ui/core/Typography';
import { zipFileExtensionIcon, videoExtensionIcon, imageExtensionIcon } from "../../../../catalogue/src/assets";
import { statisticsFormatter } from "../../../../../components/src/utils";
import { UploadedSourceFile } from "./UploadDesignFormComponents.web";

export function UploadedSourceDesigns() {

  const formik = useFormikContext<UplodaDesignInitValues>();
  const classes = useUploadDesignFormStyles();


  return (
    <>
      <HideContainer hideWhen={!formik.values.sourceFiles?.length}>
        <Typography className={classes.sectionTitleText}>
          {'Uploaded files'}
        </Typography>
      </HideContainer>

      {
        formik.values.sourceFiles
          ?.map((item, index: any) => {
            const isNewFile = Boolean(item.file);

            const type = isNewFile ? item?.file?.type : (item?.design_type ?? 'image');

            let iconImage = imageExtensionIcon;

            if (type == "application/zip")
              iconImage = zipFileExtensionIcon;

            if (type?.startsWith('video'))
              iconImage = videoExtensionIcon;

            const name = isNewFile ? item.file?.name : item.name;

            const sizeInBytes = isNewFile ? (item?.file?.size ?? 1) : item.file_size;
            const size = statisticsFormatter(sizeInBytes);

            const removeHandler = () => {
              formik.setFieldValue('sourceFiles', formik.values.sourceFiles.slice().filter((x, i) => i != index));
            }

            return (
              <UploadedSourceFile
                key={item?.name || item?.file?.name}
                removeHandler={removeHandler}
                name={name || ''}
                size={size}
                title={name || ''}
                iconImage={iconImage}
              />
            );
          })
      }

    </>
  );
}
