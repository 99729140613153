import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useRedDotStyles = makeStyles({
  greenDot: {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '50%',
    background: 'red',
    marginRight: '10px',
  }
})

export function RedDot() {
  const classes = useRedDotStyles();
  return <span className={classes.greenDot} />;
}


const useGreenDotStyles = makeStyles({
  greenDot: {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '50%',
    background: '#01cd54',
    marginRight: '10px',
  }
})

export function GreenDot() {
  const classes = useGreenDotStyles();
  return <span className={classes.greenDot} />;
}