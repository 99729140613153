export const profileKeys = {

  updateSocialMedia: (params: { designerId: number }) => ['social-media', params] as const,
  updateAbout: (params: { designerId: number }) => ['update-about', params] as const,

  fetchProfile: (params: { id: number }) => ['profile', params] as const,
  fetchProfileByUser: (params: { id: number }) => ['profile-by-user', params] as const,
  fetchUserProfileByOther: (params: { userId: number }) => ['user-profile-by-other', params] as const,

  updateCover: () => ['change-cover'] as const,
  updateProfileImage: () => ['change-profile-image'] as const,
  updateProfile: () => ['update-profile'] as const,

  updateUsernameAndEmail: () => ['update-username-email'] as const,
  updateBioAndSkills: () => ['update-bio-skills'] as const,
  deleteAccount: () => ['delete-account'] as const,
  updatePassword: () => ['update-password'] as const,

  submitBankAccount: (params: { id: number }) => ['submit-bank-account', params] as const,

  validateAadhaarNumber: (params: { id: number }) => ['validate-aadhaar', params] as const,

  verifyAadhaar: (params: { id: number }) => ['verify-aadhaar', params] as const,
}
