import React, { ComponentProps, useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MaterialSlider from "@material-ui/core/Slider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MaterialDialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { ChevronLeft, Close as CloseIcon, Edit } from "@material-ui/icons";
import { PromotionType } from "../api-types";
import { useCheckIfPromoted, usePromote, useValidateCreditCard } from "../hooks";
import { HideContainer, DezinerAlert, DezinerMedia, DezinerButton } from "../design-system";
import { Formik, Form, } from 'formik';
import * as yup from 'yup';

const creditCardFormValidation = yup.object().shape({
  cardNumber: yup.string().required('card number required').length(16),
  cardExpiration: yup.string().required('card expiration is required')
    .test('is-valid',
      'Expiration should have the format MM/YY (e.g: 12/27)',
      (value) => {
        try {
          let hasValidFormat = /^\d{2}\/\d{2}$/.test(value);
          let month = parseInt(value.split('/')[0] || 0);
          let year = parseInt(value.split('/')[1] || 0);

          let hasValidMonth = month >= 0 && month <= 12;
          let hasValidYear = (2000 + year) > new Date().getFullYear();

          const isValid = hasValidFormat && hasValidMonth && hasValidYear;

          return isValid;
        } catch (error) {
          return false;
        }
      }),
  cvv: yup.string().required().min(3).max(4),
});


const TextField = withStyles({
  root: {
    '& > *': {
      maxHeight: '40px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#7c7d7f',
      },
      '&:hover fieldset': {
        borderColor: '#7c7d7f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#black',
      },
    },
  }
})(MaterialTextField);

const Slider = withStyles({
  root: {
    color: '#323335',
    height: 8,
    display: 'block',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '8px solid #08080a',
    marginTop: -8,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    whiteSpace: 'nowrap',
    left: 'calc(-50% - 5px)',
    top: -28,
    '& *': {
      background: 'transparent',
      color: '#767676',
    },
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'gray',
  },
  markLabel: {
    '&[data-index="0"]': {
      top: '40px !important',
      left: "10% !important",
      width: "200px",
      transform: "translateX(-20%)",
      whiteSpace: "nowrap",
      color: '#767676',
    },
    '&[data-index="1"]': {
      top: '40px !important',
      left: "90% !important",
      width: "200px",
      textAlign: "right",
      transform: "translateX(-80%)",
      whiteSpace: "nowrap",
      color: '#767676',
    },
  },
  track: {
    height: 8,
    borderRadius: 0,
  },
  rail: {
    height: 8,
    borderRadius: 0,
  },
})(MaterialSlider);

const Dialog = withStyles({
  paper: {
    borderRadius: '10px',
  }
})(MaterialDialog);


const useStyles = makeStyles({
  imageContainer: {
    background: "#e7e7e7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px"
  },
  innerImageContainer: {
    maxWidth: "80%",
    width: 'auto',
    position: "relative",
    top: "-50px",
  },
  image: {
    maxHeight: "320px",
    borderRadius: "10px",
    boxShadow: "4px 4px 4px #5e5e5e",
    objectFit: 'cover',
  },
  contentContainer: {
    padding: "20px 40px",
    overflowX: 'hidden',
    '& > *': {
      margin: '20px 0',
    }
  },

  centerText: {
    textAlign: 'center',
  },
  iconButton: {
    background: '#e1e1e1'
  },
  closeBtn: {
    position: 'fixed',
    top: '3vh',
    right: '3vw',
  },
  closeIcon: {
    color: 'white'
  },
  stepTitle: {
    textAlign: 'center',
    margin: "0",
    fontSize: "1.4rem",
    fontWeight: 900
  },
  sliderSpace: {
    height: '8px'
  },
  calculatedPhrase: {
    color: '#767676',
    fontWeight: 700, fontSize: "0.9rem"
  },
  totalPhrase: {
    color: 'black',
  },
  nextBtn: {
    backgroundColor: "black",
    color: "white",
    textTransform: 'none',
    borderRadius: '10px',
    minWidth: '45px',
    minHeight: '45px',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: "black",
    }
  },
  prevBtn: {
    backgroundColor: "#e2e3e5",
    color: "black",
    borderRadius: '10px',
    minWidth: '45px',
    minHeight: '45px',
    maxWidth: '45px',
    maxHeight: '45px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0'
  },
  titleText: {
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: 800,
  },
  subTitleText: {
    color: '#7c7d7f',
    fontSize: '1rem',
    fontWeight: 800,
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',

    '& label': {
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 800,
      marginBottom: '10px'
    }
  },
  horizontalSpace: {
    width: '40px'
  },
  step3Container: {
    '&>*': {
      margin: '20px 0'
    },
  }
});



let marks: any = [
  {
    value: 10000,
    label: "10,000",
  },
  {
    value: 100000,
    label: "1,00,000 impressions",
  },
];


let marksMonth: any = [
  {
    value: 1,
    label: "1 Month",
  },
  {
    value: 12,
    label: "12 Months",
  },
];


export function CreditCardForm({
  totalAmount,
  onBack,
  onSubmit,
  disabled,
  subscription,
}: {
  disabled: boolean,
  totalAmount: number,
  onBack: () => void,
  onSubmit: (params: {
    cardNumber: string,
    expMonth: number,
    expYear: number,
    cvv: string,
  }) => void,
  subscription: any
}) {

  const classes = useStyles();

  const { mutate: validateCreditCard, isError: isInvalidCard, isLoading } = useValidateCreditCard();

  return (
    <Formik
      initialValues={{
        cardNumber: '',
        cardExpiration: '',
        cvv: '',
      }}
      validationSchema={creditCardFormValidation}
      onSubmit={(values) => {

        validateCreditCard({
          cardNumber: values.cardNumber,
          cvc: values.cvv,
          expMonth: parseInt(values.cardExpiration.split('/')[0]),
          expYear: 2000 + parseInt(values.cardExpiration.split('/')[1]),
        }, {
          onSuccess() {
            onSubmit({
              cardNumber: values.cardNumber,
              cvv: values.cvv,
              expMonth: parseInt(values.cardExpiration.split('/')[0]),
              expYear: 2000 + parseInt(values.cardExpiration.split('/')[1]),
            });
          }
        })
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {
        formik => (
          <Form translate="yes">
            <div className={classes.step3Container}>

              <Typography variant="body1" className={classes.stepTitle}>
                {'Payment Details'}
              </Typography>


              <div className={classes.formItem}>
                <label>{'Card Number'}</label>
                <TextField
                  variant="outlined"
                  value={formik.values.cardNumber}
                  placeholder="0000 0000 0000 0000"
                  type="text"
                  onChange={(event) => formik.setFieldValue('cardNumber', event.target.value)}
                  inputProps={{
                    maxLength: 16,
                    minLength: 16,
                  }}
                />

                <HideContainer hideWhen={!formik.errors.cardNumber}>
                  <DezinerAlert>
                    {formik.errors.cardNumber}
                  </DezinerAlert>
                </HideContainer>

              </div>

              <div className={classes.flexBetween}>

                <div className={classes.formItem}>
                  <label>{'Expiration Date'}</label>
                  <TextField
                    value={formik.values.cardExpiration}
                    placeholder="MM/YY"
                    type="text"
                    variant="outlined"
                    onChange={(event) => formik.setFieldValue('cardExpiration', event.target.value)}
                    inputProps={{
                      maxLength: 5,
                      minLength: 5,
                    }}
                  />

                  <HideContainer hideWhen={!formik.errors.cardExpiration}>
                    <DezinerAlert>
                      {formik.errors.cardExpiration}
                    </DezinerAlert>
                  </HideContainer>

                </div>

                <div className={classes.horizontalSpace} />

                <div className={classes.formItem}>
                  <label>{'CVV'}</label>
                  <TextField
                    value={formik.values.cvv}
                    onChange={(event) => formik.setFieldValue('cvv', event.target.value)}
                    placeholder="123"
                    variant="outlined"
                    type="text"
                    inputProps={{
                      maxLength: 4,
                      minLength: 3,
                    }}
                  />

                  <HideContainer hideWhen={!formik.errors.cvv}>
                    <DezinerAlert>
                      {formik.errors.cvv}
                    </DezinerAlert>
                  </HideContainer>
                </div>

              </div>

              <Divider />

              <div>
                <div className={classes.flexBetween}>
                  <Typography className={classes.titleText}>{'Subtotal'}</Typography>
                  <Typography
                    className={classes.subTitleText}
                  >
                    {`Rs. ${totalAmount}`}
                  </Typography>
                </div>

                <div className={classes.flexBetween}>
                  <Typography className={classes.titleText}>{'Total'}</Typography>
                  <Typography className={classes.titleText}>{`Rs. ${totalAmount}`}</Typography>
                </div>
              </div>

            </div>

            <HideContainer hideWhen={!isInvalidCard}>
              <DezinerAlert>
                {'Invalid Credit Card'}
              </DezinerAlert>
            </HideContainer>
            <DialogActions className={classes.dialogActions}>
              {!subscription && (
                <Button className={classes.prevBtn} variant="outlined" onClick={() => onBack()}>
                  <ChevronLeft fontSize="large" />
                </Button>
              )}

              <DezinerButton
                className={classes.nextBtn}
                variant="contained"
                type="submit"
                disabled={isLoading || disabled}
                loading={isLoading}
              >
                {subscription ? "Subscibe" : "Promote"}
              </DezinerButton>
            </DialogActions>
          </Form>
        )
      }
    </Formik>
  );
}

function ReviewPromotion({
  onSubmit,
  totalAmount,
  impressions,
  onEdit,
}: {
  onSubmit: () => void,
  totalAmount: number,
  impressions: number,
  onEdit: () => void,
}) {

  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" className={classes.stepTitle}>
        {'Review Your Boost'}
      </Typography>


      <div className={classes.flexBetween}>
        <div>
          <Typography className={classes.titleText}>{'Button Link'}</Typography>
          <Typography className={classes.subTitleText}>{'Design'}</Typography>
        </div>
      </div>

      <div className={classes.flexBetween}>
        <div>
          <Typography className={classes.titleText}>{'Total views'}</Typography>
          <Typography className={classes.subTitleText}>{impressions}</Typography>
        </div>
        <IconButton>
          <Edit onClick={onEdit} />
        </IconButton>
      </div>

      <div className={classes.flexBetween}>
        <div>
          <Typography className={classes.titleText}>{'Total Price'}</Typography>
          <Typography className={classes.subTitleText}>{`Rs. ${totalAmount}`}</Typography>
        </div>
      </div>

      <DialogActions className={classes.dialogActions}>


        <Button className={classes.prevBtn} variant="outlined" onClick={() => onEdit()}>
          <ChevronLeft fontSize="large" />
        </Button>

        <DezinerButton
          className={classes.nextBtn}
          variant="contained"
          onClick={onSubmit}
        >
          {"Next"}
        </DezinerButton>
      </DialogActions>

    </>
  );

}

function PromoteSlider(props: {
  totalAmount: number,
  id: string,
  type: PromotionType,
  onSubmit: (params: { impressions: number, duration: number }) => void,
  impressions: number,
  duration: number,
}) {


  const {
    totalAmount,
    id,
    type,
    onSubmit,
    impressions: initImpressions,
    duration: initDuration,
  } = props;

  const classes = useStyles();

  const [impressions, setImpressions] = useState(initImpressions);
  const [duration, setDuration] = useState(initDuration);

  const { mutate: checkIfPromoted, isError: isAlreadyPromoted, isLoading } = useCheckIfPromoted({ id });

  function nextHandler() {
    checkIfPromoted({ type, id }, {
      onSuccess() {
        onSubmit({ impressions, duration })
      }
    })
  }


  return (
    <>
      <Typography variant="body1" className={classes.stepTitle}>
        {'Budget & Duration'}
      </Typography>

      <div className={classes.sliderSpace} />

      <Slider
        defaultValue={impressions}
        min={10000}
        max={100000}
        valueLabelFormat={x => x}
        onChange={(_e, v) => setImpressions(Number(v))}
        aria-labelledby="impressions"
        step={10000}
        marks={marks}
        valueLabelDisplay="on"
      />

      <div className={classes.sliderSpace} />

      <Slider
        defaultValue={duration}
        min={1}
        max={12}
        valueLabelFormat={x => x}
        onChange={(_e, v) => setDuration(Number(v))}
        aria-labelledby="duration"
        step={1}
        valueLabelDisplay="on"
        marks={marksMonth}
      />

      <div className={classes.sliderSpace} />

      <Grid container justifyContent="space-between">
        <Grid item xs={9}>
          <Typography className={classes.calculatedPhrase}>
            {`${impressions} total impressions over ${duration} Month`}
          </Typography>
        </Grid>

        <Grid item xs={3} container justifyContent="flex-end">
          <Typography className={classes.totalPhrase}>
            <b>{`Rs. ${totalAmount}`}</b>
          </Typography>
        </Grid>
      </Grid>

      <HideContainer hideWhen={!isAlreadyPromoted}>
        <DezinerAlert>
          {'Already promoted'}
        </DezinerAlert>
      </HideContainer>

      <DialogActions className={classes.dialogActions}>

        <span />

        <DezinerButton
          disabled={isLoading || isAlreadyPromoted}
          className={classes.nextBtn}
          variant="contained"
          onClick={nextHandler}
          loading={isLoading}
        >
          {"Next"}
        </DezinerButton>
      </DialogActions>
    </>
  );
}

function calculateAmount({
  impressions, duration, promoteType,
}: {
  impressions: number, duration: number, promoteType: PromotionType
}) {

  let totalAmount = (impressions / 10000) * 500;

  if (promoteType == "Account") {
    totalAmount = (impressions / 10000) * 1000;
  } else if (promoteType == "Collection") {
    totalAmount = (impressions / 10000) * 500 + 250;
  }
  /* else if (promoteType == "Group/Club") {
     totalAmount = (impressions / 10000) * 1500;
   }*/

  if (duration < 12) {
    const durationTax = (12 - duration) * 5;
    totalAmount += (totalAmount * durationTax) / 100;
  }

  return totalAmount;
}


const useDialogStyles = makeStyles({
  paper: {
    borderRadius: '10px',
    overflow: 'inherit',
  }
});


export function PromoteDialog({
  open,
  onClose,
  id,
  promoteType,
  mediaProps,
}: {
  open: boolean,
  onClose: () => void,
  id: string,
  promoteType: PromotionType,
  mediaProps: ComponentProps<typeof DezinerMedia>,
}) {

  const classes = useStyles();

  const [impressions, setImpressions] = useState(10000);
  const [duration, setDuration] = useState(1);
  const [step, setStep] = useState(1);

  const {
    mutate: promote,
    isSuccess: promoteSuccess,
    isError: promoteError,
    isLoading,
    reset: resetPromote
  } = usePromote({ id });

  function closeHandler() {
    onClose()
    setStep(1);
    setDuration(1);
    setImpressions(10000);
    resetPromote();
  }


  let totalAmount = calculateAmount({
    impressions,
    duration,
    promoteType
  });

  useEffect(() => {
    if (promoteSuccess) {
      setTimeout(closeHandler, 1000);
    }
  }, [promoteSuccess]);

  const Step1 = (<PromoteSlider
    impressions={impressions}
    duration={duration}
    onSubmit={({ duration, impressions }) => {
      setDuration(duration);
      setImpressions(impressions);
      setStep(2)
    }}
    type={promoteType}
    totalAmount={totalAmount}
    id={id}
  />);

  const Step2 = (<ReviewPromotion
    onSubmit={() => setStep(3)}
    onEdit={() => setStep(1)}
    impressions={impressions}
    totalAmount={totalAmount}
  />);


  const Step3 = (
    <CreditCardForm
      disabled={isLoading}
      totalAmount={totalAmount}
      onSubmit={({ cvv, expMonth, expYear, cardNumber }) => {

        promote({
          cardNumber,
          expMonth,
          expYear,
          cvc: cvv,
          id,
          type: promoteType,
          interval: duration,
          impressions,
          amount: totalAmount,
        });

      }}
      onBack={() => setStep(x => x - 1)}
      subscription={false}
    />
  );

  const renderCurrentStep = (step: number) => {
    switch (step) {
      case 1: return Step1;
      case 2: return Step2;
      case 3: return Step3;
    }
  }


  const dialogClasses = useDialogStyles();



  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      classes={dialogClasses}
      onClose={closeHandler}
    >


      <div className={classes.imageContainer}>
        <DezinerMedia
          containerClassName={classes.innerImageContainer}
          className={classes.image}
          {...mediaProps}
        />
      </div>

      <IconButton
        className={classes.closeBtn}
        onClick={()=>closeHandler()}
      >
        <CloseIcon className={classes.closeIcon} fontSize="large" />
      </IconButton>

      <DialogContent className={classes.contentContainer}>

        {renderCurrentStep(step)}

        <HideContainer hideWhen={!promoteError}>
          <DezinerAlert>
            {'Promote Failed'}
          </DezinerAlert>
        </HideContainer>

        <HideContainer hideWhen={!promoteSuccess}>
          <DezinerAlert severity="success">
            {'Promote Succeed'}
          </DezinerAlert>
        </HideContainer>

      </DialogContent>

    </Dialog>
  );
}



