import { CometChat } from "@cometchat-pro/chat";
import { CometChatUser } from "../types";
import { useMyProfile } from '../../../../components/src/hooks';

const COMET_CHAT_APP_ID = "2121819a3e582809";
const COMET_CHAT_REGION = "us";
const COMET_CHAT_AUTH_KEY = "fbaf44e1055ff4159d8acb997a5154934f74f5f4"

export function useMyUID() {
  const profileQuery = useMyProfile();
  const uid = profileQuery?.data?.data?.attributes?.uid ?? '';

  return uid;
}


export function useIsCometChatInitialized() {

  return CometChat.isInitialized();
}


export async function useCometChatUser({ uid }: { uid: string }): Promise<Partial<CometChatUser>> {

  try {
    const isInitialized = useIsCometChatInitialized();

    if (isInitialized) {
      let user: unknown = await CometChat.getLoggedinUser();

      if (!user) {
        user = await CometChat.login(uid, COMET_CHAT_AUTH_KEY)
      }

      return user as CometChatUser;
    }

    return {}

  } catch (error) {
    console.error(error);
    return {}
  }
}



export async function useInitCometChat({
  uid,
  enabled,
}: {
  uid: string,
  enabled: boolean
}) {

  let appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(COMET_CHAT_REGION)
    .autoEstablishSocketConnection(true)
    .build();

  async function onSuccess() {
    console.log("CometChat Initialization completed successfully");

    await CometChat.getLoggedinUser()
      .then(async (user) => {
        if (!user) {
          await CometChat.login(uid, COMET_CHAT_AUTH_KEY)
        } else {
          console.log(`CometChat user`, { user });
        }


        /////////////////
        /////////////////
      })
      .catch((error) => {
        console.log("CometChat Something went wrong", error);
      });

  }

  function onError(error: any) {
    console.error("CometChat Initialization failed with error:", error);
  }

  if (enabled)
    await CometChat.init(COMET_CHAT_APP_ID, appSetting).then(onSuccess, onError);
}
