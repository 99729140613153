export const logo = require("../assets/ReactNative_Signin_assets_b59377a7d70a02966414b6bb1f9c58acb29b174f.png");
export const designLogo = require("../assets/brush_black_24dp.svg");
export const star = require("../assets/star.svg");
export const lightbulb = require("../assets/lightbulb.svg");
export const likes = require("../assets/likes.svg");
export const edit = require("../assets/edit.svg");
export const trending_up = require("../assets/trending_up.svg");
export const love = require("../assets/love.png");
export const plus = require("../assets/plus.svg");
export const delete_icon = require("../assets/delete.svg");
export const forum = require("../assets/forum.svg");
export const group = require("../assets/group.svg");
export const share = require("../assets/share.svg");
export const share2 = require("../assets/share.png");
export const shareGray = require("../assets/share-gray.svg");
export const camera = require("../assets/camera.svg");
export const close = require("../assets/close.svg");
export const darklogo = require("../assets/darkLogo.png");
export const design = require("../assets/design.svg");
export const about = require("../assets/about.svg");
export const col = require("../assets/col.png");
export const cloud = require("../assets/cloud-arrow-up-solid.svg");
export const tick = require("../assets/tick.svg");
export const darkLogo = require("../assets/darkLogo.png");
export const pin = require("../assets/pin.png");
export const promote = require("../assets/promote.png");
export const wishlist = require("../assets/wishlist.png");
export const inspireHeader = require("../assets/inspire-icon-dark.png");
export const starHeader = require("../assets/star.png");
export const shareTitle = require("../assets/share.png");
export const likeGray = require("../assets/like-gray.svg");
export const starGray = require("../assets/star-gray.svg");
export const inspireGray = require("../assets/inspire-gray.svg");
export const editSolidDark = require("../assets/edit-solid-dark.svg");
export const colBlack = require("../assets/col-black.svg");
export const promoteBlack = require("../assets/promote-black.svg");
export const designsBlack = require("../assets/designs-black.svg");
export const filterListWhite = require("../assets/filter_list_white.svg");
export const filterListBlack = require("../assets/filter_list_black.svg");
export const penIcon = require("../assets/pen.png");
export const groupIcon = require("../assets/group.svg");
export const clubIcon = require("../assets/club.svg");
export const dezinerKnotLogo = require('../assets/dezinerknot_logo.svg');
export const chatBulbIcon = require('../assets/chat_bulb.svg');
export const saveIcon = require('../assets/save.svg');
export const eyeIcon = require('../assets/eye.svg');
export const inspireBlackIcon = require('../assets/inspire-black.svg');
export const facebookIcon = require('../assets/facebook.svg');
export const instagramIcon = require('../assets/instagram.svg');
export const microsoftIcon = require('../assets/microsoft.svg');
export const shareArrowIcon = require('../assets/share_arrow.svg');
export const linkIcon = require('../assets/link.svg');
export const plusIcon = require('../assets/plus.svg');
export const checkIcon = require('../assets/check.svg');
export const markerIcon = require('../assets/marker.svg');
export const personIcon = require('../assets/person.svg');
export const trashSolidIcon = require('../assets/trash-solid.svg');
export const privateMarkIcon = require('../assets/private-mark.png');
export const passwordIcon = require('../assets/password.svg');
export const socialProfilesIcon = require('../assets/social_profiles.svg');
export const logoutIcon = require('../assets/logout.svg');
export const accountSettingsIcon = require('../assets/account_settings.svg');
export const editProfileIcon = require('../assets/edit-profile.svg');
export const subscribeIcon = require('../assets/subscribe.svg');
export const secureIcon = require('../assets/secure.png');

