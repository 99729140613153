import React from "react";
import {
  dezinerKnotLogo
} from "../../landingpage/src/assets";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { VerticalSpacer } from '../../../components/src/design-system';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px 40px',
  },
  logo: {
    maxWidth: '350px'
  },
  verifyEmailText: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  descriptionText: {
    fontSize: '1.2rem',
    color: '#a9a9a9',
    textAlign: 'center',
  },
  centerText: {
    textAlign: 'center'
  },

});


export default function EmailVerificationDialog({ open, onClose, email }: {
  open: boolean,
  onClose: () => void,
  email: string
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <div className={classes.header}>
        <img
          src={dezinerKnotLogo}
          alt="Deziner Knto"
          className={classes.logo}
        />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <VerticalSpacer />


      <DialogContent style={{}}>

        <Grid container direction="column" alignItems="center">
          <Typography
            className={classes.verifyEmailText}
          >
            {'Verify your email'}
          </Typography>
          <Typography
            className={classes.descriptionText}
          >
            {`We\'ve sent an email to ${email}. Kindly verify to activate your account.`}
          </Typography>
        </Grid>

        <VerticalSpacer />
        <VerticalSpacer />

        <Typography className={classes.centerText}>
          &#169; {`DEZINERSKNOT ${new Date().getFullYear()}`}
        </Typography>

        <VerticalSpacer />

      </DialogContent>
    </Dialog>
  )
}

