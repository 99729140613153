import React, { useState } from 'react';
import { saveIcon, } from '../../assets';
import { useSingleDesign, } from '../../../../../components/src/hooks';
import { SaveDesignDialog } from '../../common';
import { DezinerButton, } from '../../../../../components/src/design-system';
import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles({
  floatButton: {
    boxShadow: '0 0 5px #cecaca',
    padding: '6px 18px',
    border: 'none !important',
    marginLeft: '15px',
  },
  icon: {
    maxWidth: '20px',
  },
});


export function SaveButton({ designId, isSaved }: { designId: number, isSaved: boolean }) {

  const classes = useStyles();

  const [saveDesignOpen, setSaveDesignOpen] = useState(false);

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;

  return (
    <>
      <SaveDesignDialog
        open={saveDesignOpen}
        onClose={() => setSaveDesignOpen(false)}
        designId={designId}
        mediaProps={{
          id: String(designAttributes?.id),
          src: designAttributes?.image_url,
          mediaType: designAttributes?.main_image_type?.includes('video') ? 'video' : 'image',
          watermarkURL: designAttributes?.watermark_url,
          watermarkHeight: parseFloat(designAttributes?.height ?? '0'),
          watermarkWidth: parseFloat(designAttributes?.width ?? '0'),
        }}
      />
      <DezinerButton
        color="primary"
        variant={isSaved ? "contained" : "outlined"}
        className={classes.floatButton}
        startIcon={<img src={saveIcon} className={classes.icon} />}
        onClick={() => setSaveDesignOpen(true)}
        disabled={isSaved}
      >
        {isSaved ? 'Saved' : 'Save'}
      </DezinerButton>
    </>
  );
}
