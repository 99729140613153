import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useInspireDesign, useInspiredUsers, useIsDesigner, useIsMyProfile, useIsUser, useNavigate, useRateDesign, useSingleDesign
} from '../../../../../components/src/hooks';
import clsx from 'clsx';
import Star from '@material-ui/icons/StarRounded';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MaterialPopover from '@material-ui/core/Popover';
import { inspireBlackIcon, saveIcon, chatBulbIcon, eyeIcon, likes } from '../../assets';
import { DezinerButton, HorizontalSpacer, DezinerAlert } from '../../../../../components/src/design-system';



const Dialog = withStyles({
  paper: {
    borderRadius: '20px',
  },

})(MaterialDialog);

const Popover = withStyles({
  root: {
    top: '30px',
  },
  paper: {
    padding: '10px',
    background: '#313234',
    minWidth: '230px',
  },
})(MaterialPopover);


const useInspiredUsersStyles = makeStyles({
  anchorRoot: {
    cursor: 'pointer',
  },
  icon: {
    width: '20px',
  },
  profilePicture: {
    width: '30px',
    height: '30px',
    borderRadius: '40%',
    marginRight: '10px',
    objectFit: 'cover',
  },
  profileName: {
    color: 'white'
  },
  statsText: {
    padding: '0 10px'
  },
  statsDivider: {
    height: '20px',
    width: '2px',
    margin: '0 10px'
  },
  inspiredUserContainer: {
    marginBottom: '6px',
  },
});


export function InspiredUsersPopover({ designId }: { designId: number }) {

  const [open, setOpen] = React.useState(false);

  const anchorRef = useRef(null);

  const classes = useInspiredUsersStyles();

  const inspiredUsers = useInspiredUsers({ designId });

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const isLoading = designQuery.isLoading;
  const { mutate: inspire } = useInspireDesign({ designId });


  if (isLoading)
    return null;

  return (
    <div>

      <span
        style={{ cursor: 'pointer' }}
        onMouseEnter={() => {
          setTimeout(() => {
            if (inspiredUsers.data?.data?.length)
              setOpen(true);
          }, 1000);
        }}
        ref={anchorRef}
        onClick={() => inspire({ designId })}
      >
        <Grid
          className={classes.anchorRoot}
          item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img
            src={inspireBlackIcon}
            className={clsx('black-svg', classes.icon)}
            alt="inspire"
            style={{ width: '25px' }}
          />
          <Typography className={classes.statsText}>
            {designAttributes?.inspired_count ?? 0}
          </Typography>
        </Grid>
      </span>

      <Popover
        //id={id}
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div>
          {
            inspiredUsers?.data?.data?.slice(0, 5)?.map(account => (
              <Grid key={account?.id} container alignItems="center" className={classes.inspiredUserContainer}>
                <img
                  className={classes.profilePicture}
                  src={account?.attributes?.profile_thumbnail_url}
                  alt={account?.attributes?.first_name}
                />
                <Typography className={classes.profileName}>
                  {account?.attributes?.first_name}
                </Typography>
              </Grid>
            ))
          }
        </div>
      </Popover>
    </div>
  );
}



function RateDesignDialog({
  open,
  onClose,
  designId,
}: {
  open: boolean,
  onClose: () => void,
  designId: number,
}) {

  const [currentRate, setCurrentRate] = useState(3);
  const { mutate: rateDesign } = useRateDesign({ designId });

  const designQuery = useSingleDesign({ designId });

  const myPreviousRating = designQuery.data?.design.data?.attributes?.my_rating;

  useEffect(() => {
    setCurrentRate(myPreviousRating ?? 3);
  }, [myPreviousRating]);


  function submitRating() {
    rateDesign({
      designId,
      rate: currentRate
    }, {
      onSettled: onClose,
    });

  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      style={{
        maxWidth: '300px',
        margin: 'auto',
        borderRadius: '20px',
      }}
    >
      <DialogTitle>
        <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {'Rate the Design'}
        </Typography>
      </DialogTitle>

      <DialogContent dividers>

        <Grid container alignItems="center" justifyContent="center">
          {
            [1, 2, 3, 4, 5].map((rate) => (
              <Star
                key={rate}
                style={{
                  color: rate <= currentRate ? '#313234' : '#a9aaac',
                  cursor: 'pointer',
                }}
                onClick={() => { setCurrentRate(rate) }}
              />
            ))
          }
        </Grid>
        <Typography style={{ color: 'lightgray', textAlign: 'center', }}>
          {'Tap a Star to Rate'}
        </Typography>

        {
          myPreviousRating ?
            <DezinerAlert>
              {'You already rated this design'}
            </DezinerAlert>
            :
            null
        }

      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between', padding: '0 20px' }}>
        <DezinerButton onClick={onClose} style={{ fontWeight: 'bold', padding: '10px 10px' }}>
          {'Cancel'}
        </DezinerButton>
        <Divider style={{ height: '30px' }} orientation="vertical" />
        <DezinerButton
          disabled={Boolean(myPreviousRating)}
          onClick={submitRating} style={{ fontWeight: 'bold', padding: '10px 10px' }}>
          {'Submit'}
        </DezinerButton>
      </DialogActions>
    </Dialog>
  );
}










const useDesignControlsStyles = makeStyles({
  statsText: {
    padding: '0 10px'
  },
  statsDivider: {
    height: '20px',
    width: '2px',
    margin: '0 10px'
  },
});






function ControlsByOthers({
  designId,
}: {
  designId: number,
}) {

  const classes = useDesignControlsStyles();
  const [rateDesignOpen, setRateDesignOpen] = useState(false);

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;

  return (
    <div>

      <RateDesignDialog
        designId={designId}
        onClose={() => {
          setRateDesignOpen(false);
        }}
        open={rateDesignOpen}
      />

      <Grid container alignItems="center" >

        <Grid item container sm={9} xs={12}>

          <InspiredUsersPopover designId={designId} />

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={chatBulbIcon} style={{ width: '18px', }} alt="chat" className="black-svg" />
            <Typography className={classes.statsText}>
              {designAttributes?.comment_count ?? 0}
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>


          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={likes} style={{ width: '20px', }} alt="like" className="black-svg" />
            <Typography className={classes.statsText}>
              {designAttributes?.like_count ?? 0}
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={eyeIcon} style={{ width: '20px', }} alt="eyviews" className="black-svg" />
            <Typography className={classes.statsText}>
              {designAttributes?.view_count ?? 0}
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>


          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={saveIcon} style={{ width: '22px', }} alt="eyviews" className="black-svg" />
            <Typography className={classes.statsText}>
              {designAttributes?.save_designs_count ?? 0}
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid
            onClick={() => setRateDesignOpen(true)}
            style={{ cursor: 'pointer' }}
            item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <Star />
            <Typography className={classes.statsText}>
              {designAttributes?.rating_count ?? 0}
            </Typography>
          </Grid>

        </Grid>

      </Grid>
    </div>
  )
}

function DesignerControls({
  designId,
}: {
  designId: number,
}) {

  const classes = useDesignControlsStyles();
  const designQuery = useSingleDesign({ designId });

  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designerAttributes = designQuery?.data?.design?.data?.attributes?.designer?.data?.attributes;

  const isMyProfile = useIsMyProfile({ id: designerAttributes?.id ?? 0 });

  const navigate = useNavigate();



  return (
    <Grid container alignItems="center" >

      <Grid item container sm={9} xs={12}>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img src={eyeIcon} style={{ width: '20px', }} alt="eyviews" className="black-svg" />
          <Typography className={classes.statsText}>
            {designAttributes?.view_count ?? 0}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Divider className={classes.statsDivider} />
        </Grid>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img src={likes} style={{ width: '20px', }} alt="like" className="black-svg" />
          <Typography className={classes.statsText}>
            {designAttributes?.like_count ?? 0}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Divider className={classes.statsDivider} />
        </Grid>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img src={inspireBlackIcon} style={{ width: '28px', }} alt="inspire" className="black-svg" />
          <Typography className={classes.statsText}>
            {designAttributes?.inspired_count ?? 0}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Divider className={classes.statsDivider} />
        </Grid>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img src={chatBulbIcon} style={{ width: '18px', }} alt="chat" className="black-svg" />
          <Typography className={classes.statsText}>
            {designAttributes?.comment_count ?? 0}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Divider className={classes.statsDivider} />
        </Grid>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <img src={saveIcon} style={{ width: '22px', }} alt="eyviews" className="black-svg" />
          <Typography className={classes.statsText}>
            {designAttributes?.save_designs_count ?? 0}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Divider className={classes.statsDivider} />
        </Grid>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          <Star />
          <Typography className={classes.statsText}>
            {designAttributes?.rating_count ?? 0}
          </Typography>
        </Grid>

      </Grid>

      {
        isMyProfile ?
          <Grid item container sm={3} xs={12} justifyContent="flex-end" wrap="nowrap" style={{ padding: '10px 0' }}>
            <DezinerButton size="small" variant="outlined"
              onClick={() => {
                if (isMyProfile)
                  navigate(`DesignerDesignEdit/${designAttributes?.id}`);
              }}>
              {'Edit'}
            </DezinerButton>

            <HorizontalSpacer />

            <DezinerButton size="small" variant="outlined"
              onClick={() => { }}>
              {'Delete'}
            </DezinerButton>
          </Grid>
          :
          null
      }

    </Grid>
  );
}




export default function DesignControls({
  designId,
}: {
  designId: number,
}) {

  const designQuery = useSingleDesign({ designId });

  const designerAttributes = designQuery?.data?.design?.data?.attributes?.designer?.data?.attributes;
  const isMyProfile = useIsMyProfile({ id: designerAttributes?.id ?? 0 });

  const isDesigner = useIsDesigner();
  const isUser = useIsUser();



  if (isUser || (isDesigner && !isMyProfile))
    return (<ControlsByOthers designId={designId} />);


  if (isDesigner)
    return (<DesignerControls designId={designId} />);


  return null;
}
