import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DezinerMarkDown } from '../../../../components/src/common';
import { DezinerCard } from '../../../../components/src/design-system';


const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
  whatIsGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    ['@media screen and (max-width: 400px)']: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  stepsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    ['@media screen and (max-width: 400px)']: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
});

////////////////

interface Step { title: string, description: string }

const steps: Step[] = [
  {
    title: 'Register',
    description: 'Sign up and earn with us if you are a designer / sign up and get your projects done if you are a user',
  },
  {
    title: 'Search',
    description: 'Find your clients and get projetcs/Look for designs or designers based on your requirements',
  },
  {
    title: 'Chat',
    description: 'Users and designers can connect and agree upon a project or a design',
  },
  {
    title: 'Deliver/ make payment',
    description: 'Designers get paid for their work/users get the products as downloadable files or in other forms',
  },
];


interface WhatIsDeziner { title: string, description: string }

const whatIsDezinerknot: WhatIsDeziner[] = [
  {
    title: 'Worldwide Access',
    description: 'It is a huge network having association with designers and customers across the planet',
  },
  {
    title: 'Discover',
    description: 'Track down and manifest the designs that you are interested in without much hassle',
  },
  {
    title: 'One-Stop Destination',
    description: 'Buying, selling or freelancing an out-and-out online marketplace',
  },
  {
    title: 'Unrivalled range of products',
    description: 'Logo, product/web design, branding or animation – an all inclusive store',
  },
  {
    title: 'Transparent Pricing',
    description: '100% reasonable taxes and service charges. No hidden charges',
  },
  {
    title: 'Personalized Service',
    description: 'Your needs, our priority! Our designers carefully listen to your requirements and curate brilliant designs',
  },
]
////////////////


export default function ExplorePage() {

  const classes = useStyles();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'EXPLORE'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {''}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 19, 2022'}
          </Typography>
        </Grid>
        <hr />

        <Typography style={{ fontStyle: 'italic', }}>
          {'Get started with 4 easy steps…'}
        </Typography>

        <div className={classes.stepsContainer}>
          {
            steps.map((step) => (
              <StepCard
                key={step.title}
                step={step}
              />
            ))
          }
        </div>

        <DezinerMarkDown>
          {`> In a nutshell, what is in Deziner’s Knot for you?`}
        </DezinerMarkDown>


        <div className={classes.whatIsGridContainer}>
          {
            whatIsDezinerknot.map((whatIs) => (
              <WhatIsCard
                key={whatIs.title}
                whatIs={whatIs}
              />
            ))
          }
        </div>

      </div>
    </div>
  );
}


////////////

const useStepStyles = makeStyles({
  container: {
    margin: '10px 10px',
    background: '#F5F5F5',
    borderTop: '4px solid black',
    borderRadius: '0 0 10px 10px',
    minHeight: '200px',
    border: 'none',
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '20px 0 10px',
    fontSize: '1.2rem',
  },
  description: {
    textAlign: 'center',
    color: '#777777',
    fontSize: '0.9rem',
  },
});

function StepCard({ step }: { step: Step }) {

  const classes = useStepStyles();
  const { title, description } = step;

  return (
    <DezinerCard className={classes.container}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>
        {description}
      </Typography>
    </DezinerCard>
  );
}


/////////

const useWhatIsStyles = makeStyles({
  container: {
    border: 'none',
    boxShadow: '0 0 10px #80808045',
    margin: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  description: {
    color: '#5E5E5E',
    textAlign: 'center',
  },
});


function WhatIsCard({ whatIs }: { whatIs: WhatIsDeziner }) {

  const classes = useWhatIsStyles();
  const { title, description } = whatIs;

  return (
    <DezinerCard className={classes.container}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>
        {description}
      </Typography>
    </DezinerCard>
  );
}

/////////
