import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import CreateUserCollectionForm from './CreateUserCollectionForm';
import { VerticalSpacer } from '../../../../../components/src/design-system';


export default function CreateCollectionDialog({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean,
  onClose: () => void,
  onSuccess: (response: any) => void,
}) {


  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >

      <DialogTitle style={{ position: 'relative' }}>
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          {'Create Collection'}
        </Typography>
        <IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ display: 'flex', flexDirection: 'column', padding: '0 50px' }}>
        <VerticalSpacer />
        <CreateUserCollectionForm onSuccess={onSuccess} />
        <VerticalSpacer />
      </DialogContent>

    </Dialog>
  );
}

