import React from 'react';
import { LabelListing, LabelListingProps } from '../../../../../components/src/common';
import { DezinerHeader, HeaderControls } from '../../../../../components/src/layout';
import { useLogout, useMyProfile, useNavigate } from '../../../../../components/src/hooks';
import {
  clubIcon, group, passwordIcon, editProfileIcon, accountSettingsIcon, socialProfilesIcon, logoutIcon,
} from '../../../../landingpage/src/assets';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../components/src/store/store';

const RouteToLabelIdMapper: any = {
  DesignerEditProfile: 1,
  DesignerAccountSettings: 2,
  DesignerGroupPageView: 3,
  DesignClubPageView: 4,
  DesignerEditPasswords: 5,
  DesignerEditSocialProfiles: 6,
}




export function DesignerProfileLayout(props: any) {

  const profileQuery = useMyProfile();
  const profileData = profileQuery?.data?.data?.attributes;
  const navigate = useNavigate();

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const currentRoute = window.location?.href?.split('/')?.pop() ?? '';
  const profileLoading = profileQuery?.isLoading ?? false;
  const logout = useLogout();

  const arrLabels = [
    {
      id: 1,
      name: "Edit Profile",
      icon: editProfileIcon,
      onClick: () => navigate("DesignerEditProfile"),
    },
    {
      id: 2,
      name: "Account Settings",
      icon: accountSettingsIcon,
      onClick: () => navigate("DesignerAccountSettings"),
    },
    {
      id: 3,
      name: "Group",
      icon: group,
      onClick: () => navigate("DesignerGroupPageView"),
      isActive: pathname?.toLowerCase()?.includes('group'),
    },
    {
      id: 4,
      name: "Club",
      icon: clubIcon,
      onClick: () => navigate("DesignClubPageView"),
      isActive: pathname?.toLowerCase()?.includes('club'),
    },
    {
      id: 5,
      name: "Password",
      icon: passwordIcon,
      onClick: () => navigate("DesignerEditPasswords"),
    },
    {
      id: 6,
      name: "Social Profiles",
      icon: socialProfilesIcon,
      onClick: () => navigate("DesignerEditSocialProfiles"),
    },
    {
      id: 7,
      name: "Logout",
      icon: logoutIcon,
      iconStyle: {
        maxWidth: '15px',
      },
      onClick: logout,
    }
  ]


  const list: LabelListingProps['list'] = arrLabels
    .map(({ name, ...rest }) => ({ label: name, ...rest }))
    .map(({ isActive, ...item }) => ({
      ...item,
      active: isActive || item.id == (RouteToLabelIdMapper?.[currentRoute] ?? 0)
    }));



  return (
    <div>

      <DezinerHeader
        fallbackColor={profileData?.color ?? ''}
        title={profileData?.first_name ?? ''}
        subTitle={'DESIGNER'}
        profileImageURL={profileData?.profile_thumbnail_url ?? ''}
        coverImageURL={profileData?.cover ?? ''}
        loading={profileLoading}
        vertical
      />

      <HeaderControls>
        <HeaderControls.MiddleSection>
          <LabelListing list={list} />
        </HeaderControls.MiddleSection>
      </HeaderControls>

      {props?.children}

    </div>
  );

}
