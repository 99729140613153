import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Profile } from "../api-types";
import { profileMessages } from "../messages-generator";
import { profileKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";
import { navigateTo } from "../store/navigation/navigation.action";
import { useDecodedToken, useIsMyProfile } from "./utils";

export function useDeleteAccount() {

  const dispatch = useDispatch();

  return useDezinerMutation({
    queryKey: profileKeys.deleteAccount(),
    messageGenerator: profileMessages.deleteAccount,
    options: {
      onSuccess: () => {
        localStorage.clear();
        dispatch(navigateTo({ url: 'EmailAccountRegistrationWeb' }));
      },
    }
  });
}

export function useUpdateUsernameAndEmail() {

  return useDezinerMutation<Parameters<typeof profileMessages.updateUsernameAndEmail>[0]>({
    queryKey: profileKeys.updateUsernameAndEmail(),
    messageGenerator: profileMessages.updateUsernameAndEmail,
  });
}

export function useUpdateBioAndSkills({ designerId }: { designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof profileMessages.updateBioAndSkills>[0]>({
    messageGenerator: profileMessages.updateBioAndSkills,
    queryKey: profileKeys.updateBioAndSkills(),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfile({ id: Number(designerId) }));
      }
    }
  });
}

export function useUpdateSocialMedia({ designerId }: { designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof profileMessages.updateSocialMedia>[0]>({
    messageGenerator: profileMessages.updateSocialMedia,
    queryKey: profileKeys.updateSocialMedia({ designerId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfile({ id: designerId }));
      }
    }
  });
}


export function useDesignerProfileByUser({ id, options }: { id: number, options?: QueryObserverOptions }) {

  return useDezinerQuery<{ data: Profile }>({
    message: profileMessages.fetchDesignerProfileByUser({ designerId: id }),
    queryKey: profileKeys.fetchProfileByUser({ id }),
    options,
  });

}

export function useProfile({ id }: { id: number }, options?: QueryObserverOptions) {

  const isMyProfile = useIsMyProfile({ id });

  const myProfileQuery = useMyProfile({
    id, options: {
      ...options,
      enabled: (options?.enabled ?? true) && isMyProfile
    }
  });

  const designerProfileByUserQuery = useDesignerProfileByUser({
    id, options: {
      ...options,
      enabled: (options?.enabled ?? true) && !isMyProfile,
    }
  });

  return isMyProfile ? myProfileQuery : designerProfileByUserQuery;
}

export function useUserProfile({ id, }: { id: number, }, options?: QueryObserverOptions) {

  const isMyProfile = useIsMyProfile({ id });

  const myProfile = useMyProfile({ id, options });

  const profileByOther = useUserProfileByOther({ userId: id });

  return isMyProfile ? myProfile : profileByOther;
}

export function useMyProfile({ id, options }: { id?: number, options?: QueryObserverOptions } = {}) {

  const token = useDecodedToken();


  return useDezinerQuery<{ data: Profile }>({
    message: profileMessages.fetchProfile(),
    queryKey: profileKeys.fetchProfile({ id: id || token.id }),
    options: {
      ...options,
      enabled: Boolean(token.id) && (options?.enabled ?? true)
    },
  });

}

export function useEditProfile() {

  const queryClient = useQueryClient();

  const { id } = useDecodedToken();

  return useDezinerMutation<Parameters<typeof profileMessages.editProfile>[0]>({
    messageGenerator: profileMessages.editProfile,
    queryKey: profileKeys.updateProfile(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(profileKeys.fetchProfile({ id }));
      }
    }
  })

}

export function useUploadProfileImage() {

  const queryClient = useQueryClient();
  const { id } = useDecodedToken();

  return useDezinerMutation<Parameters<typeof profileMessages.uploadProfileImage>[0]>({
    messageGenerator: profileMessages.uploadProfileImage,
    queryKey: profileKeys.updateProfileImage(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(profileKeys.fetchProfile({ id }));
      }
    }
  })

}


export function useUpdatePassword() {

  return useDezinerMutation({
    queryKey: profileKeys.updatePassword(),
    messageGenerator: profileMessages.updatePassword,
  });
}


export function useUpdateCoverImage() {

  const queryClient = useQueryClient();
  const { id } = useDecodedToken();

  return useDezinerMutation({
    queryKey: profileKeys.updateCover(),
    messageGenerator: profileMessages.changeCoverMessage,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfile({ id }));
      }
    },
  });
}



export function useAadhaarVerify({ id }: { id: number }) {

  return useDezinerMutation<Parameters<typeof profileMessages.verifyAadhaarNumber>[0]>({
    messageGenerator: profileMessages.verifyAadhaarNumber,
    queryKey: profileKeys.verifyAadhaar({ id }),
  });
}



export function useValidateAadhaarOtp({ id }: { id: number }) {

  return useDezinerMutation<Parameters<typeof profileMessages.validateAadhaarOtp>[0]>({
    messageGenerator: profileMessages.validateAadhaarOtp,
    queryKey: profileKeys.validateAadhaarNumber({ id }),
  });
}




export function useSubmitBankAccount({ id }: { id: number }) {

  return useDezinerMutation<Parameters<typeof profileMessages.submitBankAccount>[0]>({
    queryKey: profileKeys.submitBankAccount({ id }),
    messageGenerator: profileMessages.submitBankAccount,
  });
}


export function useUserProfileByOther({ userId }: { userId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Profile }>({
    message: profileMessages.fetchUserProfileByOther({ userId }),
    queryKey: profileKeys.fetchUserProfileByOther({ userId }),
    options,
  });
}

