import React, { useEffect, } from 'react';
import DesignerProfilesSlider from "./profile-slider";
import { Member } from "../../../../components/src/api-types";
import { useIsLoggedIn, useLandingPageData, useNavigate } from "../../../../components/src/hooks";


export function DesignersSliderContainer() {

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();


  const landingPageQuery = useLandingPageData({
    selectedFilter: 'All',
    selectedCategories: [],
    sorting: 'recent',
  });

  const allDesignersResponse: Member[] =
    landingPageQuery?.data?.pages?.[0]?.data?.designers?.data ?? [];


  useEffect(function removeObserverWhenDataIsReady() {
    if (allDesignersResponse.length)
      landingPageQuery.remove();
  }, [allDesignersResponse]);


  function designerClickHandler(id: string) {
    if (isLoggedIn)
      navigate(`DesignerHome/${id}`);
  }


  return (
    <DesignerProfilesSlider
      members={allDesignersResponse}
      onClick={designerClickHandler}
    />
  );
}
