import { DesignCategory } from "../api-types";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');

export interface CreateGroupPayload {
  name: string,
  description: string,
  image: string,
  cover: string,
  account_ids: { account_id: number }[],
  groups_sub_categories_attributes: { sub_category_id: number }[],
}

export const groupsMessages = {


  followGroup({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: configJSON.followGroupAPI,
      httpBody: {
        "data": {
          "attributes": {
            "group_id": groupId
          }
        }
      }
    });
  },

  unfollowGroup({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: configJSON.unfollowGroupAPI,
      httpBody: {
        "data": {
          "attributes": {
            "group_id": groupId
          }
        }
      }
    });
  },

  fetchGroupFollowers({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.clubGroupFollowersAPI}`,
    });
  },

  declineInvitation({ groupId, accountId }: { groupId: number, accountId: number }) {

    const httpBody = {
      data: {
        attributes: {
          group_id: groupId,
          account_id: accountId,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.declineInvitationAPI,
    });

  },

  acceptInvitation({ groupId, accountId }: { groupId: number, accountId: number }) {

    const httpBody = {
      data: {
        attributes: {
          group_id: groupId,
          account_id: accountId,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.acceptInvitationAPI,
    });

  },




  fetchNonResponsdingMembers({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.nonRespondinMembersAPI}`,
    });
  },


  createGroup({
    name,
    description,
    image,
    cover,
    account_ids,
    groups_sub_categories_attributes,
  }: CreateGroupPayload) {


    const httpBody = {
      group: {
        name,
        description,
        image: {
          data: image,
        },
        cover: {
          data: cover,
        },
        accounts_groups_attributes: account_ids,
        groups_sub_categories_attributes: groups_sub_categories_attributes,
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.groupBaseAPI,
    });

  },


  fetchSingleGroup({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}`,
    });
  },

  fetchGroups() {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.groupBaseAPI,
    });
  },


  fetchMembers({ groupId }: { groupId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.fetchGroupMembersAPI}`,
    });
  },

  addAsAdmin({ groupId, memberId }: { groupId: number, memberId: number }) {


    const httpBody = {
      data: {
        attributes: {
          account_id: memberId,
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.addAsAdminAPI}`,
    });
  },


  removeAdminPermission({ groupId, memberId }: { groupId: number, memberId: number }) {

    const httpBody = {
      data: {
        attributes: {
          account_id: memberId
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/remove_as_admin`,
    });
  },


  removeMember({ groupId, memberId }: { groupId: number, memberId: number }) {

    const httpBody = {
      data: {
        attributes: {
          account_id: memberId
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.DeleteMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/remove_member`,
    });

  },

  addMemberToGroup({
    groupId,
    accountId,
    contribution,
    user_type,
  }: {
    groupId: number,
    accountId: number,
    contribution: number,
    user_type: "Pattern designer" | "Admin",
  }) {


    const httpBody = {
      data: {
        attributes: {
          account_id: accountId,
          contribution,
          role: user_type,
        }
      }
    }

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.addMemberAPI}`,
      httpBody,
    });

  },


  changeCover({ image, groupId }: { image: string, groupId: number }) {

    const httpBody = {
      data: {
        attributes: {
          cover: {
            data: image
          },
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.changeGroupClubCoverImageAPI}`,
      httpMethod: configJSON.PutMethod,
      httpBody,
    });

  },


  updateGroupInfo({
    groupId, skills, description,
  }: {
    groupId: number, skills: number[], description: string,
  }) {

    const httpBody = {
      "data": {
        "attributes": {
          description,
          clubs_skills_attributes: skills.map(skill_id => ({ skill_id }))
        }
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.updateGroupClubInfoAPI}`,
    });
  },

  updateGroupSocialProfiles({
    groupId,
    facebook,
    twitter,
    instagram,
  }: {
    groupId: number,
    facebook: string,
    twitter: string,
    instagram: string,
  }) {


    const httpBody = {
      "data": {
        "attributes": {
          "facebook_url": facebook,
          "twitter_url": twitter,
          "instagram_url": instagram,
        }
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: `${configJSON.groupBaseAPI}/${groupId}/${configJSON.updateGroupClubSocialProfilesAPI}`,
    });
  },


  fetchGroupsDesignsByCategory({
    category,
    labelId,
    groupId,
  }: {
    category: DesignCategory,
    labelId?: number,
    groupId: number,
  }) {

    let endpoint = '';
    let requestParams: any = {}

    if (labelId)
      requestParams.label_id = labelId;

    switch (category) {
      case 'liked': { endpoint = 'like_designs'; break; }
      case 'designs': { endpoint = 'all_designs'; break; }
      case 'rated': { endpoint = 'raterd_designs'; break; }
      case 'inspired': { endpoint = 'inspired_designs'; break; }
      case 'promoted': { endpoint = 'promoted_designs'; break; }
      case 'free': { endpoint = 'free_designs'; break; }
      case 'paid': { endpoint = 'paid_designs'; break; }

    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: `${configJSON.groupBaseAPI}/${groupId}/${endpoint}`,
      httpMethod: configJSON.GetMethod,
      params: requestParams
    });
  },
}

