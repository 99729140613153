import React, { useEffect, } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  useCometChatMarkAsRead,
} from './hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { HideContainer, DezinerAudio } from '../../../components/src/design-system';
import { CometChatGlobalMessage } from '../../../components/src/store/cometchat/message/message.reducer';


const useMessageStyles = makeStyles({
  container: {
    maxWidth: '80%',
  },
  messageRoot: {
    fontSize: '1rem',
    padding: '10px',
    margin: '5px 0',
    position: 'relative',
  },
  myMessageContainer: {
    alignSelf: 'end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  myMessage: {
    borderRadius: '10px 10px 0px 10px',
    background: 'black',
    color: 'white',
  },
  myAudioMessage: {
    borderRadius: '10px 10px 0px 10px',
    background: 'white',
    color: 'white',
    border: "2px solid black",
    alignSelf: 'end',
  },
  otherUserMessageContainer: {
    alignSelf: 'start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  otherUserMessage: {
    borderRadius: '0px 10px 10px 10px',
    background: '#EEEFF1',
    color: '#868789',
  },
  floatingIcon: {
    position: 'absolute',
    bottom: 0,
    right: '-18px',
    color: 'black',
    fontSize: '14px'
  },
  name: {
    color: '#666',
    fontSize: '0.8rem',
  },
  time: {
    color: '#9d9d9d',
    fontSize: '0.7rem',
  }
});

function formatCometChatMessagesTime(sentAt: number) {
  if (!sentAt) return '';

  sentAt = sentAt * 1000;

  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;
  let currentDay = new Date().getDate();

  let sentYear = new Date(sentAt).getFullYear();
  let sentMonth = new Date(sentAt).getMonth() + 1;
  let sentDay = new Date(sentAt).getDate();

  let format = '';
  if (currentYear != sentYear)
    format += 'YYYY ';

  if (currentMonth != sentMonth || currentDay != sentDay)
    format += 'MM-DD ';

  format += 'hh:mm a';

  return moment(sentAt).format(format)
}



export default function ChatMessageView({
  message,
  isMyMessage,
  enableReadMarker,
  showSenderName,
  isLast,
}: {
  message: CometChatGlobalMessage,
  isMyMessage: boolean,
  enableReadMarker: boolean,
  showSenderName: boolean,
  isLast: boolean,
}) {

  const classes = useMessageStyles();

  const read = useSelector((state: RootState) => state.messages.read[message.id]);
  const delivered = useSelector((state: RootState) => state.messages.delivered[message.id]);

  const markAsRead = useCometChatMarkAsRead();

  useEffect(function onLoad() {
    if (message.type == 'text' && !isMyMessage && enableReadMarker)
      markAsRead(message)
  }, [enableReadMarker, isMyMessage]);


  const readIcon = <VisibilityIcon className={classes.floatingIcon} />;
  const deliveredIcon = <DoneAllIcon className={classes.floatingIcon} />;


  const showReadIcon = isLast && read;
  const showDeliveredIcon = isLast && !read && delivered;

  const floatingIcon = (
    <>
      <HideContainer hideWhen={!showReadIcon}>
        {readIcon}
      </HideContainer>

      <HideContainer hideWhen={!showDeliveredIcon}>
        {deliveredIcon}
      </HideContainer>
    </>
  );

  let time = formatCometChatMessagesTime(message.sentAt);


  if (message.type == "text")
    return (
      <div
        title={time}
        className={clsx(classes.container, {
          [classes.myMessageContainer]: isMyMessage,
          [classes.otherUserMessageContainer]: !isMyMessage,
        })}>
        <HideContainer hideWhen={isMyMessage || !showSenderName} >
          <Typography className={classes.name}>{message?.sender?.name}</Typography>
        </HideContainer>
        <Typography
          className={clsx(classes.messageRoot, {
            [classes.myMessage]: isMyMessage,
            [classes.otherUserMessage]: !isMyMessage,
          })}>
          {message?.text?.split(/\n/).map((line) => <React.Fragment key={line}>{line}<br /></React.Fragment>)}

          {floatingIcon}
        </Typography>
        <HideContainer hideWhen={!isLast}>
          <Typography className={classes.time}>
            {time}
          </Typography>
        </HideContainer>
      </div>
    );

  if (message.type == 'audio')
    return (
      <div
        title={time}
        className={clsx(classes.container, {
          [classes.myMessageContainer]: isMyMessage,
          [classes.otherUserMessageContainer]: !isMyMessage,
        })}>
        <HideContainer hideWhen={isMyMessage || !showSenderName}>
          <Typography className={classes.name}>{message?.sender?.name}</Typography>
        </HideContainer>
        <div className={clsx(classes.messageRoot, {
          [classes.myAudioMessage]: isMyMessage,
          [classes.otherUserMessage]: !isMyMessage,
        })}>
          <DezinerAudio
            src={message.data.url}
            onListeningFinish={() => {
              if (!isMyMessage && enableReadMarker)
                markAsRead(message)
            }}
          />
          {floatingIcon}
        </div>
        <HideContainer hideWhen={!isLast}>
          <Typography className={classes.time}>
            {time}
          </Typography>
        </HideContainer>
      </div>
    );


  return null;
}
