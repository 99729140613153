import React, { useContext, } from 'react';
import { useLandingPageData } from "../../../../components/src/hooks";
import { NoDataContainer, HideContainer } from '../../../../components/src/design-system';
import { LandingPageContext } from './landing-page-content';

export function LandingPageNoDataContainer() {

  const { selectedFilter, selectedCategories, selectedSortOption } = useContext(LandingPageContext);

  const landingPageQuery = useLandingPageData({
    selectedFilter,
    selectedCategories,
    sorting: selectedSortOption,
  });

  const hasDesigns = Boolean(landingPageQuery?.data?.pages?.[0]?.data?.designes?.data?.length);
  const selectedACategory = Boolean(selectedCategories.length);
  const isFetching = landingPageQuery.isFetching

  return (
    <>
      <HideContainer hideWhen={selectedACategory || isFetching || hasDesigns}>
        <NoDataContainer message={'Sorry, No data found.'} />
      </HideContainer>

      <HideContainer hideWhen={!selectedACategory || isFetching || hasDesigns}>
        <NoDataContainer message={'Sorry, No data found for the selected filters. Please change the selected filter to search for new designs.'} />
      </HideContainer>
    </>
  );
}
