import React, { useState } from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { DesignComment, } from "../../../components/src/api-types";
import { HideContainer } from "../../../components/src/design-system";
import { CommentButtons } from "./CommentButtons";
import { CommentAttachements } from "./CommentAttachments";
import { CommentReplyInput } from "./CommentReplyInput";
import { CommentReplies } from "./CommentReplies";
import { CommentHeader } from "./CommentHeader";
import { CommentProfilePicture } from "./CommentProfilePicture";


interface CommentProps {
  comment: DesignComment,
  // hide reply voice recorder
  hideVoiceRecorder?: boolean,
  // hide reply file input
  hideFileAttachements?: boolean,
  replyPlaceholder?: string,
  designId: number,
  // when the user interact with the comment without log in yet
  onAnonymousInteraction?: () => void,
}


const useCommentStyles = makeStyles({
  commentText: {
    wordBreak: 'break-word',
    color: '#515151',
    fontWeight: 600,
    fontSize: '0.9rem',
    padding: '10px 0',
  },
  commentRootContainer: {
    padding: '30px 0',
    margin: '5px 0',
    borderBottom: '1px solid #ddd'
  },
  breakWords: {
    overflow: "hidden",
    wordBreak: "break-all",
    whiteSpace: "normal"
  },
});

export function Comment(props: CommentProps) {

  const { comment, designId, onAnonymousInteraction, } = props;

  const commentAttr = comment.attributes
  const classes = useCommentStyles();
  const [showReply, setShowReply] = useState(false);


  const body = (
    <Grid container>

      <Grid item xs={12} className={classes.breakWords}>
        <Typography className={classes.commentText}>
          {commentAttr.comment}
        </Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end" alignItems="flex-start">
        <CommentButtons {...{ comment, designId, showReply, setShowReply, onAnonymousInteraction }} allowReply />
      </Grid>

      <Grid item xs={12}>
        <CommentAttachements {...{ comment }} />
      </Grid>

      <Grid item xs={12}>
        <HideContainer hideWhen={!showReply}>
          <CommentReplyInput {...props} onReplySuccess={() => setShowReply(false)} />
        </HideContainer>
      </Grid>


    </Grid>
  );



  return (
    <div className={classes.commentRootContainer}>

      <Grid container>
        <Hidden smDown>
          <Grid item sm={'auto'} xs={12} justifyContent="center">
            <CommentProfilePicture comment={comment} size="small" />
          </Grid>
        </Hidden>

        <Grid item sm xs>
          <CommentHeader {...{ comment }} />
          {body}
        </Grid>

        <Grid item xs={12} style={{ paddingLeft: '30px' }}>
          <CommentReplies {...{ comment, designId, onAnonymousInteraction }} />
        </Grid>

      </Grid>

    </div>
  );
}

