import React, { useState } from 'react';
import { useSelector, } from 'react-redux';
import { useLogout, useSingleGroup, useDecodedToken, useIsLoggedIn, useIsUser, useNavigate, useProfile, useSingleClub } from '../hooks';
import { RootState } from '../store/store';
import { useParams } from '../utils';
import { PromoteDialog } from '../common';
import { DezinerTopNav } from './index';
// import SearchPopup from '../../../blocks/AdvancedSearch/src/SearchPopup.web';
const configJSON = require('../config.js');


export function DesignerTopNavLayout(props: { children: any }) {

  const [showSearch, setShowSearch] = useState(false);
  const { id: profileId } = useDecodedToken();

  const isUser = useIsUser();
  const isLoggedIn = useIsLoggedIn();

  const profileQuery = useProfile({ id: profileId });

  const navigate = useNavigate();
  const profileData = profileQuery?.data?.data?.attributes;

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname.toLowerCase();

  const inClubPage = pathname.includes('club');
  const inGroupPage = pathname.includes('group');

  const params = useParams();


  const clubId = Number(params.clubId);
  const groupId = Number(params.groupId);

  const clubQuery = useSingleClub({ clubId }, { enabled: Boolean(params.clubId && inClubPage) });
  const _clubName = clubQuery?.data?.data?.attributes?.name;
  const clubName = _clubName ? `${_clubName} Club` : undefined;

  const groupQuery = useSingleGroup({ groupId }, { enabled: Boolean(params.groupId && inGroupPage) });
  const _groupName = groupQuery?.data?.data?.attributes?.name;
  const groupName = _groupName ? `${_groupName} Group` : undefined;


  const logout = useLogout();



  const [promoteDialog, setPromoteDialog] = useState<{
    id: number,
    open: boolean,
    mediaSrc: string,
    mediaType: 'video' | 'image',
  }>({
    id: 0,
    open: false,
    mediaSrc: '',
    mediaType: 'image',
  });


  return (
    <>

      <PromoteDialog
        id={String(promoteDialog.id)}
        promoteType="Account"
        onClose={() => setPromoteDialog({ id: 0, open: false, mediaType: 'image', mediaSrc: '' })}
        open={promoteDialog.open}
        mediaProps={{
          id: String(promoteDialog.id),
          src: promoteDialog.mediaSrc,
          mediaType: promoteDialog.mediaType,
        }}
      />

      <DezinerTopNav
        hideAvatarMenu={isUser}
        role={isUser ? "User" : "Designer"}
        isLoggedIn={isLoggedIn}
        onSignInAsDesigner={() => navigate("EmailAccountLoginBlock")}
        onSignInAsUser={() => navigate("EmailAccountLoginBlock")}
        profileImage={profileData?.profile_thumbnail_url ?? ''}
        color={profileData?.color ?? ''}
        designerInfo={{
          logoutHandler: logout,
          promoteHandler: () => {
            setPromoteDialog({
              id: profileId,
              open: true,
              mediaType: 'image',
              mediaSrc: profileData?.profile_url ?? ''
            });
          },
          editProfileHandler: () => navigate('DesignerEditProfile'),
          groupClickHandler: () => navigate("DesignerGroupPageView"),
          clubClickHandler: () => navigate("DesignClubPageView"),
          onMySelfClickHandler: () => {
            if (profileId)
              navigate(`DesignerHome/${profileId}`);
          },
          currentGroupName: groupName,
          currentClubName: clubName,
        }}
        onLogoClick={() => navigate("LandingPage")}
        // onNotificationClick={() => navigate("Notifications")}
        onNotificationClick={() => {}}
        userInfo={{
          onProfileClick: () => navigate(`UserEditProfile/${profileId}`),

        }}
        onSearchClick={() => setShowSearch(!showSearch)}
      />

      {/* {showSearch ? <SearchPopup closePopup={() => setShowSearch(false)} showSearch /> : null} */}

      {props?.children}

    </>
  );
}

