import React, { useEffect, useState, } from 'react';
import { useFloating, shift, offset } from '@floating-ui/react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  useCometchatReceiveListeners,
  useCometChatUserPresence,
  useInitCometChat,
  useMyUID,
} from './hooks';
import { useIsLoggedIn, } from '../../../components/src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { closeChatConversationList, closeNewChatWidget, openChatConversationList, openNewChatWidget, toggleChatMinimize } from '../../../components/src/store/ui/ui.actions';
import { CHAT_WIDGET_WIDTH, CHAT_WIDGET_HEIGHT, } from "./CometChatSettings";
import * as R from 'ramda';
import ConversationListContainer from './ConversationListContainer';
import LiveChatMenu from './LiveChatMenu';

const defaultToZero = R.defaultTo(0);

const useStyles = makeStyles({
  mainMenu: {
    width: `${CHAT_WIDGET_WIDTH}px`,
    height: `${CHAT_WIDGET_HEIGHT}px`,
    borderRadius: '10px',
    background: 'white',
    zIndex: 10000,
    boxShadow: '0px 0px 5px #bdbdbd',
  },
  subMenu: {
    width: `${CHAT_WIDGET_WIDTH}px`,
    height: `${CHAT_WIDGET_HEIGHT + 60}px`,
    borderRadius: '10px',
    background: 'white',
    zIndex: 10000,
    boxShadow: '0px 0px 5px #bdbdbd',
  },
  subMenuClose: {
    height: '0px'
  },
  floatingBtn: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    background: '#797A7C',
    zIndex: 1000,
  },
  addIcon: {
    color: 'white'
  },
});



function calculateMainAxis({ minimized, mainMenuOpen }: { minimized: boolean, mainMenuOpen: boolean }) {

  if (minimized)
    return 0;
  else {
    if (mainMenuOpen)
      return -30;
    else
      return 10;
  }
}

function calculateCrossAxis({ minimized, mainMenuOpen }: { minimized: boolean, mainMenuOpen: boolean }) {

  if (mainMenuOpen)
    return -(CHAT_WIDGET_WIDTH + 40)
  else {
    if (minimized)
      return -70
    else
      return -20;
  }
}

const ENABLE_CHAT_WIDGET = true;

export default function DezinerChatWidget() {

  // open lock is used not to control sub menu open, but to pass it's value to the useFloating hooks only
  // because we can't dispatch a function from here, where we don't have the full
  // info needed to open it(like uid, name...)
  const [openLock, setOpenLock] = useState(false);

  const mainMenuOpen = useSelector((state: RootState) => state.ui.chatConversationList.open);
  const dispatch = useDispatch();
  const subMenuOpen = useSelector((state: RootState) => state.ui.chatWidget.open)
  const isLoggedIn = useIsLoggedIn();

  const myUID = useMyUID();
  const classes = useStyles();

  useInitCometChat({ uid: myUID, enabled: Boolean(isLoggedIn && myUID) })
  useCometchatReceiveListeners({ uid: myUID });

  let unsubscribeUserPresence = useCometChatUserPresence({ uid: myUID });

  useEffect(function onMount() {
    return function onUnmount() {
      unsubscribeUserPresence();
    }
  }, [myUID]);


  function toggleChatConversationListOpenState(open: boolean) {
    if (!open)
      dispatch(openChatConversationList({}));
    else
      dispatch(closeChatConversationList({}));
  }

  let { x: x1, y: y1, reference: reference1, floating: floating1, strategy: strategy1 } = useFloating({
    strategy: 'fixed',
    placement: 'top-start',
    open: mainMenuOpen,
    onOpenChange: toggleChatConversationListOpenState,
    middleware: [shift(), offset({ mainAxis: 10, crossAxis: -20 })],
  });

  x1 = defaultToZero(x1);
  y1 = defaultToZero(y1);

  const minimized = useSelector((state: RootState) => state.ui.chatWidget.minimized);

  let { x: x2, y: y2, reference: reference2, floating: floating2, strategy: strategy2 } = useFloating({
    strategy: 'fixed',
    placement: 'top-start',
    open: openLock,
    onOpenChange: setOpenLock,
    middleware: [shift(), offset({
      mainAxis: calculateMainAxis({ minimized, mainMenuOpen }),
      crossAxis: calculateCrossAxis({ mainMenuOpen, minimized }),
    })],
  });

  x2 = defaultToZero(x2);
  y2 = defaultToZero(y2);

  const openSingleChatMenu = subMenuOpen || openLock;


  if(!ENABLE_CHAT_WIDGET)
    return null;

  if (!isLoggedIn)
    return null;

  return (
    <>

      <Fab
        onClick={() => setOpenLock(x => !x)}
        ref={reference2}
        size="small"
        className={classes.floatingBtn}
        style={{ opacity: 0 }}
        aria-label="open-chat-with-user">
        <AddIcon className={classes.addIcon} fontSize={'small'} />
      </Fab>

      <Fab
        onClick={() => toggleChatConversationListOpenState(mainMenuOpen)}
        ref={reference1}
        size="small"
        className={classes.floatingBtn}
        aria-label="open-chat"
      >
        <AddIcon className={classes.addIcon} fontSize={'small'} />
      </Fab>

      {
        mainMenuOpen ?
          <div
            ref={floating1}
            style={{
              position: strategy1,
              top: y1,
              left: x1,
            }}
            className={classes.mainMenu}
          >
            <ConversationListContainer
              onConversationClick={({ uid, name, onlineStatus, chatType }) => {
                dispatch(openNewChatWidget({ uid, name, status: onlineStatus as any, chatType }));
                if (minimized) {
                  dispatch(toggleChatMinimize({}));
                }
              }}
            />
          </div>
          :
          null
      }

      {
        openSingleChatMenu ?
          <div
            ref={floating2}
            style={{
              position: strategy2,
              top: y2,
              left: x2,
            }}
            className={clsx(classes.subMenu, {
              [classes.subMenuClose]: minimized
            })}
          >

            <LiveChatMenu
              onClose={() => {
                dispatch(closeNewChatWidget({}));
                setOpenLock(false);
              }} />

          </div>
          :
          null
      }

    </>
  );
}
