import React from 'react';
import CollectionViewPageContent from './collection-page-content';
import CollectionPageController, { Props } from './CollectionPageController.web';

export default class CollectionPage extends CollectionPageController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (<CollectionViewPageContent />);
  }
}

