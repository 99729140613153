import React from "react";
// Customizable Area Start
import CommunityForumController, {
  Props,
} from "./CommunityForumController.web";
import { DezinerknotForum } from "./forum";
// Customizable Area End


class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (<DezinerknotForum />);
  }
}



// Customizable Area Start
export default CommunityForum;
// Customizable Area End
