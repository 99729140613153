
export const commentsKeys = {

  likeComment: (params: { commentId: number }) => ['like-comment', params] as const,

  fetchComments: (params: { designId: number }) => ['comments', params] as const,
  fetchRepliesToReply: (params: { replyId: number }) => ['replies-to-reply', params] as const,

  postReplyComment: (params: {
    designId: number,
    parentCommentId: number,
  }) => ['post-reply', params] as const,

  postComment: (params: {
    designId: number,
  }) => ['post-comment', params] as const,
}
