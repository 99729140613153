import React from 'react';
import { GeneralResponsiveCardsContainer, DesignCardWithProfileContainer } from '../../../components/src/common';
import { LandingPageDesign } from '../../../components/src/api-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '60px 50px',
  },
});

export function DesignCardsWithProfilesResponsiveContainer(props: {
  data: LandingPageDesign[],
}) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GeneralResponsiveCardsContainer>
        {
          ({ itemHeight }) =>
            props.data.map((item: any) => (
              <DesignCardWithProfileContainer
                key={item?.id}
                imgHeight={itemHeight}
                item={item}
              />
            ))
        }
      </GeneralResponsiveCardsContainer>
    </div>
  );
};
