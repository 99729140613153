import React, { useRef } from 'react';
import { SubDesign } from "../../design/upload-design-form/UploadDesignFormComponents.web";
import { blobToBase64 } from "../../../../../components/src/utils";
import Dropzone from "react-dropzone";
import { isImageFile, isImageBelowLimits, not, FormMedia } from '../../design/upload-design-form/UploadDesignForm.web';
import { useUploadDesignFormStyles } from "../../design/upload-design-form/UploadDesignFormStyle.web";
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PlusIcon from '@material-ui/icons/Add';
import { headerIcon, descriptionIcon } from '../../../../catalogue/src/assets';
import { HideContainer, DezinerMedia, DezinerButton, VerticalSpacer, DezinerLabel, DezinerAlert, FormikTextInput } from "../../../../../components/src/design-system";
import { MainMediaPlaceholder } from '../../design/upload-design-form/main-media-placeholder';


const uploadDesignValidationSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  media: yup.array().min(1, 'Please upload one image').of(yup.string()),
});


interface InitValues {
  title: string,
  description: string,
  media: FormMedia[],
}

export default function UploadUserDesignDialog({
  open,
  onUpload,
  onClose,
  loading,
  successMessage,
}: {
  open: boolean,
  onUpload: (params: { title: string, description: string, media: string[], resetForm: () => void, }) => void,
  onClose: () => void,
  loading?: boolean,
  successMessage?: string,
}) {



  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
        media: [],
      } as InitValues}
      onSubmit={(values, helpers) => {
        onUpload({
          title: values.title,
          description: values.description,
          media: values.media.map(x => x.data),
          resetForm: helpers.resetForm
        })
      }}
      validationSchema={uploadDesignValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {
        formik => <Form translate="yes">
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={onClose}>


            <DialogTitle style={{ position: 'relative' }}>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
                {'Upload Design'}
              </Typography>
              <IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent style={{}}>

              <Grid container justifyContent="space-between">

                <FormDropzones
                  media={formik.values.media}
                  setErrors={formik.setErrors}
                  errors={formik.errors}
                  setFieldValue={formik.setFieldValue}
                />

                <Grid item style={{ width: '35%' }}>

                  <Grid container alignItems="center">
                    <img src={headerIcon} alt="" style={{ maxWidth: '14px', marginRight: '10px', }} />
                    <DezinerLabel>
                      {'Title'}
                    </DezinerLabel>
                  </Grid>
                  <FormikTextInput
                    name="title"
                    variant="outlined"
                    fullWidth
                  />

                  <VerticalSpacer />

                  <Grid container alignItems="center">
                    <img src={descriptionIcon} alt="" className={''} style={{ marginRight: '10px' }} />
                    <DezinerLabel>
                      {'Description'}
                    </DezinerLabel>
                  </Grid>
                  <div style={{ position: 'relative' }}>
                    <FormikTextInput
                      placeholder="about your design"
                      name="description"
                      variant="outlined"
                      multiline
                      minRows={5}
                      autoHeight
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>

              <DezinerAlert severity="success">
                {successMessage}
              </DezinerAlert>

            </DialogContent>

            <DialogActions style={{ padding: '20px 50px', }}>
              <DezinerButton
                color="primary"
                variant="contained"
                onClick={formik.submitForm}
                loading={loading}
                disabled={Boolean(loading || successMessage)}
              >
                {'Upload'}
              </DezinerButton>
            </DialogActions>

          </Dialog>
        </Form>
      }
    </Formik>
  );

}



function FormDropzones({
  media,
  setErrors,
  errors,
  setFieldValue,
}: {
  media: FormMedia[],
  setErrors: (...args: any) => void,
  setFieldValue: (...args: any) => void,
  errors: any,
}) {

  const mediaFilesRef = useRef<any>();
  const subDesignsRef = useRef<any>();
  const classes = useUploadDesignFormStyles();

  async function onMainImageDrop(files: any[]) {
    try {
      if (!files.length) return;

      let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));

      if (imagesHasExceededLimits)
        setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
      else
        setErrors({ 'media': '' })

      const filteredFiles =
        files
          .filter(file => {
            if (isImageFile(file))
              return isImageBelowLimits(file);
          })

      let filesBase64: string[] = await Promise.all(
        filteredFiles.map(blobToBase64)
      );

      let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
        file,
        data: filesBase64[index],
        design_type: file?.type,
        name: file?.name,
        file_size: file?.size,
      }));

      setFieldValue('media', [...newFiles, ...media]);

    } catch (error) {
      console.error(error);
    }
  }


  async function onSubDesignDrop(files: any[], fileRejected: any) {
    try {
      if (!files.length) return;

      let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));

      if (imagesHasExceededLimits)
        setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
      else
        setErrors({ 'media': '' })

      const filteredFiles =
        files
          .filter(file => {
            if (isImageFile(file))
              return isImageBelowLimits(file);
          })

      let filesBase64 = await Promise.all(filteredFiles.map(blobToBase64));

      let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
        file,
        data: filesBase64[index],
        design_type: file?.type,
        file_size: file?.size,
        name: file?.name,
      }));

      setFieldValue('media', [...media, ...newFiles]);
    } catch (error) {
      console.error(error);
    }
  }



  return (
    <Grid item style={{ width: '60%' }}>

      <Dropzone
        multiple
        accept={{
          'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
        }}
        maxFiles={5 - media.length}
        onDrop={onMainImageDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({ className: "dropzone" })}
            onClick={() => {
              if (!media[0] && mediaFilesRef && mediaFilesRef.current)
                mediaFilesRef.current.click();
            }}
          >

            <div className={classes.dropzoneArea}>

              <HideContainer
                hideWhen={media?.[0]}
              >
                <MainMediaPlaceholder hideVideo />
              </HideContainer>

              <HideContainer
                hideWhen={!(media?.[0]?.data)}
              >
                <DezinerMedia
                  id={media?.[0]?.name}
                  mediaType={media?.[0]?.design_type?.startsWith('image') ? 'image' : 'video'}
                  src={media?.[0]?.data}
                  className={classes.mainImg}
                  alt="main image"
                />
              </HideContainer>

            </div>



            {/* @ts-ignore */}
            <input
              type="file"
              {...getInputProps()}
              ref={mediaFilesRef}
            />
          </div>
        )}
      </Dropzone>

      <DezinerAlert>
        {errors.media}
      </DezinerAlert>


      <div>
        <VerticalSpacer />
        <DezinerLabel>
          {'Add Subdesigns'}
        </DezinerLabel>
        <Grid container>
          {
            media
              ?.slice(1) // first media file is skipped
              ?.map((item, index) => {

                const isNewFile = Boolean(item.file);

                const type = isNewFile ? item?.file?.type : item.design_type;
                const name = isNewFile ? (item?.file?.name ?? '') : (item?.name);

                function moveDesignToTheBegining() {
                  setFieldValue('media', [item, ...media.slice().filter((x, i) => i != index + 1)]);
                }

                function removeDesign() {
                  setFieldValue('media', media.slice().filter((x, i) => i != index + 1));
                }

                return (<SubDesign
                  key={`${name}_${index}_${type}`}
                  data={item?.data}
                  type={type || 'image'}
                  clickHandler={moveDesignToTheBegining}
                  removeHandler={removeDesign}
                />);

              })
          }

          <HideContainer hideWhen={media.length >= 5}>
            <Dropzone
              multiple
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
              }}
              maxFiles={5 - media.length}
              onDrop={onSubDesignDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: "dropzone" })}
                  onClick={() => {
                    if (subDesignsRef && subDesignsRef.current)
                      subDesignsRef.current.click();
                  }}
                >
                  <div className={classes.subDesignAddContainer}>
                    <PlusIcon fontSize="large" style={{ color: '#aaaaac' }} />
                  </div>

                  {/* @ts-ignore */}
                  <input
                    {...getInputProps()}
                    ref={subDesignsRef}
                    type="file"
                  />
                </div>
              )}
            </Dropzone>
          </HideContainer>

        </Grid>

      </div>

    </Grid>
  );
}

