import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PostedBy, Profile, } from '../../../components/src/api-types';
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from '@material-ui/core/styles';
import { DezinerButton, HorizontalSpacer } from '../../../components/src/design-system';
import { subscribeIcon } from '../../../blocks/landingpage/src/assets';
import { useDecodedToken, useNavigate, } from '../../../components/src/hooks';
import { ProfileAvatarUI } from '../../../components/src/common';
import { FollowDesignerButton } from '../../landingpage/src/designer/designer-home/follow-designer-button';
import { SubscribeDesignerButton } from '../../landingpage/src/designer/designer-home/subscribe-designer-button';


const useStyles = makeStyles({
  root: {
    padding: '15px 0',
    borderBottom: '1px solid #ddd',
    flexWrap: 'nowrap',
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  role: {},
  location: {
    color: '#666'
  },
  bio: {
    color: '#999',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  circle: {
    fontSize: '0.6rem',
    lineHeight: 2.4
  },
  followersCount: {
    fontSize: '1.0rem',
    color: 'black',
  },
  followersString: {
    fontSize: '0.9rem',
    color: '#999',
  },
});


export default function FollowerCard({
  follower,
}: {
  follower: Profile | PostedBy,
}) {

  const classes = useStyles();

  const { id: myId } = useDecodedToken();
  const isMyProfile = myId == follower.attributes.id

  const role = follower.type == 'account' ?
    follower?.attributes?.role :
    follower?.attributes?.account_role;


  const isUser = role?.toLowerCase() == 'user';
  const isDesigner = role?.toLowerCase() == 'designer';

  const navigate = useNavigate();

  function nameClickHandler() {
    if (isUser)
      navigate(`userDesignList/${follower?.id}`);
    else if (isDesigner)
      navigate(`DesignerHome/${follower?.id}`);
  }

  const canFollow = isDesigner;



  return (
    <Grid container className={classes.root}>

      <Grid item>
        <ProfileAvatarUI
          onClick={nameClickHandler}
          image={follower?.attributes?.profile_url}
          alt={follower?.attributes?.first_name}
        />
      </Grid>

      <Grid item>
        <HorizontalSpacer />
      </Grid>

      <Grid item container direction="column" xs>

        <Typography className={classes.name} onClick={nameClickHandler}>
          {follower?.attributes?.first_name}
        </Typography>

        <Grid container>
          <Typography className={classes.role}>
            {role}
          </Typography>

          <HorizontalSpacer />
          <Typography className={classes.circle}>
            {"⚫"}
          </Typography>
          <HorizontalSpacer />

          <Typography className={classes.location}>
            {follower?.attributes?.location}
          </Typography>
        </Grid>

        <Typography className={classes.bio}>
          {follower?.attributes?.bio}
        </Typography>

        <Grid container justifyContent="flex-start" alignItems="center">

          <FollowDesignerButton
            designerId={follower?.attributes?.id}
            designer={follower.attributes}
          >
            {({ onClick, isFollowed, loading }) => (
              <DezinerButton
                loading={loading}
                startIcon={<AddIcon fontSize="large" />}
                size="small"
                variant={isFollowed ? "contained" : "outlined"}
                color="primary"
                onClick={onClick}
                disabled={!canFollow || isMyProfile}
              >
                {isFollowed ? 'Followed' : 'Follow'}
              </DezinerButton>
            )}
          </FollowDesignerButton>


          <HorizontalSpacer />

          <SubscribeDesignerButton designerId={follower.attributes.id} designer={follower?.attributes}>
            {({ onClick, isSubscribed }) => (
              <DezinerButton
                startIcon={<img src={subscribeIcon} alt="subscribe" style={{ maxWidth: '18px' }} />}
                size="small"
                variant="outlined"
                color="primary"
                onClick={onClick}
                disabled={!canFollow || isMyProfile}
              >
                {isSubscribed ? 'Subscribed' : 'Subscribe'}
              </DezinerButton>
            )}
          </SubscribeDesignerButton>

          <HorizontalSpacer />

          <Grid item container direction="column" style={{ maxWidth: 'max-content' }}>
            <Typography className={classes.followersCount}>{follower?.attributes?.follower_count ?? 0}</Typography>
            <Typography className={classes.followersString}>{'Followers'}</Typography>
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );
}
