import React from 'react';


export default function EmojiPicker({
  onChange,
}: {
  onChange: (text: string) => void,
}) {

  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    ref.current.addEventListener('emoji-click', (event: any) => {
      onChange(event.detail.unicode);
    });
  }, [])

  return React.createElement('emoji-picker', { ref });
}
