import React from 'react';
import { ForumTopNav } from './forum-top-nav';

export function ForumTopNavLayout(props: any) {

  const { children, ...otherProps } = props;

  return (
    <>

      <ForumTopNav {...otherProps} />

        {children}

    </>
  );
}
