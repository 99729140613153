export const codeOfConductContent = `

# 1.  I will upload only work that I’ve created
    

Share your own work on Deziner’s Knot.

-   Don’t post others’ work.
    
-   Don’t take credit for others’ work.
    
-   Don’t post copyrighted or trademarked content.
    
-   Don’t plagiarize others’ work. A remix/rebound is ok. An exact copy/ replica is not. (See: “I will give due credit”).
    

If your design violates these guidelines, it will be removed from Deziner’s Knot and your account may be suspended. Note: As per our Terms of Service, Deziner’s Knot has final say over whether content is appropriate.

# 2.  I will upload design work
    

Deziner’s Knot is a place to upload what you’re currently working on and portfolio quality work. With updates to Deziner’s Knot’s increased file size and file formats, Deziner’s Knot members should be thoughtful about the design work they upload.

-   Don’t share content that you would share on a video-sharing platform—including promotional videos, commercials, and tutorials. Video uploads should be used for animation, motion design, 3D explorations, UI/UX prototypes, and design work of this nature.
    
-   Don’t share show reels—let your collective designs tell the story of your work.
    
-   If you’re using audio to enhance your design, please use it thoughtfully. Make sure you have permission before using audio that's copyrighted or stick to audio that's under creative commons.
    

  

If your design violates these guidelines, it will be removed from Deziner’s Knot. Note: As per our Terms of Service, Deziner’s Knot has final say over whether content is appropriate.

# 3.  I won’t post inappropriate content
    

Deziner’s Knot aspires to be a welcoming and inclusive community. Please don’t post content that is:

-   Illegal
    
-   Pornographic, mature in content, or gratuitously or sexual
    
-   Racist, sexist, casteist, defamatory or otherwise offensive
    
-   Shockingly graphic, grotesque, or obscene
    
-   Inflammatory (e.g. name-calling, preaching, ranting, stirring up controversy, or venting frustrations)
    

Deziner’s Knot has final say over whether content is appropriate. If you repost content that’s been removed or continue to post content that violates these guidelines your account may be suspended or removed.

If you notice a Deziner’s Knot member posting inappropriate content, flag the desingn or report it by contacting us.

# 4.  I will follow the following guidelines for my profile
    

-   For all profiles:
    

-   Do not replace your name with an email address, website url, or keywords to attempt to appear higher in search results.
    

-   Do: Ravi Kumar
    
-   Don’t: Ravi Kumar • Logos • Branding • Graphic Design
    

-   For Pro profiles:
    

-   Do not include an email address or website url in the masthead description or image on your profile (including the use of special characters)
    
-   Do not include any profanity, offensive or hurtful language in the masthead description or image of your profile.
    
-   Do not post known false information including onsite statistics, follower counts or other misleading information
    
-   Do not include any inappropriate content in the masthead image on your profile. Inappropriate content includes text and images that are:
    
-   Illegal
    
-   Pornographic, mature in content, or gratuitously or sexual
    
-   Racist, sexist, casteist, defamatory or otherwise offensive
    
-   Shockingly graphic, grotesque, or obscene
    
-   Inflammatory (e.g. name-calling, preaching, ranting, stirring up controversy, or venting frustrations)
    

  

# 5.  If you post inappropriate content, it may be removed by Deziner’s Knot as per our Terms of Service. 
Deziner’s Knot has final say over whether content is appropriate. If you repost content that’s been removed or continue to post content that violates these guidelines your account may be suspended or removed.
    

  

# 6.  I won’t spam other Deziner’s Knot Members
    

Deziner’s Knot provides many features that allow users to interact with one-another, including messages. Please don’t use these tools indiscriminately to spam or send unsolicited messages to other members. If you’re caught spamming other Deziner’s Knot members, your account may be suspended or removed.

What do we consider spam?

-   Using likes, follows, and other disingenuous social gestures to draw attention to your account
    
-   Linking to your own work in comments on other members’ designs
    
-   Asking for likes and/or follows in exchange for a Deziner’s Knot invitation
    
-   Leaving many comments that are short on substance to draw attention to your account
    
-   Misusing site features and functionality in a way that isn’t in line with their core purpose for personal gain
    

If you see a Deziner’s Knot member spamming other, report it by contacting us.

# 7.  I won’t use Deziner’s Knot invitations to draw attention to my own account
    

-   Invitations should not be used as prizes for contests, but given genuinely based on your interest in following someone’s work
    
-   Invitation contests that require or suggest likes and follows for entry is prohibited
    
-   Deziner’s Knot’s invitation system aims to increase the number of talented designers and interesting work on Deziner’s Knot—not to draw attention to your profile
    
-   Please do not attempt to sell or buy Deziner’s Knot invitations or pursue fake follows on Deziner’s Knot
    

![Icon warning](https://lh6.googleusercontent.com/7JPW9RBYCxIpoH7LKUxRx0AnzjGfEOgJUq2uTH6LI-LhJnJmjF01UpqYQOWr574ptBPrNJC0C9k_IWldpEMolNTY8h0zsUclYd5D6I_kP86bL4y-Jf1PfwmSy23GzbrtO_rVu0IkpEmT5ssbnACeSr-UjPi286e0UZs745HY_cpPqzgMGed8fBwp7jBy62vArjiYVQ) Your account may be suspended or removed if you engage in activity that violates these guidelines.

# 8.  I will give due credit
    

If your work is inspired by other work on Deziner’s Knot, make sure to give credit. Know that work that’s inspired by others may be interpreted as stolen.

If you’re the owner of work that has been posted on Deziner’s Knot without your consent, please review our Copyright Policy.

Please link back to Deziner’s Knot when posting Deziner’s Knot content elsewhere.

# 9.  I will be constructive in my feedback
    

When providing criticism or feedback to other Deziner’s Knot members—be helpful, not mean.

# 10.  I will use messages to contact Deziner’s Knot members about serious work inquires only
    

When sending a message to a designer, please follow these guidelines:

-   Your message must be a work inquiry regarding a design job or project and not of any other subject.
    
-   Please personalize the messages you send—don’t send boilerplate messages. They tend to feel impersonal and lack the detail necessary to be appealing. This means you should use their name, provide a brief description of the work or job for which you’re hiring, and any details you find compelling about the designer’s work on Deziner’s Knot.
    
-   Please make sure the designer you’re contacting is a good fit for your job or project as indicated by the work, bio and skills on their profile. Many designers also declare the type work in which they’re interested on the message form—so make sure your message conforms with their stated preferences.
    
-   Have a specific job or project in mind when contacting a designer. Don’t ask if someone is generally looking for new opportunities or if anyone they know is looking for new opportunities.
    
-   Please don’t message designers to ask for an invitation. Your messaging privileges will be revoked.
    

# 11.  I recognize that accounts are only to be used for posting my personal work
    

All work posted under an individual account represents that account holders personal work and not the work of a team, collective, community or any other group of individuals.
`;
