import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { Message, } from "../../../../../framework/src/Message";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../../framework/src/IBlock";

export const configJSON = require("../../config");



export interface Props {
  navigation: any;
  id: string;
}


interface S {
}

interface SS {

}


export default class DesignerEditProfilePageController extends BlockComponent<
  Props,
  S,
  SS
  > {

  constructor(props: Props) {
    super(props);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }


  receive(from: string, message: Message): void {
    // Customizable Area Start
    // Customizable Area End
  }

}
