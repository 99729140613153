import { Watermark } from "../api-types";
import { watermarkMessages } from "../messages-generator";
import { watermarksKeys } from "../query-keys";
import { useDezinerQuery } from "../ReactQuery.web";

export function useWatermarks() {

  return useDezinerQuery<{ data: Watermark[] }>({
    message: watermarkMessages.fetchWaterMarks(),
    queryKey: watermarksKeys.fetchWatermarks(),
  });
}
