import React from "react";
import AccountSettingForm from "../../common/AccountSettingsForm";
import DesignerAccountSettingsPageController, {
  Props,
} from "./DesignerAccountSettingsController.web";


class DesignerAccountSettings extends DesignerAccountSettingsPageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <AccountSettingForm />
    );
  }
}




export default DesignerAccountSettings;
