import React, { ReactNode, } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useLabelStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    display: 'block'
  },
  centerLabel: {
    textAlign: 'center'
  }
});

export function DezinerLabel({
  children, centerLabel, className, htmlFor, id,
}: {
    children: ReactNode, centerLabel?: boolean, className?: any, htmlFor?: string, id?: string
}) {

  const classes = useLabelStyles();

  return (
    <InputLabel 
      id={id}
      htmlFor={htmlFor} 
      className={clsx(classes.root, className, {
      [classes.centerLabel]: centerLabel
    })}>
      {children}
    </InputLabel>
  )
}
