export const cometchatKeys = {

  conversationHistory:
    (params: { uid: string, chatType: 'group'|'user' }) => ['conversation-history', params] as const,

  conversationsList:
    (params: { uid: string }) => ['conversations-list', params] as const,

  sendMessage:
    (params: { uid: string }) => ['send-message', params] as const,


  joinGroup:
    (params: { gid: string }) => ['join-gorup', params] as const,

  groupMembersList:
    (params: { gid: string }) => ['group-members', params] as const,
}
