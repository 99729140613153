import { produce } from 'immer';
import { CometChatAudioMessageGroup, CometChatAudioMessageUser, CometChatDeliveredMessage, CometChatMessage, CometChatReadMessage } from '../../../../../blocks/LiveChat2/src/types';
import { ClearCometChatAction, CLEAR_COMETCHAT_MESSAGES, SaveCometChatMessageAction, SaveDeliveredMessageAction, SaveReadMessageAction, SAVE_COMETCHAT_MESSAGE, SAVE_DELIVERED_MESSAGE, SAVE_READ_MESSAGE } from './message.action';

export type CometChatGlobalMessage = CometChatMessage | CometChatAudioMessageUser | CometChatAudioMessageGroup;

export interface MessagesState {
  realTime: { [uid: string]: CometChatGlobalMessage[] },
  read: { [messageId: string]: CometChatReadMessage },
  delivered: { [messageId: string]: CometChatDeliveredMessage },
}

const initState: MessagesState = {
  realTime: {},
  read: {},
  delivered: {},
}

type MessagesActions = ClearCometChatAction | SaveCometChatMessageAction | SaveReadMessageAction | SaveDeliveredMessageAction;

export const messageReducer = produce((state: MessagesState, action: MessagesActions) => {

  switch (action.type) {

    case SAVE_READ_MESSAGE: {
      state.read[action.message.messageId] = action.message;
      break;
    }

    case SAVE_DELIVERED_MESSAGE: {
      state.delivered[action.message.messageId] = action.message;
      break;
    }

    case SAVE_COMETCHAT_MESSAGE: {

      const { uid, message } = action;

      if (!state.realTime[uid])
        state.realTime[uid] = [];

      state.realTime[uid].push(message);

      break;
    }

    case CLEAR_COMETCHAT_MESSAGES: {

      state.realTime[action.uid] = [];

      break;
    }
  }


  return state;

}, initState)
