import React, { useRef } from 'react';
import { useFormikContext } from "formik";
import PlusIcon from '@material-ui/icons/Add';
import Dropzone from "react-dropzone";
import { DezinerLabel } from "../../../../../components/src/design-system";
import { blobToBase64 } from "../../../../../components/src/utils";
import { FormMedia, isImageFile, isVideoFile, UplodaDesignInitValues, not, isVideoBelowLimits, isImageBelowLimits } from "./UploadDesignForm.web";
import { SubDesign } from "./UploadDesignFormComponents.web";
import { useUploadDesignFormStyles } from "./UploadDesignFormStyle.web";
import Grid from '@material-ui/core/Grid';


export function SubDesignsInput() {

  const classes = useUploadDesignFormStyles();
  const subDesignsRef = useRef<any>();
  const formik = useFormikContext<UplodaDesignInitValues>();

  return (
    <div>
      <DezinerLabel>
        {'Add Subdesigns'}
      </DezinerLabel>
      <Grid container>
        {
          formik.values.media
            ?.slice(1) // first media file is skipped
            ?.map((item, index) => {

              const isNewFile = Boolean(item.file);

              const type = isNewFile ? item?.file?.type : item.design_type;
              const name = isNewFile ? (item?.file?.name ?? '') : (item?.name ?? '');

              const clickHandler = () => {
                formik.setFieldValue('media', [item, ...formik.values.media.slice().filter((x, i) => i != index + 1)]);
              }
              const removeHandler = () => {
                formik.setFieldValue('media', formik.values.media.slice().filter((x, i) => i != index + 1));
              }

              return (<SubDesign
                key={`${name}_${index}_${type}`}
                data={item?.data}
                type={type || 'image'}
                clickHandler={clickHandler}
                removeHandler={removeHandler}
              />);

            })
        }

        {
          formik.values.media.length < 5 ?
            <Dropzone
              multiple
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
                'video/*': ['.mp4', '.mkv', '.mov']
              }}
              maxFiles={5 - formik.values.media.length}
              onDropRejected={(filesRejected) => {
                let rejectedLength = filesRejected.length;
                let extraFilesLength = Math.abs(5 - (formik.values.media.length + rejectedLength));
                console.error(`You can\'t upload more than 5 files, you uploaded ${extraFilesLength} more files `);
              }}
              onDrop={async (files: any[], fileRejected: any) => {
                try {
                  if (!files.length) return;


                  let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));
                  let videosHasExceededLimits = files.filter(isVideoFile).some(not(isVideoBelowLimits));

                  if (imagesHasExceededLimits || videosHasExceededLimits)
                    formik.setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
                  else
                    formik.setErrors({ 'media': '' })

                  const filteredFiles =
                    files
                      .filter(file => {
                        if (isImageFile(file))
                          return isImageBelowLimits(file);
                        else if (isVideoFile(file))
                          return isVideoBelowLimits(file);
                      })


                  let filesBase64 = await Promise.all(filteredFiles.map(blobToBase64));

                  let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
                    file,
                    data: filesBase64[index],
                    design_type: file?.type,
                    file_size: file?.size,
                    name: file?.name,
                  }));

                  formik.setFieldValue('media', [...formik.values.media, ...newFiles]);
                } catch (error) {
                  console.error(error);
                }
              }}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: "dropzone" })}
                  onClick={() => {
                    if (subDesignsRef && subDesignsRef.current)
                      subDesignsRef.current.click();
                  }}
                >
                  <div className={classes.subDesignAddContainer}>
                    <PlusIcon fontSize="large" style={{ color: '#aaaaac' }} />
                  </div>
                  {/* @ts-ignore */}
                  <input
                    {...getInputProps()}
                    ref={subDesignsRef}
                    type="file"
                  />
                </div>
              )}
            </Dropzone>
            :
            null
        }
      </Grid>

    </div>
  );
}
