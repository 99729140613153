import React from 'react';
import { useComments, usePostComment, } from '../../../../../components/src/hooks';
import Typography from '@material-ui/core/Typography';
import {DezinerSpinner} from '../../../../../components/src/design-system';
import { CommentInput } from '../../../../Comments/src/CommentInput';
import { Comment } from '../../../../../blocks/Comments/src/Comments';



export default function DesignCommentsSection({ designId }: { designId: number }) {

  const commentsQuery = useComments({ designId });
  const { mutate: postComment, isLoading: postCommentLoading } = usePostComment({ designId });

  return (
    <>

      <Typography style={{
        fontWeight: 'bold',
        fontSize: '1.5rem'
      }}>
        {'Comments'}
      </Typography>

      <CommentInput
        onComment={({ commentText, base64Image, base64Audio }) => {
          postComment({
            designId,
            commentAttachement: base64Image,
            commentAudio: base64Audio,
            commentText,
          });
        }}
      />

      {
        commentsQuery?.isLoading || postCommentLoading ?
          <DezinerSpinner noHeight />
          :
          null
      }

      {
        (commentsQuery?.data?.data ?? [])?.map((comment) =>
          <Comment
            key={comment.id}
            designId={designId}
            comment={comment}
          />
        )
      }
    </>
  );
}


