export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const microsoft = require("../assets/ReactNative_Signin_assets_1367fdf4fb40eca42235a4cae608a3d562a70a12.png");
export const google = require("../assets/ReactNative_Signin_assets_5fd51105ef513e40780482b21ff14e947a8e87f3.png");
export const logo = require("../assets/ReactNative_Signin_assets_b59377a7d70a02966414b6bb1f9c58acb29b174f.png");
export const camera = require('../assets/camera.png');
export const cameraGray = require('../assets/camera-gray.svg');
export const rightArrow = require('../assets/rightArrow.svg');
export const downArrow = require('../assets/downArrow.svg');
export const darkLogo = require('../assets/darkLogo.png');
export const profileUpload = require('../assets/profile-upload.png');
export const logoCropped = require('../assets/logo-cropped.png');