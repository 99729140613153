import * as yup from 'yup';

export const uploadDesignValidationSchema1 = yup.object().shape({
  waterMarkWidth: yup.number(),
  waterMarkHeight: yup.number(),
  media: yup.array().of(yup.mixed()).min(1, 'Please upload one image/video'),
  watermarkId: yup.number(),
  title: yup.string().required(),
  tags: yup.array().of(yup.mixed()).min(1),
  privacy: yup.string().required(),
  isThumbnail: yup.bool(),
  description: yup.string().required(),
  descriptionAudio: yup.string(),
  price: yup.number().when('privacy', {
    is: value => value?.toLowerCase() == 'private',
    then: yup.number().moreThan(0, 'Please put a price for your design').required(),
    otherwise: yup.number(),
  }),
});

const priceConditionItemSchema = yup.object().shape({
  year: yup.number().moreThan(-1, 'year should be equal or grater than zero').required('year is required').default(0),
  month: yup.number().moreThan(-1, 'month should be grater than zero').required('month is required'),
  price: yup.number().moreThan(0, 'price can not be zero or less').required('price is required'),
});

export const uploadDesignValidationSchema2 = yup.object().shape({
  sourceLink: yup.string().url('Source Link should be a valid URL'),
  sourceFiles: yup.array().of(yup.mixed()).when('sourceLink', {
    is: value => value && value.length > 0,
    then: yup.array().of(yup.mixed()),
    otherwise: yup.array().of(yup.mixed()).min(1, 'Please upload one file'),
  }),
  timePeriod: yup.string(),

  priceConditions: yup.array()
    .of(priceConditionItemSchema),

});

