import React from 'react';
import { trending_up } from "../../../blocks/catalogue/src/assets";
import { defaultValuesTo } from '../utils';
import { ProfileAvatarUI } from '../common';
import { DEFAULT_DESIGNER_INFO, DesignerInfo } from './deziner-top-nav';
import { MenuItem, IconButton, Divider, Popover as MaterialPopover, Menu, ListItemText, ListItemIcon } from '@material-ui/core';
import { withStyles, } from "@material-ui/core/styles";

const StyledMenuItem = withStyles({
  root: {
    color: '#363636',
    '&:focus': {
      backgroundColor: '#ddd',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#363636'
      },
    },
  },
})(MenuItem);

const Popover = withStyles({
  root: {

  },
  paper: {

  },
})(MaterialPopover);



const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));


export function NavProfileAvatar({
  image,
  color,
  designerInfo,
  onClick,
  hideMenu,
}: {
  image: any,
  color: any,
  onClick?: () => void,
  hideMenu?: boolean,
  designerInfo?: DesignerInfo,
}) {

  const {
    promoteHandler,
    logoutHandler,
    editProfileHandler,
  } = defaultValuesTo<DesignerInfo>(DEFAULT_DESIGNER_INFO)(designerInfo)


  const [popoverElement, setPopoverElement] = React.useState<HTMLButtonElement | null>(null);

  function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!hideMenu)
      setPopoverElement(event.currentTarget);

    onClick && onClick();
  }

  function handlePopoverClose() {
    setPopoverElement(null);
  }

  const isPopoverOpen = Boolean(popoverElement);
  const popoverId = isPopoverOpen ? 'avatar-popover' : undefined;


  const logoutClickHandler = (e: any) => {
    e.preventDefault();
    logoutHandler && logoutHandler();
  };


  const hasActiveGroup = Boolean(designerInfo?.currentGroupName);
  const hasActiveClub = Boolean(designerInfo?.currentClubName);

  let promoteTextClubOrGroup = `Promote ${hasActiveClub ? "Club" : "Group"}`;

  let promoteText = (hasActiveClub || hasActiveGroup) ?
    promoteTextClubOrGroup
    :
    "Promote Profile";


  return (
    <div>
      <IconButton onClick={handlePopoverClick}>
        <ProfileAvatarUI
          image={image}
          color={color}
          size="small"
        />
      </IconButton>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledMenu
          id={popoverId}
          anchorEl={popoverElement}
          keepMounted
          open={isPopoverOpen}
          onClose={handlePopoverClose}
        >


          <StyledMenuItem>
            <ListItemText
              onClick={editProfileHandler}
              primary={`Edit Profile`} />
          </StyledMenuItem>


          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={promoteHandler}>
            <ListItemIcon style={{ minWidth: '32px' }}>
              <img
                className="icon-img"
                alt="Promote collection"
                src={trending_up}
                style={{ maxWidth: '20px' }}
              />
            </ListItemIcon>
            <ListItemText primary={promoteText} />
          </StyledMenuItem>

          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={logoutClickHandler}>
            <ListItemText
              primary={`Logout`} />
          </StyledMenuItem>


        </StyledMenu>


      </Popover>

    </div>
  );

}
