import { DezinerActionCreator } from "../store";

export const OPEN_NEED_LOGIN_DIALOG = 'OPEN_NEED_LOGIN_DIALOG';
export const CLOSE_NEED_LOGIN_DIALOG = 'CLOSE_NEED_LOGIN_DIALOG';

export interface OpenNeedLoginDialogAction {
  type: typeof OPEN_NEED_LOGIN_DIALOG,
  redirectURLAfterLogin: string,
  redirectParamsAfterLogin: string,
}

export interface CloseNeedLoginDialogAction {
  type: typeof CLOSE_NEED_LOGIN_DIALOG,
}

export const openNeedLoginDialog: DezinerActionCreator<OpenNeedLoginDialogAction> =
  (params) => ({
    type: OPEN_NEED_LOGIN_DIALOG,
    ...params,
  });

export const closeNeedLoginDialog: DezinerActionCreator<CloseNeedLoginDialogAction> =
  (params) => ({
    type: CLOSE_NEED_LOGIN_DIALOG,
    ...params,
  });

/////////// 

export const SET_CURRENT_VIDEO_ID = 'SET_CURRENT_VIDEO_ID';

export interface SetCurrentVideoIdAction {
  type: typeof SET_CURRENT_VIDEO_ID,
  videoId: string,
}

export const setCurrentVideoId: DezinerActionCreator<SetCurrentVideoIdAction> =
  (params) => ({
    type: SET_CURRENT_VIDEO_ID,
    ...params,
  });

//////// 

export const SET_USER_ONLINE_STATUS = 'SET_USER_ONLINE_STATUS';

export interface SetUserOnlineStatusAction {
  type: typeof SET_USER_ONLINE_STATUS,
  status: 'online' | 'offline',
  uid: string,
}

export const setUserOnlineStatus: DezinerActionCreator<SetUserOnlineStatusAction> =
  (params) => ({
    type: SET_USER_ONLINE_STATUS,
    ...params,
  });

//////// 

export const TOGGLE_CHAT_MINIMIZE = 'TOGGLE_CHAT_MINIMIZE';

export interface ToggleChatMinimizeAction {
  type: typeof TOGGLE_CHAT_MINIMIZE,
}

export const toggleChatMinimize: DezinerActionCreator<ToggleChatMinimizeAction> =
  (params) => ({
    type: TOGGLE_CHAT_MINIMIZE,
    ...params,
  });

//////////// 

export const OPEN_CHAT_CONVERSATION_LIST = 'OPEN_CHAT_CONVERSATION_LIST';

export interface OpenChatConversationListAction {
  type: typeof OPEN_CHAT_CONVERSATION_LIST,
}

export const openChatConversationList: DezinerActionCreator<OpenChatConversationListAction> =
  (params) => ({
    type: OPEN_CHAT_CONVERSATION_LIST,
    ...params,
  });

//////////

export const CLOSE_CHAT_CONVERSATION_LIST = 'CLOSE_CHAT_CONVERSATION_LIST';

export interface CloseChatConversationListAction {
  type: typeof CLOSE_CHAT_CONVERSATION_LIST,
}

export const closeChatConversationList: DezinerActionCreator<CloseChatConversationListAction> =
  (params) => ({
    type: CLOSE_CHAT_CONVERSATION_LIST,
    ...params,
  });


///////// 

export const OPEN_NEW_CHAT_WIDGET = 'OPEN_NEW_CHAT_WIDGET';

export interface OpenNewChatWidgetAction {
  type: typeof OPEN_NEW_CHAT_WIDGET,
  uid: string,
  name: string,
  status: 'offline' | 'online',
  chatType: 'user' | 'group'
}

export const openNewChatWidget: DezinerActionCreator<OpenNewChatWidgetAction> =
  (params) => ({
    type: OPEN_NEW_CHAT_WIDGET,
    ...params,
  });

//////

export const CLOSE_NEW_CHAT_WIDGET = 'CLOSE_NEW_CHAT_WIDGET';

export interface CloseNewChatWidgetAction {
  type: typeof CLOSE_NEW_CHAT_WIDGET,
}

export const closeNewChatWidget: DezinerActionCreator<CloseNewChatWidgetAction> =
  (params) => ({
    type: CLOSE_NEW_CHAT_WIDGET,
    ...params,
  });

//////////////

export const INCREMENT_CHAT_UNREAD_COUNT = 'INCREMENT_CHAT_UNREAD_COUNT'

export interface IncrementChatUnreadCountAction {
  type: typeof INCREMENT_CHAT_UNREAD_COUNT,
  uid: string,
}

export const incrementChatUnreadCount: DezinerActionCreator<IncrementChatUnreadCountAction> =
  (params) => ({
    type: INCREMENT_CHAT_UNREAD_COUNT,
    ...params
  });

//////////

export const CLEAR_CHAT_UNREAD_COUNT = 'CLEAR_CHAT_UNREAD_COUN';

export interface ClearChatUnreadCountAction {
  type: typeof CLEAR_CHAT_UNREAD_COUNT,
  uid: string,
}

export const clearChatUnreadCount: DezinerActionCreator<ClearChatUnreadCountAction> =
  (params) => ({
    type: CLEAR_CHAT_UNREAD_COUNT,
    ...params,
  });
