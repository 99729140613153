import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DesignComment, DesignReply } from "../../../components/src/api-types";
import { useIsLoggedIn, useLikeComment } from "../../../components/src/hooks";
import { statisticsFormatter } from "../../../components/src/utils";
import { DezinerButton, HideContainer } from "../../../components/src/design-system";


const useCommentButtonsStyles = makeStyles({
  commentsButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blackHoverButton: {
    textTransform: 'none',
    fontWeight: 700,
    color: '#979797',
    '&:hover': {
      color: '#515151',
    }
  },
  blackLikeButton: {
    color: '#515151',
  },
});

export function CommentButtons(props: {
  comment: DesignComment | DesignReply,
  designId: number,
  showReply: boolean,
  setShowReply: React.Dispatch<React.SetStateAction<boolean>>,
  parentCommentId?: number,
  allowReply?: boolean,
  onAnonymousInteraction?: () => void,
}) {

  const classes = useCommentButtonsStyles();

  const {
    designId,
    showReply,
    setShowReply,
    comment,
    parentCommentId,
    allowReply,
    onAnonymousInteraction,
  } = props;

  const commentAttr = comment.attributes;

  const isLoggedIn = useIsLoggedIn();

  const { mutate: likeComment, } = useLikeComment({
    designId,
    commentId: commentAttr.id,
    parentCommentId,
  });


  function replyShowToggler() {
    if (!isLoggedIn && onAnonymousInteraction)
      onAnonymousInteraction();
    else
      setShowReply(x => !x);
  }

  const likesCount = commentAttr?.like_count;
  const likesCountString = likesCount > 0 ? `${statisticsFormatter(likesCount)}` : '';

  function likeHandler() {
    if (!isLoggedIn && onAnonymousInteraction)
      onAnonymousInteraction();
    else
      likeComment({ commentId: commentAttr.id });
  }

  return (
    <div className={classes.commentsButtonsContainer}>

      <HideContainer hideWhen={!allowReply}>
        <DezinerButton
          variant="text"
          className={classes.blackHoverButton}
          onClick={replyShowToggler}>
          {showReply ? 'Cancel' : 'Reply'}
        </DezinerButton>
      </HideContainer>

      <DezinerButton
        onClick={likeHandler}
        variant="text"
        className={clsx(classes.blackHoverButton, {
          [classes.blackLikeButton]: commentAttr.liked
        })}>
        {commentAttr.liked ? `${likesCountString} Liked` : `${likesCountString} Like`}
      </DezinerButton>

    </div>
  );
}
