import React from "react";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { HorizontalSpacer, VerticalSpacer, DezinerButton } from '../../../components/src/design-system';

const useStyles = makeStyles({
  actionsButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  overlay: {

    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'black',
    zIndex: -100,
    opacity: 0.6,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  cardText: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textAlign: 'center',
    margin: '10px 30px',
  },
  card: {
    borderRadius: '20px'
  },
});

export default function MemberInvitationPopup({
  ownerName,
  contribution,
  conditionCheck,
  changeConditionCheck,
  onAccept,
  onDecline,
  orgName,
  orgType,
  role,
  currentTab,
  acceptLoading,
  declineLoading
}: {
  ownerName: string,
  contribution: string,
  conditionCheck: boolean,
  changeConditionCheck: (params: { e: any }) => void,
  onAccept: () => void,
  onDecline: () => void,
  orgName: string,
  orgType: string,
  role: string,
  currentTab: number,
  acceptLoading: boolean,
  declineLoading: boolean,
}) {

  const classes = useStyles();

  return (
    <div className={classes.container}>

      <div className={classes.overlay} />
      <Card className={classes.card}>

        <CardContent>
          {
            currentTab == 0 ?
              <Typography className={classes.cardText}>
                {`${ownerName} has invited you to join ${orgName} ${orgType} as a ${role},`}
                <br />
                {`accept invitation and see ${orgType} activities`}
              </Typography>
              :
              <Typography className={classes.cardText}>
                {`${ownerName} has assigned you ${contribution} revenue for your contribution`}
              </Typography>
          }

          <FormControlLabel
            style={{
              justifyContent: 'center',
              width: '100%'
            }}
            control={
              <Checkbox
                checked={conditionCheck}
                onChange={(e: any) => changeConditionCheck(e)}
                name="termCondition"
                color="primary"
              />
            }
            label="Terms and Conditions"
          />

        </CardContent>


        <CardActions className={classes.actionsButtonsContainer}>
          <DezinerButton
            variant='contained'
            color='primary'
            disabled={acceptLoading || !conditionCheck}
            onClick={onAccept}
            loading={acceptLoading}
          >
            {'Accept'}
          </DezinerButton>
          <HorizontalSpacer />
          <DezinerButton
            color="default"
            variant='contained'
            onClick={onDecline}
            loading={declineLoading}
            disabled={declineLoading}
          >
            {'Decline'}
          </DezinerButton>
        </CardActions>

        <VerticalSpacer />

      </Card>


    </div>
  );
}
