import React from "react"
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fileIconBtn: {
    transform: 'rotate(45deg)'
  },
});

export function CommentAttachIcon({
  onClick,
  hide,
}: {
  onClick: () => void,
  hide?: boolean,
}) {

  const classes = useStyles();

  if (hide)
    return null;

  return (
    <IconButton data-testid="file-upload-btn" onClick={onClick} size="small" className={classes.fileIconBtn}>
      <AttachFileIcon fontSize="small" />
    </IconButton>
  );

}
