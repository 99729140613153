import { CometChat } from "@cometchat-pro/chat";
import { useQuery } from '@tanstack/react-query';
import { useIsLoggedIn } from "../../../../components/src/hooks";
import { cometchatKeys } from "../query-keys";
import { CometChatGlobalMessage } from "../../../../components/src/store/cometchat/message/message.reducer";
import { CometChatGlobalConversation, } from "../types";



export function useCometChatConverstaionHistoryWithUser({ uid, chatType }: { uid: string, chatType: 'user' | 'group' }) {

  async function dataFetcher() {
    let limit = 30;

    function getRequestBuilder() {

      if (chatType == 'group')
        return new CometChat.MessagesRequestBuilder()
          .setGUID(uid)
          .setLimit(limit)
          .build();

      return new CometChat.MessagesRequestBuilder()
        .setUID(uid)
        .setLimit(limit)
        .build();
    }

    const messages = await getRequestBuilder().fetchPrevious()

    return messages as CometChatGlobalMessage[];
  }

  return useQuery<CometChatGlobalMessage[]>(
    cometchatKeys.conversationHistory({ uid, chatType }),
    dataFetcher, {
    enabled: Boolean(uid),
  });
}


export function useCometChatLatestConversationsList({ uid }: { uid: string }) {

  const isLoggedIn = useIsLoggedIn();

  function fetchConversations(): Promise<CometChatGlobalConversation[]> {

    let limit = 30;
    let conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(limit)
      .build();

    return new Promise((resolve, reject) => {
      conversationRequest.fetchNext()
        .then((res) => {
          resolve(res as CometChatGlobalConversation[]);
        })
        .catch((error) => {
          console.error('cometChat fetchConversations error', error)
          reject(error);
        });
    });
  }

  return useQuery<CometChatGlobalConversation[]>(
    cometchatKeys.conversationsList({ uid }),
    fetchConversations, {
    enabled: Boolean(uid && isLoggedIn),
  });
}
