
exports.DeleteMethod = 'DELETE';
exports.GetMethod = 'GET';
exports.PostMethod = 'POST';
exports.PutMethod = 'PUT';

exports.JsonContentType = 'application/json';

exports.fetchProfileAPI = 'account_block/accounts/profile';


exports.fetchCollectionsAPI = "account_block/collections";


exports.addLabelAPI = "bx_block_categories/labels";



exports.fetchLabelsAPI = "bx_block_categories/labels";
exports.fetchCollectionsAPI = "account_block/collections";
exports.fetchDesignsBaseAPI = "account_block/accounts";
exports.fetchDesignerDesignsAPI = "bx_block_attachment/designs";

exports.addNewLabelAPI = "bx_block_categories/labels";

exports.createCollectionAPI = 'account_block/collections';

exports.fetchDesignerProfileByUserAPI = "account_block/accounts/:id/designer_profile_by_users";
exports.fetchDesignerLabelsByUserAPI = "account_block/accounts/:id/designer_labels_by_users";
exports.fetchDesignerCollectionsByUserAPI = "account_block/accounts/:id/designer_collections_by_users";
exports.fetchDesignerDesignsByUserBaseAPI = "account_block/accounts/:id";



exports.pinCollectionAPI = "bx_block_cfpindesigns2/pins";
exports.unpinCollectionAPI = "bx_block_cfpindesigns2/pins/unpin";



exports.userDesignsBaseAPI = "account_block/accounts";



exports.changeCoverImageAPI = "account_block/accounts/change_cover";

exports.updateProfileAPI = "account_block/accounts/update_profile";


exports.uploadProfilePictureAPI = "account_block/accounts/upload_profile";

exports.closeAccountAPI = "account_block/accounts/close_account";
exports.updateAccountSettingsAPI = "account_block/accounts/account_settings";

exports.changePasswordAPI = "account_block/accounts/change_password";

exports.updateAboutAPI = "account_block/accounts/update_about";


exports.categoriesListAPI = "bx_block_categories/categories";

exports.landingPageDesignsAPI = "account_block/landings/landing_page";

exports.skillsAPI = "bx_block_categories/skills";
exports.updateSocialMedialAPI = "account_block/accounts/social_profile";
exports.updateAboutAPI = "account_block/accounts/update_about";

exports.updateBankDetailsAPI = "account_block/bank_details";

exports.verifyAadharOtpAPI = "account_block/accounts/aadhaar_otp_varification";

exports.verifyAadharAPI = "account_block/accounts/aadhaar_ekyc";


exports.fetchSingleDesignAPI = 'bx_block_attachment/designs';

exports.fetchDesignShareLinkAPI = "bx_block_share/share";

exports.fetchMoreDesignsAPI = "bx_block_attachment/designs/:id/more_designs";

exports.postCommentAPI = 'bx_block_comments/comments';
exports.fetchCommentsAPI = "bx_block_attachment/designs/:id/design_comments";

exports.fetchCollectionAPI = "account_block/collections";

exports.inspiredUsersAPI = "bx_block_attachment/designs/:id/inspire_accounts";

exports.submitRatingAPI = 'bx_block_catalogue/ratings';

exports.fetchWatermarksAPI = 'bx_block_attachment/watermarks';
exports.uploadDesignAPI = 'bx_block_attachment/designs'



exports.fetchSourceFilesAPI = 'bx_block_attachment/designs/:id/source_designs';

exports.createForumAPI = 'bx_block_communityforum/forums';
exports.fetchForumsAPI = "bx_block_communityforum/forums";
exports.likeDesignAPI = 'bx_block_like/likes';
exports.inspireDesignAPI = 'bx_block_catalogue/inspires';

exports.followDesignerAPI = "bx_block_followers/follows";
exports.createDesignPaymentIntentAPI = "bx_block_attachment/buy_designs";
exports.unfollowDesignerAPI = "bx_block_followers/follows/unfollow";

exports.likeCommentAPI = "bx_block_comments/comments/:id/like";
exports.saveDesignAPI = "bx_block_attachment/save_designs";

exports.fetchUserProfileByOther = "account_block/accounts/:id/other_user_about";


exports.designerListAPI = "bx_block_groups/clubs/designers_list";

// Clubs
exports.clubBaseAPI = "bx_block_groups/clubs";

exports.fetchClubMembersAPI = "club_members";
exports.fetchClubRolesAPI = "club_roles";

// shared between groups and clubs 
exports.addAsAdminAPI = "add_as_admin";
exports.addMemberAPI = "add_member";

exports.changeGroupClubCoverImageAPI = "change_cover";
exports.updateGroupClubInfoAPI = "update_about";
exports.updateGroupClubSocialProfilesAPI = "social_profile";

// Groups
exports.groupBaseAPI = "bx_block_groups/groups";

exports.fetchGroupMembersAPI = "group_members";




exports.fetchUserCollectionsByOthersAPI = "account_block/accounts/:id/designer_collections_by_users";

exports.nonRespondinMembersAPI = "non_responding_members";

exports.fetchDesignerFollowersAPI = "account_block/accounts/:id/designer_followers_by_users";

exports.followClubAPI = "bx_block_followers/follows/club_follow";
exports.unfollowClubAPI = "bx_block_followers/follows/club_unfollow";
exports.clubGroupFollowersAPI = "following_members";

exports.followGroupAPI = "bx_block_followers/follows/group_follow";
exports.unfollowGroupAPI = "bx_block_followers/follows/group_unfollow";
exports.acceptInvitationAPI = "bx_block_groups/groups/invition_accepted";
exports.declineInvitationAPI = "bx_block_groups/groups/invition_declined";

exports.acceptInvitationClubAPI = "bx_block_groups/clubs/invition_accepted";
exports.declineInvitationClubAPI = "bx_block_groups/clubs/invition_declined";


exports.globalSearchQuery = "account_block/landings/advance_search";
exports.globalSearchSuggestionQuery = "account_block/landings/auto_search";
exports.checkIfPromotedAPI = "bx_block_attachment/promotes/check_promoted";
exports.validationCreditCardAPI = "bx_block_attachment/promotes/card_validation";
exports.promoteAPI = "bx_block_attachment/promotes";


exports.fetchRepliesToReplyAPI= "bx_block_comments/comments/:id/reply_comments";

exports.fetchDesignsCountAPI = "bx_block_attachment/designs/design_count"
exports.subscriptionPackages = "bx_block_custom_user_subs/plans";
exports.applySubscriptionPackage = "bx_block_custom_user_subs/user_subscriptions";
exports.designSubscriptionPlans = "bx_block_custom_user_subs/subscriptions";
exports.topupPlans = "bx_block_custom_user_subs/plans/topup_plans";
exports.applyGeneralPackage = "bx_block_custom_user_subs/subscriptions";
exports.getNotifications = "bx_block_notifications/notifications";
