import React from "react";
import ForumCard from "./forum-card";
import { useNavigate, useLikeForum, useDislikeForum, useIsLoggedIn } from "../../../components/src/hooks";
import { Forum } from "../../../components/src/api-types";

export function ForumCardContainer({
  forum,
  onAnonymousInteraction,
  singlePageMode,
  commentHandler,
}: {
  forum?: Forum,
  onAnonymousInteraction: () => void,
  commentHandler?: () => void,
  singlePageMode?: boolean
}) {

  const { mutate: likeForum } = useLikeForum({ id: forum?.attributes?.id ?? 0 });
  const { mutate: dislikeForum } = useDislikeForum({ id: forum?.attributes?.id ?? 0 });

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  return (
    <ForumCard
      commentHandler={commentHandler}
      forum={forum}
      singlePageMode={singlePageMode}
      onClick={() => {
        navigate(`ForumSingle/${forum?.attributes?.id}`)
      }}
      likeHandler={() => {
        if (isLoggedIn)
          likeForum({ id: forum?.attributes?.id ?? 0 })
        else
          onAnonymousInteraction()
      }}
      dislikeHandler={() => {
        if (isLoggedIn)
          dislikeForum({ id: forum?.attributes?.id ?? 0 })
        else
          onAnonymousInteraction()
      }}
    />
  );
}
