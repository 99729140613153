import React from "react"
import { DesignComment } from "../../../components/src/api-types";
import { usePostReply } from "../../../components/src/hooks";
import { CommentInput } from "./CommentInput";


export function CommentReplyInput({
  comment,
  hideFileAttachements,
  hideVoiceRecorder,
  replyPlaceholder,
  designId,
  onReplySuccess,
}: {
  comment: DesignComment,
  hideVoiceRecorder?: boolean,
  hideFileAttachements?: boolean,
  replyPlaceholder?: string,
  designId: number,
  onReplySuccess?: () => void,
}) {

  const { mutate: onReply } = usePostReply({
    designId,
    parentCommentId: comment.attributes.id,
  });

  return (
    <CommentInput
      placeholder={replyPlaceholder}
      hideFileAttachements={hideFileAttachements}
      hideVoiceRecorder={hideVoiceRecorder}
      singleLine={true}
      onComment={({ commentText, base64Image, base64Audio }) => {
        onReply({
          reply: commentText,
          parentCommentId: comment.attributes.id,
          replyAttachment: base64Image,
          replyAudio: base64Audio
        }, {
          onSuccess: onReplySuccess
        });
      }}
    />
  );
}
