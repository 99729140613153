import React, { useState } from 'react';
import { useSingleDesign, useSourceFiles } from '../../../../../components/src/hooks';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DezinerButton, } from '../../../../../components/src/design-system';
import { makeStyles } from '@material-ui/core/styles';
import { BuyOptionsDialog } from './BuyDesignDialog';

const useStyles = makeStyles({
  floatButton: {
    boxShadow: '0 0 5px #cecaca',
    padding: '6px 18px',
    border: 'none !important',
    marginLeft: '15px',
  },
  icon: {
    maxWidth: '20px',
  },
});

function downloadFile({
  fileName,
  base64,
}: {
  fileName: string,
  base64: string,

}) {

  return fetch(base64)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(console.error);
}



export function DesignBuyButton({
  hide,
  isBought,
  designId,
}: {
  hide: boolean,
  isBought: boolean,
  designId: number,
}) {

  const [buyOptionsDialogOpen, setBuyOptionsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const designQuery = useSingleDesign({ designId });

  const sourceFilesQuery = useSourceFiles({ designId }, {
    enabled: isBought,
  });

  const sourceFiles = sourceFilesQuery?.data?.data ?? [];


  if (hide)
    return null;

  if (isBought)
    return (
      <DezinerButton
        color="primary"
        variant="contained"
        className={classes.floatButton}
        startIcon={<GetAppIcon />}
        onClick={async () => {
          setIsLoading(true);
          await Promise.all(sourceFiles.filter(file => file?.attributes?.data).map(file => (
            downloadFile({
              fileName: file?.attributes?.name,
              base64: file?.attributes?.data,
            }))));
          setIsLoading(false);
        }}
        loading={isLoading}
      >
        {`Download`}
      </DezinerButton>
    );


  return (
    <>
      <BuyOptionsDialog
        open={buyOptionsDialogOpen}
        onClose={() => setBuyOptionsDialogOpen(false)}
        designId={designId}
      />
      <DezinerButton
        color="primary"
        variant="contained"
        className={classes.floatButton}
        startIcon={<LocalOfferIcon />}
        onClick={() => setBuyOptionsDialogOpen(true)}
      >
        {`Buy with ₹${designQuery?.data?.design?.data?.attributes?.price}`}
      </DezinerButton>
    </>
  );
}
