import React, { useState } from 'react';
import UserSubscriptionPopup from '../UserSubscriptionPopup.web';
import { DezinerFooter } from './footer';


export function FooterLayout(props: any) {

  const { children, ...otherProps } = props;
  const [showSubscription, setShowSubscription] = useState(false);
  return (
    <>

      {children}
      <DezinerFooter {...otherProps} setShowSubscription={() => setShowSubscription(true)}/>
      {/* <UserSubscriptionPopup
        open={showSubscription}
        onClose={() => setShowSubscription(false)}
        designerId={0}
        subscriptionType='Designs'
        designerName="Designs"
        designerProfile=''
        fallbackColor=''
      /> */}
    </>
  );
}
