import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { messageReducer } from '../cometchat/message/message.reducer';
import { uiReducer } from '../ui/ui.reducer';

export const rootReducer = (history: any) => combineReducers({

  messages: messageReducer,

  ui: uiReducer,

  router: connectRouter(history),
});
