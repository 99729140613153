import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import DesignerDesignUploadController, {
  Props,
} from "./UploadDesignController.web";
import UploadDesignForm from "../upload-design-form/UploadDesignForm.web";
import { useDecodedToken, useNavigate, useUploadDesign, } from "../../../../../components/src/hooks";
import { UploadDesignPayload } from '../../../../../components/src/messages-generator/designs';
import { DesignUploadHeader } from "../DesignUploadHeader";





class DesignerDesignUpload extends DesignerDesignUploadController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    const { classes } = this.props as any;

    const labelId = this.props.navigation.getParam("labelId", "");
    const collectionId = this.props.navigation.getParam("collectionId", "");
    const clubId = this.props.navigation.getParam("clubId", "");
    const type = this.props.navigation.getParam("type", "");


    return (
      <div className={classes.root}>

        <DesignUploadHeader headerText={'Publish Your Design'} />


        <UploadDesignFormContainer
          labelId={Number(labelId)}
          collectionId={Number(collectionId)}
          clubId={Number(clubId)}
          type={type}
        />

      </div>
    );
  }
}

function upperCaseFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

function UploadDesignFormContainer({
  labelId,
  collectionId,
  clubId,
  type,
}: {
  labelId: number,
  collectionId: number,
  clubId: number,
  type: string,
}) {

  const { id: designerId } = useDecodedToken();

  let uploadParams: Parameters<typeof useUploadDesign>[0] = {
    labelId,
    collectionId,
    designerId,
  }

  if (type == 'club')
    uploadParams.clubId = clubId;

  if (type == 'group')
    uploadParams.groupId = clubId;

  const { mutate: uploadDesign, isLoading: loading } = useUploadDesign(uploadParams);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);


  return (
    <UploadDesignForm
      onSubmit={(values) => {

        setIsLoading(true);

        const {
          title,
          tags,
          description,
          price,
          privacy,
          media,
          watermarkId,
          isThumbnail,
          descriptionAudio,
          waterMarkWidth,
          waterMarkHeight,
          sourceLink,
          sourceFiles,
          timePeriod,
          priceConditions,
        } = values;


        const sub_design = media.slice(1).map(({ data }) => ({ data }));

        const newTags = tags.map((tag) => {
          return { sub_category_id: tag.value };
        });

        const watermark_id_number = watermarkId;

        let clubOnlyParams = {
          club_id: clubId,
        }

        let groupOnlyParams = {
          group_id: clubId,
        }

        let payload: UploadDesignPayload = {
          data: {
            attributes: {
              design_type: upperCaseFirstLetter(type) as any,
              collection_id: collectionId,
              watermark_id: watermark_id_number,
              label_id: labelId,
              title: title,
              designs_sub_categories_attributes: newTags,
              privacy: privacy,
              description: description,
              price: price.toString(),
              width: waterMarkWidth,
              height: waterMarkHeight,
              is_thumbnail: isThumbnail,
              image: {
                data: media[0]?.data,
              },
              sub_designs: sub_design,
              audios: descriptionAudio ? [{
                data: descriptionAudio
              }] : [],
              design_url: sourceLink,
              source_designs_attributes: sourceFiles?.map((item) => ({
                data: item.data,
                type: item.design_type.replace('image/', '').replace('video/', ''),
                name: item.name,
                file_size: item.file_size / 1024,
              })) ?? [],
              time_period_type: timePeriod,
              design_prices_attributes: priceConditions?.map(({ year, month, price }) => ({ year, month, amount: price })) ?? [],
              ...(type == 'club' ? clubOnlyParams : {}),
              ...(type == 'group' ? groupOnlyParams : {}),
            },
          },
        };

        uploadDesign({ payload }, {
          onSuccess: (_data, payload) => {
            if (type.toLowerCase() == 'group')
              navigate(`GroupHomePage/${clubId}?label_id=${labelId}`);
            else if (type.toLowerCase() == 'club')
              navigate(`ClubHomePage/${clubId}?label_id=${labelId}`);
            else
              navigate(`Catalogue/${designerId}?label_id=${labelId}`);
          },
          onSettled: () => {
            setIsLoading(false);
          }
        });
      }}
      loading={isLoading || loading}
    />

  );

}


const useStyles: any = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "100%",
  },
};

export default withStyles(useStyles)(DesignerDesignUpload);
