import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { google } from "./assets";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "../../email-account-registration/src/microsoft";
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import { VerticalSpacer ,DezinerButton,FormikTextInput} from '../../../components/src/design-system';
import "./style.css";
const configJSON = require("../../social-media-account/src/config.js");

import EmailAccountLoginControllerWeb, {
  Props,
} from "./EmailAccountLoginController.web";
import RegisterationLayout from "../../email-account-registration/src/RegisterationLayout.web";
import LoginTypeForm from "../../email-account-registration/src/LoginTypeForm.web";
import AddInfoForm from "../../email-account-registration/src/AddInfoForm.web";
import EmailVerificationDialog from "../../email-account-registration/src/EmailVerificationDialog.web";

const loginValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const loginFormInitValues = {
  email: '',
  password: '',
}


class EmailAccountLoginBlockWeb extends EmailAccountLoginControllerWeb {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props as any;
    const { step } = this.state;

    let usualLoginForm = (
      <div className={classes.loginFormRoot}>

        <Formik
          initialValues={loginFormInitValues}
          validationSchema={loginValidationSchema}
          onSubmit={this.onLoginSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form translate="yes">

            <Grid container justifyContent="center">
              <Typography variant="h1" className={classes.formTitle}>
                {'SIGN IN'}
              </Typography>
            </Grid>

            <VerticalSpacer />

            <Grid container alignItems="center">
              <Grid item lg={6} sm={12} style={{ padding: '0 4px' }} container justifyContent="center">
                <GoogleLogin
                  clientId={configJSON.clientID}
                  render={(renderProps) => (
                    <DezinerButton
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      color="primary"
                      variant="contained"
                      noWrap
                      style={{ maxWidth: '100%', maxHeight: '35px', fontSize: '13px' }}
                    >
                      <img src={google} alt="google" style={{ margin: '0 5px' }} />
                      {'Sign in with Google'}
                    </DezinerButton>
                  )}
                  onSuccess={this.onGoogleLoginSuccess as any}
                  onFailure={this.onGoogleLoginFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
              <Hidden lgUp>
                <Grid item xs={12}><br /></Grid>
              </Hidden>
              <Grid item lg={6} sm={12} style={{ padding: '0 4px' }} container justifyContent="center">
                <MicrosoftLogin
                  buttonTheme="dark"
                  clientId="c8126b30-b0de-410c-858f-b165987f43f2"
                  redirectUri=""
                  authCallback={(err, data, msal) => {
                    this.microsoftAuthHandler(err, data as any, msal);
                  }}
                  label="Sign in with Microsoft"
                />
              </Grid>
            </Grid>

            <VerticalSpacer />
            <VerticalSpacer />

            <Grid container justifyContent="center">
              <Typography
                variant="body1"
                className={classes.orText}
              >
                {'or'}
              </Typography>
            </Grid>

            <VerticalSpacer />

            <FormikTextInput
              name="email"
              fullWidth
              variant="outlined"
              label={"Email"}
              disabled={this.state.loginLoading}
            />


            <VerticalSpacer />

            <FormikTextInput
              name="password"
              fullWidth
              variant="outlined"
              label={"Password"}
              type="password"
              disabled={this.state.loginLoading}
            />


            <Grid container justifyContent="flex-end">
              <DezinerButton bold color="secondary" variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.navigation.navigate("ForgotPasswordWeb");
                }}
              >
                {'Forgot password?'}
              </DezinerButton>
            </Grid>

            {
              this.state.errors?.email ?
                <Alert severity="error">
                  {this.state.errors?.email}
                </Alert>
                :
                null
            }

            <VerticalSpacer />

            <Grid container justifyContent="center">
              <DezinerButton
                disabled={this.state.loginLoading}
                rounded
                color="primary"
                type="submit"
                variant="contained">
                {'Sign In'}
                {
                  this.state.loginLoading ?
                    <CircularProgress style={{ margin: '0 5px' }} color="inherit" size={21} />
                    :
                    null
                }
              </DezinerButton>
            </Grid>

            <VerticalSpacer />

            <VerticalSpacer />

            <Grid container justifyContent="center" alignItems="center">
              <Typography style={{ fontWeight: 'bold' }}>
                {'Not a member?'}
              </Typography>
              <DezinerButton bold color="secondary" variant="text"
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.navigation.navigate("EmailAccountRegistrationWeb");
                }}
              >
                {'Sign Up Now'}
              </DezinerButton>
            </Grid>

          </Form>
        </Formik>

      </div>
    );

    const addInfoForm = (
      <AddInfoForm
        onSubmit={(values) => {
          this.setState({
            step: this.state.step + 1,
            profilePictureFile: values.image,
            location: values.location,
            color: values.color
          });
        }}
        onSkip={() => {
          this.setState({
            isSkippedClicked: true,
            step: this.state.step + 1
          });
        }}
      />
    );

    const loginTypeForm = (
      <LoginTypeForm
        onSubmit={(values) => this.onFinishSetup(values)}
        onBackHandler={() => {
          this.setState({
            step: this.state.step - 1
          });
        }}
        loading={this.state.createAccountLoading2}
      />
    );


    return (
      <>
        <RegisterationLayout>
          {
            step == 1 ?
              usualLoginForm :
              step == 2 ?
                addInfoForm :
                step == 3 ?
                  loginTypeForm :
                  null
          }
        </RegisterationLayout>
      </>
    );
  }
}


const useStyles: any = {
  loginFormRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
  formTitle: {
    fontWeight: 900, fontSize: '1.3rem'
  },
  orText: {
    position: 'relative',
    fontSize: '1.2rem', fontWeight: 'bold',
    '&:before': {
      width: "144px",
      height: "1px",
      content: '""',
      display: "block",
      position: "absolute",
      background: "#c5c5c5",
      top: "50%",
      left: "32px"
    },
    '&:after': {
      width: "144px",
      height: "1px",
      content: '""',
      display: "block",
      position: "absolute",
      background: "#c5c5c5",
      top: "50%",
      right: "32px"
    },
  }
};

export default withStyles(useStyles)(EmailAccountLoginBlockWeb);
