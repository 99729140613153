import { Message } from "../../../framework/src/Message";
import { appendJsonContentType, appendToken, apiCall, IApiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const collectionsMessages = {

  fetchSingleCollection({ collectionId }: { collectionId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.fetchCollectionAPI}/${collectionId}`,
    });

  },


  createCollection({
    collectionName,
    isPrivate,
    labelId,
    clubId,
    groupId,
  }: {
    collectionName: string,
    isPrivate: boolean,
    labelId: number,
    clubId?: number,
    groupId?: number,
  }) {

    let httpBody: any = {
      data: {
        attributes: {
          name: collectionName,
          label_id: labelId || null,
          privacy: isPrivate ? 'Private' : 'Public',
        }
      }
    };

    if (clubId) {
      httpBody.data.attributes = {
        ...httpBody.data.attributes,
        club_id: clubId,
        collection_type: "Club",
      }
    }

    if (groupId) {
      httpBody.data.attributes = {
        ...httpBody.data.attributes,
        group_id: groupId,
        collection_type: "Group",
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: configJson.createCollectionAPI,
    });

  },


  createUserCollection({ collectionName, isPrivate, }: {
    collectionName: string,
    isPrivate: boolean,
  }) {

    const httpBody = {
      data: {
        attributes: {
          name: collectionName,
          privacy: isPrivate ? 'Private' : 'Public',
          collection_type: "User"
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: configJson.createCollectionAPI,
    });

  },

  pinCollection({ collectionId, pin }: { collectionId: number, pin: boolean }) {

    const httpBody = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: pin ? configJson.pinCollectionAPI : configJson.unpinCollectionAPI,
    });

  },


  fetchCollections({ labelId, clubId, groupId }: { labelId?: number, clubId?: number, groupId?: number }) {

    let url = `${configJson.fetchCollectionsAPI}`;

    let params: IApiCall['params'] = {};

    if (labelId)
      params.label_id = labelId;

    if (clubId)
      params.club_id = clubId;

    if (groupId)
      params.group_id = groupId;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url,
      params,
    });

  },

  fetchMyUserCollections() {

    let url = configJson.fetchCollectionsAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url,
    });

  },

  fetchUserCollectionsByOthers({ userId }: { userId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchUserCollectionsByOthersAPI.replace(":id", userId),
    });

  },


  fetchDesignerCollectionsByUser({
    designerId,
    labelId,
  }: {
    designerId: number,
    labelId?: number,
  }) {


    let url = `${configJson.fetchDesignerCollectionsByUserAPI.replace(':id', designerId)}?label_id=${labelId && labelId > 0 ? labelId : 0}`;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url,
    });

  },

}
