Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";
exports.profileApi = 'account_block/accounts/profile';
exports.saveForum = 'bx_block_communityforum/forums';
exports.forums = "bx_block_communityforum/forums";
exports.btnExampleTitle = "CLICK ME";
exports.postCommentApi = "bx_block_comments/comments";
// Customizable Area End
