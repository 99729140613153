import React from 'react';
import clsx from 'clsx';
import { dezinerKnotLogo } from '../../../blocks/landingpage/src/assets';
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, } from '@material-ui/core';
import { DezinerButton } from '../design-system';


const EMPTY_FUNC = () => {
  // do nothing
};


const useNotLoggedInHeaderStyles = makeStyles({
  btn: {
    color: 'white',
    borderColor: 'white',
    padding: '5px 20px',
    fontSize: 'clamp(0.7rem, 0.4rem + 0.8vw, 1.0rem)',
  },
  logo: {
    maxWidth: 'clamp(120px, 35%, 160px)',
    cursor: 'pointer',
  },
});


export function NotLoggedInHeader({
  onLogoClick,
  onSignInAsUser,
  onSignInAsDesigner,
}: {
  onLogoClick?: () => void,
  onSignInAsDesigner?: () => void,
  onSignInAsUser?: () => void,
}) {

  const classes = useNotLoggedInHeaderStyles();

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <DezinerButton
          variant="outlined"
          color="primary"
          rounded
          className={classes.btn}
          onClick={onSignInAsDesigner || EMPTY_FUNC}
        >
          {'Sign In | Designer'}
        </DezinerButton>


        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className={clsx("white-svg", classes.logo)}
          onClick={onLogoClick || EMPTY_FUNC}
        />

        <DezinerButton
          variant="outlined"
          color="primary"
          rounded
          className={classes.btn}
          onClick={onSignInAsUser || EMPTY_FUNC}
        >
          {'Sign In | User'}
        </DezinerButton>


      </Toolbar>
    </AppBar>
  );
}
