import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { DesignHome } from "../../../../../components/src/api-types";
import { VerticalSpacer } from "../../../../../components/src/design-system";
import { CollectionListEmptyCard, DesignCardWithProfileContainer, SlickSliderNextArrow, SlickSliderPrevArrow } from "../../../../../components/src/common";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  slidesToShow: 4, // taken from state
  slidesToScroll: 1,
}

declare let ResizeObserver: any;

const ASPECT_RATIO = 387 / 292;

const imageClassNme = "deziner-design-home-img";

const useCollectionSliderStyles = makeStyles({
  slideItem: {
    margin: '0 5px',
  },
  sliderContainer: {
    padding: '0 20px',
  },
});





export default function UserCollectionSlider({
  designs,
  onAdd,
  allowAdd,
}: {
  designs: DesignHome[],
  onAdd: () => void,
  allowAdd: boolean,
}) {


  const classes = useCollectionSliderStyles();
  const elRef = useRef<any>();

  const [slidesToShow, setSlidsToShow] = useState(4);
  const [imageHeight, setImageHeight] = useState(200);
  const [containerWidth, setContainerWidth] = useState(1000);

  useEffect(() => {

    setTimeout(() => {
      let imgElemnts: HTMLCollectionOf<any> = document.getElementsByClassName(imageClassNme);
      let imgWidth = imgElemnts?.[0]?.offsetWidth ?? 0;
      if (imgWidth) {
        let slideImgHeight = imgWidth * 1 / ASPECT_RATIO;
        setImageHeight(slideImgHeight)
      }
    }, 50);


  }, [containerWidth]);


  const observer = useRef(
    new ResizeObserver((entries: any) => {
      const { width } = entries[0].contentRect

      let slidesToShow = 0;

      // desktop
      if (width >= 1490) {
        slidesToShow = 5;
      } else if (width >= 1220) {
        slidesToShow = 4;
      } else if (width >= 1010) {
        slidesToShow = 3;
      } else {
        slidesToShow = 2;
      }

      // tab
      if (width < 768) {
        if (width >= 745) {
          slidesToShow = 3;
        } else if (width >= 550) {
          slidesToShow = 2;
        } else {
          slidesToShow = 1;
        }
      }

      setContainerWidth(width);
      setSlidsToShow(slidesToShow);

    })
  )

  useEffect(() => {
    if (elRef.current) {
      observer.current.observe(elRef.current)
    }

    return () => {
      observer.current.unobserve(elRef.current)
    }
  }, [elRef, observer])


  return (
    <>
      <div ref={elRef} className={classes.sliderContainer}>
        <Slider
          nextArrow={<SlickSliderNextArrow />}
          prevArrow={<SlickSliderPrevArrow />}
          {...SLIDER_SETTINGS}
          slidesToShow={slidesToShow}>

          {
            allowAdd
              ?
              <CollectionListEmptyCard
                imgHeight={imageHeight}
                onClick={onAdd}
              />
              :
              null
          }

          {designs?.map((design) => (
            <DesignCardWithProfileContainer
              key={design.id}
              item={design}
              imgHeight={imageHeight}
              className={classes.slideItem}
            />
          ))}
        </Slider>
      </div>

      <VerticalSpacer />
    </>
  );
}
