import React from "react";
import { dezinerKnotLogo } from './../../../blocks/landingpage/src/assets';
import { makeStyles } from "@material-ui/core/styles";
import { facebookIcon, twitterIcon, pintrestIcon, instagramIcon } from '../../../blocks/catalogue/src/assets';
import { useDesignsCount } from '../hooks';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';



function DezinerLink(props: React.HTMLProps<HTMLAnchorElement>) {

  if (props.href && !props.href?.startsWith('http'))
    return <Link to={props.href}>{props?.children}</Link>

  return (
    <a {...props} />
  );
}


const FOOTER_LISTS = {
  DESIGNER: [
    { title: "Buy Pro", link: "" },
    { title: "Forum", link: "/CommunityForum" },
    { title: "Promote Design", link: "/PromoteDesign" },
    { title: "How it work", link: "/HowItWorks" },
    { title: "Category", link: "" },
  ],
  USER: [
    { title: "Buy Pro", link: "" },
    { title: "Buy Design", link: "/BuyDesign" },
    { title: "Search Designer", link: "/SearchDesigner" },
    { title: "Explore", link: "/Explore" },
    { title: "Category", link: "/Category" },
  ],
  COMPANY: [
    { title: "About us", link: "" },
    { title: "Advertise with us", link: "" },
    { title: "Use design", link: "" },
    { title: "Contact us", link: "" },
    { title: "Press / Media", link: "" },
    { title: "Careers", link: "" },
  ],
  INFORMATION: [
    { title: "FAQ", link: "/FAQ" },
    { title: "Design blog", link: "" },
    { title: "Testimonials", link: "/Testimonials" },
    { title: "Terms & use", link: "/TermsOfService" },
    { title: "Privacy policy", link: "/PrivacyPolicy" },
    { title: "License agreement", link: "" },
    { title: "Help center", link: "" },
    { title: "Code of Conduct", link: "/CodeOfConduct" },
    { title: "Cookie Policy", link: "/CookiePolicy" },
    { title: "Copyright Policy", link: "/CopyrightPolicy" },
  ],
  SOCIAL: [
    { icon: <img src={facebookIcon} alt="facebook" />, link: "https://www.facebook.com/dezinersknot" },
    { icon: <img src={pintrestIcon} alt="pinterest" />, link: "https://in.pinterest.com/dezinersknot/" },
    { icon: <img src={twitterIcon} alt="twitter" />, link: "https://twitter.com/deziners_knot" },
    { icon: <img src={instagramIcon} alt="instagram" />, link: "https://www.instagram.com/dezinersknot/" },
  ],
}

const useStyles = makeStyles({
  footerContainer: {
    padding: '60px 5vw 20px',
  },
  footer: {
    boxShadow: '0 -8px 10px #f6f6f6',
    background: '#f8fcfd',
    borderTop: '2px solid #f2f6f7',
  },
  footerLogo: {
    maxWidth: '300px',
  },
  footerTitle: {
    fontSize: '1.6rem',
    color: 'black',
    fontWeight: 'bold',
  },
  footerSubtitle: {
    fontSize: '1.1rem',
    color: '#797a7c',
    padding: '0 5px',
  },
  listTitle: {
    color: '#2c2b30',
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "5px"
  },
  listUL: {
    listStyle: "none",
    padding: "0",
    marginBottom: '20px',
    '& li:hover': {
      color: 'black',
    },
    '& li': {
      color: '#838486',
      marginBottom: '10px',
      '& *': {
        textDecoration: 'none',
        color: 'inherit',
        fontSize: "1.1rem",
      },
    },
  },
  socialListUL: {
    listStyle: "none",
    display: "flex",
    padding: "0",
    '& li': {
      marginRight: '10px',
      '& *': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  },
  subFooter: {
    background: 'black',
    padding: '20px 5vw',
  },
  whiteColor: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '1.1rem',
    padding: '0 5px',
  },
  grayColor: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: '#515254',
  },
});



export function DezinerFooter(props: any) {

  const classes = useStyles();
  const designsCountQuery = useDesignsCount();
  const totalDesigns = designsCountQuery?.data?.design_count ?? 0;
  return (
    <footer className={classes.footer}>

      <div className={classes.footerContainer}>
        <Grid container spacing={2}>

          <Grid item sm lg xs={12}>
            <img
              src={dezinerKnotLogo}
              alt="Deziner Knot"
              className={classes.footerLogo} />

            <Typography variant="body1" className={classes.footerTitle}>
              {'DEZINER\'S KNOT'}
              <Typography variant="caption" className={classes.footerSubtitle}>
                {'is the world'}
              </Typography>
            </Typography>
            <Typography variant="body1" className={classes.footerSubtitle}>
              {'leading design source'}
            </Typography>

          </Grid>
          <Grid item sm lg={2} xs={12}>
            <Typography className={classes.listTitle}>{'DESIGNER'}</Typography>
            <ul className={classes.listUL}>
              {FOOTER_LISTS.DESIGNER.map(({ title, link }, index) => (
                <li
                  key={title}
                  onClick={() => index === 0 && props.setShowSubscription()}
                ><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
              ))}
            </ul>

            <Typography className={classes.listTitle}>{'USER'}</Typography>
            <ul className={classes.listUL}>
              {FOOTER_LISTS.USER.map(({ title, link }, index) => (
                <li
                  key={title}
                  onClick={() => index === 0 && props.setShowSubscription()}
                ><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
              ))}
            </ul>
          </Grid>
          <Grid item sm lg={2} xs={12}>
            <Typography className={classes.listTitle}>{'COMPANY'}</Typography>
            <ul className={classes.listUL}>
              {FOOTER_LISTS.COMPANY.map(({ title, link }) => (
                <li key={title}><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
              ))}
            </ul>
          </Grid>
          <Grid item sm lg={2} xs={12}>
            <Typography className={classes.listTitle}>{'INFORMATION'}</Typography>
            <ul className={classes.listUL}>
              {FOOTER_LISTS.INFORMATION.map(({ title, link }) => (
                <li key={title}><DezinerLink href={link || '#'} target="_blank">{title}</DezinerLink></li>
              ))}
            </ul>
          </Grid>
          <Grid item sm lg={2} xs={12}>
            <Typography className={classes.listTitle}>{'SOCIAL'}</Typography>
            <ul className={classes.socialListUL}>
              {FOOTER_LISTS.SOCIAL.map(({ icon, link }) => (
                <li key={link}><DezinerLink href={link || '#'} target="_blank" >{icon}</DezinerLink></li>
              ))}
            </ul>
          </Grid>

        </Grid>

      </div>

      <Grid container justifyContent="space-between" alignItems="center" className={classes.subFooter}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.grayColor}>
            &#169;
              {` ${new Date().getFullYear()} | DEZINER'S KNOT`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="flex-end">
          <Typography variant="caption" className={classes.whiteColor}>{totalDesigns}</Typography>
          <Typography variant="caption" className={classes.grayColor}>{'Designers Knotted'}</Typography>
        </Grid>
      </Grid>

    </footer>
  );
}
