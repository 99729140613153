import React, { useState } from "react";
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import { uploadDesignValidationSchema1, uploadDesignValidationSchema2, } from './UploadDesignFormValidationSchema.web';
import { Formik, Form, } from 'formik';
import { VerticalSpacer, } from "../../../../../components/src/design-system";
import { UploadDesignFormStep2 } from "./step2";
import { SubmitFooter } from "./submit-footer";
import { UploadDesignFormStep1 } from "./step1";
const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_VIDEO_FILE_SIZE = 20 * 1024 * 1024; // 20MB


export const isImageBelowLimits = (file: any) => file.size < MAX_IMAGE_FILE_SIZE;
export const isVideoBelowLimits = (file: any) => file.size < MAX_VIDEO_FILE_SIZE;
export const isImageFile = (file: any) => file.type?.includes('image');
export const isVideoFile = (file: any) => file.type?.includes('video');

export const not = (fn: any) => (...args: any) => !fn(...args);


export interface FormMedia { file?: File, name: string, file_size: number, data: string, design_type: string, }

export interface UplodaDesignInitValues {
  // stored as "percent"
  waterMarkWidth: number,
  // stored as "percent"
  waterMarkHeight: number,
  media: FormMedia[],
  watermarkId: number,
  title: string,
  tags: {
    "value": number,
    "label": string,
    "parentLabel": string
  }[],
  privacy: string,
  isThumbnail: boolean,
  description: string,
  descriptionAudio: string,
  price: number,
  sourceLink: string,
  sourceFiles: FormMedia[],
  timePeriod: string,
  priceConditions: { year: number, month: number, price: number }[],
}

const internalInitialValues: UplodaDesignInitValues = {
  waterMarkWidth: 150,
  waterMarkHeight: 150,
  media: [],
  watermarkId: -1,
  title: '',
  tags: [],
  privacy: '',
  isThumbnail: false,
  description: '',
  descriptionAudio: '',
  price: 0,
  sourceFiles: [],
  sourceLink: '',
  timePeriod: 'Auto',
  priceConditions: [],
}

function clapOn100(value: number) {
  return Math.min(100, value);
}



/*
* UploadDesignForm
*
* Note: watermarkHeight, watermarkWidth are stored on the API in percent (e.g: 40, which means 40% of the media width) 
* so whenever you need to render the watermark you calculate the media offset width and multiply by this percent to know the watermark width/height
* and that's why on form initialization we convert these percents to pixel values, and on submit we convert pixels values to percent values
*
*/
export default function UploadDesignForm({
  initialFormValues,
  onSubmit,
  loading,
}: {
  initialFormValues?: Partial<UplodaDesignInitValues>,
  onSubmit: (values: UplodaDesignInitValues) => void,
  loading: boolean,
}) {

  /* main media file width on screen */
  const [mediaOffsetWidth, setMediaOffsetWidth] = useState(0);

  /* main media file height on screen */
  const [mediaOffsetHeight, setMediaOffsetHeight] = useState(0);

  const classes = useUploadDesignFormStyles();

  const [step, setStep] = useState(1);

  const inEditMode = Boolean(initialFormValues);

  const initValuesProps = inEditMode ? {
    ...initialFormValues,
    // calculate the watermark width/height in **pixel** based on a percentage of its offset width/height
    waterMarkWidth: mediaOffsetWidth && initialFormValues?.waterMarkWidth ? initialFormValues.waterMarkWidth * mediaOffsetWidth : 150,
    waterMarkHeight: mediaOffsetHeight && initialFormValues?.waterMarkHeight ? initialFormValues.waterMarkHeight * mediaOffsetHeight : 150,
  } : {}

  return (
    <Formik
      initialValues={Object.assign({}, internalInitialValues, initValuesProps)}
      onSubmit={(values) => {
        if (step == 1)
          setStep(2);
        else
          onSubmit({
            ...values,
            // calculate watermark width/height in **percent**, we use current media offset width/height to calculate the percentage of watermark 
            // height/width and then we store the **percentage** in the API
            waterMarkWidth: clapOn100(mediaOffsetWidth ? values.waterMarkWidth / mediaOffsetWidth : values.waterMarkWidth),
            waterMarkHeight: clapOn100(mediaOffsetHeight ? values.waterMarkHeight / mediaOffsetHeight : values.waterMarkHeight),
          });
      }}
      validationSchema={step == 1 ? uploadDesignValidationSchema1 : uploadDesignValidationSchema2}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={inEditMode}
    >
      {
        formik => <Form translate="yes" style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexGrow: 1,
        }}>


          <div className={classes.formContentContainer}>
            {
              step == 1 ?
                <UploadDesignFormStep1
                  setMediaOffsetWidth={setMediaOffsetWidth}
                  setMediaOffsetHeight={setMediaOffsetHeight}
                />
                :
                <UploadDesignFormStep2 />
            }
          </div>

          <VerticalSpacer />
          <VerticalSpacer />
          <VerticalSpacer />

          <SubmitFooter {...{ step, setStep, loading }} />

        </Form>
      }
    </Formik>
  );
}
