import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSingleDesign } from '../../../../../components/src/hooks';
import { DezinerSpinner, DezinerMedia } from '../../../../../components/src/design-system';

const useDesignMainMediaStyles = makeStyles({
  container: {
    position: 'relative',
    padding: "5px",
    border: "2px solid #e8e8e8",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& img': {
      height: "auto",
      maxWidth: "100%",
      minHeight: "400px",
      objectFit: "cover",
      borderRadius: "10px",
      width: "100%",
      maxHeight: "700px"
    },
    '& video': {
      maxHeight: "700px",
      objectFit: "cover",
      width: "100%",
      borderRadius: "10px"
    },
  },
});

export default function DesignMainMedia({
  selectedMediaIndex,
  designId,
}: {
  selectedMediaIndex: number,
  designId: number,
}) {

  const classes = useDesignMainMediaStyles();
  const designQuery = useSingleDesign({ designId });
  const isLoading = designQuery?.isLoading;

  const designAttributes = designQuery?.data?.design?.data?.attributes;


  const designImages = [{
    url: designAttributes?.image_url,
    type: designAttributes?.main_image_type
  }]
    .concat(designAttributes?.sub_designs ?? [])
    .filter(({ url, type }) => Boolean(url && type));

  const selectedMediaType: 'video' | 'image' =
    designImages?.[selectedMediaIndex]?.type?.includes('video') ? 'video' : 'image';

  const selectedMediaURL = designImages?.[selectedMediaIndex]?.url;

  if (isLoading)
    return <DezinerSpinner />

  return (
    <div className={classes.container}>
      <DezinerMedia
        id={String(designId)}
        mediaType={selectedMediaType}
        src={selectedMediaURL}
        watermarkURL={designAttributes?.watermark_url}
        watermarkWidth={parseFloat(designAttributes?.width ?? '100')}
        watermarkHeight={parseFloat(designAttributes?.height ?? '100')}
        controls={true}
      />
    </div>
  );
}
