import React, { useEffect, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core';
import { useNavigate, useSingleDesign, } from '../../../../../components/src/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { DesignPriceItem } from '../../../../../components/src/api-types';
import { DezinerButton, DezinerDropdown, VerticalSpacer, } from '../../../../../components/src/design-system';

const OptionsDialog = withStyles({
  paper: {
    borderRadius: '20px',
  },
})(Dialog);

const useBuyDialogStyles = makeStyles({
  button: {
    borderRadius: '0px',
    padding: '10px 20px !important',
    fontSize: '1.2rem',
    border: `3px solid lightgray`,
    color: 'gray',
    '&:hover': {
      border: `3px solid black`,
    },
  },
  activeButton: {
    border: `3px solid black`,
    color: 'black',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    flexWrap: 'wrap',
    '& > *': {
      margin: '5px',
    }
  },
});

function calculateLimitedPeriodString(targetPriceItem?: DesignPriceItem, _andString?: string) {
  const yearsCount = targetPriceItem?.year;
  const yearString = yearsCount ? `${yearsCount} Year(s)` : '';

  const monthsCount = targetPriceItem?.month
  const monthString = monthsCount ? `${monthsCount} Month(s)` : ''

  const andString = monthsCount && yearsCount ? (_andString || '&') : ''

  return `${yearString} ${andString} ${monthString}`;
}






export function BuyOptionsDialog({
  open,
  onClose,
  designId,
}: {
  open: boolean,
  onClose: () => void,
  designId: number,
}) {

  const classes = useBuyDialogStyles();

  const designQuery = useSingleDesign({ designId });
  const designerAttributes = designQuery?.data?.design?.data?.attributes;
  const [selectedOption, setSelectedOptions] = useState(0);
  const [isLifetime, setIsLifetime] = useState(false);
  const navigate = useNavigate();

  const selectedPriceItem = designerAttributes?.design_prices?.find(item => item.id == selectedOption);


  useEffect(function selectDefualtOption() {
    setSelectedOptions(designerAttributes?.design_prices?.[0]?.id ?? 0);
  }, [designerAttributes]);


  return (
    <OptionsDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
            {designerAttributes?.title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>

        <div className={classes.buttonsContainer}>
          <DezinerButton
            variant={'outlined'}
            onClick={() => setIsLifetime(true)}
            color="primary"
            startIcon={<CheckIcon />}
            className={clsx(classes.button, {
              [classes.activeButton]: isLifetime
            })}
          >
            {'Lifetime'}
          </DezinerButton>

          <DezinerButton
            variant={'outlined'}
            onClick={() => setIsLifetime(false)}
            color="primary"
            startIcon={<CheckIcon />}
            disabled={!designerAttributes?.design_prices?.length}
            className={clsx(classes.button, {
              [classes.activeButton]: !isLifetime
            })}
          >
            {'Limited Period'}
          </DezinerButton>
        </div>

        <VerticalSpacer />

        {
          !isLifetime && designerAttributes?.design_prices?.length ?
            <Grid container justifyContent="center">
              <DezinerDropdown
                selectedOption={String(selectedOption)}
                onClick={(v) => setSelectedOptions(parseInt(v))}
                options={designerAttributes?.design_prices
                  ?.map(item => ({
                    key: String(item.id),
                    title: calculateLimitedPeriodString(item)
                  })) ?? []
                }
                title=""
              />
            </Grid>
            :
            null
        }

        <VerticalSpacer />

        <Grid container justifyContent="center">
          <Typography style={{ fontSize: '1.2rem', }}>
            {`Buy with `}
            <span style={{
              fontWeight: 'bold',
              color: 'black',
              padding: '0 5px',
            }}>
              {'₹ '}
              {isLifetime ? (designerAttributes?.price ?? 0) : (selectedPriceItem?.amount ?? 0)}
            </span>
            {`for `}
            {
              isLifetime ?
                'Lifetime'
                :
                calculateLimitedPeriodString(selectedPriceItem, 'and')
            }
          </Typography>
        </Grid>

        <VerticalSpacer />

        <hr style={{ borderColor: '#f1f1f1' }} />

      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', padding: '30px 0', }}>
        <DezinerButton
          size="large"
          variant="contained"
          color="primary"
          style={{ padding: '16px 32px', fontSize: '1.1rem' }}
          onClick={() => {
            if (isLifetime)
              navigate(`/DesignBuy/${designId}`);
            else
              navigate(`/DesignBuy/${designId}?price_id=${selectedOption}`);
          }}>
          {'Continue to Buy'}
        </DezinerButton>
      </DialogActions>

    </OptionsDialog>
  );
}
