import { CometChat } from "@cometchat-pro/chat";
import { useMutation, } from "@tanstack/react-query";
import { useDispatch, } from "react-redux";
import { cometchatKeys } from "../query-keys";
import { saveCometChatMessage } from "../../../../components/src/store/cometchat/message/message.action";

interface SentTextMessage {
  "type": "text",
  "receiverType": "user",
  "category": "message",
  "data": {
    "text": string,
  },
  "text": string,
  "receiver": string,
}

interface SentAudioMessage {
  "receiverId": string,
  "type": "audio",
  "receiverType": "user",
  "category": "message",
  "data": {},
  "files": Blob[]
}



export function useCometChatSendMessage({ uid, type, }: { uid: string, type?: 'user' | 'group' }) {

  const dispatch = useDispatch();

  async function sendMsg({ message, }: { message: string, }) {

    let receiverID = uid;
    let messageText = message;
    let receiverType = type == 'group' ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER;
    let textMessage: SentTextMessage = (new CometChat.TextMessage(receiverID, messageText, receiverType) as any);
    let sentMessage = await CometChat.sendMessage(textMessage);

    dispatch(saveCometChatMessage({
      uid,
      message: sentMessage as any,
      chatType: type || 'user'
    }))

    return sentMessage;
  }

  return useMutation(
    cometchatKeys.sendMessage({ uid }),
    sendMsg,
  );
}



export function useCometChatSendUserMediaMessage({ uid, type }: { uid: string, type?: 'user' | 'group' }) {

  const dispatch = useDispatch();

  async function sendMsg({ file }: { file: any, }) {

    let receiverID = uid;
    let receiverType = type == 'group' ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER;

    let messageType = CometChat.MESSAGE_TYPE.AUDIO;
    let audioMessage: SentAudioMessage = (new CometChat.MediaMessage(receiverID, file, messageType, receiverType)) as any;

    let sentMessage = await CometChat.sendMediaMessage(audioMessage);

    dispatch(saveCometChatMessage({
      uid,
      message: sentMessage as any,
      chatType: type || 'user'
    }));

    return sentMessage;
  }

  return useMutation(
    cometchatKeys.sendMessage({ uid }),
    sendMsg,
  );
}

