import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormikTextInput, DezinerButton } from '../../../../../components/src/design-system';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useCreateUserCollection } from '../../../../../components/src/hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  flexContainer: { display: 'flex', flexDirection: 'column', },
  submitBtnContainer: { padding: '20px 0px', alignSelf: 'flex-end' }
});

const createCollectionValidationSchema = yup.object().shape({
  name: yup.string().required(),
  isPrivate: yup.bool().default(false),
});

export default function CreateUserCollectionForm({
  createButtonText,
  loading,
  onSuccess,
}: {
  createButtonText?: string,
  loading?: boolean,
  onSuccess?: (response: any) => void,
}) {

  const classes = useStyles();

  const { mutate: createCollection, isLoading: createLoading } = useCreateUserCollection();

  const isLoading = loading || createLoading;


  return (
    <Formik
      initialValues={{ name: '', isPrivate: false }}
      onSubmit={(values) => {
        createCollection({
          isPrivate: values.isPrivate,
          collectionName: values.name
        }, {
          onSuccess: onSuccess
        });
      }}
      validationSchema={createCollectionValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {
        formik =>
          <Form translate="yes">

            <div className={classes.flexContainer}>
              <FormikTextInput
                variant="outlined"
                name="name"
                placeholder="Enter collection name"
              />

              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={formik.values.isPrivate}
                  onChange={(event) => {
                    formik.setFieldValue('isPrivate', event.target.checked)
                  }}
                  name="isPrivate"
                />}
                label={'keep this collection private'}
              />

              <div className={classes.submitBtnContainer}>
                <DezinerButton
                  loading={loading}
                  color="primary"
                  variant="contained"
                  onClick={formik.submitForm}
                  disabled={isLoading}
                >
                  {createButtonText || 'Create'}
                </DezinerButton>
              </div>


            </div>

          </Form>
      }
    </Formik>
  );
}
