import React  from "react"
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import {HideContainer} from "../../../components/src/design-system";

const useCommentSendIconStyles = makeStyles({
  divider: {
    height: "20px",
    margin: "0px 8px",
    background: "#757575",
    width: "2px"
  }
});

export function CommentSendIcon({
  onClick,
  hide,
  hideDivider,
}: {
  onClick: () => void,
  hide?: boolean,
  hideDivider?: boolean,
}) {

  const classes = useCommentSendIconStyles();

  if (hide)
    return null;

  return (
    <>
      <HideContainer hideWhen={hideDivider}>
        <Divider className={classes.divider} />
      </HideContainer>

      <IconButton data-testid="send-btn" size="small" onClick={onClick}>
        <SendIcon fontSize="small" />
      </IconButton>
    </>
  );

}
