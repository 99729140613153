import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useCommentImageStyles = makeStyles({
  fileLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px'
  },
});

export function CommentImage({
  base64Image,
  onDelete,
  fileName,
}: {
  base64Image: string,
  onDelete: () => void,
  fileName: string,
}) {
  const classes = useCommentImageStyles();

  if (!base64Image)
    return null;

  return (
    <div className={classes.fileLinkContainer}>
      <a
        target="_blank"
        href={base64Image}
        download={fileName}
      >
        {fileName}
      </a>
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
