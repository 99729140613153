import React, { createContext, useState } from 'react';
import { LandingPageDesign, } from "../../../../components/src/api-types";
import { LandingPageFiltersContainer } from './filters';
import { DesignersSliderContainer } from './designers-slider';
import { DesignsContainer } from './designs';
import { LandingPageNoDataContainer } from './no-data';
import { LoadMoreButtonContainer } from './load-more';
import { LandingPageAPISortingOptions } from '../../../../components/src/messages-generator';


export const SortPropertyMapper: {
  [key: string]: keyof LandingPageDesign['attributes']
} = {
  "most_liked": "like_count",
  "most_viewed": "view_count",
  "most_recent": "created_at",
}

export const LandingPageContext = createContext<{
  selectedFilter: string,
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>,
  selectedCategories: number[],
  setSelectedCategories: React.Dispatch<React.SetStateAction<number[]>>,
  selectedSortOption: LandingPageAPISortingOptions,
  setSelectedSortOption: React.Dispatch<React.SetStateAction<LandingPageAPISortingOptions>>,
}>({
  selectedFilter: 'All',
  setSelectedFilter: () => { },
  selectedCategories: [],
  setSelectedCategories: () => { },
  selectedSortOption: 'recent',
  setSelectedSortOption: () => { },
});



export function LandingPageContextProvider({ children }: { children: any }) {

  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedSortOption, setSelectedSortOption] = useState<LandingPageAPISortingOptions>('recent');
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const value = {
    selectedFilter, setSelectedFilter,
    selectedSortOption, setSelectedSortOption,
    selectedCategories, setSelectedCategories
  }

  return (
    <LandingPageContext.Provider value={value}>
      {children}
    </LandingPageContext.Provider>
  );
}

export default function LandingPageContent() {


  return (
    <LandingPageContextProvider>
      <div>

        <LandingPageFiltersContainer />

        <DesignersSliderContainer />

        <DesignsContainer />

        <LandingPageNoDataContainer />

        <LoadMoreButtonContainer />

      </div>
    </LandingPageContextProvider>
  );
}
