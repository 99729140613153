import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import DesignerClubManageController, {
  Props
} from "./DesignerClubManageController.web";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares from "./MembersContributionTable.web";
import DesignerProfileWithOptions from "./DesignerProfileWithOptions.web";
import AddIcon from '@material-ui/icons/Add';
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";
import { DeleteMemberDialog } from "./DeleteMemberDialog.web";
import { useParams } from "../../../components/src/utils";
import { useAddClubMemberAsAdmin, useClubMembers, useClubsNonRespondingMembers, useDecodedToken, useNavigate, useRemoveClubMember, useRemoveClubMemberAdminPermission, useSingleClub, useAcceptClubMemberInvitation, useDeclineClubMemberInvitation } from "../../../components/src/hooks";
import { LoadingSpinnerContainer, HideContainer, DezinerButton, VerticalSpacer } from "../../../components/src/design-system";
import MemberInvitationPopup from "./MemberInvitation";
import { defaultToEmptyArray, defaultToEmptyString } from "../../../components/src/default-values";
import { ClubMember } from "../../../components/src/api-types";





class DesignerClubManage extends DesignerClubManageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <DesignerClubManageContent />
    );
  }
}

const useStyles = makeStyles({
  noActiveMembers: {
    textAlign: "center", fontSize: '1.4rem', color: 'gray', padding: '20px'
  },
  membersGrid: {
    display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))'
  },
  nonRespondingMembers: { fontWeight: 'bold', fontSize: '1.4rem' },
  nonRespondingMembersContainer: {
    borderBottom: '1px solid #e3e3e3'
  },
  memberCardContainer: {
    padding: '30px 0',
  },
  clubHeader: {

    borderBottom: '1px solid #ddd'
  },
});

function DesignerClubManageContent() {

  const [currentTab, setCurrentTab] = useState(0);
  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [deletedMember, setDeleteMember] = useState({ deleteType: '', memberId: 0, memberName: '' });
  const [approveCondition, setApprovedCondition] = useState(false);

  const classes = useStyles();

  const params = useParams();
  const clubId = Number(params.clubId);

  const membersQuery = useClubMembers({ clubId });
  const members = defaultToEmptyArray<ClubMember>(membersQuery?.data?.data);

  const clubQuery = useSingleClub({ clubId });

  const { id: myProfileId } = useDecodedToken();

  const navigate = useNavigate();

  const { mutate: removeAdminPermission } = useRemoveClubMemberAdminPermission({ clubId });
  const { mutate: removeMember } = useRemoveClubMember({ clubId });
  const { mutate: acceptInvitation, isLoading: acceptLoading } = useAcceptClubMemberInvitation({ clubId });
  const { mutate: declineInvitation, isLoading: declineLoading } = useDeclineClubMemberInvitation({ clubId });

  const nonRespondingMembersQuery = useClubsNonRespondingMembers({ clubId });
  const nonRespondingMembers = defaultToEmptyArray<ClubMember>(nonRespondingMembersQuery?.data?.data);

  const ownerName = members?.concat(nonRespondingMembers)?.find((member) => member.attributes?.user_type == "Owner")?.attributes?.first_name;
  const myMember = members?.concat(nonRespondingMembers).find(member => member.attributes.account_id == myProfileId);

  const showInvitation = myMember && !membersQuery.isLoading && !myMember?.attributes?.accepted && !myMember?.attributes?.declined;

  const currentRole = defaultToEmptyString(myMember?.attributes?.user_type);
  const isOwner = currentRole == "Owner";
  const isAdmin = currentRole == "Admin";

  const showNonRespondingMembers = isOwner && nonRespondingMembers?.length;



  return (
    <div>

      <DeleteMemberDialog
        deleteType={deletedMember.deleteType as any || "remove_member"}
        open={deleteMemberOpen}
        onClose={() => { setDeleteMemberOpen(false); }}
        memberName={deletedMember.memberName}
        onAccept={() => {

          const { deleteType, memberId } = deletedMember;

          if (deleteType == "remove_as_admin")
            removeAdminPermission({ memberId, clubId });
          else if (deleteType == "remove_member")
            removeMember({ memberId, clubId });

          setDeleteMemberOpen(false);
          setDeleteMember({ deleteType: '', memberId: 0, memberName: '' });
        }}
      />





      <div style={{ padding: '50px 0' }}>
        <LoadingSpinnerContainer loading={membersQuery.isLoading}>
          <Grid container>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm>


              <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="center"
                className={classes.clubHeader}
              >
                <GroupAndClubHorizontalCard
                  item={clubQuery?.data?.data}
                  hideButton
                  hideMembers
                  hideBorder
                  onNameClick={() => {
                    navigate(`ClubHomePage/${clubId}`);
                  }}
                />
                <DezinerTabs
                  value={currentTab}
                  onChange={(_e, tab) => setCurrentTab(tab)}
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  <DezinerTab label="Member" {...a11yProps(0)} />
                  <DezinerTab label="Role" {...a11yProps(1)} />
                </DezinerTabs>
              </Grid>

              <DezinerTabPanel value={currentTab} index={0}>


                <Members
                  onDelete={({ memberName, deleteType, memberId }) => {
                    setDeleteMember({
                      memberName,
                      deleteType,
                      memberId,
                    })
                    setDeleteMemberOpen(true);
                  }}
                />


                <VerticalSpacer />


                <Grid container justifyContent="center">
                  <HideContainer
                    hideWhen={!(isAdmin || isOwner)}
                  >
                    <DezinerButton
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        navigate(`AddClubMember/${clubId}`)
                      }
                      startIcon={
                        <AddIcon />
                      }
                    >
                      {'Add Member'}
                    </DezinerButton>
                  </HideContainer>
                </Grid>

                <HideContainer hideWhen={!showNonRespondingMembers}>
                  <NonRespondingMembers />
                </HideContainer>




              </DezinerTabPanel>
              <DezinerTabPanel value={currentTab} index={1}>

                <MembersContributionTableWithShares
                  members={members}
                  onDelete={({ memberName, memberId }) => {
                    setDeleteMember({
                      memberName,
                      memberId,
                      deleteType: "remove_member",
                    })
                    setDeleteMemberOpen(true);
                  }}
                  onAdd={() => navigate(`AddClubMember/${clubId}`)}
                  onSave={(revenu) => {
                    console.log({ revenu });
                  }}
                  currentRole={currentRole}
                  workContributionHeaderTitle="Work contribution %"
                />

                <HideContainer hideWhen={!showNonRespondingMembers}>
                  <NonRespondingMembers />
                </HideContainer>


              </DezinerTabPanel>

            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
        </LoadingSpinnerContainer>
      </div>

      <HideContainer hideWhen={!showInvitation}>
        <MemberInvitationPopup
          ownerName={defaultToEmptyString(ownerName)}
          contribution={myMember?.attributes?.contribution || "0%"}
          conditionCheck={approveCondition}
          changeConditionCheck={(e: any) => setApprovedCondition(e.target.checked)}
          onAccept={() => acceptInvitation({ clubId, accountId: myProfileId })}
          onDecline={() => declineInvitation({ clubId, accountId: myProfileId }, {
            onSuccess: () => navigate('DesignClubPageView')
          })}
          orgType={'Club'}
          orgName={defaultToEmptyString(clubQuery?.data?.data?.attributes.name)}
          role={myMember?.attributes?.user_type || ''}
          currentTab={currentTab}
          acceptLoading={acceptLoading}
          declineLoading={declineLoading}
        />
      </HideContainer>


    </div>
  );
}


function NonRespondingMembers() {

  const classes = useStyles();

  const params = useParams();
  const clubId = Number(params.clubId);

  const membersQuery = useClubMembers({ clubId });
  const members = defaultToEmptyArray<ClubMember>(membersQuery?.data?.data);

  const { id: myProfileId } = useDecodedToken();

  const nonRespondingMembersQuery = useClubsNonRespondingMembers({ clubId });
  const nonRespondingMembers = defaultToEmptyArray<ClubMember>(nonRespondingMembersQuery?.data?.data);

  const myMember = members?.concat(nonRespondingMembers).find(member => member.attributes.account_id == myProfileId);

  const currentRole = defaultToEmptyString(myMember?.attributes?.user_type);


  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      <Grid container justifyContent="center" className={classes.nonRespondingMembersContainer}>
        <Typography className={classes.nonRespondingMembers}>
          {'Non responding members'}
        </Typography>
      </Grid>

      <VerticalSpacer />

      <Grid container className={classes.membersGrid}>
        {
          nonRespondingMembers.map((member) => (
            <div key={member?.id} className={classes.memberCardContainer}>
              <DesignerProfileWithOptions
                hideOptionsMenu
                currentRole={currentRole}
                member={member}
                onDelete={() => { }}
                addAsAdmin={() => { }}
              />
            </div>
          ))
        }
      </Grid>
    </>
  );
}





function Members({
  onDelete,
}: {
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
}) {



  const classes = useStyles();

  const params = useParams();
  const clubId = Number(params.clubId);

  const membersQuery = useClubMembers({ clubId });
  const members = defaultToEmptyArray<ClubMember>(membersQuery?.data?.data);


  const { id: myProfileId } = useDecodedToken();


  const { mutate: addAsAdmin } = useAddClubMemberAsAdmin({ clubId });

  const nonRespondingMembersQuery = useClubsNonRespondingMembers({ clubId });
  const nonRespondingMembers = defaultToEmptyArray<ClubMember>(nonRespondingMembersQuery?.data?.data);

  const myMember = members?.concat(nonRespondingMembers).find(member => member.attributes.account_id == myProfileId);


  const currentRole = defaultToEmptyString(myMember?.attributes?.user_type);


  if (!members?.length)
    return (
      <Typography className={classes.noActiveMembers}>
        {'No Active Members Yet.'}
      </Typography>
    );

  return (
    <>
      {
        <Grid container className={classes.membersGrid}>
          {
            members.map((member) => (
              <div
                key={member?.id}
                className={classes.memberCardContainer}
              >
                <DesignerProfileWithOptions
                  currentRole={currentRole}
                  member={member}
                  onDelete={onDelete}
                  addAsAdmin={({ memberId }) => {
                    addAsAdmin({ memberId, clubId });
                  }}
                />
              </div>
            ))
          }
        </Grid>
      }
    </>
  );
}


export default DesignerClubManage;
