import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DezinerMarkDown } from '../../../../components/src/common';
import { VerticalSpacer, DezinerCard } from '../../../../components/src/design-system';
import { categoryStaticContent } from './CategoryStaticContent.web';
import { DEFAULT_PROFILE_IMAGE } from '../../../../components/src/defaults';


const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
  categoriesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },
});

////////////////
interface Category { title: string, description: string, image: string, }
const categories: Category[] = [
  {
    title: 'Marketing and advertising',
    description: 'Postcards, Flyers, Brochures, Social media ads, Powerpoint presentations, Website images',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'UI/UX',
    description: 'Web page designing, Application design, Game interfaces and Theme design',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Brand identity',
    description: 'Logos, color palettes, typography, style guides and image libraries',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Art and illustration',
    description: 'Patterns for textile, T-shirt design, Album art, Comic books and novels, Stock images, Infographics, Concept art and Technical illustration',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Packaging',
    description: 'Labels, Cans, Toys, Shopping bags, Gift wraps',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Motion graphics',
    description: 'Title and end credits, Advertisements, Trailers, Promotional videos, Tutorials, GIFs, Animation, Filmography and Video games',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Environmental ',
    description: 'Transport signage, Architectural plannings like the Museum exhibitions, Store interiors, Event and conference spaces, Office and stadium brandings, Wall murals',
    image: DEFAULT_PROFILE_IMAGE,
  },
  {
    title: 'Publication',
    description: 'Books, Magazines, Newsletters, Catalogs, Reports',
    image: DEFAULT_PROFILE_IMAGE,
  },
];
//////////
export default function CategoryStaticPage() {

  const classes = useStyles();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'CATEGORY'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {''}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 19, 2022'}
          </Typography>
        </Grid>
        <hr />

        <DezinerMarkDown>
          {categoryStaticContent}
        </DezinerMarkDown>

        <VerticalSpacer />

        <div className={classes.categoriesContainer}>
          {
            categories.map((category) => (
              <CategoryCard
                key={category.title}
                category={category}
              />
            ))
          }
        </div>

      </div>
    </div>
  );
}


////////////////

const useCategoryCardStyles = makeStyles({
  container: {
    padding: '10px',
    display: 'flex',
    margin: '10px',
  },
  textContainer: {
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '1.3rem',
  },
  description: {
    color: '#333333',
    fontSize: '1.0rem',
  },
  image: {
    maxWidth: '150px',
  },
});


function CategoryCard({ category }: { category: Category }) {

  const classes = useCategoryCardStyles();
  const { title, description, image } = category;

  return (
    <DezinerCard className={classes.container}>

      <img
        src={image}
        alt={title}
        className={classes.image}
      />

      <div className={classes.textContainer}>
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>
          {description}
        </Typography>
      </div>

    </DezinerCard>
  );
}
