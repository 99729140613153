import { appendJsonContentType, appendToken, apiCall, IApiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const labelsMessages = {

  fetchLabelsByOthers({ designerId }: { designerId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchDesignerLabelsByUserAPI.replace(':id', designerId),
    });
  },

  fetchLabels({ clubId, groupId }: { clubId?: number, groupId?: number }) {

    let params: IApiCall['params'] = {}

    if (clubId)
      params.club_id = clubId;

    if (groupId)
      params.group_id = groupId;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchLabelsAPI,
      params
    });

  },

  createLabel({ labelName, clubId, groupId, }: { labelName: string, clubId?: number, groupId?: number }) {

    let httpBody: any = {
      data: {
        attributes: {
          name: labelName,
        },
      },
    };

    if (clubId) {
      httpBody.data.attributes = {
        ...httpBody.data.attributes,
        "club_id": clubId,
        "label_type": "Club"
      }
    }

    if (groupId) {
      httpBody.data.attributes = {
        ...httpBody.data.attributes,
        "group_id": groupId,
        "label_type": "Group"
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: configJson.addNewLabelAPI,
    });

  },
}
