import React, { useState } from 'react';
import { FAQData } from './FAQData';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { DezinerAccordion, VerticalSpacer, DezinerButton } from '../../../../components/src/design-system';




export default function FAQPage() {

  const [activeCategory, setActiveCategory] = useState('Your Account');


  return (
    <div>

      <VerticalSpacer />
      <VerticalSpacer />
      <VerticalSpacer />

      <Grid container justifyContent="center">
        <Typography variant="h1" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {'FREQUENTLY ASKED QUESTIONS'}
        </Typography>
      </Grid>

      <VerticalSpacer />
      <VerticalSpacer />
      <VerticalSpacer />

      <Grid
        container
        justifyContent="center"
      >
        {
          Object.keys(FAQData)
            .map((key) => (
              <DezinerButton
                rounded
                onClick={() => setActiveCategory(key)}
                color={key == activeCategory ? 'primary' : 'default'}
                variant={"contained"}
                style={{ margin: '0 5px' }}
              >
                {key}
              </DezinerButton>
            ))
        }
      </Grid>


      <VerticalSpacer />
      <VerticalSpacer />

      <Grid container direction="column"
        style={{ padding: '40px', margin: '0 auto', maxWidth: '800px' }}
      >
        {
          FAQData[activeCategory]?.map((item) => (
            <DezinerAccordion
              key={item.title}
              {...item}
              renderMarkdown
            />
          ))
        }
      </Grid>

    </div>
  );
}
