import React, { useState, useRef, useEffect } from 'react'
// @ts-ignore
import styles from "./audio.module.css";
// @ts-ignore
import playIcon from '../../../assets/play.svg';
// @ts-ignore
import pauseIcon from '../../../assets/pause.svg';
// @ts-ignore
import trackIcon from '../../../assets/track.svg';

async function getDuration(url: string): Promise<number> {
  return new Promise((resolve, _reject) => {
    try {
      let _player = new Audio(url);

      _player.addEventListener("durationchange", function (e) {
        if (this.duration != Infinity) {
          let duration = this.duration
          _player.remove();
          resolve(duration);
        }
      }, false);

      _player.load();
      _player.currentTime = 24 * 60 * 60; //fake big time
      _player.volume = 0;
      _player.play();
    } catch (error) {
      console.error(error);
      _reject(error)
    }
  });
}





export function DezinerAudio({ src, onListeningFinish }: { src: string, onListeningFinish?: () => void }) {

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioTotalTime, setAudioTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);



  const audioPlayerRef = useRef<any>();   // reference our audio component
  const progressBarRef = useRef<any>();   // reference our progress bar
  const animationRef = useRef<any>();  // reference the animation

  useEffect(function onMount() {
    return function onUnmount() {
      cancelAnimationFrame(animationRef.current);
    }
  }, []);


  useEffect(function getTotalDuration() {

    //if (!audioTotalTime) 
    (async function loadDuration() {
      try {
        let totalTime = await getDuration(src);
        setAudioTotalTime(totalTime);
      } catch (error) {
        console.error('Error getting audio duration',error);
      }
    })();


  }, [src]);

  useEffect(function setTotalTimeOnLoad() {
    const seconds = audioTotalTime
    if (!Object.is(seconds, NaN)) {
      progressBarRef.current.max = seconds;
    }
  }, [audioTotalTime]);

  useEffect(function onEnd() {
    if (audioPlayerRef && audioPlayerRef.current) {
      audioPlayerRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
  }, [audioPlayerRef]);


  const calculateTime = (_seconds: any) => {
    if (Object.is(_seconds, NaN)) return '';
    const minutes = Math.floor(_seconds / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(_seconds % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }



  const togglePlayPause = async (event: any) => {
    event.preventDefault();

    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayerRef.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayerRef.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }



  const whilePlaying = () => {
    progressBarRef.current.value = audioPlayerRef.current.currentTime;
    syncCurrentTime(audioPlayerRef.current.currentTime);
    if (audioPlayerRef.current.currentTime < audioTotalTime) {
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      if (onListeningFinish)
        onListeningFinish();
    }
  }

  const rangeChangeHandler = () => {
    audioPlayerRef.current.currentTime = progressBarRef.current.value;

    syncCurrentTime(progressBarRef.current.value);
  }

  const syncCurrentTime = (_currentTime: number) => {
    const percentage = _currentTime / audioTotalTime * 100;
    // for google chrome
    progressBarRef.current.style['--seek-before-width'] = `${Math.floor(percentage)}%`;
    progressBarRef.current.style.setProperty('--seek-before-width', `${Math.floor(percentage)}%`)
    setCurrentTime(_currentTime);
  }

  return (
    <div className={styles.dezinerAudioPlayer}>

      <audio
        ref={audioPlayerRef}
        src={src}
        preload="metadata"
      >
      </audio>


      <button onClick={togglePlayPause} className={styles.dezinerPlayPause}>
        <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? "pause" : "play"} />
      </button>


      {/* progress bar */}
      <div>
        <input type="range"
          style={{
            background: `url(${trackIcon})`
          }}
          className={styles.dezinerProgressBar}
          defaultValue="0"
          ref={progressBarRef}
          onChange={rangeChangeHandler} />
      </div>

      <div className={styles.dezinerCurrentTime}>{calculateTime(currentTime)}</div>

      {/*
      <div className={styles.dezinerDuration}>{(audioTotalTime && !Object.is(audioTotalTime, NaN)) && calculateTime(audioTotalTime)}</div>
*/}
    </div>
  )
}

