import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSingleGroup, useDecodedToken, useProfile, useSingleClub, } from '../hooks';
import { RootState } from '../store/store';
import { useParams } from '../utils';
import { openChatConversationList, openNewChatWidget } from '../store/ui/ui.actions';
import { useCometChatLatestConversationsList, useMyUID } from '../../../blocks/LiveChat2/src/hooks';
import { Badge, IconButton, } from '@material-ui/core';
import ChatIcon from "@material-ui/icons/Chat";



export function TopNavChatIcon() {

  const { id: profileId } = useDecodedToken();
  const params = useParams();
  const currentDesignerOrUserId = Number(params.designerId || params.userId);


  const designerProfileQuery = useProfile({ id: currentDesignerOrUserId }, { enabled: Boolean(params.designerId) });
  const designerProfileQueryData = designerProfileQuery?.data?.data?.attributes;

  const dispatch = useDispatch();

  const groupId = Number(params.groupId);
  const clubId = Number(params.clubId);

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname.toLowerCase();

  const inGroupPage = pathname.includes('group');

  const inClubPage = pathname.includes('club');

  const groupQuery = useSingleGroup({ groupId }, { enabled: Boolean(params.groupId && inGroupPage) });

  const clubQuery = useSingleClub({ clubId }, { enabled: Boolean(params.groupId && inClubPage) });

  let gid = "";

  if (inGroupPage) {
    gid = groupQuery.data?.data?.attributes?.gid ?? '';
  } else {
    gid = clubQuery.data?.data?.attributes?.gid ?? '';
  }


  function onChatClick() {
    // don't open the chat if the user is in his own profile
    let inDesignerHomeOrUserHome = Boolean(currentDesignerOrUserId);
    let inMyHome = profileId == currentDesignerOrUserId;

    let inAnotherDesignerOrUserHome = !inMyHome && inDesignerHomeOrUserHome;
    let inGroupPage = Boolean(params.groupId);
    let inClubPage = Boolean(params.clubId);

    if (inAnotherDesignerOrUserHome) {
      let uid = designerProfileQueryData?.uid;
      let name = designerProfileQueryData?.first_name;

      if (uid && name)
        dispatch(openNewChatWidget({ uid, name, status: 'online', chatType: 'user' }));
    } else if (inGroupPage || inClubPage) {
      if (gid) {
        let name: any = 'group';
        name = inClubPage ? clubQuery?.data?.data?.attributes?.name : groupQuery?.data?.data?.attributes?.name;
        dispatch(openNewChatWidget({
          uid: gid,
          name,
          status: 'online',
          chatType: 'group',
        }));
      }
    } else {
      dispatch(openChatConversationList({}))
    }
  }

  const myUID = useMyUID();
  const chatUnreadCount = useSelector((state: RootState) => state.ui.chatUnreadCount);
  const liveUnreadMessagesCount = Object.values(chatUnreadCount).reduce((x, y) => x + y, 0);

  const chatListQuery = useCometChatLatestConversationsList({ uid: myUID });
  const unreadCountFromHistory = chatListQuery?.data?.reduce((total, item) => total + (item.unreadMessageCount || 0), 0) ?? 0;

  const totalUnreadMessagesCount = liveUnreadMessagesCount + unreadCountFromHistory;

  return (
    <IconButton onClick={onChatClick} color="inherit">
      <Badge badgeContent={totalUnreadMessagesCount} color="secondary">
        <ChatIcon />
      </Badge>
    </IconButton>
  );

}

