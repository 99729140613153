import { DesignComment, DesignReply, PostedByAttributes } from "../../../components/src/api-types";
import { useNavigate } from "../../../components/src/hooks";

export function useCommentNameClickHandler(
  comment: DesignComment | DesignReply,
) {

  const navigate = useNavigate();

  return function nameClickHandler() {

    let commenter: PostedByAttributes | undefined;

    if (comment.type == 'replies_comment')
      commenter = comment?.attributes?.repled_by?.data?.attributes;
    else
      commenter = comment?.attributes?.comment_by?.data?.attributes;

    const isCommentOwnerADesigner = commenter?.account_role == 'Designer';
    const isCommentOwnerAUser = commenter?.account_role == 'User';

    if (isCommentOwnerADesigner) {
      navigate(`DesignerHome/${commenter?.id}`);
    }
    else if (isCommentOwnerAUser) {
      navigate(`UserDesignList/${commenter?.id}`);
    }
  }
}

