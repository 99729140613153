import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { LabelListing, LabelListingProps, CreateLabelDialog, DezinerChangeCoverButton } from '../../../components/src/common';
import { infoIcon } from './assets';
import PeopleIcon from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";
import { MembersDialog } from './MembersDialog.web';
import { DeleteMemberDialog } from './DeleteMemberDialog.web';
import { useParams } from '../../../components/src/utils';
import { useDecodedToken, useIsUser, useNavigate } from '../../../components/src/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { GroupMember, Label } from '../../../components/src/api-types';
import { useAddGroupMemberAsAdmin, useCreateGroupLabel, useFollowGroup, useGroupChangeCover, useGroupsLabels, useGroupsMembers, useRemoveGroupMember, useRemoveGroupMemberAdminPermission, useSingleGroup, useUnfollowGroup } from '../../../components/src/hooks/groups';
import { defaultGroup, defaultToEmptyArray } from '../../../components/src/default-values';
import { HideContainer, DezinerButton, HorizontalSpacer } from '../../../components/src/design-system';
import { HeaderControls, DezinerHeader, } from '../../../components/src/layout';
import UserSubscriptionPopup from '../../../components/src/UserSubscriptionPopup.web';


const All_LABEL: Label = {
  id: "0",
  type: 'labels',
  attributes: {
    id: 0,
    name: "All",
    club_id: 0,
    group_id: 0,
    created_at: "",
    label_type: "Group",
    updated_at: ""
  }
}







const useStyles = makeStyles({
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
  floatButtonActive: {
    background: 'black !important',
    color: 'white !important',
  },
});


export function useIsGroupMember({ groupId }: { groupId: number }) {

  const groupsMembersQuery = useGroupsMembers({ groupId });
  const groupsMembers = defaultToEmptyArray<GroupMember>(groupsMembersQuery.data?.data);
  const isError = groupsMembersQuery.isError;
  const { id } = useDecodedToken();

  if (isError) return false;

  const isMember = Boolean(groupsMembers?.find((member) => member.attributes.account_id == id));

  return isMember;
}


export function useChangeLabelInGroupLayout() {

  const navigate = useNavigate();
  const {
    groupId,
    category,
    categoryQueryParam,
  } = useGroupLayoutParsedQueryParams();

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const inCataloguePage = Boolean(pathname?.toLowerCase()?.includes('groupcreatecollection'));

  return function changeLabel(id: number) {
    if (inCataloguePage)
      navigate(`GroupCreateCollection/${groupId}/?label_id=${id}`);
    else
      if (categoryQueryParam)
        navigate(`GroupHomePage/${groupId}/${category}?label_id=${id}`);
      else
        navigate(`GroupHomePage/${groupId}?label_id=${id}`);
  }
}


function useGroupLabelsList({ onAddLabel }: { onAddLabel: () => void }) {

  const {
    labelId,
    groupId,
  } = useGroupLayoutParsedQueryParams();

  const changeLabel = useChangeLabelInGroupLayout();
  const labelsQuery = useGroupsLabels({ groupId });
  let labels = labelsQuery?.data?.data ?? [];


  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const inGroupCatalogue = Boolean(pathname?.toLowerCase()?.includes('groupcreatecollection'));

  if (!inGroupCatalogue)
    labels = [All_LABEL, ...labels]

  const navigate = useNavigate();

  const currentUserRole: any = '';
  const currentLabel = labelId;

  let list: LabelListingProps['list'] = [];

  const firstLabelId = labelsQuery?.data?.data?.[0]?.id;
  const computedLabel = labelId || firstLabelId;
  const labelsParamsWithSaveDefault = computedLabel ? `?label_id=${computedLabel}` : '';

  const isMember = useIsGroupMember({ groupId });

  try {

    let labelsList: LabelListingProps['list'] = labels?.map((item) => ({
      id: Number(item.id),
      label: item.attributes.name,
      hide: false,
      active: item.attributes.id == currentLabel,
      onClick: changeLabel
    }));


    let createGroupItem: LabelListingProps['list'][number] = {
      id: -1,
      label: '+',
      hide: currentUserRole === 'User',
      pin: 'start',
      alt: "Create Collection",
      onClick: () => {
        navigate(`GroupCreateCollection/${groupId}${labelsParamsWithSaveDefault}`);
      }
    }

    let handleOpenItem: LabelListingProps['list'][number] = {
      id: list.length + 1,
      label: '+',
      pin: 'end',
      hide: currentUserRole === 'User',
      alt: "Add Label",
      onClick: onAddLabel,
    }

    if (isMember)
      list = [createGroupItem, ...labelsList, handleOpenItem];
    else
      list = labelsList;

  } catch (error) {
    console.error(error);
  }

  return list;
}


export function useGroupLayoutParsedQueryParams() {

  const params = useParams();
  const groupId = parseInt(params.groupId);
  const categoryQueryParam = params.category;
  const category = categoryQueryParam || 'collections';

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  const labelsParamString = labelId ? `?label_id=${labelId}` : '';

  return {
    labelId,
    category,
    groupId,
    categoryQueryParam,
    labelsParamString,
  }
}






export default function GroupLayout(props: { children: any }) {

  const {
    groupId,
    labelsParamString,
    categoryQueryParam,
  } = useGroupLayoutParsedQueryParams();

  const groupQuery = useSingleGroup({ groupId });
  const groupData = defaultGroup(groupQuery?.data?.data?.attributes);

  const navigate = useNavigate();
  const classes = useStyles();


  const { mutate: createLabel, error: createLabelError } = useCreateGroupLabel({ groupId });
  const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false);

  const activeCategory: string = categoryQueryParam;
  const list = useGroupLabelsList({ onAddLabel: () => setAddLabelDialogOpen(true) });

  const isUser = useIsUser();
  const { id: myProfileId } = useDecodedToken();


  const { mutate: changeCover, isLoading: changeCoverLoading } = useGroupChangeCover({ groupId });

  const [membersDialogOpen, setMemebersDialogOpen] = useState(false);
  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [deletedMember, setDeleteMember] = useState({ deleteType: '', memberId: 0, memberName: '' });

  const isMember = useIsGroupMember({ groupId });
  const membersQuery = useGroupsMembers({ groupId }, { enabled: isMember });
  const members = membersQuery?.data?.data ?? [];

  const { mutate: addAsAdmin } = useAddGroupMemberAsAdmin({ groupId });
  const { mutate: removeAdminPermission } = useRemoveGroupMemberAdminPermission({ groupId });
  const { mutate: removeMember } = useRemoveGroupMember({ groupId });


  const { mutate: followGroup, isLoading: followLoading } = useFollowGroup({ groupId });
  const { mutate: unfollowGroup, isLoading: unfollowLoading } = useUnfollowGroup({ groupId });

  const followGroupLoading = followLoading || unfollowLoading;

  const myMember = members.find(member => member.attributes.account_id == myProfileId);

  const isFollowed = groupData?.followed;
  const isSubscribed = groupData?.subscribed;

  const [showSubscription, setShowSubscription] = useState(false);

  const IamAdmin = myMember?.attributes.role == 'Admin';

  return (
    <div>

      <CreateLabelDialog
        open={addLabelDialogOpen}
        onClose={() => {
          setAddLabelDialogOpen(false);
        }}
        onCreate={(labelName) => createLabel({ labelName, groupId }, {
          onSuccess: () => {
            setAddLabelDialogOpen(false);
          }
        })}
        error={createLabelError?.[0]?.message}
      />


      <DeleteMemberDialog
        deleteType={deletedMember.deleteType as any || "remove_member"}
        open={deleteMemberOpen}
        memberName={deletedMember.memberName}
        onAccept={() => {
          const { deleteType, memberId } = deletedMember;

          if (deleteType == "remove_as_admin")
            removeAdminPermission({ memberId, groupId });
          else if (deleteType == "remove_member")
            removeMember({ memberId, groupId });

          setDeleteMemberOpen(false);
          setDeleteMember({ deleteType: '', memberId: 0, memberName: '' });
        }}
        onClose={() => setDeleteMemberOpen(false)}
      />



      <MembersDialog
        open={membersDialogOpen}
        onClose={() => setMemebersDialogOpen(false)}
        profileImage={groupData?.image_url}
        title={groupData?.name}
        onAddMember={() => {
          navigate(`AddGroupMember/${groupData?.id}`);
        }}
        currentRole={members
          ?.find((member) => member?.attributes?.account_id == myProfileId)
          ?.attributes?.role
          ?? ''
        }
        members={members}
        onDelete={({ deleteType, memberId, memberName }) => {
          setDeleteMemberOpen(true);
          setDeleteMember({ deleteType, memberId, memberName });
        }}
        addAsAdmin={({ memberId }) => {
          addAsAdmin({ memberId, groupId });
        }}
      />


      <DezinerHeader
      vertical
        onClick={(category) => {
          navigate(`GroupHomePage/${groupId}/${category}${labelsParamString}`);
        }}
        onFollowersClick={() => navigate(`GroupFollowers/${groupData?.id}`)}
        title={groupData?.name}
        profileImageURL={groupData?.image_url}
        coverImageURL={groupData?.cover_url}
        profileStatistics={[
          { name: 'collections', value: groupData?.totel_collections, isActive: activeCategory == 'collections' },
          { name: 'designs', value: groupData?.totel_designs, isActive: activeCategory == 'designs' },
          { name: 'liked', value: groupData?.totel_like_designs, isActive: activeCategory == 'liked' },
          { name: 'promoted', value: groupData?.totel_promoted_designs, isActive: activeCategory == 'promoted' },
          { name: 'inspired', value: groupData?.totel_inspired_designs, isActive: activeCategory == 'inspired' },
          { name: 'rated', value: groupData?.totel_rated_designs, isActive: activeCategory == 'rated' },
          { name: 'free', value: groupData?.totel_free_designs, isActive: activeCategory == 'free' },
          { name: 'paid', value: groupData?.totel_paid_designs, isActive: activeCategory == 'paid' },
          { name: 'following', value: groupData?.following_count, },
          { name: 'followers', value: groupData?.follower_count, },
        ]}
        loading={groupQuery.isLoading}
        variant="group"
      />


      <HeaderControls>
        <HeaderControls.SideSection flexStart>

          {
            !isUser ?
              <DezinerChangeCoverButton
                onChange={(image) => { changeCover({ image, groupId }) }}
                loading={changeCoverLoading}
              />
              :
              null
          }

          <HorizontalSpacer />

          <DezinerButton
            variant="contained"
            onClick={() => {
              navigate(`GroupAboutPage/${groupId}`)
            }}
            startIcon={<img src={infoIcon} />}
            className={classes.headerFloatingBtn}
          >
            {'About'}
          </DezinerButton>


        </HeaderControls.SideSection>


        <HeaderControls.MiddleSection>
          <LabelListing list={list} />
        </HeaderControls.MiddleSection>


        <HeaderControls.SideSection flexEnd>

<HideContainer hideWhen={IamAdmin}>
          <DezinerButton
            style={{ justifySelf: 'flex-end' }}
            variant="contained"
            className={clsx(classes.headerFloatingBtn, { [classes.floatButtonActive]: isFollowed })}
            onClick={() => {
              if (isFollowed)
                unfollowGroup({ groupId });
              else
                followGroup({ groupId });
            }}
            startIcon={<AddIcon fontSize="large" />}
            loading={followGroupLoading}
          >
            {isFollowed ? 'Following' : 'Follow'}
          </DezinerButton>
          </HideContainer>

          <HorizontalSpacer />

          <DezinerButton
            style={{ justifySelf: 'flex-end' }}
            variant="contained"
            className={clsx(classes.headerFloatingBtn, { [classes.floatButtonActive]: isSubscribed })}
            onClick={() => {
              setShowSubscription(true);
            }}
            startIcon={<AddIcon fontSize="large" />}
          >
            {isSubscribed ? 'Subscribed' : 'Subscribe'}
          </DezinerButton>

          <HorizontalSpacer />

          <HideContainer hideWhen={!isMember}>
            <DezinerButton
              style={{ justifySelf: 'flex-end' }}
              variant="contained"
              className={classes.headerFloatingBtn}
              onClick={() => {
                setMemebersDialogOpen(true);
              }}
              startIcon={<PeopleIcon fontSize="large" />}
            >
              {'Members'}
            </DezinerButton>
          </HideContainer>

        </HeaderControls.SideSection>

      </HeaderControls>

      {/* <UserSubscriptionPopup
        open={showSubscription}
        onClose={() => setShowSubscription(false)}
        designerId={groupId}
        subscriptionType={"BxBlockGroups::Group"}
        designerName={groupData?.name}
        designerProfile={groupData?.image_url}
        fallbackColor={''}
      
      /> */}

      {props.children}

    </div>
  );
}

