import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TwitterIcon from "@material-ui/icons/Twitter";
import { toast } from "react-toastify";
import { facebookIcon, instagramIcon, shareArrowIcon, linkIcon, plusIcon, markerIcon, personIcon, checkIcon } from '../../assets';
import DesignerAboutPageController, { Props, } from "./DesignerAboutPageController.web";
import { FormikTextInput, DezinerTextField, DezinerChip, DezinerAutocomplete, HorizontalSpacer, VerticalSpacer, DezinerButton } from '../../../../../components/src/design-system';
import { Formik, Form } from 'formik';
import { socialMediaLinksValidationSchema } from "../../../../../components/src/validation/social-media-validation";
import { EditableAreaOnHover, EditableAreaOnHoverActive, EditableAreaOnHoverInActive, ShareDesignDialog, GroupOrClubList } from "../../../../../components/src/common";
import { useIsMyProfile, useProfile, useSkills, useUpdateBioAndSkills, useUpdateSocialMedia } from "../../../../../components/src/hooks";
import { APIResponse, SkillItem } from "../../../../../components/src/api-types";
import { useParams, formatProfileAboutDate } from "../../../../../components/src/utils";
import { defaultToEmptyArray, defaultToEmptyString, defaultToZero } from "../../../../../components/src/default-values";

export const configJSON = require("../../config");





class DesignerAboutPage extends DesignerAboutPageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <AboutUsPageContent />
    );
  }
}




const styles: any = {
  noData: {
    color: '#939393',
    fontSize: '1rem'
  },
  aboutText: {
    fontWeight: 'bold',
    fontSize: '2.2rem',
    padding: '0 10px'
  },
  btnIcon: {
    width: '20px',
    maxWidth: '20px',
  },
  textIcon: {
    width: '20px',
    maxWidth: '20px',
    margin: '0 10px',
    '&~*': {
      color: '#444',
      whiteSpace: 'nowrap',
    }
  },
  grayContainer: {
    background: "#f5f5f5",
    padding: "10px 5px",
    borderRadius: "8px",
    '&>div': {
      margin: '5px'
    }
  },
  grayContainerOnHover: {
    background: "#f5f5f5",
    padding: "10px 20px",
    borderRadius: "8px",
    '&:hover': {
      background: 'transparent',
    },
    '&>div': {
      margin: '5px'
    }
  },
  pageContainer: {
    padding: "40px 20px",
    maxWidth: "1110px",
    margin: "0 auto 40px",
  },
};


const useStyles = makeStyles(styles);


const CustomizedDezinerTextInput = withStyles({
  root: {
    '& .Mui-disabled': {
      color: 'black'
    },
    '& .MuiOutlinedInput-root': {
      padding: '10px 0',
      '& fieldset': {
        border: '0px solid gray'
      },
      '&:hover fieldset': {
        border: '0px solid gray'
      },
      '&.Mui-focused fieldset': {
        border: '0px solid gray'
      },
    },
  }
})(DezinerTextField);



function AboutUsPageContent() {

  const classes = useStyles();


  const [shareDialogStatus, setShareDialogStatus] = useState(false);
  const [skills, setSkills] = useState<SkillItem[]>([]);
  const [bio, setBio] = useState('');

  const params = useParams();
  const { designerId } = params;

  const profileQuery = useProfile({ id: Number(designerId) });
  const profileAttributes = profileQuery?.data?.data?.attributes;

  const allowEdit = useIsMyProfile({ id: designerId });

  const skillsQuery = useSkills();
  const { mutate: updateSocialMedia, isLoading: updateSocialMediaLoading } = useUpdateSocialMedia({ designerId });
  const { mutate: updateBioAndSkills, isLoading: updateBioLoading } = useUpdateBioAndSkills({ designerId });


  useEffect(() => {
    setSkills(defaultToEmptyArray<APIResponse<SkillItem>>(profileAttributes?.skills?.data)?.map(skill => skill.attributes));
    setBio(defaultToEmptyString(profileAttributes?.bio));
  }, [profileAttributes]);


  return (
    <>
      <Grid
        container
        className={classes.pageContainer}
        justifyContent="space-between"
        alignItems="flex-start">

        <Grid item xs={12} sm={7}>

          <Typography variant="h3" className={classes.aboutText}>
            {'About'}
          </Typography>

          <VerticalSpacer />

          <EditableAreaOnHover
            title="Bio"
            allowEdit={allowEdit}
            onSave={() => {
              updateBioAndSkills({
                bio,
                skills: skills.map(skill => ({ skill_id: skill.id })),
              });
              return true;
            }}
            saveButtonTitle="Save Bio"
            editButtonTitle="Edit Bio"
            loading={updateBioLoading}
          >

            <EditableAreaOnHoverActive>
              <CustomizedDezinerTextInput
                placeholder={"Write your bio here"}
                autoHeight
                value={bio}
                onChange={(e: any) => {
                  setBio(e.target.value);
                }}
                multiline
                minRows={bio?.split(' ')?.length < 20 ? 2 : 6}
                fullWidth
                variant="outlined"
              />
            </EditableAreaOnHoverActive>

            <EditableAreaOnHoverInActive>
              {
                bio ?
                  <Typography variant="caption" className={classes.noData}>
                    {bio}
                  </Typography>
                  :
                  <Typography variant="caption" className={classes.noData}>
                    {
                      allowEdit ?
                        'No Bio. You can write your bio by clicking on (Edit Bio)'
                        :
                        'No Bio.'
                    }
                  </Typography>
              }

            </EditableAreaOnHoverInActive>


          </EditableAreaOnHover>




          <VerticalSpacer />


          <EditableAreaOnHover
            title="Skills"
            onSave={() => {
              updateBioAndSkills({
                bio: defaultToEmptyString(profileAttributes?.bio),
                skills: skills.map(skill => ({ skill_id: skill.id })),
              });
              return true;
            }}
            allowEdit={allowEdit}
            editButtonTitle="Edit Skills"
            saveButtonTitle="Save Skills"
            loading={updateBioLoading}
          >
            <EditableAreaOnHoverActive>
              <div className={classes.listSelection}>
                <DezinerAutocomplete
                  fullWidth
                  id="skills"
                  options={defaultToEmptyArray<APIResponse<SkillItem>>(skillsQuery?.data?.data)?.map(skill => skill.attributes)}
                  getOptionLabel={(option: any) => option.name}
                  defaultValue={defaultToEmptyArray<APIResponse<SkillItem>>(profileAttributes?.skills?.data)?.map(skill => skill.attributes)}
                  filterSelectedOptions
                  multiple
                  onChange={(event, value: any) => {
                    if (value)
                      setSkills(value);
                  }}
                  renderInput={(params: any) => <TextField  {...params} />}
                />
              </div>
            </EditableAreaOnHoverActive>
            <EditableAreaOnHoverInActive>
              <div>
                {
                  profileAttributes?.skills?.data?.length ?
                    profileAttributes?.skills?.data
                      ?.map(skill => skill.attributes)
                      ?.map((item: any) => (
                        <DezinerChip key={item?.name} label={item?.name} />
                      ))
                    :
                    <Typography variant="caption" className={classes.noData}>
                      {
                        allowEdit ?
                          'No Skill, you can add new skills by clicking on (Edit Skills)'
                          :
                          'No Skill'
                      }
                    </Typography>
                }
              </div>
            </EditableAreaOnHoverInActive>
          </EditableAreaOnHover>




          {
            profileAttributes?.clubs?.data?.length ?
              <>
                <VerticalSpacer />

                <GroupOrClubList
                  groups={defaultToEmptyArray(profileAttributes?.clubs?.data)}
                  title="On Club"
                />

              </>
              :
              null
          }

          {
            profileAttributes?.groups?.data?.length ?
              <>
                <VerticalSpacer />

                <GroupOrClubList
                  groups={defaultToEmptyArray(profileAttributes?.groups?.data)}
                  title="On Group"
                />
              </>
              :
              null
          }


        </Grid>
        <Grid item xs={12} sm={1} />
        <Grid item xs={12} sm={4}>

          <Grid container>
            <DezinerButton
              startIcon={<img className={classes.btnIcon} src={shareArrowIcon} alt="share" />}
              color="primary"
              variant="outlined"
              onClick={() => setShareDialogStatus(true)}
            >
              {'Share'}
            </DezinerButton>
            <HorizontalSpacer />
            <DezinerButton
              startIcon={<img className={classes.btnIcon} src={linkIcon} alt="link" />}
              color="primary"
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success("Link Copied");
              }}
            >
              {'Copy'}
            </DezinerButton>
          </Grid>

          <VerticalSpacer />
          <VerticalSpacer />

          <Grid container className={classes.grayContainer}>
            <Grid item xs container alignItems="center" wrap="nowrap">
              <img src={plusIcon} alt="followers" className={classes.textIcon} />
              <Typography>
                {`${defaultToZero(profileAttributes?.follower_count)} Followers`}
              </Typography>
            </Grid>
            <Grid item xs container alignItems="center" wrap="nowrap">
              <img src={checkIcon} alt="following" className={classes.textIcon} />
              <Typography>
                {`${defaultToZero(profileAttributes?.following_count)} Following`}
              </Typography>
            </Grid>
          </Grid>

          <VerticalSpacer />
          <VerticalSpacer />

          <Grid container direction="column" className={classes.grayContainer}>
            <Grid item xs={12} container alignItems="center">
              <img src={markerIcon} alt="location" className={classes.textIcon} />
              <Typography>
                {defaultToEmptyString(profileAttributes?.location)}
              </Typography>
            </Grid>
            <Grid item xs={12} container alignItems="center">
              <img src={personIcon} alt="person" className={classes.textIcon} />
              <Typography>
                {`Member Since ${formatProfileAboutDate(defaultToEmptyString(profileAttributes?.created_at))}`}
              </Typography>
            </Grid>
          </Grid>

          <VerticalSpacer />
          <VerticalSpacer />

          <Formik
            initialValues={{
              facebook: defaultToEmptyString(profileAttributes?.facebook_url),
              twitter: defaultToEmptyString(profileAttributes?.twitter_url),
              instagram: defaultToEmptyString(profileAttributes?.instagram_url),
            }}
            validationSchema={socialMediaLinksValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
              updateSocialMedia(values);
            }}
          >
            {
              formik => <Form translate="yes">

                <EditableAreaOnHover
                  title="Social"
                  onSave={() => {
                    if (formik.isValid) {
                      formik.submitForm();
                      return true;
                    }
                    else
                      return false;
                  }}
                  allowEdit={allowEdit}
                  className={allowEdit ? classes.grayContainerOnHover : ''}
                  editButtonTitle="Edit Social Profile"
                  loading={updateSocialMediaLoading}
                >
                  <EditableAreaOnHoverActive>
                    <Grid container>

                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center" style={{ paddingTop: '6px' }}>
                          <TwitterIcon className={classes.textIcon} />
                        </Grid>
                        <Grid item xs>
                          <FormikTextInput
                            name="twitter"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center" style={{ paddingTop: '6px' }}>
                          <img src={facebookIcon} alt="facebook" className={classes.textIcon} style={{ width: '15px' }} />
                        </Grid>
                        <Grid item xs>
                          <FormikTextInput
                            name="facebook"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center" style={{ paddingTop: '6px' }}>
                          <img src={instagramIcon} alt="instagram" className={classes.textIcon} />
                        </Grid>
                        <Grid item xs>
                          <FormikTextInput
                            name="instagram"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </EditableAreaOnHoverActive>
                  <EditableAreaOnHoverInActive>


                    <a href={profileAttributes?.twitter_url} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center">
                          <TwitterIcon className={classes.textIcon} />
                        </Grid>
                        <Grid item xs>
                          <Typography> {'Twitter'} </Typography>
                        </Grid>
                      </Grid>
                    </a>

                    <VerticalSpacer />

                    <a href={profileAttributes?.facebook_url} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center">
                          <img src={facebookIcon} alt="facebook" className={classes.textIcon} style={{ width: '15px' }} />
                        </Grid>
                        <Grid item xs>
                          <Typography> {'Facebook'} </Typography>
                        </Grid>
                      </Grid>
                    </a>

                    <VerticalSpacer />

                    <a href={profileAttributes?.instagram_url} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Grid item xs={12} container alignItems="flex-start">
                        <Grid item xs={2} container justifyContent="center">
                          <img src={instagramIcon} alt="instagram" className={classes.textIcon} />
                        </Grid>
                        <Grid item xs>
                          <Typography> {'Instagram'} </Typography>
                        </Grid>
                      </Grid>
                    </a>

                  </EditableAreaOnHoverInActive>
                </EditableAreaOnHover>

              </Form>
            }
          </Formik>


        </Grid>

      </Grid>

      <ShareDesignDialog
        rounded
        open={shareDialogStatus}
        mediaProps={{
          id: designerId,
          src: defaultToEmptyString(profileAttributes?.profile_thumbnail_url),
          mediaType: 'image'
        }}
        shareLink={window.location.href}
        onClose={() => { setShareDialogStatus(false) }}
        onCopy={() => {
          navigator.clipboard.writeText(window.location.href);
          toast.success("Link Copied");
        }}
      />


    </>
  );
}





export default withStyles(styles)(DesignerAboutPage);
