import React from 'react';
import { CollectionHome, } from '../api-types';
import { Popover as MaterialPopover, Typography, Menu, MenuItem, ListItemText as MaterialListItemText, ListItemIcon as MaterialListItemIcon, Divider } from '@material-ui/core';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { pin, promote } from "../../../blocks/landingpage/src/assets";
import {DezinerMedia} from '../design-system';

const Popover = withStyles({
  paper: {
    display: 'none !important'
  }
})(MaterialPopover);


const ListItemIcon = withStyles({
  root: {
    '& img': {
      opacity: 0.5,
    }
  }
})(MaterialListItemIcon);


const ListItemText = withStyles({
  root: {
    '&>span': {
      fontSize: '0.8rem',
      color: '#8a8a8a',
    }
  }
})(MaterialListItemText);

const StyledMenuItem = withStyles({
  root: {
    padding: '4px 35px 4px 15px',
    '&:focus': {
      backgroundColor: '#f1f1f1',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      },
    },
  },
})(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));



const useCoverStyles = makeStyles({
  collectionCover: {
    position: 'relative',
    background: "white",
    height: "150px",
    width: "100%",
    borderRadius: "15px",
    cursor: "pointer",
    border: "2px solid black",
    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
  collectionCoverEmpty: {
    background: "#F3F4F6",
    borderRadius: "15px",
    height: "150px",
    width: "100%",
    position: "relative",
    top: "-10px",
    right: "-10px",
    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
  collectionCoverMedia: {
    height: "150px",
    width: "100%",
    borderRadius: "15px",

    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },

  },
  collectionCoverMediaContainer: {
    position: "relative",
    top: "-10px",
    right: "-10px",
    borderRadius: "15px",

    height: "150px",
    width: "100%",

    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
  collectionTitleContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "20px",
    ">*": {
      fontWeight: "600 !important",
      textTransform: "uppercase",
    },
    '& p': {
      fontSize: '14px',
    },
    '& sub': {
      position: "relative", right: "-5px", top: "-5px"
    },
    '&>span': {
      cursor: 'pointer',
    },
  },
  collectionTitleIconContainer: {
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '&:hover': {
      background: 'lightgray',
    },
  },
  iconImg: {
    maxWidth: '20px',
  },
});

export function CollectionCover({
  collection,
  height,
  mediaURL,
  mediaType,
  mediaWaterMark,
  alt,
  watermarkWidth,
  watermarkHeight,
  onPin,
  onPromote,
  hideOptions,
}: {
  collection: CollectionHome,
  height: number,
  mediaURL: string,
  mediaWaterMark: string,
  mediaType: string,
  alt: string,
  watermarkWidth: number,
  watermarkHeight: number,
  onPin: (params: { collectionId: number }) => void,
  onPromote: (params: { collectionId: number }) => void,
  hideOptions?: boolean,
}) {

  const classes = useCoverStyles();

  const [popoverElement, setPopoverElement] = React.useState<HTMLButtonElement | null>(null);

  function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
    setPopoverElement(event.currentTarget);
  };

  function handlePopoverClose() {
    setPopoverElement(null);
  };

  const isPopoverOpen = Boolean(popoverElement);
  const popoverId = isPopoverOpen ? 'collection-popover' : undefined;





  return (
    <div>

      <div className={classes.collectionTitleContainer}>
        <Typography variant="body1">
          <b style={{ fontSize: '1rem' }}>
            {collection?.attributes?.name?.toUpperCase()}
          </b>
          <sub>
            {`(${collection?.attributes?.design_count > 0 ? 1 : 0}/${collection?.attributes?.design_count})`}
          </sub>
        </Typography>

        {
          hideOptions ? null :
            <span
              onClick={handlePopoverClick as any}
              className={classes.collectionTitleIconContainer}
            >
              <MoreHorizIcon
                fontSize="small"
              />
            </span>
        }
      </div>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledMenu
          id={popoverId}
          anchorEl={popoverElement}
          keepMounted
          open={isPopoverOpen}
          onClose={handlePopoverClose}
        >
          <StyledMenuItem
            onClick={() => {
              onPromote({ collectionId: collection.attributes.id })
            }}>
            <ListItemIcon style={{ minWidth: '32px' }}>
              <img
                className={classes.iconImg}
                alt="Promote collection"
                src={promote} />
            </ListItemIcon>
            <ListItemText primary="Promote Collection" />
          </StyledMenuItem>
          <Divider style={{ margin: '4px 0' }} />
          <StyledMenuItem onClick={() => {
            onPin({ collectionId: collection?.attributes.id })
          }}>
            <ListItemIcon
              style={{ minWidth: '32px' }}
            >
              <img
                className={classes.iconImg}
                alt="unpin"
                src={pin} />
            </ListItemIcon>
            <ListItemText primary={collection?.attributes?.pined ? 'Unpin' : 'Pin'} />
          </StyledMenuItem>
        </StyledMenu>


      </Popover>


      <div
        className={classes.collectionCover}
        style={{ height: `${height}px`, }}
      >

        {
          mediaURL ?
            <DezinerMedia
              id={collection.id}
              mediaType={mediaType?.includes('video/') ? 'video' : 'image'}
              className={classes.collectionCoverMedia}
              containerClassName={classes.collectionCoverMediaContainer}
              style={{ height: `${height}px` }}
              containerStyles={{ height: `${height}px` }}
              src={mediaURL}
              alt={alt}
              watermarkURL={mediaWaterMark}
              watermarkWidth={watermarkWidth}
              watermarkHeight={watermarkHeight}
            />
            :
            <div
              style={{ height: `${height}px` }}
              className={classes.collectionCoverEmpty}
            />
        }
      </div>
    </div>
  );
}



