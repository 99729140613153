// App.js - WEB
import React, { Component, ReactNode } from 'react';
import { View } from 'react-native';
// import firebase from 'firebase';
import { connect } from 'react-firebase';
import './assets/css/global.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as ReduxProvider } from 'react-redux';
import ReduxDezinerMiddleware from '../../components/src/store/redux-deziner-middleware.web';

import { ConnectedRouter } from 'connected-react-router'
import { dezinerHistory, dezinerReduxStore } from '../../components/src/store/store';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';

// very important to keep it imported because it's responsible for importing RestApiClient Block
import HomeScreen from '../../components/src/HomeScreen';

import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb.web';
import CatalogueWeb from '../../blocks/catalogue/src/Catalogue.web';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';

import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum.web';


import DesignerFollowerPageByUser from '../../blocks/Followers/src/DesignerFollowerPageByUser.web';
import ForumSingle from '../../blocks/CommunityForum/src/ForumSingle.web';
import NotificationsWeb from '../../blocks/Notifications/src/NotificationsWeb.web';
import ArchiveDesigns from '../../blocks/ContentManagement/src/ArchiveDesigns.web';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import EmailIsActivatedPage from '../../blocks/landingpage/src/EmailIsActivatedPage.web';
import TermsOfServicePage from '../../blocks/email-account-registration/src/TermsOfService.web';
import PrivacyPolicyPage from '../../blocks/email-account-registration/src/PrivacyPolicy.web';
import { DesignerTopNavLayout, FooterLayout, } from '../../components/src/layout';
import { DesignerProfileLayout } from '../../blocks/landingpage/src/designer/layouts/DesignerProfileLayout.web';
import { ScrollToTop } from '../../components/src/utils';
import VerifyEmailPage from '../../blocks/email-account-login/src/VerifyEmailPage.web';

import DesignerHomeLayout from '../../blocks/landingpage/src/designer/designer-home/DesignerHomeLayout.web';

// design
import DesignerDesignEdit from '../../blocks/landingpage/src/design/edit-design/EditDesign.web';
import DesignerDesignUpload from '../../blocks/landingpage/src/design/upload-design/UploadDesign.web';
import DesignViewPage from '../../blocks/landingpage/src/design/design-view/DesignView.web';

// clubs
import ClubLayout from '../../blocks/Groups/src/ClubLayout.web';
import DesignClubPageView from '../../blocks/Groups/src/DesignClubPageView.web';
import ClubAboutPage from '../../blocks/Groups/src/ClubAboutPage.web';
import DesignerClubManage from '../../blocks/Groups/src/DesignerClubManage.web';
import CreateClub from '../../blocks/Groups/src/CreateClub.web';
import AddClubMember from '../../blocks/Groups/src/AddClubMember.web';
import ClubHomePage from '../../blocks/Groups/src/ClubHomePage.web';
import ClubFollowers from '../../blocks/Groups/src/ClubFollowers.web';
import ClubCreateCollection from '../../blocks/Groups/src/ClubCreateCollection.web';

// Groups
import GroupLayout from '../../blocks/Groups/src/GroupLayout.web';
import CreateGroup from '../../blocks/Groups/src/CreateGroup.web';
import DesignerGroupPageView from '../../blocks/Groups/src/DesignerGroupPageView.web';
import GroupHomePage from '../../blocks/Groups/src/GroupHomePage.web';
import GroupFollowers from '../../blocks/Groups/src/GroupFollowers.web';
import DesignerGroupManage from '../../blocks/Groups/src/DesignerGroupManage.web';
import AddGroupMember from '../../blocks/Groups/src/AddGroupMember.web';
import GroupAboutPage from '../../blocks/Groups/src/GroupAboutPage.web.tsx';
import GroupCreateCollection from '../../blocks/Groups/src/GroupCreateCollection.web';

// Designer Routes
import DesignerHome from '../../blocks/landingpage/src/designer/designer-home/DesignerHome.web';
import DesignerEditSocialProfiles from '../../blocks/landingpage/src/designer/social-profile/DesignerEditSocialProfiles.web';
import DesignerEditPasswords from '../../blocks/landingpage/src/designer/edit-password/DesignerEditPassword.web';
import DesignerAccountSettingsPage from '../../blocks/landingpage/src/designer/account-settings/DesignerAccountSettings.web';
import DesignerEditProfile from '../../blocks/landingpage/src/designer/edit-profile/DesignerEditProfile.web';
import DesignBuy from '../../blocks/landingpage/src/designer/buy-design/BuyDesign.web';
import DesignerAboutPage from '../../blocks/landingpage/src/designer/about/DesignerAboutPage.web';

// User Routes
import UserEditAbout from '../../blocks/landingpage/src/user/about/UserAbout.web';
import UserEditProfile from '../../blocks/landingpage/src/user/edit-profile/UserEditProfile.web';
import UserEditAccount from '../../blocks/landingpage/src/user/edit-account/UserEditAccount.web';
import UserDesignList from '../../blocks/landingpage/src/user/design-list/UserDesignList.web';
import UserEditPassword from '../../blocks/landingpage/src/user/edit-password/UserEditPassword.web';
import UserHome from '../../blocks/landingpage/src/user/user-home/UserHome.web';
import UserProfileLayout from '../../blocks/landingpage/src/user/layout/UserProfileLayout.web';

// Landing Page
import LandingPageWeb from '../../blocks/landingpage/src/landing-page/LandingPage.web';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  RouterContextProvider, LoginGuard, IsMyProfileGuard,
  RequireValidTokenContext, DesignOwnerOnlyCanAccessGuard, DezinerPageWrapper,
} from '../../components/src/utils';
import { ForumTopNavLayout } from '../../blocks/CommunityForum/src/layout'
import CollectionPage from '../../blocks/landingpage/src/designer/collection/CollectionPage.web';
import ForumEditPage from '../../blocks/CommunityForum/src/ForumEdit.web';
import SearchResultsWeb from '../../blocks/AdvancedSearch/src/SearchResultsPage.web';
import DezinerChatWidget from '../../blocks/LiveChat2/src/CometChatWidget';
// import { onMessageListener, setToken } from './firebase';


// static pages
import BuyDesignStatic from '../../blocks/landingpage/src/static-pages/BuyDesignStatic.web.tsx';
import SearchDesignerStatic from '../../blocks/landingpage/src/static-pages/SearchDesignerStatic.web.tsx';
import TestimonialsStaticPage from '../../blocks/landingpage/src/static-pages/TestimonialsStatic.web';
import PromoteDesignStaticPage from '../../blocks/landingpage/src/static-pages/PromoteDesignStatic.web';
import ExploreStaticPage from '../../blocks/landingpage/src/static-pages/ExploreStatistic.web';
import CategoryStaticPage from '../../blocks/landingpage/src/static-pages/CategoryStatic.web';
import HowItWorksStaticPage from '../../blocks/landingpage/src/static-pages/HowItWorksStatic.web';
import FAQPage from '../../blocks/landingpage/src/static-pages/FAQPage.web';
import CodeOfConductPage from '../../blocks/landingpage/src/static-pages/CodeOfConductPage.web';
import CookiePolicyPage from '../../blocks/landingpage/src/static-pages/CookiePolicyPage.web';
import CopyrightPolicyPage from '../../blocks/landingpage/src/static-pages/CopyrightPolicyPage.web';
import { GlobalDialogs } from '../../components/src/common';


const dezinerQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000 * 60,   // 10 minutes 
    }
  }
});


/*
function ComponentLayoutWrapper({ layout, component, }) {
  return (props) => (
    React.createElement(layout, props, React.createElement(component, props))
  );
}
*/


const routeMap = {
  Collection: {
    path: '/collection/:collectionId',
    component: DezinerPageWrapper({
      page: CollectionPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
  },
  DesignView: {
    path: '/DesignView/:designId',
    component: DezinerPageWrapper({
      page: DesignViewPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    })
  },
  VerifyEmailPage: {
    path: '/verify',
    component: DezinerPageWrapper({
      page: VerifyEmailPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [],
    }),
  },
  DesignerEditProfile: {
    component: DezinerPageWrapper({
      page: DesignerEditProfile,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditProfile'
  },
  DesignerAccountSettings: {
    component: DezinerPageWrapper({
      page: DesignerAccountSettingsPage,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerAccountSettings'
  },
  DesignerGroupPageView: {
    component: DezinerPageWrapper({
      page: DesignerGroupPageView,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerGroupPageView'
  },
  DesignerEditPasswords: {
    component: DezinerPageWrapper({
      page: DesignerEditPasswords,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditPasswords'
  },
  DesignerEditSocialProfiles: {
    component: DezinerPageWrapper({
      page: DesignerEditSocialProfiles,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditSocialProfiles',
  },
  DesignClubPageView: {
    component: DezinerPageWrapper({
      page: DesignClubPageView,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignClubPageView'
  },

  DesignerDesignEdit: {
    component: DezinerPageWrapper({
      page: DesignerDesignEdit,
      guards: [LoginGuard, DesignOwnerOnlyCanAccessGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerDesignEdit/:designId/:type?/:clubOrGroupId?'
  },
  TermsOfService: {
    component: DezinerPageWrapper({
      page: TermsOfServicePage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/TermsOfService'
  },
  HowItWorks: {
    component: DezinerPageWrapper({
      page: HowItWorksStaticPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/HowItWorks'
  },
  Category: {
    component: DezinerPageWrapper({
      page: CategoryStaticPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/Category'
  },
  Explore: {
    component: DezinerPageWrapper({
      page: ExploreStaticPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/Explore'
  },
  PromoteDesign: {
    component: DezinerPageWrapper({
      page: PromoteDesignStaticPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/PromoteDesign'
  },
  Testimonials: {
    component: DezinerPageWrapper({
      page: TestimonialsStaticPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/Testimonials'
  },
  SearchDesigner: {
    component: DezinerPageWrapper({
      page: SearchDesignerStatic,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/SearchDesigner'
  },
  BuyDesign: {
    component: DezinerPageWrapper({
      page: BuyDesignStatic,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/BuyDesign'
  },
  FAQ: {
    component: DezinerPageWrapper({
      page: FAQPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/FAQ'
  },
  EmailIsActivated: {
    component: EmailIsActivatedPage,
    path: '/EmailIsActivated'
  },
  LandingPage: {
    component: DezinerPageWrapper({
      page: LandingPageWeb,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/LandingPage',
    exact: true
  },
  Catalogue: {
    component: DezinerPageWrapper({
      page: CatalogueWeb,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard, IsMyProfileGuard],
      providers: [RouterContextProvider],
    }),
    path: '/Catalogue/:designerId?'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLoginBlock',
    exact: true
  },
  EmailAccountLoginBlockWeb: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLoginBlockWeb',
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
    exact: true
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb',
    exact: true
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPassword'
  },
  CommunityForum: {
    component: DezinerPageWrapper({
      page: CommunityForum,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/CommunityForum'
  },
  Home: {
    component: DezinerPageWrapper({
      page: LandingPageWeb,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  DesignerDesignUpload: {
    component: DezinerPageWrapper({
      page: DesignerDesignUpload,
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerDesignUpload/:labelId/:collectionId/:type/:clubId'
  },
  DesignerHome: {
    component: DezinerPageWrapper({
      page: DesignerHome,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerHome/:designerId?/:category?'
  },
  UserHome: {
    component: DezinerPageWrapper({
      page: UserHome,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/UserHome'
  },
  ClubAboutPage: {
    component: DezinerPageWrapper({
      page: ClubAboutPage,
      layouts: [ClubLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/ClubAboutPage/:clubId'
  },
  CreateGroup: {
    component: CreateGroup,
    path: '/CreateGroup'
  },
  CreateClub: {
    component: DezinerPageWrapper({
      page: CreateClub,
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/CreateClub'
  },
  ClubFollowers: {
    component: DezinerPageWrapper({
      page: ClubFollowers,
      layouts: [ClubLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/ClubFollowers/:clubId'
  },
  ClubHomePage: {
    component: DezinerPageWrapper({
      page: ClubHomePage,
      layouts: [ClubLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/ClubHomePage/:clubId/:category?'
  },
  DesignerClubManage: {
    component: DezinerPageWrapper({
      page: DesignerClubManage,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerClubManage/:clubId'
  },
  AddClubMember: {
    component: DezinerPageWrapper({
      page: AddClubMember,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/AddClubMember/:clubId'
  },
  DesignerFollower: {
    component: DezinerPageWrapper({
      page: DesignerFollowerPageByUser,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerFollower/:designerId'
  },
  ForumSingle: {
    component: DezinerPageWrapper({
      page: ForumSingle,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/ForumSingle/:id'
  },
  ForumEdit: {
    component: DezinerPageWrapper({
      page: ForumEditPage,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/ForumEdit/:forumId'
  },
  UserEditProfile: {
    component: DezinerPageWrapper({
      page: UserEditProfile,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditProfile/:userId'
  },
  UserEditAccount: {
    component: DezinerPageWrapper({
      page: UserEditAccount,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditAccount/:userId'
  },
  UserDesignList: {
    component: DezinerPageWrapper({
      page: UserDesignList,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/UserDesignList/:userId'
  },
  UserEditAbout: {
    component: DezinerPageWrapper({
      page: UserEditAbout,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditAbout/:userId'
  },
  UserEditPassword: {
    component: DezinerPageWrapper({
      page: UserEditPassword,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditPassword/:userId'
  },
  GroupFollowers: {
    component: DezinerPageWrapper({
      page: GroupFollowers,
      layouts: [GroupLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/GroupFollowers/:groupId'
  },
  GroupHomePage: {
    component: DezinerPageWrapper({
      page: GroupHomePage,
      layouts: [GroupLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/GroupHomePage/:groupId/:category?'
  },
  DesignerGroupManage: {
    component: DezinerPageWrapper({
      page: DesignerGroupManage,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerGroupManage/:groupId'
  },
  DesignBuy: {
    component: DezinerPageWrapper({
      page: DesignBuy,
      layouts: [],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignBuy/:designId'
  },
  AddGroupMember: {
    component: DezinerPageWrapper({
      page: AddGroupMember,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/AddGroupMember/:groupId'
  },
  GroupAboutPage: {
    component: DezinerPageWrapper({
      page: GroupAboutPage,
      layouts: [GroupLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/GroupAboutPage/:groupId'
  },
  DesignerAboutPage: {
    component: DezinerPageWrapper({
      page: DesignerAboutPage,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerAboutPage/:designerId?'
  },
  // NotificationsWeb: {
  //   component: NotificationsWeb,
  //   path: '/Notifications'
  // },
  ClubCreateCollection: {
    component: DezinerPageWrapper({
      page: ClubCreateCollection,
      layouts: [ClubLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/ClubCreateCollection/:clubId'
  },
  GroupCreateCollection: {
    component: DezinerPageWrapper({
      page: GroupCreateCollection,
      layouts: [GroupLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/GroupCreateCollection/:groupId'
  },
  ArchiveDesigns: {
    component: ArchiveDesigns,
    path: '/ArchiveDesigns'
  },
  PrivacyPolicy: {
    component: DezinerPageWrapper({
      page: PrivacyPolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/PrivacyPolicy'
  },
  CodeOfConduct: {
    component: DezinerPageWrapper({
      page: CodeOfConductPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CodeOfConduct'
  },
  CopyrightPolicy: {
    component: DezinerPageWrapper({
      page: CopyrightPolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CopyrightPolicy'
  },
  CookiePolicy: {
    component: DezinerPageWrapper({
      page: CookiePolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CookiePolicy'
  },
  SearchResultsWeb: {
    component: DezinerPageWrapper({
      page: SearchResultsWeb,
      layouts: [DesignerTopNavLayout, FooterLayout],
      providers: [RouterContextProvider]
    }),
    path: '/SearchResults'
  },
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });

const MaterialUITheme = createTheme({
  typography: {
    fontFamily: 'Calibri'
  },
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#2681FD'
    },
  },
  overrides: {
    MuiButton: {
      color: '#18181A',
      backgroundColor: '#E2E3E5',
    }
  }
});


class App extends Component {
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
    // const [isTokenFound, setTokenFound] = useState(false);
    // setToken();

    // onMessageListener()
    // .then((payload) => {
    //   console.log("FIREBASE_CONSOLE_NOTI_FOREGROUND_APPJS payload", payload);   
    // })
    // .catch((err) => console.log('failed: ', err));

    return (
      <ThemeProvider theme={MaterialUITheme}>
        <View style={{ height: 'auto' }}>

          <QueryClientProvider client={dezinerQueryClient}>
            <ReactQueryDevtools />
            <ReduxProvider store={dezinerReduxStore}>
              <ConnectedRouter history={dezinerHistory}>
                <>

                  <RouterContextProvider>
                    <DezinerChatWidget />
                  </RouterContextProvider>

                  <ScrollToTop />

                  <ReduxDezinerMiddleware />

                  <RequireValidTokenContext>
                    <GlobalDialogs/>
                    <div style={{
                      minHeight: '100vh',
                      height: '100vh',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}>
                      {WebRoutesGenerator({ routeMap })}
                    </div>
                  </RequireValidTokenContext>

                </>
              </ConnectedRouter>
            </ReduxProvider>
          </QueryClientProvider>

          <ModalContainer />
          {/* <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </View>
      </ThemeProvider>
    );
  }
}

export default App;
