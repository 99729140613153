import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseCircleIcon from '@material-ui/icons/HighlightOff';


const useCommentCloseIconStyles = makeStyles({
  closeIcon: {
    color: '#e1e1e1'
  },
});

export function CommentCloseIcon({
  onClick,
  hide,
}: {
  onClick: () => void,
  hide: boolean,
}) {
  const classes = useCommentCloseIconStyles();

  if (hide)
    return null;

  return (
    <IconButton onClick={onClick} size="small">
      <CloseCircleIcon fontSize="small" className={classes.closeIcon} />
    </IconButton>
  );
}
