import React, { useState } from "react";
// Customizable Area Start
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  star,
  lightbulb,
  trending_up,
  edit,
  love,
} from "../../assets";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// Customizable Area End
import DesignerHomeController, { Props } from "./DesignerHomeController.web";
import { useParsedQueryParams, useChangeLabelInDesignerHome, useLabelsInDesignerHome } from "./DesignerHomeLayout.web";
import { useParams } from "../../../../../components/src/utils";
import { CollectionHome, Design, } from "../../../../../components/src/api-types";
import { NoDataContainer, VerticalSpacer, DezinerSpinner, CollectionGirdCards } from "../../../../../components/src/design-system";
import { useCollections, useDesigns, useIsDesigner, useIsMyProfile, useNavigate, usePinCollection } from "../../../../../components/src/hooks";
import { DesignCardsResponsiveContainer, DezinerCollectionList, LabelsDropdown, PromoteDialog } from "../../../../../components/src/common";

const categoryLogoMapper: any = {
  collections: '',
  designs: edit,
  liked: love,
  promoted: trending_up,
  inspired: lightbulb,
  rated: star,
}

export default class DesignerHome extends DesignerHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <>
        <DesignerHomeContent />
      </>
    );
  }
}


function NoData() {

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>No Data</h2>
    </div>
  );
}





function DesignerHomeContent() {

  const params = useParams();
  const categoryQueryParam = params?.category;
  const designerId = parseInt(params.designerId);

  const displayCollectionsAsList = !categoryQueryParam;

  const navigate = useNavigate();
  const { category, labelId } = useParsedQueryParams();


  const designsQuery = useDesigns({ category, labelId, designerId });
  const collectionsQuery = useCollections({ designerId, category, labelId });

  const designs = category == "collections" ?
    (collectionsQuery?.data?.data ?? []) :
    (designsQuery?.data?.data ?? []);

  const [promoteDialog, setPromoteDialog] = useState<{
    id: number,
    open: boolean,
    mediaSrc: string,
    mediaType: 'video' | 'image',
  }>({
    id: 0,
    open: false,
    mediaSrc: '',
    mediaType: 'image',
  });

  const loading = designsQuery?.isFetching || collectionsQuery?.isFetching || false;

  const { mutate: pinCollection } = usePinCollection({ designerId, labelId });
  const changeLabel = useChangeLabelInDesignerHome();

  const hasDesigns = (designs?.length ?? 0) > 0

  const isMyProfile = useIsMyProfile({ id: designerId });
  const isDesigner = useIsDesigner();

  const labels = useLabelsInDesignerHome();

  const showCollectionOptions = isDesigner && isMyProfile;


  const CollectionList = (
    <div>
      <PromoteDialog
        id={String(promoteDialog.id)}
        promoteType="Collection"
        onClose={() => setPromoteDialog({ id: 0, open: false, mediaType: 'image', mediaSrc: '' })}
        open={promoteDialog.open}
        mediaProps={{
          id: String(promoteDialog.id),
          src: promoteDialog.mediaSrc,
          mediaType: promoteDialog.mediaType,
        }}
      />
      {
        hasDesigns ?
          (designs as CollectionHome[])?.map((collection) => (
            <DezinerCollectionList
              hideCollectionOptions={!showCollectionOptions}
              onDesignClick={(design: any) => {
                navigate(`DesignView/${design.id}`);
              }}
              key={collection.id}
              onPromote={({ collectionId }) => {
                setPromoteDialog({
                  id: collectionId,
                  mediaType: collection?.attributes?.designs?.data?.[0]?.attributes?.main_image_type?.includes('video') ? 'video' : 'image',
                  mediaSrc: collection?.attributes?.designs?.data?.[0]?.attributes?.image_url,
                  open: true,
                });
              }}
              collection={collection}
              onPin={() => {
                pinCollection({
                  collectionId: collection.attributes.id,
                  pin: !collection.attributes.pined
                })
              }}
              onCreate={() => { }}
            />
          ))
          :
          <NoData />
      }
    </div>
  );

  const DesignsAndCollections = (
    category == 'collections' ?
      <NoDataContainer dataLength={designs?.length}>
        <CollectionGirdCards
          collection={designs as CollectionHome[]}
          onClick={(collection) => navigate(`collection/${collection.id}?label_id=${collection.attributes.label_id}`)}
        />
      </NoDataContainer>
      :
      <NoDataContainer dataLength={designs?.length}>
        <DesignCardsResponsiveContainer
          designs={(designs) as Design[]}
          onClick={(item) => {
            navigate(`DesignView/${item.id}`);
          }}
          onTrend={() => { }}
          onEdit={(item) => {
            navigate(`DesignerDesignEdit/${item.id}`);
          }}
        />
      </NoDataContainer>
  );



  if (loading)
    return <DezinerSpinner />


  return (
    <div
      style={{ padding: '20px 20px 80px' }}
    >

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >

        <Grid xs={6} item container
          justifyContent="flex-start"
          alignItems="center"
        >
          {
            categoryLogoMapper?.[categoryQueryParam] ?
              <img
                style={{ maxWidth: '20px', marginRight: '10px', }}
                src={categoryLogoMapper?.[categoryQueryParam]}
                alt={categoryQueryParam}
              />
              :
              null
          }
          <Typography style={{ fontWeight: 'bold' }}>
            {categoryQueryParam?.toUpperCase()}
          </Typography>
        </Grid>

        <LabelsDropdown
          labels={labels}
          currentLabel={labelId}
          onChange={(labelId) => {
            changeLabel(labelId)
          }}
        />
      </Grid>

      <VerticalSpacer />

      {
        displayCollectionsAsList ?
          CollectionList
          :
          DesignsAndCollections
      }
    </div>
  );

}


