import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { FormikTextInput, DezinerButton, VerticalSpacer, DezinerAlert } from '../../../../components/src/design-system';
import { makeStyles } from '@material-ui/core/styles';
import { passwordPolicyValidationSchema } from '../../../../components/src/validation/password-validation';
import { useNavigate } from '../../../../components/src/hooks';

const initValues = {
  oldPassword: '',
  newPassword: '',
}



const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('please insert your old password'),
  newPassword: passwordPolicyValidationSchema('new password')
    .notOneOf([yup.ref('oldPassword')], 'old and new password should not be the same')
});



const useChangePasswordFormStyles = makeStyles({
  container: {
    margin: '50px auto',
    width: '400px',
  },
});

export function ChangePasswordForm({
  onSubmit,
  loading,
  errors,
  isSuccess,
}: {
  onSubmit: (params: { newPassword: string, oldPassword: string }) => void,
  loading: boolean,
  errors: { [key: string]: string },
  isSuccess?: boolean,
}) {

  const classes = useChangePasswordFormStyles();

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {
        formik => <Form translate="yes">

          <Grid direction='column' alignItems="center" className={classes.container}>

            <FormikTextInput
              fullWidth
              variant='outlined'
              name="oldPassword"
              label="Old Password"
              type="password"
            />

            {
              errors?.old_password ?
                <DezinerAlert>
                  {errors?.old_password}
                </DezinerAlert>
                :
                null
            }
            <VerticalSpacer />


            <FormikTextInput
              fullWidth
              variant='outlined'
              name="newPassword"
              label="New Password"
              type="password"
            />
            {
              errors?.password ?
                <DezinerAlert>
                  {errors?.password}
                </DezinerAlert>
                :
                null
            }
            <VerticalSpacer />


            <VerticalSpacer />

            <SuccessMessage
              isSuccess={isSuccess}
              resetForm={formik.resetForm}
            />


            <Grid container justifyContent='flex-end'>
              <DezinerButton
                color="primary"
                variant='contained'
                type='submit'
                disabled={loading || isSuccess}
                loading={loading}
              >
                {'Change'}
              </DezinerButton>
            </Grid>

          </Grid>

        </Form>
      }

    </Formik>
  );
}

function SuccessMessage({
  isSuccess,
  resetForm,
}: {
  isSuccess?: boolean,
  resetForm: () => void,
}) {

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) resetForm();
  }, [isSuccess, resetForm]);


  return (
    <>
      {
        isSuccess ?
          <Alert severity="success" style={{ maxWidth: '400px', margin: '10px auto' }}>
            {'You\'ve successfully changed your password. You can login with your new password now'}
            <DezinerButton
              onClick={() => {
                localStorage.clear();
                navigate('EmailAccountLoginBlock');
              }}>
              {'Login'}
            </DezinerButton>
          </Alert>
          :
          null
      }
    </>
  );
}

