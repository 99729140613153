import React from 'react';
import {
  withStyles, makeStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {
  useFollowDesigner,
  useUnfollowDesigner,
} from '../../../../../components/src/hooks';
import AddIcon from '@material-ui/icons/Add';
import MaterialPopover from '@material-ui/core/Popover';
import { DezinerButton, } from '../../../../../components/src/design-system';
import { PostedBy, Profile } from '../../../../../components/src/api-types';

const Popover = withStyles({
  root: {
    top: '50px',
  },
  paper: {
    padding: '10px',
    background: 'black',
    minWidth: '130px',
    borderRadius: 0,
  },
})(MaterialPopover);

const useStyles = makeStyles({
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
  aboutButton: {
    '&:hover': {
      background: 'black',
      color: 'white',
    }
  },
  floatButtonActive: {
    background: 'black !important',
    color: 'white !important',
  },
});

interface FollowDesignerButtonUIProps {
  isFollowed: boolean,
  onClick: () => void,
  followersCount: number,
  loading: boolean,
}

/*
 * this component support render props children, so you can call it with a function as children to get 
 * the state and render any UI you like, or without passing any children, the component render the default 
 * UI available
 */
export function FollowDesignerButton({
  designerId,
  children,
  designer,
}: {
  designerId: number,
  children?: (props: FollowDesignerButtonUIProps) => JSX.Element,
  designer?: Profile['attributes'] | PostedBy['attributes']
}) {

  const profileData = designer;
  const isFollowed = profileData?.followed ?? false;

  const { mutate: followDesigner, isLoading: followLoading } = useFollowDesigner({ designerId });
  const { mutate: unfollowDesigner, isLoading: unfollowLoading } = useUnfollowDesigner({ designerId });

  const loading = followLoading || unfollowLoading;

  function onClick() {
    if (isFollowed)
      unfollowDesigner({ designerId });
    else
      followDesigner({ designerId });
  }

  let followersCount = 0;

  if (profileData?.follower_count)
    followersCount = profileData.follower_count;


  const uiProps: FollowDesignerButtonUIProps = {
    isFollowed,
    followersCount,
    onClick,
    loading,
  }

  if (children)
    return children(uiProps);

  return (
    <FollowDesignerButtonUI {...uiProps} />
  );
}

function FollowDesignerButtonUI({
  isFollowed,
  onClick,
  followersCount,
  loading,
}: FollowDesignerButtonUIProps) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <span
        onMouseEnter={(event: any) => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
        aria-owns={open ? 'followers-popover' : undefined}
        style={{
          padding: '10px 0'
        }}
      >
        <DezinerButton
          onClick={onClick}
          variant="contained"
          startIcon={<AddIcon />}
          className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: isFollowed })}
          aria-pressed={isFollowed ? "true" : "false"}
          loading={loading}
        >
          {isFollowed ? 'Following' : 'Follow'}
        </DezinerButton>
      </span>

      <Popover
        style={{ pointerEvents: 'none' }}
        id="followers-popover"
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography style={{ color: 'white', textAlign: 'center' }}>
          {`${followersCount} Followers`}
        </Typography>
      </Popover>

    </>
  );
}



