import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  postImageCard: {
    background: "#F3F4F6",
    height: "150px",
    width: "90%",
    borderRadius: "15px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 5px",
    '& img, & video': {
      borderRadius: "15px",
      height: "100%",
      width: "100%",
      objectFit: "cover"
    },
    '&:hover $postImageCardOverlay': {
      display: 'flex',
    },
  }
});

export function CollectionListEmptyCard({
  imgHeight,
  onClick,
}: {
  imgHeight: number,
  onClick: () => void,
}) {

  const classes = useStyles();

  return (
    <div
      className={classes.postImageCard}
      style={{ position: "relative", height: `${imgHeight || 150}px`, }}
      onClick={onClick}
    >

      <AddIcon style={{ color: '#CBCBCB' }} fontSize="large" />

    </div>
  );
}
