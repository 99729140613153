import React, { useState } from "react";
// Customizable Area Start
//import "../../catalogue/src/style.css";
import GroupHomePageController, { Props } from "./GroupHomePageController.web";
import { useChangeLabelInGroupLayout, useGroupLayoutParsedQueryParams, useIsGroupMember } from "./GroupLayout.web";
import { useGroupsCollections, useGroupsDesignsByCategory, useGroupsLabels } from "../../../components/src/hooks/groups";
import { useDecodedToken, useNavigate, usePinCollection } from "../../../components/src/hooks";
import { CollectionHome, Design, Label } from "../../../components/src/api-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  star,
  lightbulb,
  trending_up,
  edit,
  love,
} from "../../landingpage/src/assets";
import { VerticalSpacer, CollectionGirdCards, HideContainer, LoadingSpinnerContainer, NoDataContainer } from "../../../components/src/design-system";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { DesignCardsResponsiveContainer, DezinerCollectionList, LabelsDropdown, PromoteDialog } from "../../../components/src/common";
// Customizable Area End


class GroupHomePage extends GroupHomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <GroupHomePageContent />
    );
  }
}


const categoryLogoMapper: any = {
  collections: '',
  designs: edit,
  liked: love,
  promoted: trending_up,
  inspired: lightbulb,
  rated: star,
}


const All_LABEL: Label = {
  id: "0",
  type: 'labels',
  attributes: {
    id: 0,
    name: "All",
    club_id: 0,
    group_id: 0,
    created_at: "",
    label_type: "Club",
    updated_at: ""
  }
}

function GroupHomePageContent() {

  const { groupId, labelId, categoryQueryParam } = useGroupLayoutParsedQueryParams();

  const labelsQuery = useGroupsLabels({ groupId });
  const labels = [All_LABEL].concat(defaultToEmptyArray<Label>(labelsQuery?.data?.data));

  const changeLabel = useChangeLabelInGroupLayout();

  return (
    <div>

      <div style={{ padding: '20px 20px 80px' }}>

        <VerticalSpacer />


        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >

          <Grid xs={6} item container
            justifyContent="flex-start"
            alignItems="center"
          >

            <HideContainer hideWhen={!(categoryLogoMapper?.[categoryQueryParam])}>
              <img
                style={{ maxWidth: '20px', marginRight: '10px', }}
                src={categoryLogoMapper?.[categoryQueryParam]}
                alt={categoryQueryParam}
              />
            </HideContainer>

            <Typography style={{ fontWeight: 'bold' }}>
              {categoryQueryParam?.toUpperCase()}
            </Typography>
          </Grid>

          <LabelsDropdown
            labels={labels}
            currentLabel={labelId}
            onChange={changeLabel}
          />
        </Grid>



        <div style={{ margin: '30px 0' }}>

          <Designs />

        </div>


      </div>


    </div>
  );
}


function Designs() {


  const { groupId, labelId, category, categoryQueryParam } = useGroupLayoutParsedQueryParams();

  const displayCollectionsAsList = !categoryQueryParam;

  const collectionsQuery = useGroupsCollections({ groupId, labelId });

  const designsQuery = useGroupsDesignsByCategory({ groupId, labelId, category }, {
    enabled: category != 'collections'
  });

  const navigate = useNavigate();

  const collections = defaultToEmptyArray<CollectionHome>(collectionsQuery?.data?.data);
  const collectionsLoading = collectionsQuery.isLoading;

  const designs = defaultToEmptyArray<Design>(designsQuery?.data?.data);
  const designsLoading = designsQuery.isLoading;

  const { id: myProfileId } = useDecodedToken();
  const { mutate: pinCollection } = usePinCollection({ labelId, designerId: myProfileId, groupId });

  const isMember = useIsGroupMember({ groupId });

  const [promoteDialog, setPromoteDialog] = useState<{
    id: number,
    open: boolean,
    mediaSrc: string,
    mediaType: 'video' | 'image',
  }>({
    id: 0,
    open: false,
    mediaSrc: '',
    mediaType: 'image',
  });


  if (displayCollectionsAsList) {
    return (
      <>
        <PromoteDialog
          id={String(promoteDialog.id)}
          promoteType="Collection"
          onClose={() => setPromoteDialog({ id: 0, open: false, mediaType: 'image', mediaSrc: '' })}
          open={promoteDialog.open}
          mediaProps={{
            id: String(promoteDialog.id),
            src: promoteDialog.mediaSrc,
            mediaType: promoteDialog.mediaType,
          }}
        />
        <LoadingSpinnerContainer loading={collectionsLoading}>
          <NoDataContainer dataLength={collections.length}>
            {collections.map((item) => (
              <DezinerCollectionList
                hideCollectionOptions={!isMember}
                key={item.id}
                onPin={() => {
                  pinCollection({
                    collectionId: item.attributes.id,
                    pin: !item.attributes.pined
                  });
                }}
                onDesignClick={(design) => {
                  navigate(`DesignView/${design.attributes.id}`);
                }}
                onCreate={() => { }}
                onPromote={({ collectionId }) => {
                  setPromoteDialog({
                    id: collectionId,
                    mediaType: item?.attributes?.designs?.data?.[0]?.attributes?.main_image_type?.includes('video') ? 'video' : 'image',
                    mediaSrc: item?.attributes?.designs?.data?.[0]?.attributes?.image_url,
                    open: true,
                  });
                }}
                collection={item}
              />
            ))}
          </NoDataContainer>
        </LoadingSpinnerContainer>
      </>
    );
  }


  if (category == 'collections') {

    return <LoadingSpinnerContainer loading={collectionsLoading} >
      <NoDataContainer dataLength={collections.length} >
        <CollectionGirdCards
          collection={collections}
          onClick={(collection) => navigate(`collection/${collection.id}?label_id=${collection.attributes.label_id}&group_id=${groupId}`)}
        />
      </NoDataContainer>
    </LoadingSpinnerContainer>

  } else {

    return <LoadingSpinnerContainer loading={designsLoading} >
      <NoDataContainer dataLength={designs.length}>
        <DesignCardsResponsiveContainer
          designs={designs}
          onClick={(item) => {
            navigate(`DesignView/${item?.id}`);
          }}
          onTrend={() => { }}
          onEdit={(item) => {
            navigate(`DesignerDesignEdit/${item?.id}`);
          }}
        />
      </NoDataContainer>
    </LoadingSpinnerContainer>
  }

}



// Customizable Area Start
export default GroupHomePage;
// Customizable Area End
