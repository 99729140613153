import React from 'react';
import VerifyEmailPageController from './VerifyEmailPageController.web';
import { dezinerKnotLogo } from '../../../blocks/landingpage/src/assets';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {DezinerAlert,DezinerSpinner} from '../../../components/src/design-system';


export default class VerifyEmailPage extends VerifyEmailPageController {



  render() {

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: '50vh',
        }}
      >

        <img
          src={dezinerKnotLogo}
          alt="DezinerKnot"
          style={{
            maxWidth: '400px',
          }}
        />

        {
          this.state.verifyFailed ?
            <DezinerAlert style={{ maxWidth: '300px' }}>
              {'Verifying your email failed, please check the link we sent you in your inbox again.'}
            </DezinerAlert>
            :
            <>
              <DezinerSpinner />

              <Typography>
                {'Verifying your email...'}
              </Typography>
            </>
        }

      </Grid>
    );
  }

}
