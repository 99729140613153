
import { Message } from "../../../framework/src/Message";
import { appendJsonContentType, apiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const categoriesMessages = {
  fetchCategories() {

    return apiCall({
      header: appendJsonContentType({}),
      httpMethod: configJSON.GetMethod,
      url: configJSON.categoriesListAPI,
    });
  },

}
