import React from 'react';
import { FieldArray, useFormikContext } from "formik";
import { DezinerAlert, DezinerButton, FormikTextInput, HideContainer } from "../../../../../components/src/design-system";
import { UplodaDesignInitValues } from "./UploadDesignForm.web";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useUploadDesignFormStyles } from "./UploadDesignFormStyle.web";

export function PriceConditionsInput() {

  const formik = useFormikContext<UplodaDesignInitValues>();

  if (!formik.values.timePeriod)
    return null;

  return (
    <>
      <FieldArray
        name="priceConditions"
        render={(arrayHelpers) => (
          <div>

            <Grid container direction="column">
              {
                formik.values.priceConditions
                  ?.map((_condition, conditionIndex) => (
                    <ConditionItem
                      key={conditionIndex}
                      conditionIndex={conditionIndex}
                      onRemove={() => arrayHelpers.remove(conditionIndex)}
                    />
                  ))
              }
            </Grid>


            <DezinerButton
              color="primary"
              variant="contained"
              onClick={() => {
                if (formik.values.timePeriod?.toLowerCase() == 'auto') {
                  if (formik.values.priceConditions.length < 4) {
                    let nextMonthOption = [3, 6, 9, 12].filter(option => !formik.values.priceConditions.map(x => x.month).includes(option));
                    arrayHelpers.push({ year: 0, month: nextMonthOption[0], price: 100 });
                  }
                }
                else
                  arrayHelpers.push({ year: 0, month: 3, price: 100 });
              }}>
              {'Add'}
            </DezinerButton>
          </div>
        )}
      />
    </>
  );
}


function ConditionItem({
  conditionIndex,
  onRemove,
}: {
  conditionIndex: number,
  onRemove: () => void,
}) {

  const formik = useFormikContext<UplodaDesignInitValues>();
  const classes = useUploadDesignFormStyles();
  const condition = formik.values.priceConditions[conditionIndex];

  const isAuto = formik.values.timePeriod?.toLowerCase() == 'auto';

  return (
    <Grid
      container style={{
        padding: '5px 0',
      }}>

      <Grid item xs container alignItems="flex-start" style={{
        background: isAuto ? 'white' : '#f1f1f1',
        padding: isAuto ? '0px' : '10px',
        borderRadius: '10px',
      }}>

        <HideContainer hideWhen={formik.values.timePeriod?.toLowerCase() == 'auto'}>
          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <div>
              <Grid container direction="column">
                <FormikTextInput
                  hideErrors
                  type="number"
                  variant="outlined"
                  name={`priceConditions[${conditionIndex}].year`}
                  className={classes.yearMonthInput}
                />
              </Grid>
            </div>
            <Typography className={classes.priceInputLabel}>
              {'Year'}
            </Typography>
          </Grid>
        </HideContainer>

        <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
          {
            formik.values.timePeriod.toLowerCase() == 'auto' ?
              <Select
                variant="outlined"
                style={{ maxHeight: '30px', background: 'white', marginRight: '10px', position: 'relative', top: '5px' }}
                fullWidth
                labelId="type of user"
                value={formik.values.priceConditions[conditionIndex].month}
                defaultValue={3}
                onChange={(event) => {
                  let newMonths = event.target.value;
                  formik.setFieldValue('priceConditions', formik.values.priceConditions.slice().map((_item, _index) => ({
                    ..._item,
                    month: _index == conditionIndex ? newMonths : _item.month
                  })))
                }}
              >
                {
                  [3, 6, 9, 12]
                    .filter(monthOption =>
                      formik.values.priceConditions.length && condition.month != monthOption ?
                        !formik.values.priceConditions.map(x => x.month).includes(monthOption)
                        :
                        true
                    )
                    .map((months) => (
                      <MenuItem value={months} key={months}>{`${months} Months`}</MenuItem>
                    ))
                }
              </Select>
              :
              <>
                <div>
                  <Grid container direction="column">
                    <FormikTextInput
                      hideErrors
                      type="number"
                      variant="outlined"
                      name={`priceConditions[${conditionIndex}].month`}
                      className={classes.yearMonthInput}
                    />
                  </Grid>
                </div>
                <Typography className={classes.priceInputLabel}>
                  {'Month'}
                </Typography>
              </>
          }
        </Grid>


        <Grid item xs={4}>
          <FormikTextInput
            style={{ background: 'white', maxWidth: '100px' }}
            type="number"
            variant="outlined"
            hideErrors
            name={`priceConditions[${conditionIndex}].price`}
            InputProps={{
              startAdornment: <span>{'₹'}</span>
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {
            Array.isArray(formik.errors?.priceConditions) &&
            Object.values(formik.errors?.priceConditions?.[conditionIndex] ?? {})
              ?.map(error =>
                <DezinerAlert>
                  {error}
                </DezinerAlert>
              )
          }
        </Grid>
      </Grid>


      <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
        <IconButton
          onClick={() => {
            onRemove();
          }}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
