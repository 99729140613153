import React, { useRef, useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  DezinerCard,
  FormikTextInput,
  DezinerLabel,
  DezinerButton,
  HorizontalSpacer,
  VerticalSpacer,
  HideContainer
} from '../../../components/src/design-system';
import CloseIcon from '@material-ui/icons/Close'
import { blobToBase64 } from '../../../components/src/utils';



const initValues = {
  title: '',
  description: '',
  media: '',
}


const validationSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  media: yup.string(),
});



export default function CreateForumForm({
  loading,
  onSubmit,
  onCancel,
  initialValues,
}: {
  loading: boolean,
  onSubmit: (values: typeof initValues) => void,
  onCancel: () => void,
  initialValues?: { title: string, description: string, media: string },
}) {


  const fileRef = useRef<any>();
  const [fileName, setFileName] = useState('');

  return (
    <DezinerCard style={{ maxWidth: '800px', margin: '40px auto', padding: '20px 40px' }}>
      <Formik
        initialValues={Object.assign({}, initValues, initialValues)}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {
          formik => <Form translate="yes">


            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>

                <FormikTextInput
                  label={"Title"}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="title"
                  lightGrayBorder
                />

                <VerticalSpacer />

                <Grid container alignItems='center'>
                  <DezinerLabel>{'Media'}</DezinerLabel>
                  <DezinerButton
                    variant="contained"
                    size='small'
                    style={{ margin: '0 15px' }}
                    onClick={() => {
                      if (fileRef && fileRef.current) {
                        fileRef.current.click();
                      }
                    }}
                  >
                    {'Choose File'}
                  </DezinerButton>

                  <input
                    type="file"
                    hidden
                    ref={fileRef}
                    onChange={(e) => {
                      e.preventDefault();
                      let file = e.target?.files?.[0];
                      setFileName(file?.name ?? '');
                      blobToBase64(file).then(
                        (res: any) => {
                          formik.setFieldValue('media', res)
                        }
                      );
                    }}
                    accept="image/*, video/*"
                  />

                  <HideContainer hideWhen={!formik.values.media}>
                    <Grid container alignItems='center'>
                      <Typography>
                        {fileName || '1 File attached'}
                      </Typography>
                      <IconButton onClick={() => {
                        formik.setFieldValue('media', '');
                      }}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </HideContainer>

                </Grid>

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormikTextInput
                  label={"Description"}
                  multiline
                  minRows={6}
                  variant="outlined"
                  name="description"
                  fullWidth
                  autoHeight
                  lightGrayBorder
                />
              </Grid>
            </Grid>

            <VerticalSpacer />
            <Divider id="divider" />
            <VerticalSpacer />

            <Grid container justifyContent='center' alignItems='center'>
              <DezinerButton
                rounded
                variant="contained"
                onClick={() => {
                  formik.resetForm();
                  onCancel();
                }}
                style={{
                  minWidth: '140px'
                }}
              >
                {'Cancel'}
              </DezinerButton>

              <HorizontalSpacer />

              <DezinerButton
                variant="contained"
                rounded
                color="primary"
                type="submit"
                style={{
                  minWidth: '140px'
                }}
                disabled={loading}
                loading={loading}
              >
                {'Save'}
              </DezinerButton>
            </Grid>


          </Form>
        }
      </Formik >
    </DezinerCard>
  );
}
