import React from "react";
// Customizable Area Start
import GroupCreateCollectionController, {
  Props,
} from "./GroupCreateCollectionController.web";
import { useGroupLayoutParsedQueryParams } from "./GroupLayout.web";
import { useCreateGroupCollection, useGroupsCollections, useGroupsLabels } from "../../../components/src/hooks/groups";
import { useDecodedToken, useNavigate, usePinCollection } from "../../../components/src/hooks";
import { DezinerCollectionList, CreateCollectionForm } from "../../../components/src/common";
import { LoadingSpinnerContainer, NoDataContainer } from "../../../components/src/design-system";
// Customizable Area End

class GroupCreateCollection extends GroupCreateCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (<GroupCreateCollectionContent />);
  }
}

function CreateCollectionFormContainer() {

  const { groupId, labelId } = useGroupLayoutParsedQueryParams();

  const labelsQuery = useGroupsLabels({ groupId });
  const hasLabels = Boolean(labelsQuery?.data?.data?.length ?? 0);

  const { mutate: createCollection, isLoading: createCollectionLoading } =
    useCreateGroupCollection({ groupId });

  return (
    <CreateCollectionForm
      selectedALabel={Boolean(labelId)}
      onSubmit={(values) => {
        createCollection({ ...values, labelId, groupId });
      }}
      labelLoading={labelsQuery.isLoading}
      hasLabels={hasLabels}
      loading={createCollectionLoading}
    />
  );
}


function GroupCreateCollectionContent() {


  const { groupId, labelId, } = useGroupLayoutParsedQueryParams();

  const collectionsQuery = useGroupsCollections({ groupId, labelId });

  const collections = collectionsQuery?.data?.data ?? [];
  const collectionsLoading = collectionsQuery.isLoading;

  const { id: myProfileId } = useDecodedToken();
  const { mutate: pinCollection } = usePinCollection({ labelId, designerId: myProfileId, groupId });

  const navigate = useNavigate();


  return (
    <div
      style={{
        padding: '0 40px',
        minHeight: '100vh',
      }}
    >

      <CreateCollectionFormContainer />

      <LoadingSpinnerContainer loading={collectionsLoading} >
        <NoDataContainer dataLength={collections.length}>
          {
            collections?.map((collection) =>
              <DezinerCollectionList
                key={collection.id}
                onDesignClick={(design) => {
                  navigate(`DesignView/${design.id}`);
                }}
                collection={collection}
                onCreate={({ collectionId }) => {
                  navigate(`DesignerDesignUpload/${labelId}/${collection.attributes.id}/group/${groupId}`);
                }}
                onPin={() => pinCollection({ pin: !collection.attributes.pined, collectionId: collection.attributes.id })}
                onPromote={() => { }}
                showAddSlots
              />
            )
          }
        </NoDataContainer>
      </LoadingSpinnerContainer>

    </div>
  );
}


// Customizable Area Start
export default GroupCreateCollection;
// Customizable Area End
