import React from 'react';
import { withStyles, } from '@material-ui/core/styles'
import MaterialCard from "@material-ui/core/Card";

export const DezinerCard = withStyles({
  root: {
    boxShadow: "none",
    border: "1px solid #e7e7e7",
    borderRadius: "10px",
    marginBottom: '20px',
    padding: '10px 20px',
  }
})(MaterialCard);

