import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';

export const ScrollToTop: any = withRouter(function _ScrollToTop({ history }: any) {


  useEffect(() => {
    const unlisten = history.listen(() => {
      console.log('history changed', history);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 0);
    });
    return () => {
      unlisten();
    }
  }, []);


  return (
    <>
    </>
  );
});
