import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import DesignerGroupPageViewController, {
  Props
} from "./DesignerGroupPageViewController.web";
import GroupAndClubSearchHeader from "./SearchHeader.web";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";

import { useGroups } from "../../../components/src/hooks/groups";
import { useNavigate } from "../../../components/src/hooks";
import { VerticalSpacer, DezinerSpinner } from "../../../components/src/design-system";
import { Group } from "../../../components/src/api-types";
import { defaultToEmptyArray } from "../../../components/src/default-values";


const PAGE_SIZE = 10;

class DesignerGroupPageView extends DesignerGroupPageViewController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <GroupsList />
    );
  }
}

const styles: any = {
  container: {
    padding: '40px 10px',
    maxWidth: '650px',
    margin: '0 auto',
  },
  noData: {
    fontSize: '1.4rem',
    textAlign: 'center',
    color: '#97989A'
  },
};

const useStyles = makeStyles(styles);

function GroupsList() {

  const classes = useStyles();
  const groupsQuery = useGroups();
  const [page, setPage] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const groups = defaultToEmptyArray<Group>(groupsQuery?.data?.data);

  return (
    <>

      <Grid container>
        <Grid item sm={2}></Grid>
        <Grid item xs={12} sm>

          <div className={classes.container}>

            <GroupAndClubSearchHeader
              query={searchQuery}
              setQuery={setSearchQuery}
              btnTitle={'Create Group'}
              onClick={() => {
                navigate(`CreateGroup`)
              }}
            />

            <VerticalSpacer />
            <VerticalSpacer />

            <Groups
              page={page}
              searchQuery={searchQuery}
            />

            <Grid container justifyContent="flex-end">
              {
                groups.length > 0 ?
                  <Pagination
                    disabled={Boolean(searchQuery)}
                    color="primary"
                    count={Math.ceil(groups.length / PAGE_SIZE)}
                    page={page + 1}
                    onChange={(_e, v) => setPage(v - 1)}
                  />
                  :
                  null
              }
            </Grid>
          </div>

        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>


    </>
  );
}

function Groups({
  page,
  searchQuery,
}: {
  page: number,
  searchQuery: string,
}) {

  const classes = useStyles();
  const groupsQuery = useGroups();
  const navigate = useNavigate();

  const groups = defaultToEmptyArray<Group>(groupsQuery?.data?.data);


  const count = page * PAGE_SIZE;
  const sliceStart = searchQuery ? 0 : count;
  const sliceEnd = searchQuery ? groups.length : count + PAGE_SIZE;

  if (groupsQuery?.isLoading)
    return <DezinerSpinner />

  if (!groups.length)
    return (
      <Typography className={classes.noData}>
        {'No Groups'}
      </Typography>
    );

  return (
    <>
      {
        groups
          ?.slice(
            // only filter if search query is not filled
            sliceStart,
            sliceEnd,
          )
          ?.filter((item) => {
            if (searchQuery)
              return item.attributes.name.toLowerCase().includes(searchQuery.toLowerCase());
            else
              return true;
          })
          .map((item) => (
            <GroupAndClubHorizontalCard
              tagName={item?.attributes?.account_type == "Owner" ? "Admin" : ""}
              key={item.id}
              item={item}
              onClick={(item) => {
                navigate(`DesignerGroupManage/${item.attributes.id}`);
              }}
              onNameClick={() => navigate(`GroupHomePage/${item.id}`)}
              buttonTitle="Manage Group"
            />
          ))
      }
    </>
  );
}

export default DesignerGroupPageView;
