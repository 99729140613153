import React from 'react';
import { useField } from 'formik';
import { DezinerTextField } from './text-field';
import { DezinerAlert } from '../alert';


export function FormikTextInput(formikTextInputProps: React.ComponentProps<typeof DezinerTextField> & { hideErrors?: boolean }) {

  const { hideErrors, ...props } = formikTextInputProps;

  const inputName = props?.name ?? '';

  const [inputProps, meta] = useField(inputName);


  if (!inputName)
    return null;

  return (
    <>
      <DezinerTextField
        {...props}
        {...inputProps}
      />

      {
        meta?.error && !hideErrors ?
          <DezinerAlert>
            {meta.error}
          </DezinerAlert>
          :
          null
      }
    </>
  );
}


