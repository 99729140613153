import React from "react";
import { dezinerKnotLogo } from '../assets';
import { useNavigate, } from "../../../../components/src/hooks";
import { AppBar, Toolbar, Typography } from '@material-ui/core';

export function DesignUploadHeader({ headerText, onLogoClick, }: { headerText?: string, onLogoClick?: () => void, }) {

  const navigate = useNavigate();

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onLogoClick) onLogoClick();
            else
              navigate(`LandingPage`);
          }}
        />


        <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          {headerText || 'Publish Your Design'}
        </Typography>

        <div style={{ width: '160px' }} />

      </Toolbar>
    </AppBar>
  );
}
