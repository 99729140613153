import { DesignCategory } from "../api-types";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');

export interface CreateClubPayload {
  name: string,
  description: string,
  email: string,
  organization_name: string,
  organization_pan_id: string,
  bank_account_name: string,
  bank_account_number: string,
  ifsc_code: string,
  pan_photo: string,
  cheque: string,
  image: string,
  cover: string,
  user_type: 'Admin' | 'Owner',
  account_ids: string[],
  clubs_sub_categories_attributes: { sub_category_id: number }[],
}


export const clubsMessages = {

  followClub({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: configJSON.followClubAPI,
      httpBody: {
        "data": {
          "attributes": {
            "club_id": clubId
          }
        }
      }
    });
  },

  unfollowClub({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: configJSON.unfollowClubAPI,
      httpBody: {
        "data": {
          "attributes": {
            "club_id": clubId
          }
        }
      }
    });
  },

  fetchClubFollowers({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.clubGroupFollowersAPI}`,
    });
  },


  declineInvitation({ clubId, accountId }: { clubId: number, accountId: number }) {

    const httpBody = {
      data: {
        attributes: {
          club_id: clubId,
          account_id: accountId,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.declineInvitationClubAPI,
    });

  },

  acceptInvitation({ clubId, accountId }: { clubId: number, accountId: number }) {

    const httpBody = {
      data: {
        attributes: {
          club_id: clubId,
          account_id: accountId,
        }
      }
    };

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: configJSON.acceptInvitationClubAPI,
    });

  },

  fetchNonResponsdingMembers({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.nonRespondinMembersAPI}`,
    });
  },

  createClub({
    pan_photo,
    cheque,
    image,
    cover,
    user_type,
    account_ids,
    clubs_sub_categories_attributes,
    ...restPayload
  }: CreateClubPayload) {


    let httpBody = {
      "club": {
        ...restPayload,
        "pan_photo": { data: pan_photo, },
        "cheque": { data: cheque, },
        "image": { data: image, },
        "cover": { data: cover, }
      },
      "member": {
        "user_type": user_type,
        "account_ids": account_ids
      },
      "clubs_sub_categories_attributes": clubs_sub_categories_attributes
    }


    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.clubBaseAPI,
    });

  },

  fetchClubRoles() {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.clubBaseAPI}/${configJSON.fetchClubRolesAPI}`
    });
  },

  addMemberToClub({
    clubId,
    accountId,
    contribution,
    user_type,
  }: {
    clubId: number,
    accountId: number,
    contribution: number,
    user_type: "Pattern designer" | "Admin",
  }) {


    const httpBody = {
      data: {
        attributes: {
          account_id: accountId,
          contribution,
          user_type,
        }
      }
    }

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.addMemberAPI}`,
      httpBody,
    });

  },

  fetchClubs() {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.clubBaseAPI,
    });
  },

  fetchSingleClub({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}`,
    });
  },


  fetchClubsDesignsByCategory({
    category,
    labelId,
    clubId,
  }: {
    category: DesignCategory,
    labelId?: number,
    clubId: number,
  }) {

    let endpoint = '';
    let requestParams: any = {}

    if (labelId)
      requestParams.label_id = labelId;

    switch (category) {
      case 'liked': { endpoint = 'like_designs'; break; }
      case 'designs': { endpoint = 'all_designs'; break; }
      case 'rated': { endpoint = 'raterd_designs'; break; }
      case 'inspired': { endpoint = 'inspired_designs'; break; }
      case 'promoted': { endpoint = 'promoted_designs'; break; }
      case 'free': { endpoint = 'free_designs'; break; }
      case 'paid': { endpoint = 'paid_designs'; break; }

    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: `${configJSON.clubBaseAPI}/${clubId}/${endpoint}`,
      httpMethod: configJSON.GetMethod,
      params: requestParams
    });
  },

  changeCover({ image, clubId }: { image: string, clubId: number }) {

    const httpBody = {
      data: {
        attributes: {
          cover: {
            data: image
          },
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.changeGroupClubCoverImageAPI}`,
      httpMethod: configJSON.PutMethod,
      httpBody,
    });

  },


  fetchMembers({ clubId }: { clubId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.fetchClubMembersAPI}`
    });
  },


  addAsAdmin({ clubId, memberId }: { clubId: number, memberId: number }) {


    const httpBody = {
      data: {
        attributes: {
          account_id: memberId,
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.addAsAdminAPI}`,
    });
  },

  removeAdminPermission({ clubId, memberId }: { clubId: number, memberId: number }) {

    const httpBody = {
      data: {
        attributes: {
          account_id: memberId
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/remove_as_admin`,
    });
  },

  removeMember({ clubId, memberId }: { clubId: number, memberId: number }) {

    const httpBody = {
      data: {
        attributes: {
          account_id: memberId
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.DeleteMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/remove_member`,
    });

  },

  updateClubInfo({
    clubId, skills, description,
  }: {
    clubId: number, skills: number[], description: string,
  }) {

    const httpBody = {
      "data": {
        "attributes": {
          description,
          clubs_skills_attributes: skills.map(skill_id => ({ skill_id }))
        }
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.updateGroupClubInfoAPI}`,
    });
  },

  updateClubSocialProfiles({
    clubId,
    facebook,
    twitter,
    instagram,
  }: {
    clubId: number,
    facebook: string,
    twitter: string,
    instagram: string,
  }) {


    const httpBody = {
      "data": {
        "attributes": {
          "facebook_url": facebook,
          "twitter_url": twitter,
          "instagram_url": instagram,
        }
      }
    }

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PutMethod,
      url: `${configJSON.clubBaseAPI}/${clubId}/${configJSON.updateGroupClubSocialProfilesAPI}`,
    });
  },

}
