import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useFormikContext } from 'formik';
import { Grid, } from '@material-ui/core';
import { DezinerAlert, DezinerLabel, HideContainer, } from '../../../../../components/src/design-system';
import { privacyIcon } from "../../../../catalogue/src/assets";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  smallIcon: {
    maxWidth: '18px',
    marginRight: '5px',
  },
});

export function PrivacyInput() {

  const formik = useFormikContext<UplodaDesignInitValues>();
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center">
        <img src={privacyIcon} alt="" className={classes.smallIcon} />
        <DezinerLabel htmlFor="design-privacy" >
          {'Privacy*'}
        </DezinerLabel>
      </Grid>

      <FormControl component="fieldset">

        <RadioGroup
          id="design-privacy"
          aria-label="privacy"
          color="primary"
          name="privacy" value={formik.values.privacy} onChange={(event) => {
            formik.setFieldValue('privacy', event.target.value);
          }}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <FormControlLabel value="Public" control={<Radio color="primary" />} label="Public" />
          <FormControlLabel value="Private" control={<Radio color="primary" />} label="Private" />
        </RadioGroup>
      </FormControl>


      <HideContainer hideWhen={!formik?.errors?.privacy}>
        <DezinerAlert>
          {formik?.errors?.privacy}
        </DezinerAlert>
      </HideContainer>
    </>
  )
}
