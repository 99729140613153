import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Pagination from '@material-ui/lab/Pagination';
import DesignClubPageViewController, {
  Props
} from "./DesignClubPageViewController.web";
import { makeStyles } from '@material-ui/core/styles';
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import GroupAndClubSearchHeader from "./SearchHeader.web";
import { useClubs, useNavigate } from "../../../components/src/hooks";
import { DezinerSpinner,VerticalSpacer } from "../../../components/src/design-system";
import { Club } from "../../../components/src/api-types";
import { defaultToEmptyArray } from "../../../components/src/default-values";



const PAGE_SIZE = 10;



class DesignClubPageView extends DesignClubPageViewController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {


    return (
      <ClubListPageContent />
    );
  }
}




const useClubsListPageStyles = makeStyles({
  container: {
    padding: '40px 10px',
    maxWidth: '650px',
    margin: '0 auto',
  },
  noData: {
    fontSize: '1.4rem',
    textAlign: 'center',
    color: '#97989A'
  },
});


function ClubListPageContent() {

  const classes = useClubsListPageStyles();

  const clubsQuery = useClubs();
  const clubs = defaultToEmptyArray<Club>(clubsQuery?.data?.data);

  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();



  return (
    <div>

      <Grid container>
        <Grid item sm={2}></Grid>
        <Grid item xs={12} sm>

          <div className={classes.container}>

            <GroupAndClubSearchHeader
              query={searchQuery}
              setQuery={setSearchQuery}
              btnTitle={'Create Club'}
              onClick={() => {
                navigate(`CreateClub`)
              }}
            />

            <VerticalSpacer />
            <VerticalSpacer />

            <Clubs
              searchQuery={searchQuery}
              page={page}
            />

            <Grid container justifyContent="flex-end">
              {
                clubs.length > 0 ?
                  <Pagination
                    disabled={Boolean(searchQuery)}
                    color="primary"
                    count={Math.ceil(clubs.length / PAGE_SIZE)}
                    page={page + 1}
                    onChange={(_e, v) => setPage(v - 1)}
                  />
                  :
                  null
              }
            </Grid>
          </div>


        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>


    </div>
  );
}

function Clubs({ searchQuery, page }: { searchQuery: string, page: number }) {

  const classes = useClubsListPageStyles();

  const clubsQuery = useClubs();
  const clubs = defaultToEmptyArray<Club>(clubsQuery?.data?.data);


  const navigate = useNavigate();

  const count = page * PAGE_SIZE;
  const sliceStart = searchQuery ? 0 : count;
  const sliceEnd = searchQuery ? clubs.length : count + PAGE_SIZE;


  if (clubsQuery.isLoading)
    return <DezinerSpinner />


  if (clubs?.length > 0)
    return (
      <>
        {
          clubs
            ?.slice(
              // only filter if search query is not filled
              sliceStart,
              sliceEnd,
            )
            ?.filter((item) => {
              if (searchQuery)
                return item.attributes.name.toLowerCase().includes(searchQuery.toLowerCase());
              else
                return true;
            })
            .map((item ) => (
              <GroupAndClubHorizontalCard
                key={item.id}
                item={item}
                onClick={(item) => {
                  navigate(`DesignerClubManage/${item.attributes.id}`);
                }}
                buttonTitle="Manage Club"
                onNameClick={() => {
                  navigate(`ClubHomePage/${item.id}`);
                }}
                tagName={item?.attributes?.account_type == "Owner" ? "Owner" : ""}
              />
            ))
        }
      </>
    );
  else
    return (
      <Typography className={classes.noData}>
        {'No Clubs'}
      </Typography>
    );

}


export default DesignClubPageView;
