import React, { useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MicIcon from '@material-ui/icons/Mic';
import SendIcon from '@material-ui/icons/Send';
import {
  useCometChatSendUserMediaMessage,
  useCometChatSendMessage,
} from './hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import ChatEmojiButton from './ChatEmojiButton';
import { CHAT_WIDGET_WIDTH } from './CometChatSettings';

const PADDING = 18;

const useStyles = makeStyles({
  chatInputContainer: {
    position: 'relative',
    width: '100%',
    padding: '4px',
  },
  chatInput: {
    width: '100%',
    border: 'none',
    fontFamily: "Calibri",
    background: '#f3f3f5 !important',
    display: 'block',
    borderRadius: '50px',
    padding: '10px 50px',
    '&:focus': {
      border: 'none'
    },
    maxHeight: '60px',
    minHeight: '40px',
  }
});

export default function ChatInput() {

  const classes = useStyles();
  const sendButtonRef = useRef<any>();
  const [inputWidth, setInputWidth] = useState(CHAT_WIDGET_WIDTH);

  const uid = useSelector((state: RootState) => state.ui.chatWidget.uid);

  const [inputValue, setInputValue] = useState('');

  const chatType = useSelector((state: RootState) => state.ui.chatWidget.chatType);

  const { mutate: send } = useCometChatSendMessage({ uid, type: chatType });
  const { mutate: sendAudioMessage } = useCometChatSendUserMediaMessage({ uid, type: chatType });

  const streamRef = useRef<any>();

  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<any>(null);

  useEffect(function onLoad() {
    console.log(sendButtonRef.current);
    if (sendButtonRef.current)
      setInputWidth(CHAT_WIDGET_WIDTH - sendButtonRef.current.offsetWidth - PADDING);
  }, []);

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  async function startRecording() {
    setIsRecording(true);
    if (!recorder) {
      let _recorder = await requestRecorder();
      setRecorder(_recorder);
      _recorder.start();
      _recorder.addEventListener("dataavailable", handleAudioData);
    } else {
      recorder.start();
    }
  }

  function recordToggleHandler() {
    if (!isRecording)
      startRecording();
    else
      stopRecording();
  }
  async function handleAudioData(event: any) {
    event.preventDefault();
    console.log('cometChat audio data', event, event.data)
    sendAudioMessage({ file: event.data })
  }

  function stopRecording(): void {
    setIsRecording(false);
    recorder.stop();
    if (streamRef && streamRef.current && streamRef.current.getTracks) {
      if (streamRef.current.getTracks().length)
        streamRef.current.getTracks()?.[0]?.stop()
    }
    setRecorder(null);
  }

  const [emojiOpen, setEmojiOpen] = useState(false);

  function sendMessageHandler() {
    let sentMessage = inputValue.trim();
    if (sentMessage) {
      send({ message: inputValue.trim(), });
      setInputValue('');
      setEmojiOpen(false);
    }
  }


  return (
    <Grid wrap="nowrap" item container alignItems='flex-end' style={{ padding: '5px' }}>

      <div className={classes.chatInputContainer}>

        <InputAdornment position="start" style={{ position: 'absolute', bottom: 24, right: 8 }}>
          <IconButton aria-label="record-voice" size="small" onClick={recordToggleHandler}>
            <MicIcon style={{ color: isRecording ? 'red' : 'inherit' }} />
          </IconButton>
        </InputAdornment>

        <TextareaAutosize
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={classes.chatInput}
          placeholder={'write your message'}
          style={{ maxWidth: `${inputWidth}px`, minWidth: `${inputWidth}px` }}
        />

        <ChatEmojiButton
          open={emojiOpen}
          setOpen={setEmojiOpen}
          onChange={(text) => { setInputValue(value => value + text); }} />
      </div>

      <IconButton ref={sendButtonRef} aria-label="send-message" onClick={sendMessageHandler}>
        <SendIcon />
      </IconButton>
    </Grid>
  )
}
