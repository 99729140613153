import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  imageExtensionIcon,
  videoExtensionIcon,
  uploadToCloudIcon,
} from "../../../../catalogue/src/assets";
import { DezinerButton } from '../../../../../components/src/design-system';


const useMainMediaPlaceHolderStyles = makeStyles({
  mainCloudImg: {
    width: '180px',
  },
  dragText1: {
    color: "black", fontSize: "2rem", fontWeight: "bold"
  },
  dragText2: {
    color: "#606062", fontSize: "1.5rem", fontWeight: "bold"
  },
  browseBtn: {
    fontWeight: 'bold',
  },
  dragText3: {
    color: "#bbbbbb", fontSize: "0.9rem"
  },
  dragText4: {
    color: "black", fontSize: "1.1rem",
    maxWidth: 'max-content',
  },
  dragText5: {
    color: '#9e9ca7',
    maxWidth: 'max-content',
  },
  dragIconExt: {
    width: '40px',
  },
});

export function MainMediaPlaceholder({ hideVideo }: { hideVideo?: boolean }) {

  const classes = useMainMediaPlaceHolderStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{
        height: "100%",
        justifyContent: "space-between",
        padding: "20px 0",
      }}
    >

      <span />

      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <img
          src={uploadToCloudIcon}
          alt="upload"
          className={clsx("gray-svg", classes.mainCloudImg)}
        />

        <Typography className={classes.dragText1}>
          {'Drag and Drop an image'}
        </Typography>


        <Grid container alignItems="center" justifyContent="center">
          <Typography className={classes.dragText2}>
            {'or'}
          </Typography>
          <DezinerButton
            color="primary" variant="text" className={classes.browseBtn}>
            {'Browse'}
          </DezinerButton>
          <Typography className={classes.dragText2}>
            {'to choose a file'}
          </Typography>
        </Grid>

        <Typography className={classes.dragText3}>
          {`1600x1200 or larger recommended. Max 10MB each ${hideVideo ? '' : '(20MB for videos)'}`}
        </Typography>
      </Grid>


      <Grid container justifyContent="center">
        <Grid item xs={5} container justifyContent="flex-end">
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs={3} container justifyContent="center" alignItems="center">
              <img src={imageExtensionIcon} className={clsx(classes.dragIconExt, 'gray-svg')} alt="image" />
            </Grid>
            <Grid item xs={8} container direction="column">
              <Typography className={classes.dragText4}>{'High resolutions images'}</Typography>
              <Typography className={classes.dragText5}>{'PNG, JPG, GIF'}</Typography>
            </Grid>
          </Grid>
        </Grid>


        {
          hideVideo ? null :
            <>
              <Divider orientation="vertical" />
              <Grid item xs={5} container justifyContent="flex-start">
                <Grid container>
                  <Grid item xs={3} container justifyContent="center" alignItems="center"> <img src={videoExtensionIcon} className={clsx(classes.dragIconExt, 'gray-svg')} alt="video" /> </Grid>
                  <Grid item xs container direction="column">
                    <Typography className={classes.dragText4}>{'Videos'}</Typography>
                    <Typography className={classes.dragText5}>{'MP4, <60 secs'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
        }
      </Grid>


    </Grid>
  );
}
