import React from "react";
// Customizable Area Start
import ForumSingleController, { Props } from "./ForumSingleController.web";
import { SingleForumPage } from "./single-forum-page";
// Customizable Area End



class ForumSingle extends ForumSingleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <SingleForumPage />
    );
  }
}

// Customizable Area Start
export default ForumSingle;
// Customizable Area End
