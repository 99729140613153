import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIsLoggedIn, useNavigate, useProfile } from '../hooks';
import { DEFAULT_PROFILE_IMAGE } from '../defaults';
import clsx from 'clsx';

const useProfileAvatarStyles = makeStyles({
  avatar: {
    width: '80px',
    height: '80px',
    maxWidth: '80px',
    maxHeight: '80px',
    minWidth: '80px',
    minHeight: '80px',
    borderRadius: '40%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  smallAvatar: {
    width: '35px',
    height: '35px',
    maxWidth: '35px',
    maxHeight: '35px',
    minWidth: '35px',
    minHeight: '35px',
  },
});

export function ProfileAvatar({ id, }: { id?: number, }) {

  const profileQuery = useProfile({ id: id || 0 }, { enabled: Boolean(id) });

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  const isDesigner = profileQuery?.data?.data?.attributes?.role == 'Designer';
  const isUser = profileQuery?.data?.data?.attributes?.role == 'User';
  const nameIsClickable = isLoggedIn && (isDesigner || isUser);

  function nameClickHandler() {
    if (nameIsClickable)
      if (isDesigner)
        navigate(`DesignerHome/${profileQuery?.data?.data?.id}`);
      else if (isUser)
        navigate(`userDesignList/${profileQuery?.data?.data?.id}`);
  }

  return (
    <ProfileAvatarUI
      image={profileQuery?.data?.data?.attributes?.profile_url}
      alt={profileQuery?.data?.data?.attributes?.first_name}
      onClick={nameClickHandler}
    />
  );
}

export function ProfileAvatarUI({ alt, image, color, size, onClick, className, }: {
  image?: string,
  color?: string,
  size?: "small",
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void,
  className?: string,
  alt?: string,
}) {

  const classes = useProfileAvatarStyles();

  const profileImage = image;

  function clickHandler(event: React.MouseEvent<any>) {
    if (onClick) onClick(event);
  }


  if (profileImage) {
    return (<img
      className={clsx(classes.avatar, {
        [classes.smallAvatar]: size == "small"
      }, className)}
      src={profileImage}
      onClick={clickHandler}
      alt={alt}
    />);
  }

  if (color) {
    return (<div
      aria-details="cover"
      role="img"
      className={clsx(classes.avatar, {
        [classes.smallAvatar]: size == "small"
      }, className)}
      onClick={clickHandler}
      style={{
        backgroundColor: color,
      }}
    />);
  }

  return (<img
    className={clsx(classes.avatar, {
      [classes.smallAvatar]: size == "small"
    }, className)}
    onClick={clickHandler}
    src={DEFAULT_PROFILE_IMAGE}
    alt={alt}
  />);
}

