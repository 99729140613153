import { useFormikContext } from 'formik';
import React from 'react';
import { DezinerButton } from '../../../../../components/src/design-system';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import Grid from '@material-ui/core/Grid';

export function SubmitFooter({
  step,
  setStep,
  loading,
}: {
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  loading: boolean,
}) {

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();

  let disabled = false;

  const isPrivate = formik.values.privacy?.toLowerCase() == 'private';
  const hasNoPriceConditions = !Boolean(formik.values.priceConditions.length);

  if (step == 2 && isPrivate && hasNoPriceConditions)
    disabled = true


  return (
    <div className={classes.footerContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.footer}
      >
        {
          step == 2 ?
            <DezinerButton disabled={loading} variant="outlined" color="primary"
              onClick={() => {
                setStep(1);
              }}
            >
              {'Back'}
            </DezinerButton>
            :
            <span />
        }

        <DezinerButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading || disabled}
          loading={loading}
        >
          {step == 1 ? 'Next' : 'Publish'}
        </DezinerButton>
      </Grid>
    </div>
  );
}
