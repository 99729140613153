import { useQueryClient } from "@tanstack/react-query";
import { promoteMessages } from "../messages-generator";
import { designsKeys, promoteKeys } from "../query-keys";
import { useDezinerMutation } from "../ReactQuery.web";

export function useCheckIfPromoted({ id }: { id: string }) {

  return useDezinerMutation<Parameters<typeof promoteMessages.checkIfPromoted>[0]>({
    messageGenerator: promoteMessages.checkIfPromoted,
    queryKey: promoteKeys.checkIfPromoted({ id }),
  });
}

export function useValidateCreditCard() {

  return useDezinerMutation<Parameters<typeof promoteMessages.validateCreditCard>[0]>({
    messageGenerator: promoteMessages.validateCreditCard,
    queryKey: promoteKeys.validateCard(),
  });
}

export function usePromote({ id }: { id: string }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof promoteMessages.promote>[0]>({
    messageGenerator: promoteMessages.promote,
    queryKey: promoteKeys.promote({ id }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId: Number(id) }));
      }
    }
  });
}
