import React from "react";
import UserEditProfileController, {
  Props,
} from "./UserEditProfileController.web";
import EditProfileForm from "../../common/EditProfileForm";
import { useEditProfile, useMyProfile, useUploadProfileImage } from "../../../../../components/src/hooks";


function EditUserProfileForm() {

  const profileQuery = useMyProfile();

  const { mutate: updateProfile, isLoading: editLoading } = useEditProfile();
  const { mutate: uploadProfileImage, isLoading: uploadLoading } = useUploadProfileImage();

  const profileAttributes = profileQuery?.data?.data?.attributes;


  return (
      <EditProfileForm
        onProfileImageUpload={({ profileImage }) => {
          uploadProfileImage({ profileImage });
        }}
        onSubmit={(values) => {
          updateProfile({
            bio: values.bio,
            first_name: values.name,
            location: values.location
          })
        }}
        initialValues={{
          bio: profileAttributes?.bio ?? '',
          name: profileAttributes?.first_name ?? '',
          location: profileAttributes?.location ?? '',
          profileImage: profileAttributes?.profile_url ?? '',
        }}
        submitLoading={editLoading}
        uploadLoading={uploadLoading}
      />
  );
}




class UserEditProfile extends UserEditProfileController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <EditUserProfileForm />
    );
  }
}

export default UserEditProfile;
