import React from 'react';
import MaterialChip from "@material-ui/core/Chip";
import { makeStyles } from '@material-ui/core/styles';

const useChipStyles = makeStyles({
    root: {
        background: '#e8e8e8',
        borderRadius: '6px',
        position: 'relative',
        margin: '2px',
        '& .MuiChip-deleteIcon': {
            position: "absolute",
            top: "-8px",
            right: "-14px",
            color: "black"
        }
    }
});

export function DezinerChip(props: React.ComponentProps<typeof MaterialChip>) {

    const classes = useChipStyles();

    return (
        <MaterialChip  {...props} classes={{ ...classes, ...props?.classes }} />
    )
}

