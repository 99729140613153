import React from 'react';
import { useIsDesigner, useIsLoggedIn, useNavigate, useProfile, useSingleDesign, } from '../../../../../components/src/hooks';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DezinerButton, HideContainer, HorizontalSpacer } from '../../../../../components/src/design-system';
import { FollowDesignerButton } from '../../designer/designer-home/follow-designer-button';
import { SubscribeDesignerButton } from '../../designer/designer-home/subscribe-designer-button';

const useStyles = makeStyles({
  nameActive: {
    cursor: 'pointer'
  },
  button: {
    padding: '0 5px',
    color: 'gray',
    minWidth: 'max-content'
  },
  gray: {
    color: 'gray',
  },
});


export default function DesignTitle({ profileId, designId }: { profileId: number, designId: number }) {

  const classes = useStyles();

  const profileQuery = useProfile({ id: profileId || 0 }, { enabled: Boolean(profileId) });
  const profileAttributes = profileQuery?.data?.data?.attributes;

  const IamDesigner = useIsDesigner();

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  const designerAttr = designAttributes?.designer?.data?.attributes;
  const isDesigner = designerAttr?.role == 'Designer';
  const isUser = designerAttr?.role == 'User';
  const nameIsClickable = isLoggedIn && (isDesigner || isUser);

  const designerId = designerAttr?.id ?? 0;

  function nameClickHandler() {
    if (nameIsClickable)
      if (isDesigner)
        navigate(`DesignerHome/${profileAttributes?.id}`);
      else if (isUser)
        navigate(`userDesignList/${profileAttributes?.id}`);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">
          {designAttributes?.title ?? ''}
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="center">
        <Typography
          className={clsx({ [classes.nameActive]: nameIsClickable })}
          variant="body1"
          onClick={nameClickHandler}
        >
          {profileAttributes?.first_name ?? ''}
        </Typography>
        <HorizontalSpacer value={8} />
        <span className={classes.gray}>{'•'}</span>
        <HorizontalSpacer value={2} />


        <FollowDesignerButton designerId={designerId} designer={profileAttributes}>
          {({ isFollowed, onClick, loading }) => (
            <DezinerButton
              onClick={onClick}
              className={classes.button}
              loading={loading}
              variant="text">
              {isFollowed ? 'Followed' : 'Follow'}
            </DezinerButton>
          )}
        </FollowDesignerButton>

        <HideContainer hideWhen={IamDesigner}>
          <HorizontalSpacer value={2} />
          <span className={classes.gray}>{'•'}</span>
          <HorizontalSpacer value={2} />

          <SubscribeDesignerButton designerId={designerId} designer={profileAttributes}>
            {({ onClick, isSubscribed }) => (
              <DezinerButton
                onClick={onClick}
                className={classes.button}
                variant="text">
                {isSubscribed ? 'Subscribed' : 'Subscribe'}
              </DezinerButton>
            )}
          </SubscribeDesignerButton>
        </HideContainer>


      </Grid>
    </Grid>
  );
}
