import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  notifyArray: any;
  profileData: any;
  selectAll: boolean;
  showAll: boolean;
  showRead: boolean;
  showunRead: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.selectOptionChange = this.selectOptionChange.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    //static data for notification (remove it after integrating api)
    const notify_data = [
      {
        id: 1,
        notification_type: "message",
        notification_title: "Tharanitharan send a new message",
        notification_desc:
          "You can now view and manage your published and scheduled posts and stories within",
        user_name: "Tharanitharan",
        date: "24 Aug 2021",
        time: "09:45 AM",
        status: "unread",
      },
      {
        id: 2,
        notification_type: "comment",
        notification_title: "Arunkumar posted on comment",
        notification_desc:
          "You can now view and manage your published and scheduled posts and stories within",
        user_name: "Arunkumar",
        date: "24 Aug 2021",
        time: "09:45 AM",
        status: "read",
      },
      {
        id: 3,
        notification_type: "invite",
        notification_title: "Arunkumar invited to join a group",
        notification_desc:
          "You can now view and manage your published and scheduled posts and stories within",
        user_name: "Arunkumar",
        date: "24 Aug 2021",
        time: "09:45 AM",
        status: "read",
      },
      {
        id: 4,
        notification_type: "information",
        notification_title: "Paromita Haque set revenue % to you",
        notification_desc:
          "You have 20% revenue for your work on Tex Designs Pvt Ltd",
        user_name: "Paromita Haque",
        date: "24 Aug 2021",
        time: "09:45 AM",
        status: "unread",
      },
    ];

    this.state = {
      token: "",
      profileData: {},
      loading: false,
      notifyArray: notify_data,
      selectAll: false,
      showAll: true,
      showRead: false,
      showunRead: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getProfile();
  }

  //get token
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileData: responseJson.data.attributes });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleChecked(event: any): void {
    this.setState({ selectAll: event.target.checked });
  }

  //read unread and all dropdown selection
  selectOptionChange(event: any): void {
    if (event.target.value === "read") {
      this.setState({ showRead: true });
      this.setState({ showunRead: false });
      this.setState({ showAll: false });
    }
    if (event.target.value === "unread") {
      this.setState({ showunRead: true });
      this.setState({ showRead: false });
      this.setState({ showAll: false });
    }
    if (event.target.value === "all") {
      this.setState({ showunRead: false });
      this.setState({ showRead: false });
      this.setState({ showAll: true });
    }
    console.log(event.target.value, "oooooooooooooooooooo");
  }
  // Customizable Area End
}
