import React from 'react';
import { useSelector } from 'react-redux';
import {
  design as designsIcon,
  editProfileIcon,
  accountSettingsIcon,
  passwordIcon,
  about as aboutIcon,
  logoutIcon,
} from '../../assets';
import { useUpdateCoverImage, useIsMyProfile, useNavigate, useProfile, useLogout } from '../../../../../components/src/hooks';
import { RootState } from '../../../../../components/src/store/store';
import { useParams } from '../../../../../components/src/utils';
import { LabelListing, LabelListingProps, DezinerChangeCoverButton } from '../../../../../components/src/common';
import { HeaderControls, DezinerHeader, } from '../../../../../components/src/layout';


interface Props {
  children: any,
}

export default function UserProfileLayout(props: Props) {

  const navigate = useNavigate();

  const { pathname }: any = useSelector((state: RootState) => state.router.location);
  const params = useParams();
  const userId = Number(params.userId);

  const isMyProfile = useIsMyProfile({ id: userId });
  const profileQuery = useProfile({ id: userId });

  const profileAttributes = profileQuery?.data?.data?.attributes;

  const logout = useLogout();


  const listOptions: LabelListingProps['list'] = [
    {
      id: 1,
      label: 'Designs',
      onClick: () => navigate(`UserDesignList/${profileAttributes?.id}`),
      active: pathname.includes('UserDesignList'),
      icon: designsIcon,
    },
    {
      id: 2,
      label: 'Edit Profile',
      onClick: () => navigate(`UserEditProfile/${profileAttributes?.id}`),
      active: pathname.includes('UserEditProfile'),
      icon: editProfileIcon,
      hide: !isMyProfile,
    },
    {
      id: 3,
      label: 'Account Settings',
      onClick: () => navigate(`UserEditAccount/${profileAttributes?.id}`),
      active: pathname.includes('UserEditAccount'),
      icon: accountSettingsIcon,
      hide: !isMyProfile,
    },
    {
      id: 4,
      label: 'Password',
      onClick: () => navigate(`UserEditPassword/${profileAttributes?.id}`),
      active: pathname.includes('UserEditPassword'),
      icon: passwordIcon,
      hide: !isMyProfile,
    },
    {
      id: 5,
      label: 'About',
      onClick: () => navigate(`UserEditAbout/${profileAttributes?.id}`),
      active: pathname.includes('UserEditAbout'),
      icon: aboutIcon,
    },
    {
      id: 6,
      label: 'Logout',
      onClick: logout,
      active: pathname.includes('LandingPage'),
      icon: logoutIcon,
      hide: !isMyProfile,
    },
  ];

  const { mutate: changeCover, isLoading: changeCoverLoading } = useUpdateCoverImage();

  return (
    <div>


      <DezinerHeader
        vertical
        fallbackColor={profileQuery.data?.data?.attributes?.color ?? ''}
        onClick={() => { }}
        title={profileAttributes?.first_name ?? ''}
        subTitle={profileAttributes?.profile_info ?? ''}
        profileImageURL={profileAttributes?.profile_thumbnail_url ?? ''}
        coverImageURL={profileAttributes?.cover ?? ''}
        loading={profileQuery?.isFetching}
      />

      <HeaderControls>

        <HeaderControls.SideSection flexStart>
          {
            isMyProfile ?
              <DezinerChangeCoverButton
                onChange={(image) => changeCover({ image })}
                loading={changeCoverLoading}
              />
              :
              null
          }
        </HeaderControls.SideSection>

        <HeaderControls.MiddleSection>
          <LabelListing list={listOptions} />
        </HeaderControls.MiddleSection>

      </HeaderControls>



      {props?.children}

    </div>
  );
}
