import { useFormikContext } from 'formik';
import React from 'react';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


export function TimePeriodInput() {

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();

  return (
    <Grid container alignItems="center">
      <Typography className={classes.sectionTitleText2}>
        {'Choose Time Period'}
      </Typography>
      <RadioGroup
        aria-label="choose time period"
        color="primary"
        name="timePeriod"
        value={formik.values.timePeriod}
        onChange={(event) => {
          let timePeriod = event.target.value;
          formik.setFieldValue('timePeriod', timePeriod);
          if (formik.values.priceConditions?.length) {
            formik.setFieldValue('priceConditions', []);
          }
        }}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}
      >
        <FormControlLabel value="Auto" control={<Radio color="primary" />} label="Auto" />
        <FormControlLabel value="Manual" control={<Radio color="primary" />} label="Manual" />
      </RadioGroup>
    </Grid>
  );
}
