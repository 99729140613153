import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DezinerMarkDown } from '../../../../components/src/common';
import { VerticalSpacer, DezinerButton } from '../../../../components/src/design-system';
import { useNavigate } from '../../../../components/src/hooks';
import { searchDesignerStaticContent } from './SearchDesignerStaticContent.web';


const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});

////////////////

export default function SearchStaticPage() {

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'SEARCH DESIGNER'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {''}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 19, 2022'}
          </Typography>
        </Grid>
        <hr />

        <DezinerMarkDown>
          {searchDesignerStaticContent}
        </DezinerMarkDown>

        <VerticalSpacer />

        <Grid container justifyContent="center">
          <DezinerButton
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigate(`/`)}
          >
            {'Search here'}
          </DezinerButton>
        </Grid>

      </div>
    </div>
  );
}
