export const designerKeys = {

  followDesigner: (params: { designerId: number }) => ['follow-designer', params] as const,
  unfollowDesigner: (params: { designerId: number }) => ['unfollow-designer', params] as const,

  fetchDesignerList: () => ['designer-list'] as const,
  fetchDesignerFollowers: (params: { designerId: number }) => ['designer-followers',params] as const,
  fetchSubscriptionPacakges: (params: { subscriptionType: string }) => ['designer-subscription-packages',params] as const,
  subscribePackage: (params: {
    designerId: number,
    planId: number,
    subscribeType: string,
    cardDetails: any
  }) => ['subscribe-package', params] as const,
  fetchNotifications: (params: { filter: string }) => ['get-notifications', params] as const,
}
