import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { CollectionHome, Design, DesignCategory, Group, GroupMember, Label } from "../api-types";
import { groupsMessages, collectionsMessages, labelsMessages } from "../messages-generator";
import { groupsKeys } from "../query-keys/groups";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";

export function useDeclineGroupMemberInvitation({ groupId }: { groupId: number }) {

  return useDezinerMutation<Parameters<typeof groupsMessages.declineInvitation>[0]>({
    messageGenerator: groupsMessages.declineInvitation,
    queryKey: groupsKeys.declineInvitation({ groupId }),
  });
}


export function useAcceptGroupMemberInvitation({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.acceptInvitation>[0]>({
    messageGenerator: groupsMessages.acceptInvitation,
    queryKey: groupsKeys.acceptInvitation({ groupId }),
    options: {
      onSuccess(){
        queryClient.invalidateQueries(groupsKeys.fetchMembers({ groupId }))
        queryClient.invalidateQueries(groupsKeys.fetchNonRespondingMembers({ groupId }));
      }
    }
  });
}

export function useGroupFollowers({ groupId }: { groupId: number }) {

  return useDezinerQuery<{ data: GroupMember[] }>({
    message: groupsMessages.fetchGroupFollowers({ groupId }),
    queryKey: groupsKeys.groupFollowers({ groupId }),
  });
}

export function useUnfollowGroup({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.unfollowGroup>[0]>({
    messageGenerator: groupsMessages.unfollowGroup,
    queryKey: groupsKeys.unfollowGroup({ groupId }),
    options:{
      onSuccess(){
        queryClient.invalidateQueries(groupsKeys.fetchSingleGroups({ groupId }));
        queryClient.invalidateQueries(groupsKeys.followGroup({ groupId }));
      }
    }
  });
}


export function useFollowGroup({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.followGroup>[0]>({
    messageGenerator: groupsMessages.followGroup,
    queryKey: groupsKeys.followGroup({ groupId }),
    options:{
      onSuccess(){
        queryClient.invalidateQueries(groupsKeys.fetchSingleGroups({ groupId }));
        queryClient.invalidateQueries(groupsKeys.groupFollowers({ groupId }));
      }
    }
  });
}


export function useGroupsNonRespondingMembers({ groupId }: { groupId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: GroupMember[] }>({
    message: groupsMessages.fetchNonResponsdingMembers({ groupId }),
    queryKey: groupsKeys.fetchNonRespondingMembers({ groupId }),
    options,
  });
}

export function useCreateGroup() {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.createGroup>[0]>({
    messageGenerator: groupsMessages.createGroup,
    queryKey: groupsKeys.createNewGroup(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchGroups());
      }
    },
  });
}

export function useAddMemberToGroup({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.addMemberToGroup>[0]>({
    messageGenerator: groupsMessages.addMemberToGroup,
    queryKey: groupsKeys.addMemberToGroup({ groupId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchMembers({ groupId }));
        queryClient.invalidateQueries(groupsKeys.fetchNonRespondingMembers({ groupId }));
      },
    }
  });
}


export function useGroups() {

  return useDezinerQuery<{ data: Group[] }>({
    message: groupsMessages.fetchGroups(),
    queryKey: groupsKeys.fetchGroups(),
  });
}

export function useSingleGroup({ groupId }: { groupId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Group }>({
    message: groupsMessages.fetchSingleGroup({ groupId }),
    queryKey: groupsKeys.fetchSingleGroups({ groupId }),
    options,
  });
}

export function useGroupsLabels({ groupId }: { groupId: number }) {

  return useDezinerQuery<{ data: Label[] }>({
    message: labelsMessages.fetchLabels({ groupId }),
    queryKey: groupsKeys.fetchLabels({ groupId }),
  });
}

export function useGroupsCollections(params: { labelId?: number, groupId: number }) {

  return useDezinerQuery<{ data: CollectionHome[] }>({
    message: collectionsMessages.fetchCollections(params),
    queryKey: groupsKeys.fetchCollections(params),
  });
}

export function useGroupsDesignsByCategory(params: {
  category: DesignCategory,
  labelId?: number,
  groupId: number,
}, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Design[] }>({
    message: groupsMessages.fetchGroupsDesignsByCategory(params),
    queryKey: groupsKeys.fetchDesignsByCategory(params),
    options,
  });
}

export function useGroupChangeCover({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.changeCover>[0]>({
    messageGenerator: groupsMessages.changeCover,
    queryKey: groupsKeys.changeCover({ groupId, }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchSingleGroups({ groupId }));
      }
    }
  });
}


export function useGroupsMembers({ groupId }: { groupId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: GroupMember[] }>({
    message: groupsMessages.fetchMembers({ groupId }),
    queryKey: groupsKeys.fetchMembers({ groupId }),
    options,
  });
}


export function useAddGroupMemberAsAdmin({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.addAsAdmin>[0]>({
    messageGenerator: groupsMessages.addAsAdmin,
    queryKey: groupsKeys.addAsAdmin({ groupId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchMembers({ groupId }));
      }
    },
  });
}


export function useRemoveGroupMemberAdminPermission({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.removeAdminPermission>[0]>({
    messageGenerator: groupsMessages.removeAdminPermission,
    queryKey: groupsKeys.removeAdminPermission({ groupId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchMembers({ groupId }));
      }
    },
  });

}


export function useRemoveGroupMember({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.removeMember>[0]>({
    messageGenerator: groupsMessages.removeMember,
    queryKey: groupsKeys.removeMember({ groupId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(groupsKeys.fetchMembers({ groupId }));
      }
    },
  });

}



export function useCreateGroupLabel({ groupId, }: { groupId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof labelsMessages.createLabel>[0]>({
    queryKey: groupsKeys.createLabel({ groupId }),
    messageGenerator: labelsMessages.createLabel,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(groupsKeys.fetchLabels({ groupId }));
      },
    },
  });

}


export function useCreateGroupCollection({ groupId, }: { groupId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.createCollection>[0]>({
    queryKey: groupsKeys.createCollection({ groupId }),
    messageGenerator: collectionsMessages.createCollection,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(groupsKeys.fetchCollections({ groupId }));
      },
    },
  });

}


export function useUpdateGroupInfo({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.updateGroupInfo>[0]>({
    queryKey: groupsKeys.updateInfo({ groupId }),
    messageGenerator: groupsMessages.updateGroupInfo,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(groupsKeys.updateInfo({ groupId }));
      },
    },
  });
}



export function useUpdateGroupSocialProfiles({ groupId }: { groupId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof groupsMessages.updateGroupSocialProfiles>[0]>({
    queryKey: groupsKeys.updateSocialProfiles({ groupId }),
    messageGenerator: groupsMessages.updateGroupSocialProfiles,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(groupsKeys.fetchSingleGroups({ groupId }));
      },
    },
  });
}
