import React, { useState } from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { DesignReply, } from "../../../components/src/api-types";
import { CommentInput } from "./CommentInput";
import { usePostReply, useRepliesToReply } from '../../../components/src/hooks';
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { DezinerSpinner, DezinerButton, HideContainer } from "../../../components/src/design-system";
import { CommentProfilePicture } from "./CommentProfilePicture";
import { CommentHeader } from "./CommentHeader";
import { CommentButtons } from "./CommentButtons";
import { CommentAttachements } from "./CommentAttachments";




const useReplyStyles = makeStyles({
  root: {
    padding: '10px 0',
  },
  replyProfilePicture: {
    width: '59px',
    height: '59px',
    borderRadius: '40%',
    marginRight: '20px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  firstName: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  duration: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  dot: {
    width: '6px',
    height: '6px',
    background: '#515151',
    margin: '0 10px 0 20px',
    borderRadius: '50%'
  },
  commentText: {
    wordBreak: 'break-word',
    color: '#515151',
    fontWeight: 600,
    fontSize: '0.9rem',
    padding: '10px 0',
    maxWidth: '90%',
  },
  attachementsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fileIcon: {
    transform: 'rotate(45deg)',
    color: '#515151',
  },
  oneAttachementText: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  iconContainer: {
    padding: '0 10px', display: 'flex', alignItems: 'center', textDecoration: 'none',
  },
  blackHoverButton: {
    textTransform: 'none',
    fontWeight: 700,
    color: '#979797',
    '&:hover': {
      color: '#515151',
    }
  },
  blackLikeButton: {
    color: '#515151',
  },
});


export function Reply({
  reply,
  allowReply,
  designId,
  parentCommentId,
  onAnonymousInteraction,
}: {
  reply: DesignReply,
  allowReply?: boolean,
  designId: number,
  parentCommentId: number,
  onAnonymousInteraction?: () => void,
}) {

  const [openReply, setOpenReply] = useState(false);

  const [readReplies, setReadReplies] = useState(false);

  const nestedRepliesQuery = useRepliesToReply({ replyId: reply.attributes.id }, { enabled: true });
  const nestedReplies = defaultToEmptyArray<DesignReply>(nestedRepliesQuery.data?.data);
  const loadingNestedReplies = nestedRepliesQuery?.isLoading;


  const { mutate: onReply, isLoading: loadingPostReply } = usePostReply({
    designId,
    parentCommentId: reply.attributes.id,
  });

  const classes = useReplyStyles();



  const replyBody = (
    <div>

      <CommentHeader comment={reply} size="small" />

      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.commentText}>
            {reply?.attributes?.comment}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between" alignItems="flex-start" >

          <HideContainer hideWhen={!allowReply}>
            <HideContainer hideWhen={readReplies}>
              <DezinerButton color="primary" variant="text"
                onClick={() => setReadReplies(true)}
                style={{ color: '#000201', fontWeight: 'bold', }}
              >
                {'View More Reply'}
              </DezinerButton>
            </HideContainer>
          </HideContainer>

          <div style={{ flexGrow: 1 }} />

          <CommentButtons
            onAnonymousInteraction={onAnonymousInteraction}
            comment={reply}
            allowReply={allowReply}
            parentCommentId={parentCommentId}
            setShowReply={setOpenReply}
            showReply={openReply}
            designId={designId}
          />

        </Grid>
      </Grid>

      <CommentAttachements
        comment={reply}
      />

    </div>
  )


  return (
    <div className={classes.root}>

      <Grid container>
        <Hidden smDown>
          <Grid item sm={'auto'} xs={12} justifyContent="center" >
            <CommentProfilePicture size="small" comment={reply} />
          </Grid>
        </Hidden>

        <Grid item sm xs>

          {replyBody}

          <HideContainer hideWhen={!openReply}>
            <CommentInput
              placeholder={`reply to ${reply?.attributes?.repled_by?.data?.attributes?.first_name ?? ''}`}
              hideFileAttachements={true}
              hideVoiceRecorder={true}
              singleLine={true}
              onComment={({ base64Audio, base64Image, commentText }) => {
                onReply({
                  parentCommentId: reply.attributes.id,
                  reply: commentText,
                  replyAudio: base64Audio,
                  replyAttachment: base64Image,
                });
                setOpenReply(false);
              }}
            />
          </HideContainer>



        </Grid>
      </Grid>

      <HideContainer hideWhen={!readReplies}>
        {
          nestedReplies.map((nestedReply) => (
            <div
              key={nestedReply.id}
              style={{ paddingLeft: '20px' }}
            >
              <Reply
                parentCommentId={reply.attributes.id}
                designId={designId}
                reply={nestedReply}
                allowReply={false}
              />
            </div>
          ))
        }
      </HideContainer>

      <HideContainer hideWhen={!(loadingNestedReplies || loadingPostReply)}>
        <DezinerSpinner noHeight size={20} />
      </HideContainer>

    </div>
  );
}

