import React from 'react';
import { Link } from 'react-router-dom';
import NotificationsIcon from "@material-ui/icons/Notifications";
import Hidden from '@material-ui/core/Hidden';
import ChatIcon from "@material-ui/icons/Chat";
import SearchIcon from "@material-ui/icons/Search";
import { dezinerKnotLogo } from '../../../../blocks/landingpage/src/assets';
import { Grid, AppBar, Toolbar, IconButton, Typography, } from '@material-ui/core';
import { useIsLoggedIn, useIsUser, useMyProfile, useNavigate } from '../../../../components/src/hooks';
import { NotLoggedInHeader } from '../../../../components/src/layout/not-authorized-nav';
import { NavProfileAvatar } from '../../../../components/src/layout/nav-profile-avatar';
import { TopNavChatIcon } from '../../../../components/src/layout/chat-icon';

const EMPTY_FUNC = () => {
  // do nothing
};

export function ForumTopNav() {

  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const profileQuery = useMyProfile();
  const profileAttribues = profileQuery?.data?.data?.attributes;
  const isUser = useIsUser();

  if (!isLoggedIn) {
    return (<NotLoggedInHeader
      onLogoClick={() => navigate('LandingPage')}
      onSignInAsDesigner={() => navigate('EmailAccountLoginBlock')}
      onSignInAsUser={() => navigate('EmailAccountLoginBlock')}
    />);
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <Grid container>
          <Link to="/CommunityForum" style={{ color: 'inherit' }}>
            <Typography style={{ fontWeight: 'bold', marginRight: '20px' }}>
              {'Forum'}
            </Typography>
          </Link>
          <Typography style={{ fontWeight: 'bold', marginRight: '20px' }}>
            {'Community'}
          </Typography>
        </Grid>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('LandingPage')}
        />

        <Grid container justifyContent="flex-end">

          <Hidden xsDown>
            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <SearchIcon />
            </IconButton>

            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <NotificationsIcon />
            </IconButton>

            <TopNavChatIcon />

          </Hidden>


          <NavProfileAvatar
            image={profileAttribues?.profile_thumbnail_url ?? ''}
            color={profileAttribues?.color ?? ''}
            designerInfo={{}}
            onClick={() => {
              if (isUser)
                navigate('UserHome')
              else
                navigate(`DesignerHome/${profileAttribues?.id}`)
            }}
            hideMenu={isUser}
          />

        </Grid>

      </Toolbar>
    </AppBar>
  );
}
