import React from 'react';
import { defaultToEmptyArray } from '../../../components/src/default-values';
import { DezinerSpinner } from '../../../components/src/design-system';
import { useParams } from '../../../components/src/utils';
import { makeStyles } from '@material-ui/core/styles';
import FollowerCard from '../../../blocks/Followers/src/FollowerCard';
import { PostedBy } from '../../../components/src/api-types';
import { useGroupFollowers } from '../../../components/src/hooks/groups';


const useStyles = makeStyles({
  followersContainer: {
    padding: '40px 10px',
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    justifyContent: 'space-around',
    alignItems: 'start',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: '100%',
      justifyContent: 'center',
    }
  },
});

export default function GroupFollowersPage() {

  const classes = useStyles();
  const params = useParams();
  const groupId = Number(params.groupId);

  const followersQuery = useGroupFollowers({ groupId });
  const followers = defaultToEmptyArray<PostedBy>(followersQuery?.data?.data);

  if (followersQuery.isLoading)
    return <DezinerSpinner />;


  return (
    <>
      <div className={classes.followersContainer}>
        {
          followers?.map(follower => (
            <FollowerCard key={follower.id} follower={follower} />
          ))
        }
      </div>
    </>
  );
}

