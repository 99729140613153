import React, { useRef, useState } from 'react';
import { useQuery, QueryObserverResult, useMutation, UseBaseMutationResult, useQueryClient, QueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { defineMessageHandlers } from "./utils";

// @ts-ignore
import { Subject } from 'rxjs';

import { BlockComponent } from '../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { IBlock } from '../../framework/src/IBlock';
import { Message } from '../../framework/src/Message';

const configJson = require('./config');

/*
  * help you use react-query useQuery within a class component
  */
function ReactQueryBinder({
  queryKey,
  fn,
  options,
  children,
}: {
  queryKey: Parameters<typeof useQuery>['0'],
  fn: Parameters<typeof useQuery>['1'],
  options: Parameters<typeof useQuery>['2'],
  children: any
}) {

  let queryResult = useQuery(queryKey, fn, { keepPreviousData: true, staleTime: 60 * 1000, ...options });

  return children(queryResult)
}


export function ReactQueryClient({
  children,
}: {
  children: (props: QueryClient) => any
}) {

  let queryResult = useQueryClient();

  return children(queryResult)
}
function ReactQueryMutationBinder({
  queryKey,
  fn,
  options,
  children,
}: {
  queryKey: Parameters<typeof useMutation>['0'],
  fn: Parameters<typeof useMutation>['1'],
  options: Parameters<typeof useMutation>['2'],
  children: any
}) {

  let queryResult = useMutation(queryKey, fn, options);

  return children(queryResult)
}



interface DezinerMutationProps<T extends any> {
  queryKey: Parameters<typeof useMutation>['0'],
  children: (props: UseBaseMutationResult<Partial<T>, unknown>) => any,
  options?: Parameters<typeof useMutation>['2'],
}


interface DezinerQueryProps<T extends any> {
  queryKey: Parameters<typeof useQuery>['0'],
  children: (props: QueryObserverResult<{ data: Partial<T>, [key: string]: any }, unknown>) => any,
  messageGenerator: () => Message,
  options?: Parameters<typeof useQuery>['2'],
}

/*
 * let you use react-query `useQuery` with builder.ai structure so you can 
 * create a message and receive the response from the run engine which will be 
 * abstracted for the end user of this component
*/
export class DezinerQuery<T> extends BlockComponent<DezinerQueryProps<T>, any, any>{

  messageId: string;
  messageSubject = new Subject();

  constructor(props: DezinerQueryProps<T>) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  sendQueryMsg = (msg: Message) => {
    return new Promise((resolve, reject) => {

      this.messageId = msg.messageId;

      runEngine.sendMessage(msg.id, msg);

      this.messageSubject.subscribe({
        next: resolve,
        error: reject,
      });
    });
  }


  receive(from: string, message: Message) {
    defineMessageHandlers({
      message,
      handlers: {
        [this.messageId]: {
          onSuccess: (response: any) => {
            this.messageSubject.next(response);
          },
          onError: (error: any) => {
            this.messageSubject.error(error);
          },
        }
      }
    });
  }


  render() {
    return (
      <ReactQueryBinder
        queryKey={this.props.queryKey}
        fn={() => this.sendQueryMsg(this.props.messageGenerator())}
        options={this.props.options}
      >
        {(query: any) => (
          <>
            {
              this.props.children ?
                this.props?.children(query)
                :
                null
            }
          </>
        )}
      </ReactQueryBinder>
    );
  }

}

export class DezinerMutation<T> extends BlockComponent<DezinerMutationProps<T>, any, any>{

  messageId: string;
  messageSubject = new Subject();

  constructor(props: DezinerMutationProps<T>) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  sendQueryMsg = (msg: Message) => {
    return new Promise((resolve, reject) => {

      this.messageId = msg.messageId;

      runEngine.sendMessage(msg.id, msg);

      this.messageSubject.subscribe({
        next: resolve,
        error: reject,
      });
    });
  }


  receive(from: string, message: Message) {
    defineMessageHandlers({
      message,
      handlers: {
        [this.messageId]: {
          onSuccess: (response: any) => {
            this.messageSubject.next(response.data);
          },
          onError: (error: any) => {
            this.messageSubject.error(error);
          },
        }
      }
    });
  }


  render() {
    return (
      <ReactQueryMutationBinder
        queryKey={this.props.queryKey}
        fn={(message: any) => this.sendQueryMsg(message)}
        options={this.props.options}
      >
        {(query: any) => (
          <>
            {
              this.props.children ?
                this.props?.children(query)
                :
                null
            }
          </>
        )}
      </ReactQueryMutationBinder>
    );
  }

}

export function useDezinerInfiniteQuery<T extends any>({
  messageGenerator,
  queryKey,
  options,
  defaultPageParam,
}: {
  messageGenerator: (params: any) => Message,
  queryKey: Parameters<typeof useInfiniteQuery>['0'],
  options?: Parameters<typeof useInfiniteQuery>['2'],
  defaultPageParam: any,
}) {

  const [messageId, setMessageId] = useState('');
  const subject = useRef<Subject>(new Subject());

  function send(message: Message) {
    runEngine.sendMessage(message.id, message);
  }

  function receive(_from: string, receivedMessage: Message) {
    defineMessageHandlers({
      message: receivedMessage,
      handlers: {
        [messageId]: {
          onSuccess: (response) => {
            subject.current.next(response);
          },
          onError: (error) => {
            subject.current.error(error);
          },
        },
      }
    });
  }

  runEngine.attachBuildingBlock({ send, receive }, [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionSaveMessage),
    getName(MessageEnum.SessionResponseMessage),
  ]);

  async function dataFetcher({ pageParam }: any): Promise<T> {

    return new Promise((resolve, reject) => {

      let sentMessage = messageGenerator(pageParam ? pageParam : defaultPageParam);

      setMessageId(sentMessage.messageId);
      send(sentMessage);

      subject.current.subscribe({
        next: resolve,
        error: reject,
      })

    });
  }

  return useInfiniteQuery<T>(queryKey, dataFetcher, options as any);
}
export function useDezinerQuery<T extends any>({
  message,
  queryKey,
  options,
}: {
  message: Message,
  queryKey: Parameters<typeof useQuery>['0'],
  options?: Parameters<typeof useQuery>['2'],
}) {

  const subject = useRef<Subject>(new Subject());

  function send(message: Message) {
    runEngine.sendMessage(message.id, message);
  }

  function receive(_from: string, receivedMessage: Message) {
    defineMessageHandlers({
      message: receivedMessage,
      handlers: {
        [message.messageId]: {
          onSuccess: (response) => {
            subject.current.next(response);
          },
          onError: (error) => {
            subject.current.error(error);
          },
        },
      }
    });
  }

  runEngine.attachBuildingBlock({ send, receive }, [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionSaveMessage),
    getName(MessageEnum.SessionResponseMessage),
  ]);

  async function dataFetcher(): Promise<T> {
    return new Promise((resolve, reject) => {
      send(message);
      subject.current.subscribe({
        next: resolve,
        error: reject,
      })

    });
  }

  return useQuery<T>(queryKey, dataFetcher, options as any);
}

export function useDezinerMutation<MutationVariables extends any>({
  messageGenerator,
  queryKey,
  options,
}: {
  messageGenerator: (...args: any) => Message,
  queryKey: Parameters<typeof useMutation>['0'],
  options?: Parameters<typeof useMutation>['2'],
}) {

  const [messageId, setMessageId] = useState('');
  const subject = useRef<Subject>(new Subject());

  function send(message: Message) {
    runEngine.sendMessage(message.id, message);
  }

  function receive(_from: string, receivedMessage: Message) {
    defineMessageHandlers({
      message: receivedMessage,
      handlers: {
        [messageId]: {
          onSuccess: (response) => {
            subject.current.next(response);
          },
          onError: (error) => {
            subject.current.error(error);
          },
        },
      }
    });
  }

  runEngine.attachBuildingBlock({ send, receive }, [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionSaveMessage),
    getName(MessageEnum.SessionResponseMessage),
  ]);

  async function dataMutator(params: MutationVariables): Promise<any> {
    return new Promise((resolve, reject) => {

      subject.current = new Subject();

      let message = messageGenerator(params);

      setMessageId(message.messageId);
      send(message);

      subject.current.subscribe({
        next: resolve,
        error: reject,
      })

    });
  }

  return useMutation<any, any, MutationVariables>(queryKey, dataMutator, options as any);
}


