import React, { useMemo, useState } from "react";
// Customizable Area Start
import { chat } from "./assets";
import CreateIcon from "@material-ui/icons/Create";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CreateForumForm from "./CreateForumForm.web";
import { VerticalSpacer, DezinerButton, DezinerSpinner, HideContainer } from "../../../components/src/design-system";
import { makeStyles } from '@material-ui/core/styles';
import { useForums, useCreateForum, useIsLoggedIn } from "../../../components/src/hooks";
import { Forum } from "../../../components/src/api-types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ForumCardContainer } from "./forum-card-container";
import { useDispatch } from "react-redux";
import { openNeedLoginDialog } from "../../../components/src/store/ui/ui.actions";
// Customizable Area End


const useStyles = makeStyles({
  root: {
    background: '#f5f6f8',
    flexGrow: 1,
  },
  contentContainer: {
    margin: '20px auto',
    maxWidth: '1100px',
    padding: '0 20px',
  },
  gridContainer: {
    //minHeight: '50vh',
    display: 'block',
    columnCount: 2,
    columnGap: '20px',
    ['@media screen and (max-width: 768px)']: {
      columnCount: 1,

    }
  },

});


export function DezinerknotForum() {

  const classes = useStyles();

  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();

  function onAnonymousInteraction() {
    dispatch(openNeedLoginDialog({
      redirectURLAfterLogin: 'CommunityForum',
      redirectParamsAfterLogin: '',
    }));
  }

  const forumsQuery = useForums();
  const { mutate: createForum, isLoading: createForumLoading } = useCreateForum();

  const isLoggedIn = useIsLoggedIn();

  const weightSum = (weight: number, forum: Forum) => {
    return weight + (forum.attributes.media ? 2 : 1)
  }

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const [forums1, forums2] = useMemo(() => {

    if (isSmallScreen) return [[], []];

    return forumsQuery?.data?.data?.reduce((result: [Forum[], Forum[]], forum) => {

      const [_forums1, _forums2]: [Forum[], Forum[]] = result;
      const _forums1Weight = _forums1.reduce(weightSum, 0)
      const _forums2Weight = _forums2.reduce(weightSum, 0)

      if (_forums1Weight <= _forums2Weight) {
        return [_forums1.concat(forum), _forums2];
      }
      else {
        return [_forums1, _forums2.concat(forum)];
      }

    }, [[], []]) ?? [[], []];


  }, [isSmallScreen, forumsQuery?.data?.data]);

  const discussionIcon = (
    <Grid item sm={4} xs={6} container alignItems="center" wrap="nowrap">
      <img src={chat} id="chat_icon" />
      <Typography style={{ margin: '0 10px', fontSize: '1.2rem', fontWeight: 'bold' }}>
        {'Discussions'}
      </Typography>
    </Grid>
  );

  const startNewTopic = (
    <Grid item sm={4} xs={12} container justifyContent="center">
      <DezinerButton
        onClick={() => {
          if (isLoggedIn)
            setShowForm(true)
          else
            onAnonymousInteraction();
        }}
        color="primary"
        variant="contained"
        rounded
        startIcon={
          <CreateIcon />
        }
      >
        {'Start a new topic'}
      </DezinerButton>
    </Grid>
  );


  const sortByDropdown = (
    <Grid item sm={4} xs={6} container justifyContent="flex-end">
      <FormControl variant="outlined" >
        <Select value="Sort by : Popular" style={{ maxHeight: '30px' }}>
          <MenuItem value="Sort by : Popular">
            {'Sort by : Popular'}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );


  return (
    <div className={classes.root}>


      <div className={classes.contentContainer}>

        <VerticalSpacer />
        <VerticalSpacer />

        {/* Forum subheader start */}
        <Grid
          direction={isSmallScreen ? 'column' : 'row'}
          container justifyContent="space-between" alignItems="center">

          {
            isSmallScreen ?
              <>
                <Grid item container justifyContent="space-between" alignItems="center">
                  {discussionIcon}
                  {sortByDropdown}
                </Grid>
                <VerticalSpacer />
                {startNewTopic}
              </>
              :
              <>
                {discussionIcon}
                {startNewTopic}
                {sortByDropdown}
              </>
          }

        </Grid>

        <VerticalSpacer />


        {
          showForm ?
            <CreateForumForm
              onSubmit={(values) => {
                createForum(values, {
                  onSuccess: () => {
                    setShowForm(false);
                  }
                })
              }}
              onCancel={() => {
                setShowForm(false);
              }}
              loading={createForumLoading}
            />
            :
            <VerticalSpacer />
        }

        <HideContainer hideWhen={!forumsQuery?.isLoading}>
          <DezinerSpinner />
        </HideContainer>

        <div style={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen ? '100%' : '48% 48%',
          justifyContent: 'space-between',
        }}>

          {isSmallScreen ?
            <div>
              {
                forumsQuery?.data?.data?.map((forum) => (
                  <ForumCardContainer
                    key={forum.id}
                    forum={forum}
                    onAnonymousInteraction={onAnonymousInteraction}
                  />
                )) ?? []
              }
            </div>
            :
            <>
              <div>
                {
                  forums1?.map((forum) => (
                    <ForumCardContainer
                      key={forum.id}
                      forum={forum}
                      onAnonymousInteraction={onAnonymousInteraction}
                    />
                  )) ?? []
                }
              </div>
              <div>
                {
                  forums2?.map((forum) => (
                    <ForumCardContainer
                      key={forum.id}
                      forum={forum}
                      onAnonymousInteraction={onAnonymousInteraction}
                    />
                  )) ?? []
                }
              </div>
            </>
          }
        </div>


      </div>
    </div>
  );
}


