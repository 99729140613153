import React from 'react';
import { Grid, } from '@material-ui/core';
import { DezinerLabel, FormikTextInput } from '../../../../../components/src/design-system';
import { headerIcon, } from "../../../../catalogue/src/assets";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  smallIcon: {
    maxWidth: '18px',
    marginRight: '5px',
  },
});

export function TitleInput() {

  const classes = useStyles();


  return (
    <>
      <Grid container alignItems="center">
        <img src={headerIcon} alt="" className={classes.smallIcon} style={{ maxWidth: '14px' }} />
        <DezinerLabel>
          {'Title'}
        </DezinerLabel>
      </Grid>
      <FormikTextInput
        name="title"
        variant="outlined"
      />
    </>
  );
}
