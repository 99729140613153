import React, { useEffect } from 'react';
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { withRouter } from 'react-router-dom';
import { useDecodedToken, useIsMyProfile, useNavigate, useSingleDesign } from '../hooks';
import * as R from 'ramda';
import moment from 'moment';


export function formatProfileAboutDate(date?: string) {
  try {
    if (!date) return '';
    return moment(date).format('DD-MM-YYYY');
  } catch (error) {
    console.error(error);
    return '';
  }
}

export const removeNilValues = R.filter(R.compose(R.not, R.isNil));

export function defaultValuesTo<T>(defaultData: any): (p?: T) => Required<T> {
  // @ts-ignore 
  return R.compose(R.mergeRight(defaultData), removeNilValues, R.defaultTo({}))
}


export function errorMerger(result: any, errorObj: any) {
  return {
    ...result,
    ...errorObj
  }
}



export const RouterContext = React.createContext<any>({});

export function _RouterContextProvider(props: any) {

  return (
    <RouterContext.Provider value={{ ...props.match.params }}>
      {props?.children}
    </RouterContext.Provider>
  );
}

export const RouterContextProvider = (withRouter as any)(_RouterContextProvider);



export function useParams() {

  let params = React.useContext(RouterContext);

  return params || {};
}




export function IsMyProfileGuard({ children }: any) {

  const params = useParams();
  const navigate = useNavigate();

  const isMyProfile = useIsMyProfile({ id: params.designerId });

  if (!isMyProfile) {
    navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}

/*
* used only for users how has old(expired) tokens
* if they have a previous token and it's invalid we require them 
* to re login again,
* if they don't have a token yet, they can navigate however they want until
* they access a page that require login or they do login by themselves
*/
export function RequireValidTokenContext({ children }: any) {

  const { exp } = useDecodedToken();
  const navigate = useNavigate();

  useEffect(function redirectIfInvalid() {
    if (exp) {
      const isInvalid = (new Date().getTime() / 1000) > exp;
      if (isInvalid) {
        localStorage.clear();
        navigate("EmailAccountLoginBlock");
      }
    }
  }, [exp]);

  return children;
}

export function LoginGuard({ children, ...props }: any) {

  let token = localStorage.getItem('token');

  if (!token) {
    props.navigation.navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}

export function DesignOwnerOnlyCanAccessGuard({ children, ...props }: any) {

  const { id: userId } = useDecodedToken();

  const params = useParams();
  const designId = parseInt(params.designId || '0');

  const singleDesignQuery = useSingleDesign({ designId });
  const ownerId = singleDesignQuery?.data?.design?.data?.attributes?.designer?.data?.attributes?.id ?? 0;

  const isTheOwner = singleDesignQuery?.isSuccess ? Boolean(ownerId == userId) : true;

  if (!designId || !userId || !isTheOwner) {
    props.navigation.navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}



const makeWrapper = (component: any, wrapper: any) =>
  (props: any) => React.createElement(wrapper, props, React.createElement(component, props));

export function DezinerPageWrapper({ page, layouts, guards, providers }: any) {

  let pageGuards = guards || [];
  let pageLayouts = layouts || [];
  let pageProviders = providers || [];

  let wrappers = pageLayouts.concat(pageGuards, pageProviders);

  const pageComponent = (props: any) => React.createElement(page, props);

  return wrappers.reduce(makeWrapper, pageComponent);
}



export function appendToken(headers: any, token?: string) {
  if (headers && !headers?.['token']) {
    let _token = token || localStorage.getItem('token') || '';
    if (_token)
      headers['token'] = _token;
  }

  return headers;
}
export function appendJsonContentType(headers: any) {
  if (headers && !headers?.['Content-Type'])
    headers['Content-Type'] = 'application/json';

  return headers;
}

export function extractAPIResponse(message: Message): {
  messageId: string | undefined,
  responseJson: any | undefined,
  errors: any | undefined
} {

  try {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiError = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const errors = apiError || responseJson?.error || responseJson?.errors || undefined;

      let messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      return {
        responseJson: errors ? undefined : responseJson,
        errors,
        messageId
      }
    }

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  } catch (error) {
    console.error(error);

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  }
}








interface MessageHandlersParams {
  message?: Message,
  handlers: {
    [id: string]: {
      onSuccess?: (response: any) => void,
      onError?: (errors: any) => void,
      afterAll?: (response: any) => void,
    }
  }
}

export function defineMessageHandlers(params: MessageHandlersParams) {


  const { handlers, message } = params;

  if (!message) return;

  const { responseJson, errors, messageId } = extractAPIResponse(message);

  if (messageId && handlers[messageId]) {

    const { onSuccess, onError, afterAll } = handlers[messageId];

    if (errors && onError)
      onError(errors);
    else if (responseJson && onSuccess)
      onSuccess(responseJson);

    if (afterAll)
      afterAll(responseJson)
  }
}




export function extractRedirectionInfo() {

  let redirectUrl = localStorage.getItem("redirectUrl") || "";
  let redirectParams = localStorage.getItem("redirectParams") || "";

  try {
    redirectParams = redirectParams ?
      JSON.parse(redirectParams)
      :
      {};
  } catch (error) {
    console.error(error);
  }

  localStorage.setItem("redirectUrl", "");
  localStorage.setItem("redirectParams", "");

  return [redirectUrl, redirectParams];
}


export async function urlToBase64(url: string) {
  return new Promise((resolve) => {

    let xhr = new XMLHttpRequest();

    xhr.onload = function() {
      let reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}


export function statisticsFormatter(value: number, digits: number = 2) {

  const statsFormats = [
    { value: 1, symbol: " " },
    { value: 1e3, symbol: " k" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " G" },
    { value: 1e12, symbol: " T" },
    { value: 1e15, symbol: " P" },
    { value: 1e18, symbol: " E" }
  ];
  const extraNumbersRegExp = /\.0+$|(\.\d*[1-9])0+$/;

  let formatterItem = statsFormats
    .slice()
    .reverse()
    .find(item => value >= item.value);

  return formatterItem ?
    (value / formatterItem.value)
      .toFixed(digits)
      .replace(extraNumbersRegExp, "$1") + formatterItem.symbol
    :
    "0";
}


export async function blobToBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {

    if (!file) reject(null);

    let reader = new FileReader();

    reader.onloadend = function onloadend() {
      let base64 = reader.result;
      resolve(base64 as string);
    };

    reader.readAsDataURL(file);
  });
}

