import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DEFAULT_PROFILE_IMAGE } from '../../../../components/src/defaults';
import { VerticalSpacer } from '../../../../components/src/design-system';


const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});

////////////////

interface HowItWorkItem {
  image: string,
  title: string,
  description: string,
  alignImage: 'left' | 'right',
}

const howItWorksList: HowItWorkItem[] = [
  {
    title: 'Register as a designer ',
    description: 'Sign up as a designer - get access to plenty of service - be part of groups and clubs - set up your profile…',
    image: DEFAULT_PROFILE_IMAGE,
    alignImage: 'left',
  },
  {
    title: 'Post your project',
    description: 'Create collections - add designs – categorize based on colour, theme and so on….',
    image: DEFAULT_PROFILE_IMAGE,
    alignImage: 'left',
  },
  {
    title: 'Chat with clients ',
    description: 'Find customers who are interested in your designs – connect, discuss and strike a deal ',
    image: DEFAULT_PROFILE_IMAGE,
    alignImage: 'right',
  },
  {
    title: 'Deliver and get paid',
    description: 'Users download or buy designs by completing the payment – give rating and feedback on their experience',
    image: DEFAULT_PROFILE_IMAGE,
    alignImage: 'right',
  },
];


export default function HowItWorksPage() {

  const classes = useStyles();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'HOW IT WORKS'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {'This is how Deziner\'s Knot works'}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {''}
          </Typography>
        </Grid>
        <hr />

        <Grid container>
          {
            howItWorksList.map((item) => (
              <HowItWorkCard
                key={item.title}
                howItWorkItem={item}
              />
            ))
          }
        </Grid>


      </div>
    </div>
  );
}

////////// 

const useCardStyles = makeStyles({
  image: {
    maxWidth: '100%',
  },
  container: {
    padding: '20px 0',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  description: {

  },
});

function HowItWorkCard({ howItWorkItem, }: { howItWorkItem: HowItWorkItem, }) {

  const classes = useCardStyles();
  const { image, title, description, alignImage } = howItWorkItem;

  return (
    <Grid
      className={classes.container}
      container
      direction={alignImage == 'right' ? 'row-reverse' : 'row'}
      item sm={6} xs={12}>

      <Grid item xs={4}>
        <img
          src={image}
          alt={title}
          className={classes.image}
        />
      </Grid>

      <Grid item xs={1}/>

      <Grid item xs>
        <Typography className={classes.title}>
          {title}
        </Typography>
        <VerticalSpacer />
        <Typography className={classes.description}>
          {description}
        </Typography>
      </Grid>

      <Grid item xs={1}/>

    </Grid>
  );

}


