import React, { useState } from 'react';
import MaterialAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { HorizontalSpacer } from '../spacer';
import { DezinerButton } from '../button';
import { DezinerChip, DezinerChipVariant } from '../chip';


const AutocompleteTextInput = withStyles({
  root: {
    margin: '5px 0',
    padding: '0 5px',
    '& input': {
      height: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-root': {
      borderRadius: '6px',
      padding: '2px 8px',
    }
  }
})(TextField);


const SingleAutocompleteTextInput = withStyles({
  root: {
    margin: '5px 0',
    padding: '0 5px',
    '& input': {
      height: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-root': {
      height: '40px',
      maxHeight: '40px',
      borderRadius: '6px',
      padding: '2px 8px',
    }
  }
})(TextField);


export interface AutocompleteSelectorItem { value: number, label: string }

export function AutocompleteSelectorInput({
  options,
  inputValue,
  onChange,
  placeholder,
  autocompleteProps,
  label,
}: {
  inputValue: AutocompleteSelectorItem[],
  options: AutocompleteSelectorItem[],
  onChange: (value: AutocompleteSelectorItem[]) => void,
  placeholder: string,
  autocompleteProps?: Partial<React.ComponentProps<typeof MaterialAutocomplete>>,
  label?: string,
}) {

  const [tempArray, setTempArray] = useState([]);

  return (
    <MaterialAutocomplete
      fullWidth
      options={
        options
          .filter((_member: any) => !inputValue.find((member: any) => member.value == _member.value))
      }
      aria-label={label}
      getOptionLabel={(option: any) => option.label}
      value={[...tempArray]}
      filterSelectedOptions
      multiple
      onChange={(event, value: any, reason) => {
        if (value)
          setTempArray(value);
      }}
      renderInput={(params: any) => (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <AutocompleteTextInput  {...params} variant="outlined" placeholder={placeholder} />
          <HorizontalSpacer />
          <DezinerButton color="primary" variant="contained"
            onClick={() => {
              if (tempArray.length) {
                let oldMembers = inputValue ?? [];
                onChange(oldMembers.concat(tempArray as any));
                setTempArray([]);
              }
            }}
          >
            {'Add'}
          </DezinerButton>
        </Grid>
      )}
      renderTags={(values) => {
        return (values as AutocompleteSelectorItem[])
          ?.filter((tagMember) => !inputValue.find((member: any) => member.value == tagMember.value))
          ?.map(tagMember =>
            <DezinerChipVariant
              style={{ maxWidth: '150px' }}
              label={tagMember.label}
              key={tagMember.value}
              onDelete={() => {
                setTempArray(oldState => oldState.filter((member: any) => member.value != tagMember.value))
              }}
            />)
      }}
      {...autocompleteProps}
    />
  )
}


export function AutocompleteSelectorInputVariant({
  options,
  inputValue,
  onChange,
  placeholder,
  autocompleteProps,
}: {
  inputValue: AutocompleteSelectorItem[],
  options: AutocompleteSelectorItem[],
  onChange: (value: AutocompleteSelectorItem[]) => void,
  placeholder: string,
  autocompleteProps?: Partial<React.ComponentProps<typeof MaterialAutocomplete>>,
}) {


  return (
    <MaterialAutocomplete
      freeSolo
      fullWidth
      options={options}
      getOptionLabel={(option: any) => option.label}
      value={[...inputValue]}
      filterSelectedOptions
      multiple
      onChange={(event, value: any, reason) => {
        if (value) {
          onChange(value);
        }
      }}
      renderInput={(params: any) => (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <AutocompleteTextInput  {...params} variant="outlined" placeholder={placeholder} style={{ padding: 0, margin: 0 }} />
        </Grid>
      )}
      renderTags={(values) => {
        return (values as AutocompleteSelectorItem[])
          ?.map(tagMember =>
            <DezinerChip
              style={{ maxWidth: '150px', margin: '10px 5px', textTransform: 'capitalize' }}
              label={tagMember.label?.toLowerCase()}
              key={tagMember.value}
              onDelete={() => {
                onChange(inputValue.filter((member) => member.value != tagMember.value))
              }}
            />)
      }}
      {...autocompleteProps}
    />
  )
}

export function AutocompleteDropdown({
  options,
  inputValue,
  onChange,
  placeholder,
  autocompleteProps,
}: {
  inputValue: AutocompleteSelectorItem | undefined,
  options: AutocompleteSelectorItem[],
  onChange: (value: AutocompleteSelectorItem) => void,
  placeholder: string,
  autocompleteProps?: Partial<React.ComponentProps<typeof MaterialAutocomplete>>,
}) {


  return (
    <MaterialAutocomplete
      fullWidth
      options={options}
      getOptionLabel={(option: any) => option.label}
      filterOptions={(options: any[], state) => {
        return options.filter(option => Boolean(option.label.length) && option.label.toLowerCase().includes(state.inputValue.toLowerCase()))
      }}
      getOptionSelected={(option: any, value) => option.value == value.value}
      value={inputValue}
      multiple={false}
      onChange={(event, value: any, reason) => {
        if (value) {
          onChange(value);
        }
      }}
      renderInput={(params: any) => (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <SingleAutocompleteTextInput  {...params} variant="outlined" placeholder={placeholder} style={{ padding: 0, margin: 0 }} />
        </Grid>
      )}
      noOptionsText="No option found"
      {...autocompleteProps}
    />
  )
}


