import React, { useState } from "react";
// Customizable Area Start
import CatalogueController, { Props } from "./CatalogueController.web";
import { CollectionHome } from "../../../components/src/api-types";
import { useCreateCollection, useDesignerCollections, useLabels, useNavigate, usePinCollection } from "../../../components/src/hooks";
import { useParams } from "../../../components/src/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../components/src/store/store";
import { DezinerSpinner } from "../../../components/src/design-system";
import { DezinerCollectionList, CreateCollectionForm, PromoteDialog } from "../../../components/src/common";
// Customizable Area End


class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End


  render() {

    return (
      <div style={{ padding: '0 40px', minHeight: '50vh', }}>

        <CreateCollectionFormContainer />

        <CatalogueContent />

      </div>
    );
  }
}


function CreateCollectionFormContainer() {

  const params = useParams();
  const designerId = parseInt(params.designerId);

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  const labelsQuery = useLabels({ designerId });
  const hasLabels = Boolean(labelsQuery?.data?.data?.length ?? 0);

  const { mutate: createCollection, isLoading: createCollectionLoading } = useCreateCollection({
    labelId,
    designerId,
  });

  return (
    <CreateCollectionForm
      selectedALabel={Boolean(labelId)}
      onSubmit={(values) => {
        createCollection({ ...values, labelId });
      }}
      labelLoading={labelsQuery.isLoading}
      hasLabels={hasLabels}
      loading={createCollectionLoading}
    />
  );
}



function CatalogueContent() {

  const params = useParams();
  const designerId = parseInt(params.designerId);

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  const collectionsQuery = useDesignerCollections({ labelId, designerId, category: 'collections' });

  const loading = collectionsQuery?.isLoading ?? false;
  const designs = collectionsQuery?.data?.data ?? [];

  const navigate = useNavigate();
  const { mutate: pinCollection } = usePinCollection({ designerId, labelId });

  const [promoteDialog, setPromoteDialog] = useState<{
    id: number,
    open: boolean,
    mediaSrc: string,
    mediaType: 'video' | 'image',
  }>({
    id: 0,
    open: false,
    mediaSrc: '',
    mediaType: 'image',
  });

  if (loading)
    return <DezinerSpinner />

  if (!designs.length)
    return <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>No Data</h2>
    </div>

  return (
    <>

      <PromoteDialog
        id={String(promoteDialog.id)}
        promoteType="Collection"
        onClose={() => setPromoteDialog({ id: 0, open: false, mediaType: 'image', mediaSrc: '' })}
        open={promoteDialog.open}
        mediaProps={{
          id: String(promoteDialog.id),
          src: promoteDialog.mediaSrc,
          mediaType: promoteDialog.mediaType,
        }}
      />


      <div>
        {
          (designs as CollectionHome[])?.map((item) =>
            <DezinerCollectionList
              key={item.id}
              onDesignClick={(design) => {
                navigate(`DesignView/${design.id}`);
              }}
              collection={item}
              onCreate={() => {
                navigate(`DesignerDesignUpload/${labelId}/${item.attributes.id}/designer/0`);
              }}
              onPin={() => pinCollection({ pin: !item.attributes.pined, collectionId: item.attributes.id })}
              onPromote={({ collectionId }) => {
                setPromoteDialog({
                  id: collectionId,
                  mediaType: item?.attributes?.designs?.data?.[0]?.attributes?.main_image_type?.includes('video') ? 'video' : 'image',
                  mediaSrc: item?.attributes?.designs?.data?.[0]?.attributes?.image_url,
                  open: true,
                });
              }}
              showAddSlots
            />
          )
        }
      </div>

    </>
  );
}



// Customizable Area Start
export default Catalogue;
// Customizable Area End

