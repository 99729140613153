import React, { useState, } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import {
  CometChatGlobalConversation,
} from './types';
import Skeleton from '@material-ui/lab/Skeleton';
import { OnConversationClick } from './ConversationListContainer';
import ConversationTitle, { useMainChatContentStyles } from './ConversationTitle';


export default function ConversationList({
  loading,
  conversations,
  onConversationClick,
}: {
  loading: boolean,
  conversations: CometChatGlobalConversation[]
  onConversationClick: OnConversationClick
}) {

  const classes = useMainChatContentStyles();

  const [searchValue, setSearchValue] = useState('');


  return (
    <div>

      <TextField
        fullWidth
        style={{
          border: 'none',
          background: '#CBCCCE',
          display: 'block',
          borderRadius: '6px 6px 0px 0px',
        }}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        variant="outlined"
        placeholder="Search"
        InputProps={{
          classes: { notchedOutline: classes.noBorder },
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />


      <div className={classes.contactsContainer}>

        {
          loading ?
            Array(8).fill(0).map((_x, index) =>
              <Skeleton
                aria-label="chat-conversation-loading"
                key={index}
                variant="rect"
                width={'100%'}
                height={40}
                style={{ margin: '5px 0' }} />
            )
            :
            null
        }
        {
          conversations
            .filter(item => {
              if (searchValue)
                return item.conversationWith?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
              return true;
            })
            .map((item) =>
              <ConversationTitle
                key={item.conversationId}
                item={item}
                onConversationClick={onConversationClick}
              />
            )
        }
      </div>


    </div>
  )
}
