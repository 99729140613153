import React from "react";
import AccountSettingForm from "../../common/AccountSettingsForm";
import UserEditAccountController, {
  Props,
} from "./UserEditAccountController.web";

class UserEditAccount extends UserEditAccountController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
        <AccountSettingForm />
    );
  }
}

export default UserEditAccount;
