import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from '@material-ui/core/Typography';
import { DezinerAudio, HideContainer } from "../../../components/src/design-system";
import { DesignComment, DesignReply } from "../../../components/src/api-types";


const useCommentAttachementsStyles = makeStyles({
  attachementsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fileIcon: {
    transform: 'rotate(45deg)',
    color: '#515151',
  },
  oneAttachementText: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  iconContainer: {
    padding: '0 10px',
    display: 'flex', alignItems: 'center',
    textDecoration: 'none',
  },
});

export function CommentAttachements(props: { comment: DesignComment | DesignReply }) {

  const classes = useCommentAttachementsStyles();

  const comment = props.comment.attributes;

  return (
    <div className={classes.attachementsContainer}>

      <HideContainer hideWhen={!comment.audio_urls?.[0]?.url}>
        <DezinerAudio
          src={comment.audio_urls?.[0]?.url}
        />
      </HideContainer>

      <HideContainer hideWhen={!comment?.attachments_url?.[0]?.url}>
        <a
          target="_blank"
          href={comment?.attachments_url?.[0]?.url}
          className={classes.iconContainer}
        >
          <IconButton>
            <AttachFileIcon className={classes.fileIcon} />
          </IconButton>
          <Typography className={classes.oneAttachementText}>
            {'1 attachment'}
          </Typography>
        </a>
      </HideContainer>

    </div>
  );
}
