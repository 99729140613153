import { CometChat } from '@cometchat-pro/chat';


export function useCometChatMarkAsDelivered() {

    return function markAsDelivered(message: any) {
        CometChat.markAsDelivered(message);
    }
}

export function useCometChatMarkAsRead() {

    return async function markAsRead(message:any) {
        try{

        let res = await CometChat.markAsRead(message);
        return res;
        }catch(error){
            console.error(error);
        }
    }
}