export const collectionsKeys = {

  fetchSingleCollection: (params: { collectionId: number }) => ['single-collection', params] as const,

  fetchCollections: (params: { designerId: number, labelId: number }) => ['collections', params] as const,

  fetchUserCollections: () => ['user-collections'] as const,
  fetchUserCollectionsByOthers: (params: { userId: number }) => ['user-collections', params] as const,

  pinCollection: (params: { designerId: number }) => ['pin-collection', params] as const,

  createCollection: (params: { labelId: number }) => ['create-collection', params] as const,
  createUserCollection: () => ['create-user-collection'] as const,


  fetchDesignerCollectionsByUser:
    (params: { designerId: number, labelId: number }) => ['collections-by-user', params] as const,


}
