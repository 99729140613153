import { takeEvery, } from "redux-saga/effects";
import { SAVE_COMETCHAT_MESSAGE } from "../cometchat/message/message.action";
import { SAVE_HTTP_RESPONSE } from "../http/http-response.action";
import { NAVIGATE_TO } from "../navigation/navigation.action";
import { navigateToSaga } from "../navigation/navigation.effec";
import { OPEN_NEED_LOGIN_DIALOG } from "../ui/ui.actions";
import { onMessageSaveSaga } from "../ui/ui.effect";
import { anyActionSaga } from "./any-action.saga";
import { needLoginSaga } from "./need-login.saga";



export function* rootSaga() {
  try {

    yield takeEvery(NAVIGATE_TO, navigateToSaga);

    yield takeEvery(OPEN_NEED_LOGIN_DIALOG, needLoginSaga);

    yield takeEvery(SAVE_COMETCHAT_MESSAGE, onMessageSaveSaga);

    yield takeEvery(
      // @ts-ignore
      (action: string) => !action.type.includes('@router') && action.type != SAVE_HTTP_RESPONSE,
      anyActionSaga
    );

  } catch (error) {
    console.error(error);
  }
}


