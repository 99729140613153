import React, { useState } from 'react';
import { Label } from '../../../../../components/src/api-types';
import { makeStyles, } from '@material-ui/core/styles';
import { infoIcon } from '../../../../../blocks/Groups/src/assets';
import clsx from 'clsx';
import {
  useCreateLabel,
  useIsDesigner,
  useIsMyProfile,
  useProfile,
  useLabels,
  useIsUser,
  useNavigate,
  useUpdateCoverImage
} from '../../../../../components/src/hooks';
import { RootState } from '../../../../../components/src/store/store';
import { useSelector } from 'react-redux';
import { useParams } from '../../../../../components/src/utils';
import { defaultProfile } from '../../../../../components/src/default-values';
import { HideContainer, DezinerButton, HorizontalSpacer } from '../../../../../components/src/design-system';
import { DezinerChangeCoverButton, LabelListing, LabelListingProps, CreateLabelDialog } from '../../../../../components/src/common';
import { HeaderControls, DezinerHeader, } from '../../../../../components/src/layout';
import { FollowDesignerButton } from './follow-designer-button';
import { SubscribeDesignerButton } from './subscribe-designer-button';







const useStyles = makeStyles({
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
  aboutButton: {
    '&:hover': {
      background: 'black',
      color: 'white',
    }
  },
  floatButtonActive: {
    background: 'black !important',
    color: 'white !important',
  },
});




export function useParsedQueryParams() {

  const params = useParams();
  const designerId = parseInt(params.designerId);
  const categoryQueryParam = params.category;
  const category = categoryQueryParam || 'collections';

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');


  return {
    labelId,
    category,
    designerId,
    categoryQueryParam,
  }
}

function useCalculatedLabels({
  allowAllLabel,
  changeLabel,
  inAboutPage,
  labelId,
  designerId,
  onCreate,
}: {
  allowAllLabel: boolean,
  changeLabel: (labelId: number) => void,
  inAboutPage: boolean,
  labelId: number,
  designerId: number,
  onCreate: () => void,
}) {

  const isMyProfile = useIsMyProfile({ id: designerId });
  const navigate = useNavigate();
  const labelsQuery = useLabels({ designerId });



  const firstLabelId = labelsQuery?.data?.data?.[0]?.id;
  const computedLabel = labelId || firstLabelId;
  const labelsParamsWithSaveDefault = computedLabel ? `?label_id=${computedLabel}` : '';

  let list: LabelListingProps['list'] = !allowAllLabel ? [] : [
    {
      id: 0,
      label: 'All',
      onClick: changeLabel,
      active: !inAboutPage && labelId == 0,
    }
  ];

  try {

    let labelsList: LabelListingProps['list'] = list.concat(labelsQuery?.data?.data?.map((item: any) => ({
      id: Number(item.id),
      label: item.attributes.name,
      hide: false,
      active: item.id == labelId,
      onClick: changeLabel
    })) ?? []);


    let createGroupItem: LabelListingProps['list'][number] = {
      id: -1,
      label: '+',
      hide: !isMyProfile,
      pin: 'start',
      onClick: () => {
        navigate(`Catalogue/${designerId}${labelsParamsWithSaveDefault}`);
      },
      alt: 'Create New Collection'
    }

    let handleOpenItem: LabelListingProps['list'][number] = {
      id: list.length + 1,
      label: '+',
      pin: 'end',
      hide: !isMyProfile,
      onClick: onCreate,
      alt: 'Create New Label'
    }

    list = [createGroupItem, ...labelsList, handleOpenItem];

  } catch (error) {
    console.error(error);
  }

  return list;
}

export function useChangeLabelInDesignerHome() {

  const {
    designerId,
    categoryQueryParam,
  } = useParsedQueryParams();

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const navigate = useNavigate();


  const inCataloguePage = Boolean(pathname?.toLowerCase()?.includes('catalogue'));

  function changeLabel(labelId: number) {
    if (inCataloguePage)
      navigate(`Catalogue/${designerId}?label_id=${labelId}`);
    else {
      const categoryParam = categoryQueryParam ? `/${categoryQueryParam}` : '';
      navigate(`DesignerHome/${designerId}${categoryParam}?label_id=${labelId}`);
    }
  }

  return changeLabel;

}

export function useLabelsInDesignerHome() {

  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const inCataloguePage = Boolean(pathname?.toLowerCase()?.includes('catalogue'));
  const allowAllLabel = !inCataloguePage;

  const { designerId } = useParsedQueryParams();

  const labelsQuery = useLabels({ designerId });

  const ALL: Label = {
    "id": "0",
    "type": "labels",
    "attributes": {
      "id": 0,
      "name": "All",
      "club_id": null,
      "group_id": null,
      "label_type": null,
      "created_at": "2022-09-26T10:18:51.675Z",
      "updated_at": "2022-09-26T10:18:51.675Z"
    }
  }

  let baseArray: Label[] = [];

  if (allowAllLabel)
    baseArray.push(ALL);

  let labelsArray = labelsQuery?.data?.data ?? [];

  return baseArray.concat(labelsArray);

}


function DesignerHomeLayout({ children }: { children: any }) {


  const {
    labelId,
    designerId,
    categoryQueryParam,
  } = useParsedQueryParams();

  const classes = useStyles();

  const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false);


  const isMyProfile = useIsMyProfile({ id: designerId });
  const isDesigner = useIsDesigner();

  const profileQuery = useProfile({ id: designerId });
  const profileData = defaultProfile(profileQuery?.data?.data?.attributes);


  const navigate = useNavigate();


  const { mutate: createLabel, error: createLabelError } = useCreateLabel({ designerId });
  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const isUser = useIsUser();


  const inCataloguePage = Boolean(pathname?.toLowerCase()?.includes('catalogue'));
  const inAboutPage = Boolean(pathname?.toLowerCase()?.includes('about'));
  const allowAllLabel = !inCataloguePage;


  const changeLabel = useChangeLabelInDesignerHome();


  const list = useCalculatedLabels({
    designerId,
    labelId,
    inAboutPage,
    changeLabel,
    allowAllLabel,
    onCreate: () => setAddLabelDialogOpen(true),
  });


  const labelsParams = labelId ? `?label_id=${labelId}` : '';

  const { mutate: changeCover, isLoading: changeCoverLoading } = useUpdateCoverImage();


  if (Object.is(NaN, designerId))
    return <div>{'There is no designer id'} </div>;


  return (
    <div>

      <CreateLabelDialog
        open={addLabelDialogOpen}
        onClose={() => {
          setAddLabelDialogOpen(false);
        }}
        onCreate={(labelName) => createLabel({ labelName }, {
          onSuccess: () => {
            setAddLabelDialogOpen(false);
          }
        })}
        error={createLabelError?.[0]?.message}
      />


      <DezinerHeader
        fallbackColor={profileData.color}
        onClick={(category) => {
          navigate(`DesignerHome/${designerId}/${category}${labelsParams}`);
        }}
        onFollowersClick={() => {
          navigate(`DesignerFollower/${designerId}`);
        }}
        title={profileData.first_name}
        subTitle={profileData.profile_info}
        profileImageURL={profileData.profile_thumbnail_url}
        coverImageURL={profileData.cover}
        profileStatistics={[
          { name: 'collections', value: profileData.totel_collections, isActive: categoryQueryParam == 'collections' },
          { name: 'designs', value: profileData.totel_designs, isActive: categoryQueryParam == 'designs' },
          { name: 'liked', value: profileData.totel_like_designs, isActive: categoryQueryParam == 'liked' },
          { name: 'promoted', value: profileData.totel_promoted_designs, isActive: categoryQueryParam == 'promoted' },
          { name: 'inspired', value: profileData.totel_inspired_designs, isActive: categoryQueryParam == 'inspired' },
          { name: 'rated', value: profileData.totel_rated_designs, isActive: categoryQueryParam == 'rated' },
          { name: 'free', value: profileData.totel_free_designs, isActive: categoryQueryParam == 'free' },
          { name: 'paid', value: profileData.totel_paid_designs, isActive: categoryQueryParam == 'paid' },
          { name: 'following', value: profileData.following_count },
          { name: 'followers', value: profileData.follower_count },
        ]}
        loading={profileQuery?.isFetching}
      />

      <HeaderControls>
        <HeaderControls.SideSection flexStart>

          <HideContainer hideWhen={!isDesigner || !isMyProfile}>
            <DezinerChangeCoverButton
              onChange={(image) => changeCover({ image })}
              loading={changeCoverLoading}
            />
          </HideContainer>

          <HorizontalSpacer />

          <DezinerButton
            variant="contained"
            onClick={() => {
              navigate(`DesignerAboutPage/${designerId}`);
            }}
            startIcon={(isHovered: boolean) => <img src={infoIcon} className={clsx({ "white-svg": isHovered || inAboutPage })} />}
            className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: inAboutPage })}
          >
            {'About'}
          </DezinerButton>


        </HeaderControls.SideSection>


        <HeaderControls.MiddleSection>
          <LabelListing list={list} />
        </HeaderControls.MiddleSection>

        <HeaderControls.SideSection flexEnd>

          <HideContainer hideWhen={isMyProfile}>
            <FollowDesignerButton designerId={designerId} designer={profileData} />
          </HideContainer>

          <HideContainer hideWhen={!isUser}>

            <HorizontalSpacer />

            <SubscribeDesignerButton
              designerId={designerId}
              designer={profileData}
            />

          </HideContainer>

        </HeaderControls.SideSection>

      </HeaderControls>


      {children}


    </div>
  );


}







export default DesignerHomeLayout
