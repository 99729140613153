import React, { ReactNode } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DesignerProfileWithOptions from "./DesignerProfileWithOptions.web";
import { useParams } from "../../../components/src/utils";
import { useGroupsMembers, useGroupsNonRespondingMembers, } from "../../../components/src/hooks/groups";
import { useClubMembers, useClubsNonRespondingMembers, useDecodedToken, } from "../../../components/src/hooks";
import { VerticalSpacer, } from "../../../components/src/design-system";
import { defaultToEmptyArray, defaultToEmptyString } from "../../../components/src/default-values";
import { ClubMember, GroupMember } from "../../../components/src/api-types";
import * as R from 'ramda';

function NonRespondingMembersLayout({ children }: { children: ReactNode }) {
  return (
    <Grid container>
      <Grid item sm={2} />
      <Grid item xs={12} sm>
        {children}
      </Grid>
      <Grid item sm={2} />
    </Grid>

  );
}

export function GroupClubNonRespondingMembers() {

  const params = useParams();
  const groupId = Number(params.groupId);
  const clubId = Number(params.clubId);

  const isGroupPage = Boolean(groupId);
  const isClubPage = Boolean(clubId);

  if (isGroupPage)
    return (
      <NonRespondingMembersLayout>
        <GroupNonRespondingMembers />
      </NonRespondingMembersLayout>
    );

  if (isClubPage)
    return (
      <NonRespondingMembersLayout>
        <ClubNonRespondingMembers />
      </NonRespondingMembersLayout>
    );

  return null;
}


const sameAccountId = (accountId: number) =>
  (member: GroupMember | ClubMember) => R.equals(R.path(['attributes', 'account_id'])(member), accountId);


function GroupNonRespondingMembers() {

  const params = useParams();
  const groupId = Number(params.groupId);

  const groupMembersQuery = useGroupsMembers({ groupId });

  const members = defaultToEmptyArray<GroupMember>(groupMembersQuery?.data?.data)

  const { id: myProfileId } = useDecodedToken();

  const groupNonRespondingMembersQuery = useGroupsNonRespondingMembers({ groupId });

  const nonRespondingMembers = defaultToEmptyArray<GroupMember>(groupNonRespondingMembersQuery?.data?.data)

  const allMembers = members?.concat(nonRespondingMembers);

  const myMember = R.find(sameAccountId(myProfileId), allMembers);

  const currentRole = defaultToEmptyString((myMember as GroupMember)?.attributes?.role)

  const isAdmin = currentRole == "Admin";
  const isOwner = currentRole == "Owner";

  const showNonRespondingMembers = (isOwner || isAdmin) && nonRespondingMembers?.length;

  if (!showNonRespondingMembers)
    return null;


  return <NonRespondingMembers nonRespondingMembers={nonRespondingMembers} currentRole={currentRole} />
}


function ClubNonRespondingMembers() {

  const params = useParams();
  const clubId = Number(params.clubId);


  const clubMembersQuery = useClubMembers({ clubId });

  const members = defaultToEmptyArray<ClubMember>(clubMembersQuery?.data?.data);

  const { id: myProfileId } = useDecodedToken();

  const clubNonRespondingMembersQuery = useClubsNonRespondingMembers({ clubId });

  const nonRespondingMembers = defaultToEmptyArray<ClubMember>(clubNonRespondingMembersQuery?.data?.data);

  const allMembers = members?.concat(nonRespondingMembers);

  const myMember = R.find(sameAccountId(myProfileId), allMembers);

  const currentRole = defaultToEmptyString((myMember as ClubMember)?.attributes?.user_type)

  const isAdmin = currentRole == "Admin";
  const isOwner = currentRole == "Owner";

  const showNonRespondingMembers = (isOwner || isAdmin) && nonRespondingMembers?.length;

  if (!showNonRespondingMembers)
    return null;

  return <NonRespondingMembers nonRespondingMembers={nonRespondingMembers} currentRole={currentRole} />
}








function NonRespondingMembers({
  nonRespondingMembers,
  currentRole,
}: {
  nonRespondingMembers: (GroupMember | ClubMember)[]
  currentRole: string,
}) {

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      <Grid container justifyContent="center" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Typography style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
          {'Non responding members'}
        </Typography>
      </Grid>

      <VerticalSpacer />

      <Grid container style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))' }}>
        {
          nonRespondingMembers.map((member) => (
            <div key={member?.id} style={{ padding: '30px 0', }}>
              <DesignerProfileWithOptions
                hideOptionsMenu
                currentRole={currentRole}
                member={member}
                onDelete={() => { }}
                addAsAdmin={() => { }}
              />
            </div>
          ))
        }
      </Grid>
    </>
  );
}
