import { useQueryClient } from "@tanstack/react-query";
import { PostedBy, Profile } from "../api-types";
import { designerMessages } from "../messages-generator";
import { clubsKeys, designerKeys, profileKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";


export function useDesignerFollowers({ designerId, }: { designerId: number }) {

  return useDezinerQuery<{ data: Profile[] }>({
    message: designerMessages.fetchDesignerFollowers({ designerId }),
    queryKey: designerKeys.fetchDesignerFollowers({ designerId }),
  });
}

export function useDesignersList() {

  return useDezinerQuery<{ data: Profile[] }>({
    message: designerMessages.fetchDesignerList(),
    queryKey: designerKeys.fetchDesignerList(),
  });
}

export function useFollowDesigner({ designerId, clubId }: { designerId: number, clubId?: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designerMessages.followDesigner>[0]>({
    messageGenerator: designerMessages.followDesigner,
    queryKey: designerKeys.followDesigner({ designerId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
        queryClient.invalidateQueries(designerKeys.fetchDesignerFollowers({ designerId }));

        if (clubId)
          queryClient.invalidateQueries(clubsKeys.clubFollowers({ clubId }));

      }
    },
  });
}

export function useUnfollowDesigner({ designerId, clubId }: { designerId: number, clubId?: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designerMessages.followDesigner>[0]>({
    messageGenerator: designerMessages.unfollowDesigner,
    queryKey: designerKeys.unfollowDesigner({ designerId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));

        if (clubId)
          queryClient.invalidateQueries(clubsKeys.clubFollowers({ clubId }));

      }
    },
  });
}

export function useSubscriptionPackages({ subscriptionType, }: { subscriptionType: string }) {

  return useDezinerQuery<{ data: any }>({
    message: designerMessages.fetchSubscriptionPackages({ subscriptionType }),
    queryKey: designerKeys.fetchSubscriptionPacakges({ subscriptionType }),
  });
}

export function useSubscribePlan({
  designerId,
  planId,
  subscribeType,
  cardDetails,
}: {
  designerId: number,
  planId: number,
  subscribeType: string,
  cardDetails: any
}) {

  return useDezinerMutation<Parameters<typeof designerMessages.subscribePackage>[0]>({
    messageGenerator: designerMessages.subscribePackage,
    queryKey: designerKeys.subscribePackage({
      designerId,
      planId,
      subscribeType,
      cardDetails,
    })
  });
}

export function useGetNotifications({
  filter
}: {
  filter: string
}) {

  return useDezinerQuery<{ data: any }>({
    message: designerMessages.fetchNotifications({
      filter
    }),
    queryKey: designerKeys.fetchNotifications({
      filter
    }),
  });
}
