Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.designerProfileApiUser = "account_block/accounts";
exports.deleteMethod = "DELETE";
exports.followApi = 'bx_block_followers/follows';
exports.profileApi = 'account_block/accounts/profile';
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End