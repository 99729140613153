import React, { useState } from 'react';
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialDialog from "@material-ui/core/Dialog";
import MaterialDialogActions from "@material-ui/core/DialogActions";
import MaterialDialogContent from "@material-ui/core/DialogContent";
import MaterialDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DezinerAlert} from '../design-system';

const CreateButton = withStyles({
  root: {
    background: 'black',
    borderRadius: '10px',
    textTransform: 'none',
    minWidth: '80px',
    fontWeight: 600,
    '&:hover': {
      background: 'black',
    }
  }
})(Button);

const CancelButton = withStyles({
  root: {
    background: '#ddd',
    borderRadius: '10px',
    textTransform: 'none',
    color: 'black',
    minWidth: '80px',
    fontWeight: 600,
    '&:hover': {
      background: '#ddd',
    }
  }
})(Button);

const TextField = withStyles({
  root: {
    '& > div': {
      borderRadius: '10px'
    }
  }
})(MaterialTextField);

const DialogContent = withStyles({
  root: {
    padding: '10px 55px'
  }
})(MaterialDialogContent);

const DialogTitle = withStyles({
  root: {
    '& p': {
      fontWeight: 700,
      fontSize: '1.4rem',
    },
    '&>*': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& svg': {
      cursor: 'pointer'
    }
  }
})(MaterialDialogTitle);

const DialogActions = withStyles({
  root: {
    justifyContent: 'space-between',
    padding: '25px 30px'
  }
})(MaterialDialogActions);

const Dialog = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  scrollPaper: {
    // width: '100%'
  },
  container: {
    height: 'auto',
  },
  paperScrollPaper: {
    borderRadius: '10px',
    minWidth: '400px',
  }
})(MaterialDialog);


export function CreateLabelDialog({ open, onClose, onCreate, error }: {
  open: boolean,
  onClose: () => void,
  onCreate: (labelName: string) => void,
  error?: string,
}) {

  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [labelName, setLabelName] = useState('');

  function createHandler() {
    onCreate(labelName);
    setLabelName('');
  }
  function closeHandler() {
    onClose();
    setLabelName('');
  }


  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="add-new-label"
      fullScreen
      maxWidth="sm"
    >
      <DialogTitle id="add-new-label">

        <Typography>
          {'Create Label'}
        </Typography>

        <CloseIcon onClick={closeHandler} />

      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          placeholder="Enter Label Name"
          type="text"
          onChange={(event) => {
            let value = event.target.value;

            if (value.toLowerCase() == 'all')
              setIsNotAllowed(true);
            else
              setIsNotAllowed(false);

            setLabelName(event.target.value);
          }}
          value={labelName}
          fullWidth
          variant='outlined'
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              createHandler();
            }
          }}
        />
        {
          error ?
            <DezinerAlert>
              {error}
            </DezinerAlert>
            :
            null
        }

        {
          isNotAllowed ?
            <DezinerAlert>
              {`You can't create a label with the name "${labelName}"`}
            </DezinerAlert>
            :
            null
        }

      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={closeHandler}
          color="primary"
        >
          {'Cancel'}
        </CancelButton>
        <CreateButton
          disabled={isNotAllowed || !labelName}
          onClick={createHandler}
          color="primary"
          variant='contained'>
          {'Create'}
        </CreateButton>
      </DialogActions>
    </Dialog>
  );
}

