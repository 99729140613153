import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import AddGroupMemberController, { Props } from "./AddGroupMemberController.web";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares, { MembersContributionTable } from "./MembersContributionTable.web";
import { HorizontalSpacer, VerticalSpacer, DezinerButton, DezinerTextField, DezinerSpinner } from "../../../components/src/design-system";
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";
import { useParams } from "../../../components/src/utils";
import { useAddMemberToGroup, useGroupsMembers, useRemoveGroupMember, useRemoveGroupMemberAdminPermission, useSingleGroup } from "../../../components/src/hooks/groups";
import { useClubsRoles, useDecodedToken, useDesignersList, useNavigate } from "../../../components/src/hooks";
import { DeleteMemberDialog } from "./DeleteMemberDialog.web";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { GroupMember, Profile } from "../../../components/src/api-types";
import { GroupClubNonRespondingMembers } from "./non-responding-members";
import { AddMemberForm } from "./add-member-form";


class AddGroupMember extends AddGroupMemberController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (<AddGroupMemberContent />);
  }
}






function AddGroupMemberContent() {

  const params = useParams();
  const groupId = Number(params.groupId);

  const membersQuery = useGroupsMembers({ groupId });
  const members = defaultToEmptyArray<GroupMember>(membersQuery?.data?.data);

  const groupQuery = useSingleGroup({ groupId });

  const { mutate: removeAdminPermission } = useRemoveGroupMemberAdminPermission({ groupId });
  const { mutate: removeMember } = useRemoveGroupMember({ groupId });

  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [deletedMember, setDeleteMember] = useState({ deleteType: '', memberId: 0, memberName: '' });
  const [currentTab, setCurrentTab] = useState(0);

  const { id: myProfileId } = useDecodedToken();

  const myMember = members.find(member => member.attributes.account_id == myProfileId);

  const currentRole = myMember?.attributes?.role ?? '';
  const isAdmin = currentRole == "Admin";

  const navigate = useNavigate();




  return (
    <>

      <DeleteMemberDialog
        deleteType={"remove_member"}
        open={deleteMemberOpen}
        memberName={deletedMember.memberName}
        onAccept={() => {
          const { deleteType, memberId } = deletedMember;

          if (deleteType == "remove_as_admin")
            removeAdminPermission({ memberId, groupId });
          else if (deleteType == "remove_member")
            removeMember({ memberId, groupId });

          setDeleteMemberOpen(false);
          setDeleteMember({ deleteType: '', memberId: 0, memberName: '' });
        }}
        onClose={() => setDeleteMemberOpen(false)}
      />









      <div style={{ padding: '50px 0' }}>
        {
          groupQuery.isLoading ?
            <DezinerSpinner />
            :
            <Grid container>
              <Grid item sm={2}></Grid>
              <Grid item xs={12} sm>


                <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
                  style={{
                    borderBottom: '1px solid #ddd'
                  }}
                >
                  <GroupAndClubHorizontalCard
                    item={groupQuery?.data?.data}
                    hideButton
                    hideMembers
                    hideBorder
                    onNameClick={() => {
                      navigate(`GroupHomePage/${groupId}`);
                    }}
                  />
                  <DezinerTabs
                    value={currentTab}
                    onChange={(_e, tab) => setCurrentTab(tab)}
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <DezinerTab label="Member" {...a11yProps(0)} />
                    <DezinerTab label="Role" {...a11yProps(1)} />
                  </DezinerTabs>
                </Grid>

                <DezinerTabPanel value={currentTab} index={0}>

                  <VerticalSpacer />

                  <AddMemberForm />

                  <VerticalSpacer />

                  <MembersContributionTable
                    members={members}
                    currentRole={currentRole}
                    isEditable={false}
                    isEqual={false}
                    revenu={{}}
                    setRevenu={() => { }}
                    onDelete={({ memberId, memberName }) => {
                      setDeleteMember({
                        memberId,
                        memberName,
                        deleteType: "remove_member"
                      });
                      setDeleteMemberOpen(true);
                    }}
                    isViewOnly={true}
                    workContributionHeaderTitle="Reveneu %"
                  />

                  <VerticalSpacer />
                  <Grid container justifyContent="flex-end">
                    {
                      isAdmin ? (
                        <DezinerButton
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setCurrentTab(1)
                          }}
                        >
                          {'Update'}
                        </DezinerButton>
                      )
                        :
                        null
                    }
                  </Grid>

                </DezinerTabPanel>
                <DezinerTabPanel value={currentTab} index={1}>

                  <MembersContributionTableWithShares
                    members={members}
                    onDelete={({ memberName, memberId }) => {
                      setDeleteMember({
                        memberName,
                        memberId,
                        deleteType: "remove_member",
                      })
                      setDeleteMemberOpen(true);
                    }}
                    onAdd={() => {
                      setCurrentTab(0)
                    }}
                    onSave={(revenu) => {
                      console.log({ revenu });
                    }}
                    currentRole={currentRole}
                    workContributionHeaderTitle="Reveneu %"
                  />

                </DezinerTabPanel>

              </Grid>
              <Grid item sm={2}></Grid>
            </Grid>
        }
      </div>


      <GroupClubNonRespondingMembers />

    </>
  );
}

export default AddGroupMember;
