import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from '../../../components/src/store/store';
import { navigateTo } from "../../../components/src/store/navigation/navigation.action";
//import { SortPropertyMapper, } from '../../../blocks/landingpage/src/landing-page/landing-page-content';
//import { sortDesigns } from '../../../blocks/landingpage/src/landing-page/designs';
import { useFiltersContainerStyles, } from '../../../blocks/landingpage/src/landing-page/filters';
import { CategoriesFilterButton } from "../../../blocks/landingpage/src/landing-page/categories-filter-button";
import { NoDataContainer, DezinerDropdown, DezinerButtonGroup } from "../../../components/src/design-system";
import { useCategories, useSearchResults, useSearchSuggestions } from "../../../components/src/hooks";
import { clubIcon, groupIcon, penIcon, forum, } from '../../../blocks/landingpage/src/assets';
import { DesignCardsWithProfilesResponsiveContainer } from "./DesignsCardsContainer.web";


export default function SearchResults() {

  const classes = useFiltersContainerStyles();
  const queryParams = useSelector((state: RootState) => state.router.location.query);
  useEffect(() => {
    setSearchTerm(decodeURI(queryParams?.searchTerm ?? ''));
    setSearchInput(decodeURI(queryParams?.searchTerm ?? ''));
  }, [queryParams]);
  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    setSearchTerm(searchInput);
  }
  const [searchTerm, setSearchTerm] = useState(decodeURI(queryParams?.searchTerm ?? ''));
  const [searchInput, setSearchInput] = useState(decodeURI(queryParams?.searchTerm ?? ''));
  const [selectedSortOption, setSelectedSortOption] = useState('');
  const selectCats: any[] = queryParams?.category_of?.split(",") ?? [];
  const [selectedCategories, setSelectedCategories] = useState<any[]>(selectCats);
  const [searchBy, setSearchBy] = useState(queryParams?.search_by ?? 'All');

  const categoriesQuery = useCategories();
  const searchPageQuery = useSearchResults({
    searchBy,
    selectedCategories,
    searchTerm,
    chargeFor: queryParams?.charge_for ?? 'Free',
    sortBy: selectedSortOption,
  });

  const searchSuggestions = useSearchSuggestions({
    searchTerm
  });



  const dispatch = useDispatch();


  function categoryToggleHandler(id: number) {
    let newSubCategories = selectedCategories.slice();

    let exist = selectedCategories.includes(id);
    if (exist)
      newSubCategories = newSubCategories.filter(x => x != id);
    else
      newSubCategories = newSubCategories.concat(id);

    setSelectedCategories(newSubCategories);
  }


  // const allDesigns: any[] = sortDesigns(
  //   (searchPageQuery?.data?.pages ?? [])?.map(group => group.data?.designes?.data)?.flat() ?? [],
  //   SortPropertyMapper[selectedSortOption]
  // );

  const allDesigns  = searchPageQuery?.data?.data?.designes?.data??[];

  return (
    <div>
      <div>
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <form
            onSubmit={handleSearchSubmit}
            style={{
              color: "white",
              backgroundColor: "white",
              height: "50px",
              // width: '50vw',
              borderRadius: "20px",
              border: "none",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              gridTemplateColumns: "0.1fr 0.8fr 0.1fr",
              padding: "0px 20px",
              margin: "0 auto",
              width: "70vh",
              boxShadow: "0 0px 10px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)",
            }}
          >
            <SearchIcon
              style={{ color: "black" }}
            />
            <input
              data-testid='search-input'
              placeholder="Search"
              style={{
                color: "black",
                backgroundColor: "transparent",
                border: "0px",
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <CloseIcon
              style={{ color: "black" }}
              onClick={() => setSearchInput('')}
            />
          </form>
          <h2 style={{ textAlign: 'center', margin: '25px' }}><b>{searchTerm}</b></h2>
          <p style={{ margin: '0px' }}>
            <span>Related:</span>
            <ul style={{ display: 'inline', fontWeight: 'bold' }}>
              {(searchSuggestions?.data?.pages ?? []).map(group => group.data?.designs?.map((designData: any, index: any) => (
                <li
                  style={{ display: "inline", padding: '0px 20px', cursor: 'pointer' }}
                  onClick={() => {
                    setSearchInput(designData.title)
                    setSearchTerm(designData.title)
                  }}
                  key={index}
                >
                  {designData.title}
                </li>
              )))}
            </ul>
          </p>
        </div>

      </div>
      <Grid
        className={classes.filtersContainer}
        container
        justifyContent="space-between"
        alignItems="center">

        <DezinerDropdown
          position="left"
          title={"Sort"}
          options={[
            { key: 'viewed', title: 'Most Viewed' },
            { key: 'inspired', title: 'Most Inspired' },
            { key: 'liked', title: 'Most Liked' },
            { key: 'recent', title: 'Most Recent' },
          ]}
          selectedOption={selectedSortOption}
          onClick={setSelectedSortOption}
        />

        <DezinerButtonGroup
          noBorders
          options={[
            {
              key: 'Forum',
              icon: forum,
              active: searchBy.toLowerCase() == 'forum',
              title: 'Forum'
            },
            {
              key: 'Designer',
              icon: penIcon,
              active: searchBy.toLowerCase() == 'designer',
              title: 'Designer'
            },
            {
              key: 'Group',
              icon: groupIcon,
              active: searchBy.toLowerCase() == 'group',
              title: 'Group'
            },
            {
              key: 'Club',
              icon: clubIcon,
              active: searchBy.toLowerCase() == 'club',
              title: 'Club'
            },
            {
              key: 'All',
              icon: null,
              active: searchBy.toLowerCase() == 'all',
              title: 'All'
            },
          ]}
          onClick={(filter) => {
            if (filter == 'Forum') {
              dispatch(navigateTo({
                url: 'CommunityForum'
              }));
            }
            else
              setSearchBy(filter);

          }}
        />



        <CategoriesFilterButton
          categories={categoriesQuery?.data?.data ?? []}
          onToggle={categoryToggleHandler}
          selectedCategoriesIds={selectedCategories}
          onClear={() => {
            setSelectedCategories([]);
          }}
        />

      </Grid>


      <DesignCardsWithProfilesResponsiveContainer
        data={allDesigns}
      />

      {
        !selectedCategories.length && !searchPageQuery.isFetching && !allDesigns?.length ?
          <NoDataContainer message={'Sorry, No data found.'} />
          :
          null
      }

      {
        selectedCategories.length && !searchPageQuery.isFetching && !allDesigns?.length ?
          <NoDataContainer message={'Sorry, No data found for the selected filters. Please change the selected filter to search for new designs.'} />
          :
          null
      }


    </div>
  );
}


