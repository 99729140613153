import React from 'react';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useFormikContext } from 'formik';
import { FormikTextInput, HideContainer, VerticalSpacer } from '../../../../../components/src/design-system';


export function PriceInput() {

  const formik = useFormikContext<UplodaDesignInitValues>();

  return (
    <HideContainer hideWhen={formik.values.privacy?.toLowerCase() != 'private'}>

      <FormikTextInput
        name="price"
        label="₹ Price*"
        variant="outlined"
        type="number"
      />

      <VerticalSpacer />

    </HideContainer>
  );
}
