import React from 'react';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useFormikContext } from 'formik';
import { Grid, } from '@material-ui/core';
import { AutocompleteSelectorInputVariant, AutocompleteSelectorItem, DezinerAlert, DezinerLabel, VerticalSpacer } from '../../../../../components/src/design-system';
import { tagIcon, } from "../../../../catalogue/src/assets";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  smallIcon: {
    maxWidth: '18px',
    marginRight: '5px',
  },
});

export function TagsInput({
  options,
}: {
  options: AutocompleteSelectorItem[]
}) {

  const formik = useFormikContext<UplodaDesignInitValues>();
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center">
        <img src={tagIcon} alt="" className={classes.smallIcon} />
        <DezinerLabel>
          {'Tags*'}
        </DezinerLabel>
      </Grid>

      <VerticalSpacer value={4} />

      <AutocompleteSelectorInputVariant
        placeholder=""
        autocompleteProps={{
          groupBy: (option: any) => option?.parentLabel,
        }}
        inputValue={formik.values.tags}
        onChange={(value) => formik.setFieldValue('tags', value)}
        options={options}
      />

      {
        formik?.errors?.tags ?
          <DezinerAlert>
            {formik?.errors?.tags}
          </DezinerAlert>
          :
          null
      }
    </>
  );
}
