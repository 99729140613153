import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { DezinerButton, HideContainer } from "../design-system";

const useEditableAreaOnHoverStyles = makeStyles({
  textAreaContainer: {
    position: 'relative',
    borderRadius: '6px',
    padding: '10px',
    '&:hover': {
      border: '1px solid #e3e3e3 !important',
    },
    '&:hover button': {
      display: 'block !important'
    }
  },
  textAreaContainerActive: {
    border: '1px solid #e3e3e3',
  },
  noBorder: {
    '&:hover': {
      border: '0px solid black !important',
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  textAreaBtn: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    color: '#767779',
    display: 'none',
  },
  cancelBtn: {
    position: 'absolute',
    top: '2px',
    right: '70px',
    color: '#767779',
    display: 'none',
  },
  textAreaBtnActive: {
    display: 'block',
  },
});

export function EditableAreaOnHover({
  children,
  title,
  allowEdit,
  onSave,
  saveButtonTitle,
  editButtonTitle,
  className,
  loading,
}: {
  children: any
  title: string,
  allowEdit: boolean,
  onSave: () => boolean,
  saveButtonTitle?: string,
  editButtonTitle?: string,
  className?: string,
  loading: boolean,
}) {
  const [editMode, setEditMode] = useState(false);

  const classes = useEditableAreaOnHoverStyles();

  return (
    <div
      className={clsx(classes.textAreaContainer, className, {
        [classes.textAreaContainerActive]: editMode,
        [classes.noBorder]: !allowEdit
      })}
    >
      <Typography variant="body1" className={classes.sectionTitle}>
        {title}
      </Typography>

      <HideContainer hideWhen={!allowEdit}>
        <DezinerButton
          onClick={() => {
            if (editMode) {
              let canContinue = onSave();
              if (canContinue)
                setEditMode(x => !x);
            } else
              setEditMode(x => !x);
          }}
          color="default"
          variant="text"
          className={clsx(classes.textAreaBtn, {
            [classes.textAreaBtnActive]: editMode
          })}
          loading={loading}
        >
          {editMode ? (saveButtonTitle || 'Save') : (editButtonTitle || 'Edit')}
        </DezinerButton>
        <HideContainer hideWhen={!editMode}>
          <DezinerButton
            color="default"
            variant="text"
            className={classes.cancelBtn}
            onClick={() => setEditMode(false)}
          >
            {'cancel'}
          </DezinerButton>
        </HideContainer>
      </HideContainer>

      {
        React.Children.map(children, child =>
          React.cloneElement(child, { editMode }),
        )
      }

    </div>
  )
}

export function EditableAreaOnHoverActive({ editMode, children }: { editMode?: boolean, children?: any }) {
  return editMode ? children : null
}

export function EditableAreaOnHoverInActive({ editMode, children }: { editMode?: boolean, children?: any }) {
  return editMode ? null : children
}


