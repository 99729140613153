import React, { useState, useRef, useEffect } from 'react';
import { blobToBase64 } from '../../../components/src/utils';

export function useRecordingUtils() {

  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<any>(null);
  const [base64Audio, setBase64Audio] = useState('');

  const streamRef = useRef<any>();

  useEffect(function onMount() {
    return function onUnMount() {
      if (recorder)
        recorder.removeEventListener("dataavailable", handleAudioData);
    }
  }, []);

  async function handleAudioData(event: any) {
    event.preventDefault();
    const result = await blobToBase64(event.data)
    setBase64Audio(result);
  }

  function recordToggleHandler() {
    if (!isRecording)
      startRecording();
    else
      stopRecording();
  }

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  async function startRecording() {
    setIsRecording(true);
    if (!recorder) {
      let _recorder = await requestRecorder();
      setRecorder(_recorder);
      _recorder.start();
      _recorder.addEventListener("dataavailable", handleAudioData);
    } else {
      recorder.start();
    }
  }

  function stopRecording(): void {
    setIsRecording(false);
    recorder.stop();
    if (streamRef && streamRef.current) {
      if (streamRef.current.getTracks().length)
        streamRef.current.getTracks()?.[0]?.stop()
    }
    setRecorder(null);
  }

  function clearRecording() {
    setBase64Audio('');
    setIsRecording(false);
  }
  return {
    recordToggleHandler,
    base64Audio,
    isRecording,
    clearRecording,
  }
}
