import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DezinerMarkDown } from '../../../../components/src/common';
import Grid from '@material-ui/core/Grid';
import { cookiePolicyContent } from './CookiePolicyContent.web';

const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});



export default function CookiePolicyPage() {

  const classes = useStyles();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'Cookie Policy'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {''}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 17, 2022'}
          </Typography>
        </Grid>
        <hr />

        <DezinerMarkDown>
          {cookiePolicyContent}
        </DezinerMarkDown>

      </div>

    </div>
  );
}
