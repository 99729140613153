import React, { useState, } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {
  plusIcon,
  likeGray,
  shareArrowIcon,
  inspireGray,
  starGray,
  colBlack,
} from "../../assets";
import UserDesignListController, {
  Props,
} from "./UserDesignListController.web";
import { CollectionHome, DesignCategory, UserDesignsFilter, Collection } from "../../../../../components/src/api-types";
import { GeneralResponsiveCardsContainer, DesignCardWithProfileContainer } from "../../../../../components/src/common";
import { DezinerDropdown, VerticalSpacer, DezinerButton, DezinerButtonGroup, DezinerSpinner } from "../../../../../components/src/design-system";
import { useUserDesigns, useMyUserCollections, useUserUploadDesign, useIsMyProfile, useUserCollections } from '../../../../../components/src/hooks';
import UploadUserDesignDialog from "./UploadUserDesignDialog";
import CreateCollectionDialog from "./CreateUserCollectionDialog";
import { QuickSaveToUserCollection } from "./QuickSaveToUserCollectionDialog";
import UserCollectionSlider from "./UserCollectionsSlider";
import { useParams } from "../../../../../components/src/utils";


const iconsMapper: any = {
  saved: plusIcon,
  collections: colBlack,
  liked: likeGray,
  rated: starGray,
  inspired: inspireGray,
  shared: shareArrowIcon,
}

class UserDesignList extends UserDesignListController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <UsersDesignsWithCategories />
    );
  }
}






function FilterDropdown({ filter, category, onFilterChange, }: {
  filter: UserDesignsFilter,
  category: DesignCategory,
  onFilterChange: (filter: UserDesignsFilter) => void,
}) {

  const { userId } = useParams();
  const designsQuery = useUserDesigns({ filter, category, userId });

  return (
    <DezinerDropdown
      position="right"
      options={category == 'all' ? [
        {
          key: 'all',
          title: `All (${designsQuery?.data?.meta?.all_designs_count ?? 0})`
        },
        {
          key: 'saved_design',
          title: `Saved Design (${designsQuery?.data?.meta?.save_designs_count ?? 0})`,
        },
        {
          key: 'uploaded_designs',
          title: `Uploaded Designs (${designsQuery?.data?.meta?.uploaded_designs_count ?? 0})`
        }
      ] : [
          {
            key: 'all',
            title: `All (${designsQuery?.data?.meta?.all_designs ?? 0})`,
          },
          {
            key: 'users',
            title: `Users (${designsQuery?.data?.meta?.users ?? 0})`,
          },
          {
            key: 'groups',
            title: `Groups (${designsQuery?.data?.meta?.groups ?? 0})`,
          },
          {
            key: 'clubs',
            title: `Clubs (${designsQuery?.data?.meta?.clubs ?? 0})`,
          },
          {
            key: 'designers',
            title: `Designers (${designsQuery?.data?.meta?.designers ?? 0})`,
          },
        ]}
      selectedOption={filter}
      onClick={(filterName) => {
        onFilterChange(filterName as any);
      }}
      title={"All"}
    />
  );
}



function UsersDesignsWithCategories() {

  const [filter, setFilter] = useState<UserDesignsFilter>('all');
  const [category, setCategory] = useState<DesignCategory>('all');

  const [createCollectonOpen, setCreateCollectionOpen] = useState(false);
  const { userId } = useParams();

  const isMyProfile = useIsMyProfile({ id: Number(userId || 0) });


  const buttonsGrouppOptions = ['Saved', 'Liked', 'Shared', 'Inspired', 'Rated', 'All']
    .map(option => ({
      title: option,
      active: option.toLowerCase() == category,
      key: option.toLowerCase(),
      icon: iconsMapper?.[option.toLowerCase()] ?? null,
    }));


  const isSavedSelected = category == 'saved';
  const categoryTitle = isSavedSelected ? `COLLECTIONS` : `${category.toUpperCase()} DESIGNS`;
  const categoryIcon = isSavedSelected ? iconsMapper?.['collections'] : iconsMapper?.[category];

  const collectionsQuery = useMyUserCollections();
  const [selectedCollectionId, setSelectedCollectionId] = useState(0);
  const [uploadDesignOpen, setUploadDesignOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [quickSaveOpen, setQuickSaveOpen] = useState(false);

  const { mutate: uploadUserDesign, isLoading: uploadLoading } = useUserUploadDesign();


  return (
    <div style={{ padding: '20px 30px' }}>

      <QuickSaveToUserCollection
        collectionId={selectedCollectionId}
        open={isMyProfile && quickSaveOpen}
        onClose={() => setQuickSaveOpen(false)}
        onUpload={() => setUploadDesignOpen(true)}
        collectionName={collectionsQuery?.data?.data?.find(col => Number(col.id) == selectedCollectionId)?.attributes?.name ?? ''}
      />



      <UploadUserDesignDialog
        open={isMyProfile && uploadDesignOpen}
        onClose={() => setUploadDesignOpen(false)}
        loading={uploadLoading}
        successMessage={successMessage}
        onUpload={(values) => {
          const { resetForm, ...payload } = values;
          uploadUserDesign({
            collectionId: selectedCollectionId,
            payload,
          }, {
            onSuccess() {
              setSuccessMessage('Your design is saved');
              setTimeout(() => {
                setUploadDesignOpen(false);
                setSuccessMessage('');
                resetForm();
              }, 2500);
            },
          });
        }}
      />



      <CreateCollectionDialog
        open={isMyProfile && createCollectonOpen}
        onClose={() => setCreateCollectionOpen(false)}
        onSuccess={(response) => {
          setCreateCollectionOpen(false);
          setQuickSaveOpen(true);
          setSelectedCollectionId(response?.data?.attributes?.id);
        }}
      />

      <Grid
        container
        justifyContent="center"
        style={{
          padding: '30px 0'
        }}
      >

        <DezinerButtonGroup
          grayColors
          noBorders
          options={buttonsGrouppOptions}
          onClick={(category) => {
            setCategory(category as any);
            setFilter('all');
          }}
        />

      </Grid>

      <Grid container justifyContent="space-between">

        <Grid item xs={6} container alignItems="center">
          {
            categoryIcon ?
              <img
                src={categoryIcon}
                alt={category}
                style={{ marginRight: '5px', maxWidth: '25px', }}
              />
              :
              null
          }
          <Typography style={{ fontWeight: 'bold' }}>
            {categoryTitle}
          </Typography>
        </Grid>

        <CategoryButtons
          isMyProfile={isMyProfile}
          filter={filter}
          category={category}
          setFilter={setFilter}
          onCreateCollection={() => setCreateCollectionOpen(true)}
        />

      </Grid>

      <VerticalSpacer />



      {
        isSavedSelected ?
          <SavedUserCollections
            onAdd={(collectionId) => {
              setUploadDesignOpen(true)
              setSelectedCollectionId(collectionId);
            }}
            allowUploadDesign={isMyProfile}
          />
          :
          <UsersDesigns filter={filter} category={category} />
      }

      <VerticalSpacer />
      <VerticalSpacer />
    </div>
  );
}

function CategoryButtons({
  isMyProfile,
  filter,
  category,
  setFilter,
  onCreateCollection,
}: {
  isMyProfile: boolean,
  filter: UserDesignsFilter,
  category: DesignCategory;
  setFilter: (v: UserDesignsFilter) => void,
  onCreateCollection: () => void,
}) {

  const isSavedSelected = category == 'saved';

  if (isSavedSelected) {
    if (isMyProfile)
      return <DezinerButton
        onClick={onCreateCollection}
        color="primary"
        variant="contained"
      >
        {'Create Collection'}
      </DezinerButton>
    else
      return null;
  }

  return <FilterDropdown
    filter={filter}
    category={category}
    onFilterChange={setFilter}
  />

}


function SavedUserCollections({
  onAdd,
  allowUploadDesign,
}: {
  onAdd: (collectionId: number) => void,
  allowUploadDesign: boolean,
}) {

  const params = useParams();
  const userId = Number(params.userId);

  const collectionsQuery = useUserCollections({ userId });

  if (collectionsQuery?.isFetching)
    return <DezinerSpinner />

  return (
    <div>
      {
        (collectionsQuery?.data?.data as ((CollectionHome | Collection)[]))
          ?.filter(function hideEmptyCollectionsForAnonymousUsers(collection) {
            if (!allowUploadDesign)
              return Boolean(collection?.attributes?.designs?.data?.length);
            return true;
          })
          ?.map((collection) => {

            const savedDesigns = collection.type == "collection_home" ?
              collection?.attributes?.saved_designs?.data ?? []
              :
              [];
            const designs = collection?.attributes?.designs?.data?.concat(savedDesigns)

            return (

              <div key={collection.id}>

                <Typography style={{ fontWeight: 'bold', fontSize: '1.3rem', padding: '10px 22px' }}>
                  {`${collection?.attributes?.name ?? ''} (${collection?.attributes?.design_count ?? 0})`}
                </Typography>

                <UserCollectionSlider
                  onAdd={() => onAdd(collection?.attributes?.id)}
                  designs={designs}
                  allowAdd={allowUploadDesign}
                />

              </div>
            );
          })
      }
    </div>
  );
}




function UsersDesigns({ filter, category, }: {
  filter: UserDesignsFilter,
  category: DesignCategory,
}) {

  const { userId } = useParams();
  const designsQuery = useUserDesigns({ filter, category, userId });


  if (designsQuery.isFetching)
    return <DezinerSpinner />

  return (
    <GeneralResponsiveCardsContainer>
      {
        ({ itemHeight, itemWidth }) =>
          designsQuery.data?.data?.map((item: any) => (
            <DesignCardWithProfileContainer
              key={item?.id}
              imgHeight={itemHeight}
              item={item}
            />
          ))
      }
    </GeneralResponsiveCardsContainer>
  );
}

export default UserDesignList;
