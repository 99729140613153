import * as yup from 'yup';

export const passwordPolicyValidationSchema = (fieldName: string) => yup.string()
  .min(8, 'Please enter at least 8 chars')
  .test('has-lowercase', 'Please write at least one lowercase char', (value: any) => {
    if (!value) return true;
    return /[a-z]/g.test(value);
  })
  .test('has-uppercase', 'Please write at least one uppercase char', (value: any) => {
    if (!value) return true;
    return /[A-Z]/g.test(value);
  })
  .test('have-special-char', 'Please insert one special char', (value: any) => {
    if (!value) return true;
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
  })
  .test('has-digit', 'Please write at least one digit', (value: any) => {
    if (!value) return true;
    return /\d/g.test(value);
  })
  .required(`Please enter your ${fieldName}`);
