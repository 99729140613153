import produce from "immer";
import {
  ClearChatUnreadCountAction,
  CLEAR_CHAT_UNREAD_COUNT,
  CloseChatConversationListAction,
  CloseNeedLoginDialogAction,
  CloseNewChatWidgetAction,
  CLOSE_CHAT_CONVERSATION_LIST,
  CLOSE_NEED_LOGIN_DIALOG,
  CLOSE_NEW_CHAT_WIDGET,
  IncrementChatUnreadCountAction,
  INCREMENT_CHAT_UNREAD_COUNT,
  OpenChatConversationListAction,
  OpenNeedLoginDialogAction,
  OpenNewChatWidgetAction,
  OPEN_CHAT_CONVERSATION_LIST,
  OPEN_NEED_LOGIN_DIALOG,
  OPEN_NEW_CHAT_WIDGET,
  SetCurrentVideoIdAction,
  SetUserOnlineStatusAction,
  SET_CURRENT_VIDEO_ID,
  SET_USER_ONLINE_STATUS,
  ToggleChatMinimizeAction,
  TOGGLE_CHAT_MINIMIZE,
} from "./ui.actions";

type UIActions = OpenNewChatWidgetAction |
  CloseNewChatWidgetAction |
  ClearChatUnreadCountAction |
  IncrementChatUnreadCountAction |
  OpenChatConversationListAction |
  CloseChatConversationListAction |
  ToggleChatMinimizeAction |
  SetUserOnlineStatusAction |
  SetCurrentVideoIdAction |
  OpenNeedLoginDialogAction |
  CloseNeedLoginDialogAction;

export interface UIState {
  chatWidget: {
    open: boolean,
    uid: string,
    name: string,
    status: 'offline' | 'online',
    chatType: 'user' | 'group',
    minimized: boolean,
  },
  chatConversationList: {
    open: boolean,
  },
  chatUnreadCount: {
    [uid: string]: number
  },
  chatOnlineStatus: {
    [uid: string]: 'online' | 'offline'
  },
  videoPlayer: {
    currentVideoId: string,
  },
  needLoginDialog: {
    open: boolean,
    redirectURLAfterLogin: string,
    redirectParamsAfterLogin: string,
  },
  notificationUnread: number,
}

const initState: UIState = {
  chatWidget: {
    open: false,
    uid: '',
    name: '',
    status: 'offline',
    chatType: 'user',
    minimized: false,
  },
  chatUnreadCount: {},
  chatConversationList: {
    open: false,
  },
  chatOnlineStatus: {},
  videoPlayer: {
    currentVideoId: '',
  },
  needLoginDialog: {
    open: false,
    redirectURLAfterLogin: '',
    redirectParamsAfterLogin: '',
  },
  notificationUnread: 0
}

export const uiReducer = produce((state: UIState, action: UIActions) => {

  switch (action.type) {

    case OPEN_NEED_LOGIN_DIALOG: {
      state.needLoginDialog.open = true;
      state.needLoginDialog.redirectParamsAfterLogin = action.redirectParamsAfterLogin;
      state.needLoginDialog.redirectURLAfterLogin = action.redirectURLAfterLogin;
      break;
    }

    case CLOSE_NEED_LOGIN_DIALOG: {
      state.needLoginDialog.open = false;
      break;
    }

    case SET_CURRENT_VIDEO_ID: {
      state.videoPlayer.currentVideoId = action.videoId;
      break;
    }

    case SET_USER_ONLINE_STATUS: {
      state.chatOnlineStatus[action.uid] = action.status;
      break;
    }

    case TOGGLE_CHAT_MINIMIZE: {
      state.chatWidget.minimized = !state.chatWidget.minimized;
      break;
    }

    case OPEN_CHAT_CONVERSATION_LIST: {
      state.chatConversationList.open = true;
      break;
    }
    case CLOSE_CHAT_CONVERSATION_LIST: {
      state.chatConversationList.open = false;
      break;
    }

    case CLEAR_CHAT_UNREAD_COUNT: {
      const { uid } = action;
      if (state.chatUnreadCount[uid])
        state.chatUnreadCount[uid] = 0;
      break;
    }

    case INCREMENT_CHAT_UNREAD_COUNT: {
      const { uid } = action;
      const openedUID = state.chatWidget.uid;
      const isOpen = state.chatWidget.open;

      if (!state.chatUnreadCount[uid])
        state.chatUnreadCount[uid] = 0;

      // we don't want to increment for the current opened chats
      if (!isOpen || openedUID != uid)
        state.chatUnreadCount[uid] = state.chatUnreadCount[uid] + 1;
      break;
    }

    case OPEN_NEW_CHAT_WIDGET: {
      state.chatWidget.open = true;
      state.chatWidget.uid = action.uid;
      state.chatWidget.name = action.name;
      state.chatWidget.status = action.status;
      state.chatWidget.chatType = action.chatType;
      break;
    }

    case CLOSE_NEW_CHAT_WIDGET: {
      state.chatWidget.open = false;
      state.chatWidget.uid = '';
      state.chatWidget.chatType = 'user';
      state.chatWidget.status = 'offline';
      state.chatWidget.name = '';
      break;
    }

    // case SET_NOTIFICATION_COUNT: {
    //   state.notificationUnread = action.number
    // }
  }

}, initState);
