import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "../hooks";
import { RootState } from "../store/store";
import { closeNeedLoginDialog } from "../store/ui/ui.actions";
import { NeedLoginDialog } from "./need-login-dialog";

export function GlobalDialogs() {

  const openNeedLoginDialog = useSelector((state: RootState) => state.ui.needLoginDialog.open);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>

      <NeedLoginDialog
        open={openNeedLoginDialog}
        onClose={() => {
          dispatch(closeNeedLoginDialog({}));
        }}
        onLogin={() => {
          navigate('EmailAccountLoginBlock');
          dispatch(closeNeedLoginDialog({}));
        }}
      />

    </>
  );
}
