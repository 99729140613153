import React from 'react';
import { useFormikContext } from 'formik';
import { Watermark } from '../../../../../components/src/api-types';
import { defaultToEmptyArray } from '../../../../../components/src/default-values';
import { DezinerAlert, DezinerLabel, HideContainer } from '../../../../../components/src/design-system';
import { useWatermarks } from '../../../../../components/src/hooks';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';


export function WatermarksInput() {

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();

  const watermarksQuery = useWatermarks();
  const watermarks = defaultToEmptyArray<Watermark>(watermarksQuery?.data?.data)

  return (
    <>
      <div>
        <DezinerLabel>
          {'Choose watermark*'}
        </DezinerLabel>
        <Grid container wrap="nowrap">
          {
            watermarks?.map((item) => (
              <img
                key={item?.attributes?.id}
                className={clsx(classes.watermarkImg, {
                  [classes.watermarkImgSelected]: item?.attributes?.id == formik.values.watermarkId
                })}
                src={item?.attributes?.watermark_url}
                onClick={() => {
                  formik.setFieldValue('watermarkId', item?.attributes?.id);
                }}
              />
            ))
          }
          <div
            className={clsx(classes.watermarkImg, classes.noWatermark, {
              [classes.watermarkImgSelected]: -1 == formik.values.watermarkId
            })}
            onClick={() => {
              formik.setFieldValue('watermarkId', -1);
            }}
          >
            {'No Watermark'}
          </div>
        </Grid>

      </div>

      <HideContainer hideWhen={!formik?.errors?.watermarkId}>
        <DezinerAlert>
          {formik?.errors?.watermarkId}
        </DezinerAlert>
      </HideContainer>
    </>
  );
}
