import React, { ReactNode } from "react";
// Customizable Area Start
import { makeStyles } from "@material-ui/core/styles";
import {
  dezinerKnotLogo
} from "../../landingpage/src/assets";
import "./style.css";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import { useNavigate } from "../../../components/src/hooks";

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
  },
  logoImg: {
    height: "auto", maxWidth: "400px",
  },
  horizontalDivider: {
    background: '#afafaf',
    width: '70vw',
    height: '2px',
  },
  verticalDivider: {
    background: '#afafaf',
    width: '2px',
    height: '70vh',
  },
  formContainer: {
    ["@media screen and (max-width:600px)"]: {
      alignItems: 'flex-start'
    }
  },
  formRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
});



export default function RegisterationLayout({ children }: { children: ReactNode }) {

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container className={classes.root}>

      <Grid item xs={12} sm={5} container justifyContent="center" alignItems="center">
        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className={classes.logoImg}
          onClick={() => navigate('LandingPage')}
        />

        <Hidden smUp>
          <Divider className={classes.horizontalDivider} />
        </Hidden>
      </Grid>

      <Hidden xsDown>
        <Grid item xs={12} sm={1} container justifyContent="center" alignItems="center" >
          <Divider className={classes.verticalDivider} />
        </Grid>
      </Hidden>

      <Grid item
        xs={12}
        sm={6}
        container
        justifyContent="center"
        alignItems="center"
        className={classes.formContainer}>
        {children}
      </Grid>
    </Grid>
  );
}
