import { Category } from "../api-types";
import { categoriesMessages } from "../messages-generator";
import { categoriesKeys } from "../query-keys";
import { useDezinerQuery } from "../ReactQuery.web";

export function useCategories() {

  return useDezinerQuery<{ data: Category[] }>({
    message: categoriesMessages.fetchCategories(),
    queryKey: categoriesKeys.fetchCategories(),
  });
}

