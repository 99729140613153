import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LockIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { useCometChatGroupMembers } from './hooks';
import { openNewChatWidget, toggleChatMinimize } from '../../../components/src/store/ui/ui.actions';
import { useMyProfile } from '../../../components/src/hooks';

const useStyles = makeStyles({
  mainContainer: {
    width: '100%',
    paddingLeft: '20px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end'
  },
  mainLine: {
    margin: '5px 0px',
    cursor: 'pointer'
  },
  membersList: {
    position: 'absolute',
    left: '35%',
    zIndex: 99999,
    bottom: 0,
    marginBottom: '20px',
    background: 'white',
    borderRadius: '10px',
    border: '1px solid lightgrey',
    wordWrap: 'break-word',
  }
});


export default function PrivateChat() {
  const profileQuery = useMyProfile();
  const profileData = profileQuery?.data?.data?.attributes;

  const classes = useStyles();

  const uid = useSelector((state: RootState) => state.ui.chatWidget.uid);
  const minimized = useSelector((state: RootState) => state.ui.chatWidget.minimized);

  const membersList: any = useCometChatGroupMembers({ guid: uid });
  console.log('spec_members_list', membersList);

  const [showList, setShowList] = useState(false);
  const dispatch = useDispatch();

  function toggleMembers() {
    setShowList(!showList);
  }

  function openChat({ uid, name, onlineStatus, chatType }: {
    uid: string,
    name: string,
    onlineStatus: any,
    chatType: "user" | "group",
  }) {
    // queryClient.invalidateQueries(cometchatKeys.conversationsList({ uid: myUID }))
    // dispatch(clearChatUnreadCount({ uid }));
    dispatch(openNewChatWidget({ uid, name, status: onlineStatus as any, chatType }));
    if (minimized) {
      dispatch(toggleChatMinimize({}));
    }
  }

  return (
      <div className={classes.mainContainer}>
        <p onClick={toggleMembers} className={classes.mainLine}>
        <LockIcon style={{ fontSize: '12px' }} /> Private chat
        </p>
        <div style={{ display: showList ? 'block' : 'none' }} className={classes.membersList}>
          <List component="nav" aria-label="main mailbox folders">
            {membersList?.data?.map((member: any) => member.uid != profileData?.uid && (
              <>
                <ListItem button onClick={() => openChat({
                  uid: member.uid,
                  name: member.name,
                  onlineStatus: member.status,
                  chatType: "user"
                })}>
                  <ListItemText primary={member.name} />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </div>
      </div>
  );
}

