import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialPopover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import MaterialListItem from '@material-ui/core/ListItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { DezinerButton } from '../button';

const Popover = withStyles({
  paper: {
    borderRadius: '10px',
  },
})(MaterialPopover);

const ListItem = withStyles({
  root: {
    padding: '1px 6px',
  }
})(MaterialListItem);

const ListItemText = withStyles({
  root: {
    padding: '4px 2px',
    '&>*': {
      fontSize: '0.9rem',
      color: '#767779',
      '&:hover': {
        color: '#929395'
      }
    }
  }
})(MaterialListItemText);


const useDezinerDropdownStyles = makeStyles({
  button: {
    fontWeight: 700,
    fontSize: '0.9rem',
    color: '#767779',
    padding: '6px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandIcon: {
    color: "#767779"
  },
  listContainer: {
    padding: '10px 0px',
  },
  text: {
    fontSize: '0.9rem',
    padding: '0 10px',
  },
});

export function DezinerDropdown({
  options,
  onClick,
  selectedOption,
  title,
  position,
}: {
  title: string,
  options: { key: string, title: string }[],
  selectedOption: string,
  onClick: (option: string) => void,
  position?: 'left' | 'right',
}) {

  const classes = useDezinerDropdownStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'sort' : undefined;


  return (
    <>
      <DezinerButton
        aria-describedby={id}
        aria-label={title}
        className={classes.button}
        variant="outlined"
        color="default"
        onClick={handleClick}>
        {
          selectedOption ?
            options?.find(item => item.key == selectedOption)?.title
            :
            title
        }
        {
          !open ?
            <ExpandMore className={classes.expandIcon} />
            :
            <ExpandLess className={classes.expandIcon} />
        }
      </DezinerButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position || 'right',
        }}

      >
        <div className={classes.listContainer}>
          <List component="div" disablePadding>
            {
              options.map((item, index) => (
                <div key={item.key}>
                  <ListItem button onClick={() => {
                    onClick(item.key)
                    handleClose();
                  }}>
                    <ListItemText>
                      <Typography
                        className={classes.text}
                        style={{
                          fontWeight: item.key == selectedOption ? 'bold' : 'unset'
                        }}>
                        {item.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {
                    index < (options.length - 1) ?
                      <Divider />
                      :
                      null
                  }
                </div>
              ))
            }

          </List>

        </div>
      </Popover>
    </>
  )
}
