import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { DezinerButton, DezinerTextField, AutocompleteDropdown, FormikError, AutocompleteSelectorItem } from "../../../components/src/design-system";
import { useParams } from "../../../components/src/utils";
import {
  useAddMemberToGroup, useGroupsMembers, useGroupsNonRespondingMembers,
  useClubMembers, useClubsNonRespondingMembers,
  useClubsRoles, useDesignersList, useAddMemberToClub,
} from "../../../components/src/hooks";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { ClubMember, GroupMember, Profile } from "../../../components/src/api-types";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contribution: {
    color: 'gray',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    paddingRight: '5px'
  },
  contributionInput: {
    minWidth: "70px",
    maxWidth: '70px',
  }
});


const validationSchema = yup.object().shape({
  member: yup.object().shape({
    label: yup.string(),
    value: yup.number().moreThan(-1, 'Please choose a member').required('Please choose a member'),
  }),
  role: yup.object().shape({
    label: yup.string(),
    value: yup.number().moreThan(-1, 'Please choose a role').required('Please choose a role'),
  }),
  contribution: yup.number().moreThan(0, 'Please enter a contribution percent'),
});


const EMPTY_OPTION = { label: '', value: -1 }
type SelectedMember = "Pattern designer" | "Admin";




export function AddMemberForm() {

  const params = useParams();
  const groupId = Number(params.groupId);
  const clubId = Number(params.clubId);

  if (groupId)
    return <GroupAddMemberForm />
  if (clubId)
    return <ClubAddMemberForm />
  return null;
}

function ClubAddMemberForm() {

  const params = useParams();
  const clubId = Number(params.clubId);

  const clubsRolesQuery = useClubsRoles();
  let clubsRoles = defaultToEmptyArray<string>(clubsRolesQuery?.data?.user_types);
  clubsRoles = clubsRoles?.filter(role => role != "Owner")


  const membersQuery = useClubMembers({ clubId });
  const members = defaultToEmptyArray<ClubMember>(membersQuery?.data?.data);

  const nonRespondingMembersQuery = useClubsNonRespondingMembers({ clubId });
  const nonRespondingMembers = defaultToEmptyArray<ClubMember>(nonRespondingMembersQuery?.data?.data);

  const allMembers = members.concat(nonRespondingMembers);
  const allMembersIds = allMembers.map(member => member.attributes.account_id);

  const allContributions = allMembers
    .filter(member => member.attributes.contribution)
    .map(member => parseFloat(member.attributes.contribution.replace('%', '')));

  const allContributionsSum = allContributions.reduce((x, y) => x + y, 0);
  const allowedContribution = Math.abs(100 - allContributionsSum);

  const designerListQuery = useDesignersList();
  let designerList = defaultToEmptyArray<Profile>(designerListQuery?.data?.data);

  const { mutate: addMember, isLoading: loading } = useAddMemberToClub({ clubId });


  let filteredDesignerList = designerList
    .filter((designer) => !allMembersIds.includes(designer.attributes.id));

  const membersOptions =
    filteredDesignerList
      .map(function mapToOption(member) {
        return {
          label: member.attributes.first_name,
          value: member.attributes.id,
        }
      })
      .concat(EMPTY_OPTION);



  const rolesOptions = clubsRoles
    ?.map((role, index) => ({ label: role, value: index }))
    .concat(EMPTY_OPTION);


  function onSubmit(values: OnSubmitParams) {
    addMember({
      clubId,
      ...values,
    });
  }

  const addMemberFormUIProps = {
    onSubmit,
    allowedContribution,
    loading,
    membersOptions,
    rolesOptions,
  }


  return (<AddMemberFormUI {...addMemberFormUIProps} />);

}


function GroupAddMemberForm() {

  const params = useParams();
  const groupId = Number(params.groupId);

  const clubsRolesQuery = useClubsRoles();
  let clubsRoles = defaultToEmptyArray<string>(clubsRolesQuery?.data?.user_types);
  clubsRoles = clubsRoles?.filter(role => role != "Owner")


  const membersQuery = useGroupsMembers({ groupId });
  const members = defaultToEmptyArray<GroupMember>(membersQuery?.data?.data);

  const nonRespondingMembersQuery = useGroupsNonRespondingMembers({ groupId });
  const nonRespondingMembers = defaultToEmptyArray<GroupMember>(nonRespondingMembersQuery?.data?.data);

  const allMembers = members.concat(nonRespondingMembers);
  const allMembersIds = allMembers.map(member => member.attributes.account_id);

  const allContributions = allMembers
    .filter(member => member.attributes.contribution)
    .map(member => parseFloat(member.attributes.contribution.replace('%', '')));

  const allContributionsSum = allContributions.reduce((x, y) => x + y, 0);
  const allowedContribution = Math.abs(100 - allContributionsSum);

  const designerListQuery = useDesignersList();
  let designerList = defaultToEmptyArray<Profile>(designerListQuery?.data?.data);

  const { mutate: addMember, isLoading: loading } = useAddMemberToGroup({ groupId });


  let filteredDesignerList = designerList
    .filter((designer) => !allMembersIds.includes(designer.attributes.id));

  const membersOptions =
    filteredDesignerList
      .map(function mapToOption(member) {
        return {
          label: member.attributes.first_name,
          value: member.attributes.id,
        }
      })
      .concat(EMPTY_OPTION);



  const rolesOptions = clubsRoles
    ?.map((role, index) => ({ label: role, value: index }))
    .concat(EMPTY_OPTION);


  function onSubmit(values: OnSubmitParams) {
    addMember({
      groupId,
      ...values,
    });
  }

  const addMemberFormUIProps = {
    onSubmit,
    allowedContribution,
    loading,
    membersOptions,
    rolesOptions,
  }


  return (<AddMemberFormUI {...addMemberFormUIProps} />);

}

interface OnSubmitParams {
  accountId: number,
  user_type: SelectedMember,
  contribution: number,
}

function AddMemberFormUI({
  onSubmit,
  allowedContribution,
  loading,
  membersOptions,
  rolesOptions,
}: {
  loading: boolean,
  onSubmit: (values: OnSubmitParams) => void,
  allowedContribution: number,
  membersOptions: AutocompleteSelectorItem[],
  rolesOptions: AutocompleteSelectorItem[],
}) {

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        member: EMPTY_OPTION,
        role: EMPTY_OPTION,
        contribution: 0,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={({ member, contribution, role }, formik) => {
        if (contribution > allowedContribution)
          formik.setFieldError('contribution', `You can't add a contribution percent over ${allowedContribution}%`);

        else
          onSubmit({
            accountId: member.value,
            user_type: role.label as SelectedMember,
            contribution,
          });
      }}
      validationSchema={validationSchema}
    >
      {
        formik => <Form translate="yes">

          <Grid container justifyContent="space-between" alignItems="flex-start">

            <Grid item xs={3} container direction="column" alignItems="flex-start">

              <AutocompleteDropdown
                options={membersOptions}
                onChange={(newMember) => formik.setFieldValue('member', newMember)}
                inputValue={formik.values.member}
                placeholder={'Add member'}
              />

              <FormikError name="member" errorProperty="value" />

            </Grid>
            <Grid item xs={3} container direction="column" alignItems="flex-start">

              <AutocompleteDropdown
                options={rolesOptions}
                onChange={(newRole) => formik.setFieldValue('role', newRole)}
                inputValue={formik.values.role}
                placeholder={'Role'}
              />

              <FormikError name="role" errorProperty="value" />

            </Grid>
            <Grid item xs={3} container direction="column" alignItems="flex-start">

              <Grid container alignItems="center" wrap="nowrap">
                <Typography className={classes.contribution}>
                  {'Contribution %'}
                </Typography>

                <DezinerTextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  // @ts-ignore
                  max={100}
                  min={0}
                  value={formik.values.contribution}
                  onChange={(e) => {
                    let value = Number(e.target.value);
                    if (value >= 0)
                      formik.setFieldValue('contribution', value)
                  }}
                  className={classes.contributionInput}
                />
              </Grid>

              <FormikError name="contribution" />

            </Grid>
            <Grid item xs={1}>

              <DezinerButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading}
                type="submit"
              >
                {'Add'}
              </DezinerButton>
            </Grid>

          </Grid>

        </Form>
      }
    </Formik>
  );
}
