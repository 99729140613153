import React  from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {DezinerAudio} from "../../../components/src/design-system";


const useCommentAudioStyles = makeStyles({
  audioContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px'
  },
});

export function CommentAudio({
  base64Audio,
  onDelete,
}: {
  base64Audio: string,
  onDelete: () => void,
}) {
  const classes = useCommentAudioStyles();

  if (!base64Audio)
    return null;

  return (
    <div className={classes.audioContainer}>
      <DezinerAudio
        src={base64Audio}
      />
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

