import { NavigateToAction } from "./navigation.action";
import { push } from 'connected-react-router';
import { put } from "redux-saga/effects";

export function* navigateToSaga(action: NavigateToAction) {

  let url = action.url;


  if (url)
    yield put(push(url.startsWith('/') ? url : `/${url}`))
}
