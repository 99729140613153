import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';
import { ProfileAvatarUI } from '../../../components/src/common';
import { Club, Group } from '../../../components/src/api-types';
import { DezinerChip, DezinerButton, HideContainer } from '../../../components/src/design-system';

const useStyles = makeStyles({
  container: {
    width: 'auto',
    padding: '20px 0',
    marginBottom: '10px',
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '92%',
      height: '1px',
      background: '#d5d5d5'
    },
  },
  hideBorder: {
    '&::after': {
      background: 'transparent'
    }
  },
  img: {
    width: '60px', height: '60px', borderRadius: '40%',
  },
  textContentContainer: {
    padding: '0 12px'
  },
  linkStyle: {
    color: 'black', textDecoration: 'none'
  },
  nameStyle: {
    color: '#27282A',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    cursor: "pointer",
  },
  tag: {
    fontSize: "1rem",
    background: "black",
    color: "white",
    height: "26px",
    padding: "12px 0px",
    margin: "0 10px",
    fontWeight: "bold"
  },
  members: {
    color: '#97989A', fontSize: '1rem', fontWeight: 'bold',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px 0'
  },
  btn: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    padding: '6px 16px',
  }
});



export default function GroupAndClubHorizontalCard({
  item,
  onClick,
  buttonTitle,
  tagName,
  hideMembers,
  hideButton,
  hideBorder,
  onNameClick,
}: {
  item?: Club | Group,
  onClick?: (item: any) => void,
  buttonTitle?: string,
  tagName?: string,
  hideMembers?: boolean,
  hideButton?: boolean,
  hideBorder?: boolean,
  onNameClick?: () => void,
}) {

  const classes = useStyles();


  return (
    <Grid container
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.container, {
        [classes.hideBorder]: hideBorder
      })}
    >

      <Grid item xs={2} sm={2} container justifyContent="flex-end">
        <ProfileAvatarUI
          image={item?.attributes?.image_url}
          alt={item?.attributes?.name}
          onClick={onNameClick}
        />
      </Grid>

      <Grid item xs sm container direction="column" className={classes.textContentContainer}>

        <Grid container alignItems="center">
          <Typography
            onClick={onNameClick}
            variant="h4"
            className={classes.nameStyle}>
            {item?.attributes?.name}
          </Typography>

          <HideContainer hideWhen={!tagName}>
            <DezinerChip label={tagName} className={classes.tag} />
          </HideContainer>

        </Grid>

        <HideContainer hideWhen={hideMembers}>
          <Typography variant="body2" className={classes.members}>
            {
              (item?.attributes?.members?.data as any)
                ?.map((member: any) => member?.attributes?.first_name)
                .join(" / ")
            }
          </Typography>
        </HideContainer>
      </Grid>

      <HideContainer hideWhen={hideButton}>
        <Grid item xs={12} sm={3} container justifyContent="flex-end">
          <DezinerButton
            color="primary"
            variant="outlined"
            size="small"
            className={classes.btn}
            onClick={() =>
              onClick && onClick(item)
            }
          >
            {buttonTitle}
          </DezinerButton>
        </Grid>
      </HideContainer>

    </Grid>
  )
}
