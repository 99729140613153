import React from "react";
// Customizable Area Start
import { makeStyles } from '@material-ui/core/styles';
import DesignerFollowerPageByUserController, {
  Props
} from "./DesignerFollowerPageByUserController.web";
import { useParams } from "../../../components/src/utils";
import { useDesignerFollowers } from '../../../components/src/hooks';
import FollowerCard from "./FollowerCard";
import { DezinerSpinner } from "../../../components/src/design-system";
// Customizable Area End

class DesignerFollowerPageByUser extends DesignerFollowerPageByUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <DesignerFollowersContent />
    );
  }
}

const useStyles = makeStyles({
  followersContainer: {
    padding: '40px 10px',
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    justifyContent: 'space-around',
    alignItems: 'start',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: '100%',
      justifyContent: 'center',
    }
  },
});


function DesignerFollowersContent() {

  const params = useParams();
  const designerId = Number(params.designerId);
  const classes = useStyles();

  const followersQuery = useDesignerFollowers({ designerId });
  const followers = followersQuery?.data?.data ?? [];

  if (followersQuery.isLoading)
    return <DezinerSpinner />;


  return (
    <>
      <div className={classes.followersContainer}>
        {
          followers?.map(follower => (
            <FollowerCard key={follower.id} follower={follower} />
          ))
        }
      </div>
    </>
  );
}

// Customizable Area Start
export default DesignerFollowerPageByUser;
// Customizable Area End
