import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import {DezinerButton} from '../../../components/src/design-system';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  titleText: {
fontSize: "1.3rem", fontWeight: "bold"
  },
  contentText: {
 color: "#6e6e6e", fontSize: "1.1rem",
  },
});



export function NeedLoginDialog({
  open,
  onClose,
  onLogin,
}: {
  open: boolean,
  onClose: () => void,
  onLogin: () => void,
}) {


  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      className={classes.root}
    >
      <DialogTitle>
        <Typography className={classes.titleText}>
          {'You need to login first'}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>

        <Typography className={classes.contentText}>
          {'Forum Page requires you to be logged in, please click on Login button below.'}
        </Typography>

      </DialogContent>

      <DialogActions>
        <DezinerButton variant="contained" onClick={onClose}>
          {'Cancel'}
        </DezinerButton>
        <DezinerButton color="primary" variant="contained" onClick={onLogin}>
          {'LOGIN'}
        </DezinerButton>
      </DialogActions>

    </Dialog>
  );
}
