Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.labelApi = 'bx_block_categories/labels';
exports.exampleApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.collectionsApi = 'account_block/collections';
exports.profileApi = 'account_block/accounts/profile';
exports.addCollectionApi = 'account_block/collections';
exports.addNewLabel = 'bx_block_categories/labels';
exports.watermarksApi = 'bx_block_attachment/watermarks';
exports.publishDesignApi = 'bx_block_attachment/designs'
exports.getClubApi = 'bx_block_groups/clubs'
exports.getClubLabelsApi = 'bx_block_categories/labels'
exports.createClubLabelApi = 'bx_block_categories/labels'
exports.getClubCollections = 'account_block/collections';
exports.getCategoriesApi = 'bx_block_categories/categories';
exports.followApi = 'bx_block_followers/follows';
exports.deleteMethod = 'DELETE';
exports.groupApi = 'bx_block_groups/groups';
exports.changeCoverDesigner = "account_block/accounts/change_cover";
exports.putMethod = "PUT";
exports.totalDesigns = "bx_block_attachment/designs/design_count";
exports.pinCollection = "bx_block_cfpindesigns2/pins";
exports.unPinCollection = "bx_block_cfpindesigns2/pins/unpin";
exports.designApi = 'bx_block_attachment/designs';
exports.fetchSourceFilesAPI = 'bx_block_attachment/designs/:id/source_designs';
exports.fetchSourceFilesMethod = 'GET';
// Customizable Area End
