import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import StarIcon from "@material-ui/icons/Star";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {  Design, DesignHome } from '../api-types';
import { makeStyles } from "@material-ui/core/styles";
import {DezinerMedia} from '../design-system';
import { statisticsFormatter } from '../utils';


const useCollectionListCardStyles = makeStyles({
  postImageCard: {
    background: "#F3F4F6",
    height: "150px",
    width: "90%",
    borderRadius: "15px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 5px",
    '& img, & video': {
      borderRadius: "15px",
      height: "100%",
      width: "100%",
      objectFit: "cover"
    },
    '&:hover $postImageCardOverlay': {
      display: 'flex',
    },
  },
  postImageCardOverlay: {
    display: "none",
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    borderRadius: "15px",
    top: "0",
    height: "100%",
    width: "100%",
    padding: "20px",
    transition: "all 0.5s ease",
    flexDirection: "column",
    justifyContent: "space-between",
    '& h4': {
      margin: 0,
    },
  },
  postImageCardDetails: {
    fontSize: "14px",
    fontWeight: 500,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    width: "100%",
    alignItems: "center",
    "& div:nth-last-child(-n+2)": {
      justifySelf: 'end',
    },
    '& p': {
      margin: '0',
    },
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      '&>span': {
        padding: '0 4px',
      }
    },
  },
});


export function CollectionListCard({
  designData,
  collectionId,
  imgHeight,
  onClick,
}: {
  designData: Design | DesignHome,
  collectionId: number,
  imgHeight: number,
  onClick: (params: { collectionId: number, designId: number }) => void,
}) {

  const classes = useCollectionListCardStyles();

  const {
    title, like_count,
    inspired_count, rating_count, image_url, privacy, main_image_type,
    watermark_url, width, height,
  } = designData?.attributes || {};


  function clickHandler() {
    onClick({
      designId: designData?.attributes?.id,
      collectionId,
    });
  }



  return (
    <div
      className={classes.postImageCard}
      style={{ position: "relative", height: `${imgHeight || 150}px`,  }}
      onClick={clickHandler}
    >

      {
        image_url ?
          <DezinerMedia
            id={designData.id}
            mediaType={main_image_type?.includes('video/') ? 'video' : 'image'}
            style={{ height: `${imgHeight || 150}px` }}
            className="deziner-img"
            src={image_url}
            alt={title}
            watermarkURL={watermark_url}
            watermarkWidth={Number(width || 100)}
            watermarkHeight={Number(height || 100)}
          />
          :
          <AddIcon style={{ color: '#CBCBCB' }} fontSize="large" />
      }

      {
        image_url && title && privacy ?
          <div className={classes.postImageCardOverlay}>
            <h4>{title}</h4>
            <div className={classes.postImageCardDetails}>
              <p>{privacy}</p>
              <div>
                <EmojiObjectsIcon style={{ fontSize: 14 }} />
                <span> {statisticsFormatter(inspired_count)} </span>
              </div>
              <div>
                <StarIcon style={{ fontSize: 14 }} />
                <span> {statisticsFormatter(rating_count)} </span>
              </div>
              <div>
                <FavoriteIcon style={{ fontSize: 14 }} />{" "}
                <span> {statisticsFormatter(like_count)} </span>
              </div>
            </div>
          </div>
          :
          null
      }

    </div>
  );
}

