import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { DesignComment, DesignReply } from "../api-types";
import { commentsMessages } from "../messages-generator";
import { commentsKeys, designsKeys, forumsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";

export function useRepliesToReply({ replyId }: { replyId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: DesignReply[] }>({
    message: commentsMessages.fetchRepliesToReply({ replyId }),
    queryKey: commentsKeys.fetchRepliesToReply({ replyId }),
    options,
  });
}

export function useComments({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: DesignComment[] }>({
    message: commentsMessages.fetchComments({ designId }),
    queryKey: commentsKeys.fetchComments({ designId }),
  });
}

export function useLikeComment({ commentId, designId, parentCommentId }: { commentId: number, designId: number, parentCommentId?: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof commentsMessages.likeComment>[0]>({
    messageGenerator: commentsMessages.likeComment,
    queryKey: commentsKeys.likeComment({ commentId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(commentsKeys.fetchComments({ designId }));
        queryClient.invalidateQueries(forumsKeys.fetchSingleForumComments({ id: designId }));

        if (parentCommentId)
          queryClient.invalidateQueries(commentsKeys.fetchRepliesToReply({ replyId: parentCommentId }));
      },
    },
  });
}

export function usePostComment({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof commentsMessages.postComment>[0]>({
    messageGenerator: commentsMessages.postComment,
    queryKey: commentsKeys.postComment({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(commentsKeys.fetchComments({ designId }));
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));
      },
    }
  });
}

export function usePostReply({ designId, parentCommentId }: { designId: number, parentCommentId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof commentsMessages.postReplyComment>[0]>({
    messageGenerator: commentsMessages.postReplyComment,
    queryKey: commentsKeys.postReplyComment({ designId, parentCommentId }),
    options: {
      onSuccess: () => {
        // because the same hook is used for both forums and designs we need to invalidate both
        queryClient.invalidateQueries(forumsKeys.fetchSingleForum({ id: designId }));
        queryClient.invalidateQueries(forumsKeys.fetchSingleForumComments({ id: designId }));
        queryClient.invalidateQueries(commentsKeys.fetchComments({ designId }));
        queryClient.invalidateQueries(commentsKeys.fetchRepliesToReply({ replyId: parentCommentId }));
      },
    },
  });
}

