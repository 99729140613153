import React, { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { DezinerLabel, DezinerSpinner } from "../../../components/src/design-system";
import { useParams } from "../../../components/src/utils";
import { useForumsComments, useIsLoggedIn, usePostCommentForum, useSingleForum } from "../../../components/src/hooks";
import { DesignComment } from "../../../components/src/api-types";
import { CommentInput } from "../../Comments/src/CommentInput";
import { Comment } from "../../Comments/src/Comments";
import { ForumCardContainer } from "./forum-card-container";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { useDispatch } from "react-redux";
import { openNeedLoginDialog } from "../../../components/src/store/ui/ui.actions";




const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: '40px 100px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1.6fr 1fr',
    gridColumnGap: '20px',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: '100%',
      padding: '20px 0px',
    }
  },
  commentsContainer: {
    padding: '0 36px',
  },
});


export function SingleForumPage() {

  const params = useParams();
  const forumId = parseInt(params.id);

  const inputRef = useRef<HTMLInputElement>();

  const forumQuery = useSingleForum({ id: forumId });

  const isLoggedIn = useIsLoggedIn();
  const commentsQuery = useForumsComments({ id: forumId });
  const comments = defaultToEmptyArray<DesignComment>(commentsQuery?.data?.data);

  const { mutate: postComment } = usePostCommentForum({ forumId });

  const classes = useStyles();
  const dispatch = useDispatch();

  function onAnonymousInteraction() {
    console.log('on anonymous interaction');
    dispatch(openNeedLoginDialog({
      redirectURLAfterLogin: `ForumSingle`,
      redirectParamsAfterLogin: JSON.stringify({ id: forumId }),
    }))
  }

  if (forumQuery.isLoading)
    return <div style={{ flexGrow: 1, }}>
      <DezinerSpinner />
    </div>

  return (
    <>


      <div className={classes.root}>


        <ForumCardContainer
          singlePageMode
          forum={forumQuery?.data?.forum?.data}
          onAnonymousInteraction={onAnonymousInteraction}
          commentHandler={() => {
            if (inputRef && inputRef.current)
              inputRef.current.focus();
          }}
        />

        <div className={classes.commentsContainer}>
          <DezinerLabel>
            {'Comments'}
          </DezinerLabel>

          <CommentInput
            inputRef={inputRef as any}
            onComment={({ commentText, base64Image, base64Audio, }) => {
              if (isLoggedIn)
                postComment({ commentText, forumId, audio: base64Audio, attachement: base64Image })
              else
                onAnonymousInteraction()
            }}
          />

          <Divider />


          {
            comments?.map((comment) =>
              <Comment
                key={comment.id}
                replyPlaceholder="Write reply"
                comment={comment}
                designId={forumId}
                onAnonymousInteraction={onAnonymousInteraction}
              />
            )
          }

        </div>
      </div>

    </>
  );
}


