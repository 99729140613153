import React, { useState } from "react";
// Customizable Area Start
// Customizable Area End
import ClubHomePageController, { Props } from "./ClubHomePageController.web";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useClubLayoutParsedQueryParams, useChangeLabelInClubLayout, useIsClubMember } from "./ClubLayout.web";
import { useClubLabels, useClubsCollections, useClubsDesignsByCategory, useDecodedToken, useNavigate, usePinCollection } from "../../../components/src/hooks";
import {
  star,
  lightbulb,
  trending_up,
  edit,
  love,
} from "../../landingpage/src/assets";
import { CollectionHome, Design, Label } from "../../../components/src/api-types";
import { LoadingSpinnerContainer, NoDataContainer, CollectionGirdCards, VerticalSpacer } from "../../../components/src/design-system";
import { defaultToEmptyArray } from "../../../components/src/default-values";
import { DesignCardsResponsiveContainer, DezinerCollectionList, LabelsDropdown, PromoteDialog } from "../../../components/src/common";


class ClubHomePage extends ClubHomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <ClubHomePageContent />
    );
  }
}


const categoryLogoMapper: any = {
  collections: '',
  designs: edit,
  liked: love,
  promoted: trending_up,
  inspired: lightbulb,
  rated: star,
}

const All_LABEL: Label = {
  id: "0",
  type: 'labels',
  attributes: {
    id: 0,
    name: "All",
    club_id: 0,
    group_id: 0,
    created_at: "",
    label_type: "Club",
    updated_at: ""
  }
}


function ClubHomePageContent() {

  const { clubId, labelId, categoryQueryParam } = useClubLayoutParsedQueryParams();

  const labelsQuery = useClubLabels({ clubId });
  const labels = [All_LABEL].concat(defaultToEmptyArray<Label>(labelsQuery?.data?.data));

  const changeLabel = useChangeLabelInClubLayout();

  return (
    <div style={{ padding: '20px 20px 80px' }}>

      <VerticalSpacer />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >

        <Grid xs={6} item container
          justifyContent="flex-start"
          alignItems="center"
        >
          {
            categoryLogoMapper?.[categoryQueryParam] ?
              <img
                style={{ maxWidth: '20px', marginRight: '10px', }}
                src={categoryLogoMapper?.[categoryQueryParam]}
                alt={categoryQueryParam}
              />
              :
              null
          }
          <Typography style={{ fontWeight: 'bold' }}>
            {categoryQueryParam?.toUpperCase()}
          </Typography>
        </Grid>

        <LabelsDropdown
          labels={labels}
          currentLabel={labelId}
          onChange={changeLabel}
        />
      </Grid>



      <div style={{ margin: '30px 0' }}>
        <Designs />
      </div>

    </div>
  );
}

function Designs() {

  const { clubId, labelId, category, categoryQueryParam } = useClubLayoutParsedQueryParams();

  const displayCollectionsAsList = !categoryQueryParam;

  const collectionsQuery = useClubsCollections({ clubId, labelId });

  const designsQuery = useClubsDesignsByCategory({ clubId, labelId, category }, {
    enabled: category != 'collections'
  });

  const navigate = useNavigate();

  const collections = defaultToEmptyArray<CollectionHome>(collectionsQuery?.data?.data);
  const collectionsLoading = collectionsQuery.isLoading;

  const designs = defaultToEmptyArray<Design>(designsQuery?.data?.data);
  const designsLoading = designsQuery.isLoading;

  const { id: myProfileId } = useDecodedToken();
  const { mutate: pinCollection } = usePinCollection({ labelId, designerId: myProfileId, clubId });


  const [promoteDialog, setPromoteDialog] = useState<{
    id: number,
    open: boolean,
    mediaSrc: string,
    mediaType: 'video' | 'image',
  }>({
    id: 0,
    open: false,
    mediaSrc: '',
    mediaType: 'image',
  });

  const isMember = useIsClubMember({ clubId });
  const showCollectionOptions = isMember;

  if (displayCollectionsAsList)
    return (<div>

      <PromoteDialog
        id={String(promoteDialog.id)}
        promoteType="Collection"
        onClose={() => setPromoteDialog({ id: 0, open: false, mediaType: 'image', mediaSrc: '' })}
        open={promoteDialog.open}
        mediaProps={{
          id: String(promoteDialog.id),
          src: promoteDialog.mediaSrc,
          mediaType: promoteDialog.mediaType,
        }}
      />

      <LoadingSpinnerContainer loading={collectionsLoading} >
        <NoDataContainer dataLength={collections.length} >
          {
            collections?.map((collection) => (
              <DezinerCollectionList
                key={collection.id}
                hideCollectionOptions={!showCollectionOptions}
                onDesignClick={(design: any) => {
                  navigate(`DesignView/${design.id}`);
                }}
                onPromote={({ collectionId }) => {
                  setPromoteDialog({
                    id: collectionId,
                    mediaType: collection?.attributes?.designs?.data?.[0]?.attributes?.main_image_type?.includes('video') ? 'video' : 'image',
                    mediaSrc: collection?.attributes?.designs?.data?.[0]?.attributes?.image_url,
                    open: true,
                  });
                }}
                collection={collection}
                onPin={() => {
                  pinCollection({
                    collectionId: collection.attributes.id,
                    pin: !collection.attributes.pined
                  })
                }}
                onCreate={() => { }}
              />
            ))
          }
        </NoDataContainer>
      </LoadingSpinnerContainer>
    </div>
    );


  if (category == 'collections') {

    return <LoadingSpinnerContainer loading={collectionsLoading} >
      <NoDataContainer dataLength={collections.length} >
        <CollectionGirdCards
          collection={collections}
          onClick={(collection) => navigate(`collection/${collection.id}?label_id=${collection.attributes.label_id}&club_id=${clubId}`)}
        />
      </NoDataContainer>
    </LoadingSpinnerContainer>

  } else {

    return <LoadingSpinnerContainer loading={designsLoading} >
      <NoDataContainer dataLength={designs.length} >
        <DesignCardsResponsiveContainer
          designs={designs}
          onClick={(item) => {
            navigate(`DesignView/${item?.id}`);
          }}
          onTrend={() => { }}
          onEdit={(item) => {
            navigate(`DesignerDesignEdit/${item?.id}`);
          }}
        />
      </NoDataContainer>
    </LoadingSpinnerContainer>

  }

}


// Customizable Area Start
export default ClubHomePage;
// Customizable Area End
