import { useQueryClient } from "@tanstack/react-query";
import { DesignComment, Forum } from "../api-types";
import { forumMessages } from "../messages-generator";
import { forumsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";




export function usePostCommentForum({ forumId }: { forumId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof forumMessages.postCommentForum>[0]>({
    messageGenerator: forumMessages.postCommentForum,
    queryKey: forumsKeys.postCommentForum({ forumId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(forumsKeys.fetchSingleForumComments({ id: forumId }));
        queryClient.invalidateQueries(forumsKeys.fetchSingleForum({ id: forumId }));
      }
    },
  });
}

export function useForumsComments({ id }: { id: number }) {

  return useDezinerQuery<{ data: DesignComment[] }>({
    message: forumMessages.fetchForumComments({ id }),
    queryKey: forumsKeys.fetchSingleForumComments({ id }),
  });
}

export function useSingleForum({ id }: { id: number }) {

  return useDezinerQuery<{ forum: { data: Forum } }>({
    message: forumMessages.fetchSingleForum({ id }),
    queryKey: forumsKeys.fetchSingleForum({ id }),
  });
}

export function useLikeForum({ id }: { id: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof forumMessages.likeForum>[0]>({
    messageGenerator: forumMessages.likeForum,
    queryKey: forumsKeys.likeForum({ id }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(forumsKeys.fetchForums());
        queryClient.invalidateQueries(forumsKeys.fetchSingleForum({ id }));
      }
    }
  });
}

export function useDislikeForum({ id }: { id: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof forumMessages.dislikeForum>[0]>({
    messageGenerator: forumMessages.dislikeForum,
    queryKey: forumsKeys.dislikeForum({ id }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(forumsKeys.fetchForums());
        queryClient.invalidateQueries(forumsKeys.fetchSingleForum({ id }));
      }
    }
  });
}


export function useForums() {

  return useDezinerQuery<{ data: Forum[] }>({
    message: forumMessages.fetchForums(),
    queryKey: forumsKeys.fetchForums(),
  });
}

export function useCreateForum() {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof forumMessages.createForum>[0]>({
    messageGenerator: forumMessages.createForum,
    queryKey: forumsKeys.createForum(),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(forumsKeys.fetchForums());
      }
    }
  });
}
