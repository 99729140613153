import React, { useState } from 'react';
import { defaultProfile } from '../../../../../components/src/default-values';
import clsx from 'clsx';
import { DezinerButton } from '../../../../../components/src/design-system';
import { useProfile } from '../../../../../components/src/hooks';
import { subscribeIcon } from '../../assets';
import UserSubscriptionPopup from '../../../../../components/src/UserSubscriptionPopup.web';
import { makeStyles, } from '@material-ui/core/styles';
import { PostedBy, Profile } from '../../../../../components/src/api-types';

const useStyles = makeStyles({
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
  aboutButton: {
    '&:hover': {
      background: 'black',
      color: 'white',
    }
  },
  floatButtonActive: {
    background: 'black !important',
    color: 'white !important',
  },
});

interface SubscribeDesignerButtonUIProps {
  onClick: () => void,
  isSubscribed: boolean,
}

export function SubscribeDesignerButton({
  designerId,
  children,
  designer,
}: {
  designerId: number,
  children?: (props: SubscribeDesignerButtonUIProps) => JSX.Element,
  designer?: Profile['attributes'] | PostedBy['attributes']
}) {

  const [showSubscription, setShowSubscription] = useState(false);

  const profileData = designer;
  const isSubscribed = profileData?.subscribed ?? false;

  const uiProps: SubscribeDesignerButtonUIProps = {
    isSubscribed,
    onClick: () => setShowSubscription(true),
  }

  let name = '', image = '', color = '';

  if (profileData?.first_name)
    name = profileData.first_name;

  if (profileData?.profile_url)
    image = profileData.profile_url;

  if (profileData?.color)
    color = profileData.color;

  return (
    <>

      {/* <UserSubscriptionPopup
        open={showSubscription}
        onClose={() => setShowSubscription(false)}
        designerId={designerId}
        subscriptionType={"AccountBlock::Account"}
        designerName={name}
        designerProfile={image}
        fallbackColor={color}
      /> */}

      {
        children ?
          children(uiProps)
          :
          <SubscribeDesignerButtonUI {...uiProps} />
      }
    </>
  );

}


function SubscribeDesignerButtonUI({
  onClick,
  isSubscribed,
}: SubscribeDesignerButtonUIProps) {

  const classes = useStyles();

  return (
    <>
      <DezinerButton
        variant="contained"
        onClick={onClick}
        startIcon={(isHovered: boolean) => <img src={subscribeIcon} style={{ maxWidth: '20px' }} className={clsx({ "white-svg": isHovered })} />}
        className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: false })}
      >
        {isSubscribed ? 'Subscribed' : 'Subscribe'}
      </DezinerButton>
    </>
  );
}
