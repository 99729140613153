import { Club } from "../api-types";

export const DEFAULT_CLUB_ATTRIBUTES: Club['attributes'] = {
  "id": 0,
  "name": '',
  "about": '',
  subscribed: false,
  "image_url": '',
  "cover_url": '',
  "account_type": "Member",
  "follower_count": 0,
  "following_count": 0,
  followed: false,
  "totel_collections": 0,
  "totel_designs": 0,
  "totel_like_designs": 0,
  "totel_promoted_designs": 0,
  "totel_inspired_designs": 0,
  "totel_rated_designs": 0,
  "totel_free_designs": 0,
  "totel_paid_designs": 0,
  "location": '',
  "is_membered": false,
  "member_since": '',
  "facebook_url": '',
  "instagram_url": '',
  "twitter_url": '',
  "created_at": '',
  "updated_at": '',
  members: { data: [] },
  "skills": { "data": [] },
  gid: '',
}

export function defaultClub(data?: Club['attributes']): Club['attributes'] {

  if (data) return data;
  else
    return DEFAULT_CLUB_ATTRIBUTES;
}
