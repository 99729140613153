import React from "react";
// Customizable Area Start
import ClubCreateCollectionController, {
  Props,
} from "./ClubCreateCollectionController.web";
import { useClubLayoutParsedQueryParams } from "./ClubLayout.web";
import { useClubLabels, useClubsCollections, useCreateClubCollection, useDecodedToken, useNavigate, usePinCollection } from "../../../components/src/hooks";
import { DezinerCollectionList , CreateCollectionForm } from "../../../components/src/common";
import { LoadingSpinnerContainer, NoDataContainer } from "../../../components/src/design-system";
// Customizable Area End



class ClubCreateCollection extends ClubCreateCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return <ClubCreateCollectionContent />;
  }
}

function ClubCreateCollectionContent() {


  const { clubId, labelId, } = useClubLayoutParsedQueryParams();

  const collectionsQuery = useClubsCollections({ clubId, labelId });

  const collections = collectionsQuery?.data?.data ?? [];
  const collectionsLoading = collectionsQuery.isLoading;

  const { id: myProfileId } = useDecodedToken();
  const { mutate: pinCollection } = usePinCollection({ labelId, designerId: myProfileId, clubId });

  const navigate = useNavigate();


  return (
    <div
      style={{
        padding: '0 40px',
        minHeight: '100vh',
      }}
    >

      <CreateCollectionFormContainer />

      <LoadingSpinnerContainer loading={collectionsLoading}>
        <NoDataContainer dataLength={collections.length}>
          {collections?.map((collection) =>
            <DezinerCollectionList
              key={collection.id}
              onDesignClick={(design) => {
                navigate(`DesignView/${design.id}`);
              }}
              collection={collection}
              onCreate={({ collectionId }) => {
                navigate(`DesignerDesignUpload/${labelId}/${collection.attributes.id}/club/${clubId}`);
              }}
              onPin={() => pinCollection({ pin: !collection.attributes.pined, collectionId: collection.attributes.id })}
              onPromote={() => { }}
              showAddSlots
            />
          )}
        </NoDataContainer>
      </LoadingSpinnerContainer>

    </div>
  );
}

function CreateCollectionFormContainer() {

  const { clubId, labelId } = useClubLayoutParsedQueryParams();

  const labelsQuery = useClubLabels({ clubId });
  const hasLabels = Boolean(labelsQuery?.data?.data?.length ?? 0);

  const { mutate: createCollection, isLoading: createCollectionLoading } =
    useCreateClubCollection({ clubId });

  return (
    <CreateCollectionForm
      selectedALabel={Boolean(labelId)}
      onSubmit={(values) => {
        createCollection({ ...values, labelId, clubId });
      }}
      labelLoading={labelsQuery.isLoading}
      hasLabels={hasLabels}
      loading={createCollectionLoading}
    />
  );
}


// Customizable Area Start
export default ClubCreateCollection;
// Customizable Area End
