import { PromotionType } from "../api-types";
import { appendJsonContentType, appendToken, apiCall } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;


const promoteMapper: { [k in PromotionType]: string } = {
  Design: "BxBlockAttachment::Design",
  Collection: "AccountBlock::Collection",
  Account: "AccountBlock::Account",
}

export const promoteMessages = {


  checkIfPromoted({ id, type }: { id: string, type: PromotionType }) {

    const httpBody = {
      "promote": {
        "promotable_id": id,
        "promotable_type": promoteMapper[type]
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.checkIfPromotedAPI,
    });
  },


  validateCreditCard({
    cardNumber, expMonth, expYear, cvc
  }: { cardNumber: string, expMonth: number, expYear: number, cvc: string }) {

    const httpBody = {
      "card": {
        "card_number": cardNumber,
        "exp_month": expMonth,
        "exp_year": expYear,
        "cvc": cvc
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.validationCreditCardAPI,
    });
  },

  promote({
    cardNumber, expMonth, expYear, cvc,
    id, type, interval, impressions, amount,
  }: {
    cardNumber: string, expMonth: number, expYear: number, cvc: string,
    id: string, type: PromotionType, interval: number, impressions: number, amount: number,
  }) {

    const httpBody = {
      "promote": {
        "promotable_id": id, 
        "promotable_type": promoteMapper[type],
        "interval_count": interval,
        "interval": "month",
        "impression": impressions,
        "amount": amount
      },
      "card": {
        "card_number": cardNumber,
        "exp_month": expMonth,
        "exp_year": expYear,
        "cvc": cvc
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.promoteAPI,
    });
  }

}
