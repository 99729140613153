import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  useMyUID,
} from './hooks';
import {
  CometChatGlobalConversation,
  CometChatGroupConversation,
} from './types';
import { CHAT_WIDGET_HEIGHT, } from './CometChatSettings';
import { ProfileAvatarUI } from '../../../components/src/common';
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { clearChatUnreadCount } from '../../../components/src/store/ui/ui.actions';
import { useQueryClient } from '@tanstack/react-query';
import { cometchatKeys } from './query-keys';
import { HideContainer,HorizontalSpacer } from '../../../components/src/design-system';
import { OnConversationClick } from './ConversationListContainer';
import { GreenDot, RedDot } from './OnlineOfflineDot';

export const useMainChatContentStyles = makeStyles({
  noBorder: {
    border: "none",
  },
  contactsContainer: {
    maxHeight: `${CHAT_WIDGET_HEIGHT - 70}px`,
    overflow: 'auto'
  },
  userCard: {
    padding: '10px 20px',
    '&:hover': {
      background: '#aaa',
      color: 'white',
      cursor: 'pointer',
    }
  },
  name: {

  },
  status: {
    color: 'gray',
  },
});

export default function ConversationTitle({
  item,
  onConversationClick,
}: {
  item: CometChatGlobalConversation,
  onConversationClick: OnConversationClick,
}) {

  const classes = useMainChatContentStyles();
  const queryClient = useQueryClient();
  const myUID = useMyUID();

  let uid = '';
  let name = '';
  let chatType: 'group' | 'user' = 'user';
  let onlineStatus = 'online'

  if (item.conversationType == 'user') {
    uid = item?.conversationWith?.uid;
    name = item?.conversationWith?.name;
    onlineStatus = item?.conversationWith?.status;
  }

  else if (item.conversationType == 'group') {
    uid = item?.conversationWith?.guid;
    name = item?.conversationWith?.name;
    chatType = 'group';
  }
  function conversationClickHandler() {

    queryClient.invalidateQueries(cometchatKeys.conversationsList({ uid: myUID }))
    dispatch(clearChatUnreadCount({ uid }));
    onConversationClick({ uid, name, onlineStatus, chatType });
  }

  const dispatch = useDispatch();
  const chatUnreadCount = useSelector((state: RootState) => state.ui.chatUnreadCount);

  let chatUid = '';
  if (item.conversationType == 'group')
    chatUid = item?.conversationWith?.guid;
  else
    chatUid = item?.conversationWith?.uid;

  let messagesCount = (item.unreadMessageCount || 0) + (chatUnreadCount?.[chatUid] ?? 0);
  const realtimeOnlineStatus = useSelector((state: RootState) => state.ui.chatOnlineStatus[chatUid])

  const isGroup = chatType == 'group';

  const userStatus = realtimeOnlineStatus || onlineStatus;
  let onlineMembersCount = 0;

  if (isGroup)
    onlineMembersCount = (item as CometChatGroupConversation)?.conversationWith?.onlineMembersCount;

  let groupStatus = onlineMembersCount > 0 ? 'online' : 'offline';

  const groupStatusDescription = groupStatus == 'online' ?
    `${onlineMembersCount} members online`
    :
    'No members online'

  const description = isGroup ?
    groupStatusDescription
    :
    userStatus;


  return (<Grid
    key={item?.conversationWith?.name}
    onClick={conversationClickHandler}
    container
    wrap="nowrap"
    className={classes.userCard}>
    <Grid item>
      <Badge badgeContent={messagesCount} color="primary">
        <ProfileAvatarUI
          image={''}
          alt={''}
          size="small"
        />
      </Badge>
    </Grid>

    <Grid item><HorizontalSpacer /></Grid>

    <Grid item container direction="column">
      <Typography className={classes.name}>{item?.conversationWith?.name}</Typography>
      <Typography className={classes.status}>
        <HideContainer hideWhen={isGroup ? groupStatus == 'offline' : userStatus == 'offline'}>
          <GreenDot />
        </HideContainer>
        <HideContainer hideWhen={isGroup ? groupStatus == 'online' : userStatus == 'online'}>
          <RedDot />
        </HideContainer>
        {description}
      </Typography>
    </Grid>
  </Grid>);
}
