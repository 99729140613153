import React, { useContext, } from 'react';
import Grid from "@material-ui/core/Grid";
import { useLandingPageData, } from "../../../../components/src/hooks";
import { LoadingSpinnerContainer, VerticalSpacer, HideContainer, DezinerButton } from "../../../../components/src/design-system";
import { LandingPageContext } from './landing-page-content';

export function LoadMoreButtonContainer() {

  const { selectedFilter, selectedCategories, selectedSortOption } = useContext(LandingPageContext);

  const landingPageQuery = useLandingPageData({
    selectedFilter,
    selectedCategories,
    sorting: selectedSortOption,
  });

  const isFetching = landingPageQuery.isFetching


  return (<LoadingSpinnerContainer loading={isFetching} >
    <HideContainer hideWhen={!landingPageQuery.hasNextPage}>

      <VerticalSpacer />
      <Grid container justifyContent="center" alignItems="center">
        <DezinerButton
          color="primary"
          variant="contained"
          onClick={() => {
            landingPageQuery?.fetchNextPage()
          }}
          rounded
        >
          {"Load More"}
        </DezinerButton>
      </Grid>
      <VerticalSpacer value={30} />

    </HideContainer>
  </LoadingSpinnerContainer>);
}
