import React, { useState } from 'react';
import { Grid, } from '@material-ui/core';
import { useIsDesigner, useIsMyProfile, useIsUser, useLikeDesign, useSingleDesign, } from '../../../../../components/src/hooks';
import { HideContainer } from '../../../../../components/src/design-system';
import { BuyOptionsDialog } from './BuyDesignDialog';
import { PromoteButton } from './PromoteButton';
import { DesignBuyButton } from './BuyButton';
import { SaveButton } from './SaveButton';
import { LikeButton } from './LikeButton';




export default function DesignActionsButtons({ designId }: { designId: number }) {


  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designerAttributes = designAttributes?.designer?.data?.attributes;

  const isMyProfile = useIsMyProfile({ id: designerAttributes?.id ?? 0 });

  const privacy = designAttributes?.privacy?.toLowerCase();
  const isPaid = privacy == 'private';

  const isUser = useIsUser();

  const isDesigner = useIsDesigner();

  const { mutate: likeDesign } = useLikeDesign({ designId });

  const [buyOptionsDialogOpen, setBuyOptionsDialogOpen] = useState(false);

  const isBought = Boolean(designAttributes?.buyed);



  return (
    <>

      <BuyOptionsDialog
        open={buyOptionsDialogOpen}
        onClose={() => setBuyOptionsDialogOpen(false)}
        designId={designId}
      />

      <Grid container justifyContent="flex-end" alignItems="center">


        <HideContainer hideWhen={!isMyProfile}>

          <HideContainer hideWhen={isUser}>
            <PromoteButton designId={designId} />
          </HideContainer>

        </HideContainer>
        <HideContainer hideWhen={isMyProfile}>

          <DesignBuyButton
            hide={!(isUser && isPaid)}
            isBought={isBought}
            designId={designId}
          />

          <HideContainer hideWhen={!isUser}>
            <SaveButton
              isSaved={designAttributes?.saved ?? false}
              designId={designId}
            />
          </HideContainer>

        </HideContainer>


        <LikeButton
          show={isUser || (isDesigner && !isMyProfile)}
          onClick={() => likeDesign({ designId })}
          liked={designQuery?.data?.design?.data?.attributes?.liked}
        />


      </Grid>
    </>
  );
}
