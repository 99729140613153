import React from 'react';
import { Design, DesignHome } from '../../../components/src/api-types';
import { DesignCard, AddDesignCardPlaceholder } from './design-card';
import { GeneralResponsiveCardsContainer } from "../../../components/src/common";
import { HideContainer } from '../../../components/src/design-system';

type DesignItem = DesignHome | Design;

export function DesignCardsResponsiveContainer({
  designs, onClick, onTrend, onEdit,
  includeAddDesignPlaceholder,
  onPlaceholderClick,
}: {
  designs: (DesignItem)[],
  onClick: (item: DesignItem) => void,
  onTrend: (item: DesignItem) => void,
  onEdit: (item: DesignItem) => void,
  includeAddDesignPlaceholder?: boolean,
  onPlaceholderClick?: () => void,
}) {



  return (
    <GeneralResponsiveCardsContainer>
      {({ itemHeight }) => {
        return (
          <>
            <HideContainer hideWhen={!includeAddDesignPlaceholder}>
              <AddDesignCardPlaceholder onClick={onPlaceholderClick || (() => { })} height={itemHeight} />
            </HideContainer>

            {
              designs
                ?.map((item) => (<DesignCard
                  key={item?.id}
                  imgHeight={itemHeight}
                  item={item}
                  onClick={() => onClick(item)}
                  onTrendingClick={() => onTrend(item)}
                  onEditClick={() => onEdit(item)}
                />))
            }
          </>
        );

      }}
    </GeneralResponsiveCardsContainer>
  );
}


