import React, { useEffect, useState } from 'react';
import { useNavigate, useSingleForum } from '../../../components/src/hooks';
import { urlToBase64, useParams } from "../../../components/src/utils";
import CreateForumForm from './CreateForumForm.web';

export default function ForumEdit() {

  const params = useParams();
  const forumId = parseInt(params.forumId);
  const navigate = useNavigate();

  const forumQuery = useSingleForum({
    id: forumId
  });

  const [mediaBase64, setMediaBase64] = useState('');

  const forumAttributes = forumQuery?.data?.forum?.data?.attributes;

  useEffect(() => {
    (async function convertMedia() {
      if (forumAttributes?.media) {
        try {
          let base64 = await urlToBase64(forumAttributes?.media);
          setMediaBase64(base64 as string);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [forumAttributes?.media]);


  return (
    <div>

      <CreateForumForm
        initialValues={{
          title: forumAttributes?.title ?? '',
          description: forumAttributes?.description ?? '',
          media: mediaBase64
        }}
        onSubmit={(values) => {
          console.log({ values });
        }}
        onCancel={() => {
          navigate(`ForumSingle/${forumId}`);
        }}
        loading={false}
      />

    </div>
  );
}
