import React, { useEffect, useRef, useState } from 'react';
import { trending_up } from "../../../../blocks/landingpage/src/assets";
import clsx from 'clsx';
import {DezinerMedia} from '../../design-system';
import { Collection } from '../../api-types';
import { useIsMyProfile } from '../../hooks';
import { useStyles } from './collection-grid-card.style';



const ASPECT_RATIO = 280 / 200;


export interface CollectionGridCardProps {
  id: number,
  attributes: Collection['attributes'],
  onClick: () => void,
}


export function CollectionGridCard(props: CollectionGridCardProps) {

  const ownerId = props?.attributes?.account?.data?.attributes?.id ?? 0;
  const isMyProfile = useIsMyProfile({ id: ownerId });

  return (
    <CollectionGridCardUI
      {...props}
      hidePromote={!isMyProfile}
    />
  );
}

function CollectionGridCardUI(props: CollectionGridCardProps & { hidePromote?: boolean }) {

  const { id, attributes, onClick, hidePromote, } = props;

  const [cardWidth, setCardWidth] = useState(window.innerWidth);
  const containerRef = useRef<any>(null);

  function updateCardWidth() {
    let items: any = document.getElementsByClassName('design-collection-group');
    let itemWidth = items?.[0]?.offsetWidth ?? 250;

    if (containerRef && containerRef.current && containerRef.current.offsetWidth) {
      let containerWidth = containerRef.current.offsetWidth;
      setCardWidth(containerWidth);
    } else if (itemWidth) {
      setCardWidth(itemWidth);
    }
  }

  useEffect(function preserveCardWidth() {
    updateCardWidth();
    window.addEventListener("resize", updateCardWidth);

    return () => window.removeEventListener("resize", updateCardWidth);
  }, [attributes, containerRef]);

  // while aspect ratio = width / height 
  // then height = width / aspect ratio
  const mainImageHeight = Math.max(250, cardWidth * 1 / ASPECT_RATIO);
  const subImageHeight = Math.max(50, (cardWidth / 3.5) * 1 / ASPECT_RATIO);

  const classes = useStyles();

  const mainDesignAttributes = attributes.designs?.data?.[0]?.attributes;


  return (
    <div
      className={clsx('design-collection-group', classes.designCollectionGroup)}
      onClick={() => onClick()}
      key={id}
      ref={containerRef}
      data-collectiongridlabel={attributes.label_id}
    >

      {
        attributes.designs?.data?.[0]?.attributes?.image_url ?
          <>

            <DezinerMedia
              id={String(mainDesignAttributes?.id)}
              mediaType={mainDesignAttributes?.main_image_type?.includes('video/') ? 'video' : 'image'}
              watermarkURL={mainDesignAttributes?.watermark_url}
              watermarkWidth={Number(mainDesignAttributes?.width)}
              src={mainDesignAttributes?.image_url}
              watermarkHeight={Number(mainDesignAttributes?.height)}
              containerStyles={{ height: `${mainImageHeight}px` }}
              containerClassName={classes.designCollectionMainImageContainer}
              className={classes.designCollectionMainImage}
              alt={`${attributes.name}-image-main`}
            />

            <div
              className={clsx('overlay', classes.designCollectionMainImageOverlay)}
            >
              {
                hidePromote ? <span /> :
                  <button

                    onClick={(event) => {
                      console.log({ event });
                      event.stopPropagation();
                    }}
                  >
                    <img src={trending_up} alt="promote" />
                  </button>
              }
            </div>
          </>
          :
          <div
            style={{ height: `${mainImageHeight}px` }}
            className={classes.designCollectionMainImageContainer}
          />
      }



      {
        attributes.designs?.data?.concat([{}, {}, {}, {}] as any)
          ?.slice(1, 4)
          ?.map(({
            id,
            attributes: { image_url, main_image_type, watermark_url, width, height, } = {}
          }, index) => {

            const mediaClasses = clsx(classes.collectionSubImage, {
              [classes.collectionSubImage1]: index + 1 == 1,
              [classes.collectionSubImage2]: index + 1 == 2,
              [classes.collectionSubImage3]: index + 1 == 3,
            });

            if (image_url)
              return <DezinerMedia
                id={id}
                mediaType={main_image_type?.includes('video') ? 'video' : 'image'}
                className={mediaClasses}
                src={image_url}
                alt={`${attributes.name}-image-${index}`}
                style={{ height: `${subImageHeight}px` }}
                containerStyles={{ height: `${subImageHeight}px` }}
                watermarkURL={watermark_url}
                watermarkWidth={Number(width || 50)}
                watermarkHeight={Number(height || 50)}
              />


            return <div
              className={mediaClasses}
              style={{ height: `${subImageHeight}px` }}
            />
          })
      }

      <div className={classes.groupText}>
        <div className={classes.groupTitle}>
          {attributes.name}
        </div>
        <div className={classes.collectionCounter}>
          {`${attributes.design_count} Designs`}
        </div>
      </div>

    </div>
  );
}

