import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MainDropzone } from './main-dropzone';
import { settingsGearIcon, } from "../../../../catalogue/src/assets";
import { DezinerAlert, DezinerLabel, HideContainer, VerticalSpacer } from '../../../../../components/src/design-system';
import { SubDesignsInput } from './sub-designs-input';
import { useFormikContext } from 'formik';
import { UplodaDesignInitValues } from './UploadDesignForm.web';
import { TitleInput } from "./title-input";
import { TagsInput } from "./tags-input";
import { PrivacyInput } from "./privacy-input";
import { PriceInput } from "./price-input";
import { DescriptionInput } from "./description-input";
import { WatermarksInput } from "./watermarks";
import { IsThumbnailInput } from "./is-thumbnail";
import { useCategories } from '../../../../../components/src/hooks';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  smallIcon: {
    maxWidth: '18px',
    marginRight: '5px',
  },
});

export function UploadDesignFormStep1({
  setMediaOffsetWidth,
  setMediaOffsetHeight,
}: {
  setMediaOffsetWidth: React.Dispatch<React.SetStateAction<number>>,
  setMediaOffsetHeight: React.Dispatch<React.SetStateAction<number>>,
}) {

  const classes = useStyles();

  const formik = useFormikContext<UplodaDesignInitValues>();

  const categoriesQuery = useCategories();

  const categories = categoriesQuery?.data?.data?.map((category) => {

    const subCategories = category.attributes.sub_categories.data.map((subCategory) => {
      return {
        value: subCategory.attributes.id,
        label: subCategory.attributes.name,
      };
    })

    return {
      label: category.attributes.name,
      options: subCategories,
    }
  }) ?? [];


  const listOfCategories = categories
    ?.map((item: any) => (
      item.options.map((option: any) => ({ ...option, parentLabel: item.label }))
    ))
    ?.flat();


  return (
    <Grid container>
      <Grid item sm={8} xs={12} style={{ padding: '0 80px 0 0' }}>

        <MainDropzone
          setMediaOffsetHeight={setMediaOffsetHeight}
          setMediaOffsetWidth={setMediaOffsetWidth}
        />

        <Typography style={{ textAlign: 'right', color: '#ababab' }}>
          <span style={{ color: 'black' }}>{'*'}</span>
          {' Only upload media you own the rights to'}
        </Typography>

        <VerticalSpacer />

        <SubDesignsInput />

        <HideContainer hideWhen={!formik.errors.media}>
          <DezinerAlert>
            {formik.errors.media}
          </DezinerAlert>
        </HideContainer>


      </Grid>
      <Grid item sm={4} xs={12} container direction="column">

        <TitleInput />

        <VerticalSpacer />

        <TagsInput options={listOfCategories} />

        <VerticalSpacer />

        <PrivacyInput />

        <VerticalSpacer />

        <PriceInput />

        <DescriptionInput />

        <VerticalSpacer />

        <Grid container alignItems="center">
          <img src={settingsGearIcon} alt="" className={classes.smallIcon} />
          <DezinerLabel>
            {'Settings'}
          </DezinerLabel>
        </Grid>

        <div style={{ paddingLeft: '20px' }}>
          <IsThumbnailInput />
          <VerticalSpacer />
          <WatermarksInput />
        </div>
      </Grid>
    </Grid>
  );
}
