import { CometChatAudioMessageGroup, CometChatAudioMessageUser, CometChatDeliveredMessage, CometChatMessage, CometChatReadMessage } from "../../../../../blocks/LiveChat2/src/types";
import { DezinerActionCreator } from "../../store";

export const SAVE_COMETCHAT_MESSAGE = 'SAVE_COMETCHAT_MESSAGE';

export interface SaveCometChatMessageAction {
  type: typeof SAVE_COMETCHAT_MESSAGE,
  uid: string,
  message: CometChatMessage | CometChatAudioMessageUser | CometChatAudioMessageGroup,
  chatType: 'user' | 'group'
}

export const saveCometChatMessage: DezinerActionCreator<SaveCometChatMessageAction> =
  (params) => ({
    type: SAVE_COMETCHAT_MESSAGE,
    ...params,
  });

///////////// 

export const CLEAR_COMETCHAT_MESSAGES = 'CLEAR_COMETCHAT_MESSAGES';

export interface ClearCometChatAction {
  type: typeof CLEAR_COMETCHAT_MESSAGES,
  uid: string
}

export const clearCometChatMessages: DezinerActionCreator<ClearCometChatAction> =
  (params) => ({
    type: CLEAR_COMETCHAT_MESSAGES,
    ...params,
  });

////////////

export const SAVE_READ_MESSAGE = 'SAVE_READ_MESSAGE';

export interface SaveReadMessageAction {
  type: typeof SAVE_READ_MESSAGE,
  message: CometChatReadMessage,
}

export const saveCometChatReadMessage: DezinerActionCreator<SaveReadMessageAction> =
  (params) => ({
    type: SAVE_READ_MESSAGE,
    ...params,
  });

/////////

export const SAVE_DELIVERED_MESSAGE = 'SAVE_DELIVERED_MESSAGE';

export interface SaveDeliveredMessageAction {
  type: typeof SAVE_DELIVERED_MESSAGE,
  message: CometChatDeliveredMessage,
}

export const saveCometChatDeliveredMessage: DezinerActionCreator<SaveDeliveredMessageAction> =
  (params) => ({
    type: SAVE_DELIVERED_MESSAGE,
    ...params,
  });
