import React, { useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import MicIcon from '@material-ui/icons/Mic';
import Grid from '@material-ui/core/Grid';
import MicNoneIcon from '@material-ui/icons/MicNone';
import IconButton from '@material-ui/core/IconButton';
import { useRecordingUtils } from '../../../../Comments/src/RecordingUtils';
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import { DezinerAlert, DezinerAudio, DezinerLabel, DezinerTextField, HideContainer } from '../../../../../components/src/design-system';
import { descriptionIcon, } from "../../../../catalogue/src/assets";
import { useFormikContext } from 'formik';
import { UplodaDesignInitValues } from './UploadDesignForm.web';


/**
  * We need to isolate description because it contains recording logic, that can affect parent component render and thus 
  * cause forms values to be reset
  */
export function DescriptionInput() {


  const formik = useFormikContext<UplodaDesignInitValues>();
  const { isRecording, base64Audio, clearRecording, recordToggleHandler, } = useRecordingUtils();

  const initAudio = formik.values.descriptionAudio;

  useEffect(() => {
    formik.setFieldValue('descriptionAudio', base64Audio)
  }, [base64Audio]);

  const classes = useUploadDesignFormStyles();
  const audio = base64Audio || initAudio;

  return (
    <>
      <Grid container alignItems="center">
        <img src={descriptionIcon} alt="" className={classes.smallIcon} />
        <DezinerLabel>
          {'Description'}
        </DezinerLabel>
      </Grid>
      <div style={{ position: 'relative' }}>
        <DezinerTextField
          placeholder="about your design"
          name="description"
          variant="outlined"
          multiline
          minRows={5}
          autoHeight
          fullWidth
          value={formik.values.description}
          onChange={(event) => formik.setFieldValue('description', event.target.value)}
        />
        <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
          <IconButton onClick={recordToggleHandler} size="small" className={classes.recordIconBtn}>
            {
              isRecording ?
                <>
                  <MicIcon className={classes.recordIcon} fontSize="small" />
                  <span className={classes.pulse}></span>
                </>
                :
                <MicNoneIcon fontSize="small" />
            }
          </IconButton>
        </div>
      </div>

      <HideContainer hideWhen={!formik.errors.description}>
        <DezinerAlert>
          {formik.errors.description}
        </DezinerAlert>
      </HideContainer>


      <HideContainer hideWhen={!audio}>
        <Grid container alignItems="center">
          <DezinerAudio
            src={audio}
          />
          <IconButton onClick={clearRecording}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </HideContainer>
    </>
  );


}
