import React, { useContext, } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CategoriesFilterButton } from "./categories-filter-button";
import { DezinerDropdown, DezinerButtonGroup } from "../../../../components/src/design-system";
import { useCategories, useNavigate } from "../../../../components/src/hooks";
import { clubIcon, groupIcon, penIcon, forum, } from '../assets';
import { defaultToEmptyArray } from '../../../../components/src/default-values';
import { LandingPageContext } from './landing-page-content';
import { LandingPageAPISortingOptions } from '../../../../components/src/messages-generator';



export const useFiltersContainerStyles = makeStyles({
  filtersContainer: {
    padding: '40px 50px',
    ['@media screen and (max-width: 900px)']: {
      padding: '10px',
      flexDirection: 'column',
      '& > *': {
        margin: '10px 0'
      }
    }
  }
});


const SORT_OPTIONS: {title:string, key: LandingPageAPISortingOptions}[] = [
  { key: 'viewed', title: 'Most Viewed' },
  { key: 'liked', title: 'Most Liked' },
  { key: 'recent', title: 'Most Recent' },
  { key: 'inspired', title: 'Most Inspired' },
]

const GROUP_BUTTONS_OPTIONS = [
  {
    key: 'Forum',
    icon: forum,
  },
  {
    key: 'Designer',
    icon: penIcon,
  },
  {
    key: 'Group',
    icon: groupIcon,
  },
  {
    key: 'Club',
    icon: clubIcon,
  },
  {
    key: 'All',
    icon: null,
  },
]

export function LandingPageFiltersContainer() {

  const classes = useFiltersContainerStyles();

  const {
    selectedFilter,
    setSelectedFilter,
    setSelectedCategories,
    selectedSortOption,
    setSelectedSortOption,
    selectedCategories,
  } = useContext(LandingPageContext);

  const navigate = useNavigate();
  const categoriesQuery = useCategories();

  function categoryToggleHandler(id: number) {
    setSelectedCategories(function toggleTheGivenCategoryId(selectedCategories) {

      let exist = selectedCategories.includes(id);

      if (exist)
        return selectedCategories.filter(categoryId => categoryId != id);
      else
        return selectedCategories.concat(id);
    });
  }


  function clearHandler() {
    setSelectedCategories([]);
  }

  function filterHandler(filter: string) {
    if (filter == 'Forum')
      navigate('CommunityForum');
    else
      setSelectedFilter(filter);
  }

  const landingPageGroupButtonsOptions =
    GROUP_BUTTONS_OPTIONS.map((option) => ({
      ...option,
      title: option.key,
      active: selectedFilter == option.key,
    }))


  return (
    <Grid
      className={classes.filtersContainer}
      container
      justifyContent="space-between"
      alignItems="center"
    >

      <DezinerDropdown
        position="left"
        title={"Sort"}
        options={SORT_OPTIONS}
        selectedOption={selectedSortOption}
        onClick={setSelectedSortOption as any}
      />

      <DezinerButtonGroup
        noBorders
        options={landingPageGroupButtonsOptions}
        onClick={filterHandler}
      />

      <CategoriesFilterButton
        categories={defaultToEmptyArray(categoriesQuery?.data?.data)}
        onToggle={categoryToggleHandler}
        selectedCategoriesIds={selectedCategories}
        onClear={clearHandler}
      />

    </Grid>
  );
}
