import React, { useCallback, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { blobToBase64 } from "../../../components/src/utils";
import { CommentCloseIcon } from "./CommentCloseIcon";
import { CommentAttachIcon } from "./CommentAttachIcon";
import { CommentRecordingIcon } from "./CommentRecordingIcon";
import { CommentAudio } from "./CommentAudio";
import { CommentImage } from "./CommentImage";
import { CommentPostButtons } from "./CommentPostButtons";
import { useRecordingUtils } from "./RecordingUtils";
import { CommentSendIcon } from "./CommentSendIcon";
import { DezinerTextField } from "../../../components/src/design-system";

const useCommentStyles = makeStyles({
  root: {
    margin: '10px 0', overflow: 'hidden',
  },
  inputAdornment: {
    position: 'absolute',
    right: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '10px',
  },
  inputAdornmentSingleLine: {
    bottom: '15px',
    right: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },
  attachementContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  extraInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 2px',
    flexWrap: 'wrap'
  },
  closeIcon: {
    color: '#e1e1e1'
  },
  hidden: {
    display: 'none'
  }
})




export interface OnCommentParams {
  commentText: string,
  base64Image: string,
  base64Audio: string,
}

export interface CommentInputProps {
  inputRef?: React.MutableRefObject<HTMLInputElement> | undefined,
  singleLine?: boolean,
  onComment: (params: OnCommentParams) => void,
  hideVoiceRecorder?: boolean,
  hideFileAttachements?: boolean,
  placeholder?: string,
}




export function CommentInput(props: CommentInputProps) {

  const {
    singleLine,
    onComment,
    hideVoiceRecorder,
    hideFileAttachements,
    placeholder,
    inputRef,
  } = props;


  const classes = useCommentStyles();
  const fileInputRef = useRef<any>();

  const [commentText, setCommentText] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const [fileName, setFileName] = useState('');
  const [commentTextRequired, setCommentTextRequired] = useState(false);

  const {
    recordToggleHandler,
    base64Audio,
    isRecording,
    clearRecording,
  } = useRecordingUtils();

  const commentTextChangeHandler = useCallback((event: any) => {
    let value = event.target.value;
    setCommentText(value);
    if (value.trim()) {
      setCommentTextRequired(false);
    }
  }, []);

  function reset() {
    setCommentText('');
    setBase64Image('');
    setFileName('');
    clearRecording();
  }


  function cancelHandler() {
    reset();
  }

  function postHandler() {
    let text = commentText.trim();
    if (text) {
      setCommentTextRequired(false);
      onComment({ commentText, base64Image, base64Audio });
      reset();
    } else {
      setCommentTextRequired(true);
    }
  }

  async function fileInputChangeHandler(event: any) {
    let file = event.target.files?.[0];
    let fileName = file?.name;
    if (fileName)
      setFileName(fileName);
    let _base64Image = await blobToBase64(file);
    setBase64Image(_base64Image);
  }

  function fileUploadHandler() {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function deleteAudioHandler() {
    clearRecording();
  }

  function deleteImageHandler() {
    setBase64Image('');
    setFileName('');
  }

  function keyUpHandler(event: any) {
    if (event.key == 'Enter' && singleLine) {
      postHandler();
    }
  }


  const hasUserInput = commentText || base64Image || base64Audio;
  const hasNoUserInput = !hasUserInput;
  const displayCloseButton = singleLine && hasUserInput;


  return (
    <>
      <input
        accept="image/*"
        ref={fileInputRef}
        type="file"
        className={classes.hidden}
        onChange={fileInputChangeHandler}
      />


      <div className={classes.root}>

        <div>

          <DezinerTextField
            data-testid="comment-input"
            autoHeight={true}
            multiline={!singleLine}
            minRows={5}
            placeholder={placeholder || "Write your comment..."}
            value={commentText}
            onChange={commentTextChangeHandler}
            variant="outlined"
            fullWidth
            onKeyUp={keyUpHandler}
            error={commentTextRequired}
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className={clsx(classes.inputAdornment, {
                      [classes.inputAdornmentSingleLine]: singleLine
                    })}
                  >

                    <CommentCloseIcon
                      hide={!displayCloseButton}
                      onClick={reset}
                    />
                    <CommentAttachIcon
                      onClick={fileUploadHandler}
                      hide={hideFileAttachements}
                    />
                    <CommentRecordingIcon
                      hide={hideVoiceRecorder}
                      isRecording={isRecording}
                      onClick={recordToggleHandler}
                    />

                    <CommentSendIcon
                      hide={!singleLine}
                      hideDivider={hideVoiceRecorder && hideFileAttachements}
                      onClick={postHandler}
                    />
                  </div>
                </InputAdornment>
              )
            }}
          />

        </div>


        <div className={classes.extraInfoContainer}>
          <div className={classes.attachementContainer}>
            <CommentAudio
              base64Audio={base64Audio}
              onDelete={deleteAudioHandler}
            />
            <CommentImage
              base64Image={base64Image}
              onDelete={deleteImageHandler}
              fileName={fileName}
            />
          </div>

          <CommentPostButtons
            singleLine={singleLine}
            hasNoUserInput={hasNoUserInput}
            onCancel={cancelHandler}
            onPost={postHandler}
          />
        </div>

      </div>
    </>
  );
}
