import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Label } from '../api-types';

export function LabelsDropdown({
  currentLabel,
  labels,
  onChange,
}: {
  currentLabel: number,
  labels: Label[],
  onChange: (label: number) => void,
}) {
  return (
    <div>

      <FormControl variant="outlined">
        <Select style={{ maxHeight: '40px' }} value={currentLabel}>
          {
            labels?.map((item: any) =>
            (<MenuItem
              key={item?.id}
              value={item?.id}
              className={
                item?.id == currentLabel
                  ? "active-label"
                  : ""
              }
              onClick={() => onChange(item?.id)}
            >
              {item?.attributes?.name}
            </MenuItem>))
          }
        </Select>
      </FormControl>

    </div>
  );
}
