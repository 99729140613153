import React from 'react';
import { useField } from 'formik';
import { DezinerAlert } from '../alert';

export function FormikError({ name, errorProperty }: { name: string, errorProperty?: string }) {

  const inputName = name || '';

  const [_inputProps, meta] = useField(inputName);

  let error: any = meta.error;

  if (errorProperty && error)
    error = error[errorProperty];

  if (!inputName || !error)
    return null;

  return (
    <DezinerAlert>
      {JSON.stringify(error).replace(/\"/g,'')}
    </DezinerAlert>
  );
}
