import { Message } from "../../../../framework/src/Message";
import { DezinerActionCreator } from "../store";

export const SAVE_HTTP_RESPONSE = 'SAVE_HTTP_RESPONSE';

export interface SaveHttpResponseAction {
  type: typeof SAVE_HTTP_RESPONSE,
  message: Message,
  response: any
}

export const saveHttpResponse: DezinerActionCreator<SaveHttpResponseAction> =
  (params) => ({
    type: SAVE_HTTP_RESPONSE,
    ...params,
  });
