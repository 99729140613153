import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { ClubMember, GroupMember } from "../../../components/src/api-types";
import { useDecodedToken, useNavigate } from "../../../components/src/hooks";
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_PROFILE_IMAGE } from "../../../components/src/defaults";
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  img: {
    borderRadius: '40%',
    objectFit: 'cover',
    border: '4px solid black',
  },
  imgForPatternDesigner: {
    border: '4px solid #dcdddd',
  },
  imgInHorizontalContainer: {
    alignSelf: 'center',
  },
  name: {
    textAlign: "center",
    fontSize: "1.3rem",
    color: "#656565",
    fontWeight: "bold",
    marginTop: "10px",
    whiteSpace: 'nowrap',
  },
  userType: {
    color: "#989898", fontSize: "1rem", margin: "0", whiteSpace: 'nowrap',

  },
  nameContainer: {
    paddingLeft: '0px'
  },
  nameContainerHorizontal: {
    paddingLeft: '20px',
    alignItems: 'flex-start',
  },
});

type RolesOptions = 'Admin' | 'Owner' | 'Pattern designer';

export default function DesignerProfileWithOptions(props: {
  member: ClubMember | GroupMember,
  currentRole: string,
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
  addAsAdmin: (params: {
    memberId: number,
  }) => void,
  horizontal?: boolean,
  imageSize?: number,
  hideOptionsMenu?: boolean,
}) {

  const {
    horizontal,
    imageSize,
    ...menuProps
  } = props;

  const {
    member,
  } = menuProps;

  /**
   * Menu Rules:
   * 
   * if I'm a pattern designer
   *  for each member I can see
   *    - if it's myselft: I can exit the group/club
   *    - if it's anything else I can do nothing
   * 
   * If I'm an admin/owner
   *   for each member I can see
   *     - if it's a pattern designer: I can (Add him as an admin), (remove from group/club)
   *     - if it's an owner: I can do nothing
   *     - if it's an admin: I can (remove him [that include removing myself])
   */

  const classes = useStyles();

  const navigate = useNavigate();

  // clubs has "user_type" and groups has "role"
  let userType: RolesOptions =
    (member as ClubMember)?.attributes?.user_type ||
    (member as GroupMember)?.attributes?.role;



  return (
    <Grid
      data-card="designer-profile-with-options"
      container
      direction={horizontal ? "row" : "column"}
      alignItems="center"
      className={classes.root}
      wrap={horizontal ? "nowrap" : "wrap"}
    >

      <OptionsMenu {...menuProps} />

      <img
        src={
          member?.attributes?.profile_url ||
          DEFAULT_PROFILE_IMAGE
        }
        alt={member?.attributes?.first_name}
        style={{
          width: `${imageSize || 100}px`,
          height: `${imageSize || 100}px`,
        }}
        className={clsx(classes.img, {
          [classes.imgForPatternDesigner]: userType == "Pattern designer",
          [classes.imgInHorizontalContainer]: horizontal
        })}
        onClick={() => navigate(`DesignerHome/${member.attributes.account_id}`)}
      />

      <Grid container direction="column" alignItems="center"
        className={clsx(classes.nameContainer, { [classes.nameContainerHorizontal]: horizontal })}
      >
        <Typography
          className={classes.name}
          onClick={() => navigate(`DesignerHome/${member.attributes.account_id}`)}
        >
          {member?.attributes?.first_name}
        </Typography>
        <Typography className={classes.userType} >
          {userType}
        </Typography>
      </Grid>

    </Grid>
  )
}

const useOptionsMenuStyles = makeStyles({
  iconBtn: {
    position: 'absolute', right: 0, top: '-10px'
  }
});


function OptionsMenu({
  member,
  currentRole,
  onDelete,
  addAsAdmin,
  hideOptionsMenu,
}: {
  member: ClubMember | GroupMember,
  currentRole: string,
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
  addAsAdmin: (params: {
    memberId: number,
  }) => void,
  hideOptionsMenu?: boolean,
}) {

  const classes = useOptionsMenuStyles();

  const [anchorEl, setAnchorEl] = useState<any>();

  const { id } = useDecodedToken();
  const isMyProfile = member.attributes.account_id == id;

  const iHavePatterDesignerRole = currentRole === 'Pattern designer';

  // clubs has "user_type" and groups has "role"
  let userType: RolesOptions = 'Pattern designer';

  if (member.type == 'account_club')
    userType = member?.attributes?.user_type
  else if (member.type == 'account_group')
    userType = member?.attributes?.role;


  const memberHasOwnerRole = userType === 'Owner';

  if (hideOptionsMenu)
    return null;

  if (memberHasOwnerRole)
    return null;

  if (iHavePatterDesignerRole && !isMyProfile)
    return null;


  let removeAdminOption = {
    title: isMyProfile ? 'Leave as admin' : 'Remove as admin',
    onClick: () => {
      onDelete({
        memberId: member.attributes.account_id,
        memberName: member.attributes.first_name,
        deleteType: "remove_as_admin"
      });
    }
  }

  let addAdminOption = {
    title: 'Add as admin',
    onClick: () => {
      addAsAdmin({
        memberId: member.attributes.account_id,
      });
    }
  }

  let exitOption = {
    title: isMyProfile ? 'Exit' : 'Remove',
    onClick: () => {
      onDelete({
        memberId: member.attributes.account_id,
        memberName: member.attributes.first_name,
        deleteType: "remove_member"
      });
    }
  }

  /*
  * this matrix represents the possible options we have 
  * my profile can be one of three options(owner, admin, pattern designer)
  * and the member can be one of three options(owner, admin, pattern designer)
  * so we have a small matrix of options to add
  *
  * optionsMatrix[myPermssions][memberPermission] will give 
  * you the needed option to add
  */

  const optionsMatrix: {
    [k in RolesOptions]: { [x in RolesOptions]: any | undefined }
  } = {
    Admin: {
      Admin: removeAdminOption,
      Owner: undefined,
      'Pattern designer': addAdminOption,
    },
    Owner: {
      Admin: removeAdminOption,
      Owner: undefined,
      'Pattern designer': addAdminOption,
    },
    'Pattern designer': {
      Admin: undefined,
      Owner: undefined,
      'Pattern designer': undefined,
    },
  }

  let menuOptions = [
    optionsMatrix?.[currentRole as RolesOptions]?.[userType],
    exitOption,
  ].filter(x => x);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
        className={classes.iconBtn}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        data-testid={`member-popup-${member?.attributes?.first_name}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: 40 * 4.5,
            width: "20ch"
          }
        }}
      >
        {
          menuOptions?.map(({ title, onClick }) => (
            <MenuItem
              key={title}
              onClick={onClick}
            >
              {title}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
}

