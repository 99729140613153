import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { Collection, CollectionHome, DesignCategory } from "../api-types";
import { collectionsMessages } from "../messages-generator";
import { clubsKeys, collectionsKeys, groupsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";
import { useIsMyProfile } from "./utils";


export function useDesignerCollectionsByUser({
  designerId,
  labelId,
  activeCategory,
}: {
  designerId: number,
  labelId: number,
  activeCategory: DesignCategory,
},
  options?: QueryObserverOptions) {


  return useDezinerQuery<{ data: CollectionHome[], meta: any }>({
    message: collectionsMessages.fetchDesignerCollectionsByUser({
      designerId,
      labelId,
    }),
    queryKey: collectionsKeys.fetchDesignerCollectionsByUser({ designerId, labelId }),
    options: {
      enabled: (options?.enabled ?? true) && Boolean(designerId) && activeCategory == 'collections',
      ...options,
    }
  });

}


export function useCreateUserCollection() {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.createUserCollection>[0]>({
    messageGenerator: collectionsMessages.createUserCollection,
    queryKey: collectionsKeys.createUserCollection(),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchUserCollections());
      }
    }
  });
}

export function useCreateCollection({ labelId, designerId }: { labelId: number, designerId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.createCollection>[0]>({
    messageGenerator: collectionsMessages.createCollection,
    queryKey: collectionsKeys.createCollection({ labelId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchCollections({ designerId, labelId }));
      }
    }
  });
}


export function useSingleCollection({ collectionId }: { collectionId: number }) {


  return useDezinerQuery<{ data: Collection }>({
    queryKey: collectionsKeys.fetchSingleCollection({ collectionId }),
    message: collectionsMessages.fetchSingleCollection({ collectionId }),
  });
}


export function usePinCollection({ designerId, labelId, clubId, groupId }: { designerId: number, labelId: number, clubId?: number, groupId?: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.pinCollection>[0]>({
    queryKey: collectionsKeys.pinCollection({ designerId }),
    messageGenerator: collectionsMessages.pinCollection,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchCollections({ designerId, labelId, }));
        if (clubId)
          queryClient.invalidateQueries(clubsKeys.fetchCollections({ clubId: clubId || 0, labelId }));
        if (groupId)
          queryClient.invalidateQueries(groupsKeys.fetchCollections({ groupId: groupId || 0, labelId }));
      }
    },
  });
}


export function useCollections({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {

  const isMyProfile = useIsMyProfile({ id: designerId });

  const collectionsQuery = useDesignerCollections({ designerId, category, labelId }, {
    ...options,
    enabled: (options?.enabled ?? true) && isMyProfile
  });

  const collectionsByUserQuery = useDesignerCollectionsByUser({
    designerId, activeCategory: category, labelId
  }, {
    ...options,
    enabled: (options?.enabled ?? true) && !isMyProfile
  });

  return isMyProfile ? collectionsQuery : collectionsByUserQuery;
}


export function useDesignerCollections({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: CollectionHome[] }>({
    queryKey: collectionsKeys.fetchCollections({ designerId, labelId }),
    message: collectionsMessages.fetchCollections({
      labelId
    }),
    options: {
      enabled: (options?.enabled ?? true) && category == 'collections',
      ...options
    },
  });

}

export function useMyUserCollections(options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: CollectionHome[] }>({
    queryKey: collectionsKeys.fetchUserCollections(),
    message: collectionsMessages.fetchMyUserCollections(),
    options,
  });
}



export function useUserCollectionsByOthers({ userId }: { userId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Collection[] }>({
    queryKey: collectionsKeys.fetchUserCollectionsByOthers({ userId }),
    message: collectionsMessages.fetchUserCollectionsByOthers({ userId }),
    options,
  });
}

export function useUserCollections({ userId }: { userId: number }) {

  const isMyProfile = useIsMyProfile({ id: userId });

  const myCollectionsQuery = useMyUserCollections({ enabled: isMyProfile });

  const collectionsByOtherQuery = useUserCollectionsByOthers({ userId }, { enabled: !isMyProfile });

  return isMyProfile ? myCollectionsQuery : collectionsByOtherQuery;
}



