export const searchDesignerStaticContent = `

The Search feature of Deziner’s Knot implicitly allows you to look up for exquisite designs and connect to their masters as well. More than 10,000 designers have registered to work with us and it’s no surprise as this platform stands among the top sites tied in with a solid community.

You chose us! And we’ll let you choose our designers! 

If you are looking for a readily available design or a designer who can personalise designs for your project, you can find them here. All you have to do is just type in keywords in the search box of your homepage and in a single click, best-in-class designers are at your service. You state your requirements, reach terms with them and get the work done. Simple, right? 

Subscribing to us helps you link up with more accomplished designers from our talent network. To optimize your search experience, we have advanced search options where you can filter designers based on experience, price range and expertise. If this isn’t intriguing, we are wondering what is? Go check it out for yourself and reap the benefits.

> Some features of Deziner’s Knot will be available for service once their development is complete.

`;
