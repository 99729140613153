import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { DezinerButton, DezinerAlert, FormikTextInput, FormikError } from '../design-system';


const createCollectionValidationSchema = yup.object().shape({
  collectionName: yup.string().required(),
  isPrivate: yup.bool(),
});

export function CreateCollectionForm({
  onSubmit,
  labelLoading,
  hasLabels,
  loading,
  selectedALabel,
}: {
  onSubmit: (params: { collectionName: string, isPrivate: boolean }) => void,
  labelLoading: boolean,
  loading: boolean,
  hasLabels: boolean,
  selectedALabel: boolean,
}) {




  return (
    <Formik
      initialValues={{ collectionName: '', isPrivate: false }}
      validationSchema={createCollectionValidationSchema}
      onSubmit={(values, helpers) => {
        if (selectedALabel) {
          onSubmit(values);
          helpers.resetForm();
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {
        formik => <Form translate="yes">

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{
              padding: '40px 0'
            }}
          >
            <Grid
              item
              xs={6}
              container
              direction="column"
            >
              <Grid item container alignItems="center">
                <FormikTextInput
                  hideErrors
                  name="collectionName"
                  placeholder="Your collection name"
                  variant="outlined"
                  autoHeight
                  InputProps={{
                    style: {
                      maxHeight: '40px',
                    }
                  }}
                />
                <DezinerButton
                  color="primary"
                  variant="contained"
                  style={{ margin: '0 10px' }}
                  type="submit"
                  loading={loading}
                  disabled={loading || !hasLabels || labelLoading}
                >
                  {'Create'}
                </DezinerButton>
              </Grid>




            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox
                  name="isPrivate"
                  onChange={(e) => formik.setFieldValue('isPrivate', e.target.checked)}
                  checked={formik.values.isPrivate}
                  color="primary"
                />}
                label="Keep this collection private"
              />
            </Grid>
          </Grid>

          <Grid container direction="column">

            <FormikError
              name="collectionName"
            />

            {
              !labelLoading && !hasLabels ?
                <DezinerAlert>
                  {'You need to add a new Label first in order to create a new collection'}
                </DezinerAlert>
                :
                null
            }

            {
              formik.submitCount > 0 && !selectedALabel ?
                <DezinerAlert>
                  {'Please select a label first'}
                </DezinerAlert>
                :
                null
            }
          </Grid>
        </Form>
      }
    </Formik>
  );
}

