import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/utils";
import { profileUpload } from "./assets";
import { extractRedirectionInfo } from '../../../components/src/utils';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  name: string;
  username: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  color: string;
  phone: string;
  otp: string;
  terms: boolean;
  step: number;
  profilePictureFile: string;
  loginAs: string;
  location: string;
  roles: any[];
  unique_auth_id: string;
  signUpType: string;
  inputValidations1: any;
  inputValidations2: any;
  inputValidations3: any;
  isChooseColor: boolean;
  displayConfirmPopup: boolean;
  isSkippedClicked: boolean;
  createAccountLoading: boolean;
  createAccountLoading2: boolean;
  otpSent: boolean;
  phoneOtpIsInvalid: string;
  otpError: string,
  openOTPVerify: boolean;
  otpSendLoading: boolean;
  otpVerifyLoading: boolean;
  otpSeconds: number,
  numberIsVerified: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

const OTP_TIME = 60;

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start
  // arrayholder: any[];
  // passwordReg: RegExp;
  // emailReg: RegExp;
  createAccountApiCallId: any;
  createAccount2ApiCallId: any;
  validationApiCallId: string = "";
  rolesPermissionId: any;
  createAccountViaGoogleId: any;
  createAccountViaMicrosoftId: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  optAPIId: string;
  otpVerifyAPIId: string;
  currentCountryCode: any;
  otpSecondsTimerId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.onSigninSubmit = this.onSigninSubmit.bind(this);
    this.onGoogleLoginSuccess = this.onGoogleLoginSuccess.bind(this);
    this.onGoogleLoginFailure = this.onGoogleLoginFailure.bind(this);
    this.onFinishCreatingAccount = this.onFinishCreatingAccount.bind(this);
    // this.onUpdateLocationNextButton = this.onUpdateLocationNextButton.bind(
    //   this
    // );
    // this.microsoftAuthHandler = this.microsoftAuthHandler.bind(this);
    this.onFinishSetup = this.onFinishSetup.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    // this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.onClosePoup = this.onClosePoup.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      name: "",
      username: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      color: "",
      phone: "",
      otp: "",
      loginAs: "",
      terms: false,
      step: 1,
      unique_auth_id: "",
      profilePictureFile: profileUpload,
      location: "",
      roles: [],
      signUpType: "",
      inputValidations1: {},
      inputValidations2: {},
      inputValidations3: "",
      isChooseColor: false,
      displayConfirmPopup: false,
      isSkippedClicked: false,
      createAccountLoading: false,
      createAccountLoading2: false,
      otpSent: false,
      phoneOtpIsInvalid: '',
      otpError: '',
      openOTPVerify: false,
      otpSendLoading: false,
      otpVerifyLoading: false,
      otpSeconds: OTP_TIME,
      numberIsVerified: false,
      // Customizable Area End
    };

    // Customizable Area Start



    // this.arrayholder = [];
    // this.passwordReg = new RegExp("\\w+");
    // this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  componentWillUnmount: any = () => {
    clearInterval(this.otpSecondsTimerId);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.otpVerifyAPIId) {
          this.setState({ otpVerifyLoading: false });

          if (responseJson && responseJson.message) {
            this.setState({ openOTPVerify: false, numberIsVerified: true, otpError: '' });
            this.resetTimer();
          } else {
            this.setState({
              otpError: "Please enter correct OTP number",
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.optAPIId) {

          this.setState({ otpSent: true, otpSendLoading: false });

          if (responseJson && responseJson.meta && responseJson.meta.token) {
            localStorage.setItem("otpToken", responseJson.meta.token);
            this.setState({
              openOTPVerify: true
            });
            this.resetTimer();
            this.startTimer();
          } else {
            this.setState({
              phoneOtpIsInvalid: "Please try another phone number",
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.validationApiCallId) {
          if (!responseJson.errors) {

            const role = responseJson.data.attributes.role;

            localStorage.setItem("role", role);
            localStorage.setItem("currentUserRole", role);

            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate("DesignerHome");
              else
                this.props.navigation.navigate("UserHome");
            }

          } else {
            localStorage.clear();
          }
        }
        else if (apiRequestCallId === this.createAccountApiCallId) {
          if (responseJson != null) {

            this.setState({ createAccountLoading: false });

            if (!responseJson.errors) {
              console.log(responseJson);
              localStorage.setItem("token", responseJson.meta.token);
              localStorage.setItem("cuid", responseJson.data.id);
              this.setState({
                step: 2,
              });
            } else {
              const errors = responseJson.errors.reduce((allErrors: any, error: any) => ({ ...allErrors, ...error }), {});

              this.setState({
                inputValidations1: errors,
              });
            }
          }
        } else if (apiRequestCallId === this.createAccount2ApiCallId) {
          this.setState({ createAccountLoading2: false });
          if (responseJson != null) {
            if (!responseJson.errors) {
              localStorage.setItem("cuid", responseJson.account.data.id);
              const role = responseJson.account.data.attributes.role;
              localStorage.setItem("role", role);
              localStorage.setItem("currentUserRole", role);
              if (localStorage.getItem("socialLogin") === 'true') {
                this.props.navigation.navigate("EmailAccountLoginBlockWeb");
              } else {
                this.setState({ displayConfirmPopup: true });
              }
            } else {
              console.log(responseJson);
            }
          }
        } else if (apiRequestCallId === this.createAccountViaGoogleId) {
          console.log("ID Match");
          if (responseJson != null) {
            if (!responseJson.errors) {
              console.log(responseJson);
              localStorage.setItem("token", responseJson.meta.token);
              localStorage.setItem("cuid", responseJson.data.id);
              this.setState({
                step: 2,
              });
            } else {
              const errors = responseJson.errors.reduce((allErrors: any, error: any) => ({ ...allErrors, ...error }), {});
              this.setState({
                inputValidations1: errors,
              });
            }
          }
        } else if (apiRequestCallId === this.createAccountViaMicrosoftId) {
          console.log("ID Match");
          if (responseJson != null) {
            if (!responseJson.errors) {
              console.log(responseJson);
              localStorage.setItem("token", responseJson?.meta?.token);
              //localStorage.setItem("cuid", responseJson.account.data.id);
              localStorage.setItem("cuid", responseJson?.data?.id);
              this.setState({
                step: 2,
              });
            } else {
              const errors = responseJson.errors.reduce((allErrors: any, error: any) => ({ ...allErrors, ...error }), {});

              this.setState({
                inputValidations1: errors,
              });
            }
          }
        }
        // else if (apiRequestCallId === this.rolesPermissionId) {
        //   if (responseJson != null) {
        //     console.log("Roles Permission", responseJson);
        //     this.setState({
        //       roles: responseJson.data,
        //     });
        //     console.log(this.state.roles);
        //   } else {
        //     console.log("ERROR =>>>>", errorReponse);
        //     console.log("Roles Permission", responseJson);
        //   }
        // }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  /**
   * @deprecated
   */
  getRolesId = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rolesPermissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rolesPermissionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /**
 * @deprecated
 */
  getWhichRole = (roleType: string) => {
    console.log(this.state.roles);
    const filtered = this.state.roles.filter((role) => {
      if (role.attributes.name.toLowerCase() === roleType.toLowerCase()) {
        console.log(role);
        return role.attributes.id;
      }
    });
    return filtered[0].attributes.id;
  };

  /**
  * @deprecated
  */
  encodeImageFileAsURL(file: any) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
  * @deprecated
  */
  validate3 = (loginAs: string) => {
    if (loginAs === "") {
      this.setState({
        inputValidations3: "Please select your login type",
      });
      return true;
    }
    return false;
  };

  onFinishSetup({ loginAs }: any) {
    this.setState({ createAccountLoading2: true });

    const { profilePictureFile, location, color } = this.state;

    let role;
    // Hard-Coded -> Change in Future
    if (loginAs === "designer") {
      role = 1;
    } else {
      role = 2;
    }
    let profileFinal;

    if (profilePictureFile.length > 0 && profilePictureFile !== profileUpload) {
      profileFinal = {
        data: profilePictureFile,
      };
    } else {
      profileFinal = "";
    }
    let data;
    if (this.state.isSkippedClicked === true) {
      data = {
        data: {
          attributes: {
            role_id: role,
            location: "",
            color: "",
            profile: "",
          },
        },
      }
    } else {
      data = {
        data: {
          attributes: {
            role_id: role,
            location: location,
            color: color,
            profile: profileFinal,
          },
        },
      };
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("token"),
    };

    const httpBody = data;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.apiMethodTypeChangeDetail,
      url: configJSON.signup2Api,
    });

    this.createAccount2ApiCallId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  /*
  validate1 = () => {
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errors = {
      name: "",
      username: "",
      email: "",
      password: "",
      phone: "",
      terms: "",
    };
    const onlySpecialCharReg = /^[!@#$%^&*()_-]+$/
    const onlyNumbersReg = /^\d+$/;
    const { email, name, username, password, phone, terms } = this.state;
    let flag = false;
    if (name === "") {
      errors.name = "Name is required";
      flag = true;
    } else if (name.trim().length === 0) {
      errors.name = "Please enter a valid name";
      flag = true;
    }
    if (username === "") {
      errors.username = "Username is required";
      flag = true;
    } else if (username.length < 3 || username.length > 25) {
      errors.username = "Please enter a username between 3 and 25 characters"
    }
    else if (username.trim().length === 0) {
      errors.username = "Please enter a valid username";
      flag = true;
    } else if (onlySpecialCharReg.test(username.trim())) {
      errors.username = "Only Special characters are not allowed";
      flag = true;
    } else if (onlyNumbersReg.test(username.trim())) {
      errors.username = "Only numbers are not allowed";
      flag = true;
    }
    if (password === "") {
      errors.password = "Password is required";
      flag = true;
    } else if (password.trim().length === 0) {
      errors.password = "Your password can't start or end with a blank space";
      flag = true;
    } else if (password[0] === " " || password[password.length - 1] === " ") {
      errors.password = "Your password can't start or end with a blank space";
      flag = true;
    } else if (password.trim().length < 8) {
      errors.password = "Password must be at least 8 characters";
      flag = true;
    }
    if (email.trim().length === 0) {
      errors.email = "Email is required";
      flag = true;
    } else if (!this.emailReg.test(email.trim())) {
      errors.email = "Please enter a valid email";
      flag = true;
    }
    if (phone.trim().length === 0) {
      errors.phone = "Phone is required";
      flag = true;
    } else if (phone.trim().length < 10) {
      errors.phone = "Phone must be at least 10 characters";
      flag = true;
    }
    if (terms === false) {
      errors.terms = "Please accept terms and conditions";
      flag = true;
    }
    this.setState({
      inputValidations1: errors,
    });
    return flag;
  };
*/
  onCreateAccount = ({
    name,
    username,
    email,
    phone,
    password,
    acceptTerms,
  }: any) => {

    this.setState({ createAccountLoading: true, email });
    const errors = {};
    this.setState({
      inputValidations1: errors,
    });
    // e.preventDefault();
    // const { email, name, username, password, phone, terms } = this.state;
    const full_phone_number = "91" + phone;
    const data = {
      data: {
        attributes: {
          first_name: name.trim(),
          user_name: username.trim(),
          email: email,
          full_phone_number: full_phone_number, //Add With country Code
          password: password,
          terms_and_conditions_accepted: acceptTerms,
        },
      },
    };

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = data;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.apiMethodTypeAddDetail,
      url: configJSON.signup1Api,
    });

    this.createAccountApiCallId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    // return true;
  };

  callGetValidationApi() {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getTokenValidationApi,
    });

    this.validationApiCallId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  onSigninSubmit = () => {
    this.props.navigation.navigate("EmailAccountLoginBlockWeb");
  };

  // validate2 = () => {
  //   const onlySpecialCharReg = /^[!@#$%^&*()_-]+$/
  //   const onlyNumbersReg = /^\d+$/;
  //   let flag = false;
  //   const errors = {
  //     profilePicture: "",
  //     location: "",
  //   };
  //   const { color, profilePictureFile, location } = this.state;
  //   if (color.length === 0 && profilePictureFile === profileUpload) {
  //     errors.profilePicture =
  //       "Please upload an avatar or choose one of our defaults";
  //     flag = true;
  //   }
  //   if (location.length === 0) {
  //     errors.location = "Location is required";
  //     flag = true;
  //   } else if (onlySpecialCharReg.test(location.trim())) {
  //     errors.location = "Only Special characters are not allowed";
  //     flag = true;
  //   } else if (onlyNumbersReg.test(location.trim())) {
  //     errors.location = "Only numbers are not allowed";
  //     flag = true;
  //   }
  //   this.setState({
  //     inputValidations2: errors,
  //   });
  //   return flag;
  // };


  // fileValidation(file: any) {
  //   let filePath = file.value;
  //   // Allowing file type
  //   var idxDot = filePath.lastIndexOf(".") + 1;
  //   var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
  //   if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
  //     return true;
  //   } else {
  //     const { inputValidations2 } = this.state;
  //     const errors = {
  //       ...inputValidations2,
  //       profilePicture: "Only jpg/jpeg and png files are allowed!",
  //     }
  //     this.setState({
  //       inputValidations2: errors
  //     })
  //     return false;
  //   }
  // }

  // bytesToMegaBytes = (bytes: any) => bytes / 1024 ** 2;

  // validateFileSize(
  //   inputId: string,
  //   MAX_FILE_SIZE: any,
  // ): boolean {
  //   const input = document.getElementById(inputId) as HTMLInputElement | null;
  //   if (input && input.files) {
  //     const file = input?.files[0];
  //     const fileSize = this.bytesToMegaBytes(file?.size);
  //     if (fileSize > MAX_FILE_SIZE) {
  //       const { inputValidations2 } = this.state;
  //       const errors = {
  //         ...inputValidations2,
  //         profilePicture: (`File size should be less than ${MAX_FILE_SIZE} MB`)
  //       }
  //       this.setState({
  //         inputValidations2: errors
  //       })

  //       return false;
  //     }
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  onGoogleLoginSuccess = (res: any) => {
    this.setState({
      inputValidations1: {}
    })
    console.log(res);
    const { email } = res.profileObj;
    localStorage.setItem("token", res.tokenId);
    localStorage.setItem("socialLogin", "true");
    this.setState({
      email: email,
      // name: res.profileObj.name,
      // username: "",
      // phone: "",
      unique_auth_id: res.googleId,
      signUpType: "google",
    });
    const data = {
      "data": {
        attributes: {
          first_name: res.profileObj.name,
          user_name: "",
          email: email,
          unique_auth_id: res.googleId,
          full_phone_number: "" //Add With country Code
        },
        type: "google"
      }
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("token"),
    };

    const httpBody = data;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.apiMethodTypeAddDetail,
      url: configJSON.signup1Api,
    });

    this.createAccountViaGoogleId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  };

  // createAccountViaGoogle = () => {

  //   console.log("createAccountViaGoogle");
  //   const {
  //     email,
  //     name,
  //     color,
  //     location,
  //     unique_auth_id,
  //     loginAs,
  //     profilePictureFile,
  //   } = this.state;
  //   let role;
  //   // Hard-Coded -> Change in Future
  //   if (loginAs === "designer") {
  //     role = 1;
  //   } else {
  //     role = 2;
  //   }
  //   const data = {
  //     data: {
  //       attributes: {
  //         email: email,
  //         first_name: name,
  //         user_name: "",
  //         role_id: role,
  //         full_phone_number: "", //Add With country Code
  //         location: location,
  //         color: color,
  //         unique_auth_id: unique_auth_id,
  //         profile: {
  //           data: profilePictureFile
  //         }
  //       },
  //       type: "google",
  //     },
  //   };
  //   console.log(
  //     "🚀 ~ file: EmailAccountRegistrationController.web.tsx ~ line 476 ~ data",
  //     data
  //   );

  //   const header = {
  //     "Content-Type": configJSON.contentTypeApiAddDetail,
  //   };
  //   console.log(data);
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.createAccountViaGoogleId = requestMessage.messageId;

  //   const httpBody = data;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.googleSignUpApiEndPoint
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.apiMethodTypeAddDetail
  //   );

  //   console.log("REQUEST: ", requestMessage);

  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // };

  onGoogleLoginFailure = (res: any) => {
    console.log("Google Login Failure");
    console.log(res);
  };

  /**
   * @deprecated 
   */
  onFinishCreatingAccount = (e: any) => {
    const { loginAs } = this.state;
    if (this.validate3(loginAs)) return;
    e.preventDefault();
    if (this.state.signUpType === "email") {
      // this.onFinishSetup();
    } else if (this.state.signUpType === "google") {
      // this.onGoogleFinishSetup();
    } else if (this.state.signUpType === "microsoft") {
      this.onMicrosoftFinishSetup();
    }
  };

  microsoftAuthHandler = (err: any, data: any, msal: any) => {
    console.log("MICROSOFT AUTH HANDLER FUNCTION");
    console.log(data);
    if (err !== null) {
      return console.log(err);
    }
    const { name, userName } = data.account;
    const { accessToken } = data;
    localStorage.setItem("token", accessToken);
    localStorage.setItem("socialLogin", "true");
    const microsoftData = {
      "data": {
        attributes: {
          first_name: name,
          user_name: "",
          email: userName,
          unique_auth_id: accessToken,
          full_phone_number: "" //Add With country Code
        },
        type: "microsoft"
      }
    }
    console.log("🚀 ~ file: EmailAccountRegistrationController.web.tsx ~ line 858 ~ microsoftData", microsoftData)

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("token"),
    };

    const httpBody = microsoftData;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.apiMethodTypeAddDetail,
      url: configJSON.signup1Api,
    });

    this.createAccountViaMicrosoftId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  };

  /**
   * @deprecated 
   */
  onMicrosoftFinishSetup() {
    const {
      email,
      name,
      color,
      location,
      unique_auth_id,
      loginAs,
    } = this.state;
    let role;
    // Hard-Coded -> Change in Future
    if (loginAs === "designer") {
      role = 1;
    } else {
      role = 2;
    }
    const data = {
      data: {
        attributes: {
          email: email,
          first_name: name,
          user_name: "",
          role_id: role,
          full_phone_number: "", //Add With country Code
          location: location,
          color: color,
          unique_auth_id: unique_auth_id,
        },
        type: "google",
      },
    };
    console.log(
      "🚀 ~ file: EmailAccountRegistrationController.web.tsx ~ line 476 ~ data",
      data
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    console.log(data);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountViaGoogleId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleSignUpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /**
   * @deprecated 
   */
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  /**
 * @deprecated 
 */
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // isStringNullOrBlank(str: string) {
  //   return str === null || str.length === 0;
  // }

  // isValidEmail(email: string) {
  //   return this.emailReg.test(email);
  // }

  /**
  * @deprecated 
  */
  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  /**
    * @deprecated 
    */
  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }


  /**
  * @deprecated 
  */
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };


  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;
  /********************************/

  // txtInputLastNamePrpos = {
  //   onChangeText: (text: string) => {
  //     this.setState({ name: text });

  //     //@ts-ignore
  //     this.txtInputLastNamePrpos.value = text;
  //   },
  // };

  // txtInputFirstNamePrpos = {
  //   onChangeText: (text: string) => {
  //     this.setState({ firstName: text });

  //     //@ts-ignore
  //     this.txtInputFirstNamePrpos.value = text;
  //   },
  // };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  onClosePoup() {
    this.setState({ displayConfirmPopup: false });
    this.props.navigation.navigate("EmailAccountLoginBlockWeb");
  }

  sendOtpCode({ phone }: { phone: number }) {

    this.setState({ phoneOtpIsInvalid: '', otpSendLoading: true, otpError: '' });

    // let mobileNumber = this.state.phone;
    let mobileNumber = phone;
    const fullMobileNumber = "91" + mobileNumber;
    const data = {
      data: {
        attributes: {
          full_phone_number: fullMobileNumber, //Add With country Code
        },
      },
    };

    console.log(data);
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.optAPIId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.APiOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ApiOTPMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyOtpCode({ otp }: { otp: number }) {
    this.setState({ otpError: '', otpVerifyLoading: true });

    const data = {
      data: {
        attributes: {
          pin: otp,
          token: localStorage.getItem("otpToken"),
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpVerifyAPIId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OPTVerifyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.OPTVerifyMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  startTimer() {
    this.otpSecondsTimerId = setInterval(() => {
      if (this.state.otpSeconds > 0)
        this.setState(state => ({ otpSeconds: state.otpSeconds - 1 }));
    }, 1000);
  }

  resetTimer() {
    clearInterval(this.otpSecondsTimerId);
    this.setState({ otpSeconds: OTP_TIME });
  }
  // Customizable Area End
}
