import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, AppBar, Toolbar, IconButton, Typography, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from '../../../../../components/src/utils';
import { dezinerKnotLogo, secureIcon } from '../../assets';
import CloseIcon from '@material-ui/icons/Close';
import DesignMainMedia from '../../design/design-view/DesignMainMedia.web';
import { useCreateBuyIntent, useNavigate, useSingleDesign } from '../../../../../components/src/hooks';
import { VerticalSpacer ,DezinerButton} from '../../../../../components/src/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../components/src/store/store';

import { PaymentElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLIC_KEY = 'pk_test_51KFG19SFEXBiQwUsuUlPwiDYhuExYgXSmN4SDDbSsPDpf2p8zLGvtKa8R8Bi6DlE7t6g4PCK90PCzOAglxZxNigr00dxRzukpm';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);


const useStyles = makeStyles({
  pageWrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '48% 48%',
    justifyContent: 'space-between',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: '100%',
    }
  },
  logo: {
    maxWidth: '220px',
    cursor: 'pointer',
    padding: '25px 0',
  },
  orderSummary: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  designTitle: {
    padding: '14px 14px',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  priceRow: {
    padding: '14px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#292929',
    '& > *': {
      fontSize: '1.3rem',
    },
  },
  priceRowGray: {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  grayText: {
    color: '#767676',
  },
  boldText: {
    fontWeight: 'bold',
  },
  redeemText: {
    color: 'black',
    padding: '0 2px',
  },
  meduimFont: {
    fontSize: '1.2rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  black: {
    color: 'black',
  },
  italic: {
    fontStyle: 'italic',
  },
  line: {
    margin: '10px 0',
    borderColor: '#e6e6e6',
  },
  payButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ['@media screen and (max-width: 768px)']: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  payButton: {
    padding: '15px 70px',
    fontSize: '1.6rem',
  },
  link: {
    padding: '0 5px',
    color: 'black',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});


export function PaymentStatusDialog({
  open,
  onClose,
  title,
  message,
  severity,
}: {
  open: boolean,
  onClose: () => void,
  title: string,
  message: string,
  severity: 'success' | 'error',
}) {

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open} onClose={onClose}>

      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <Typography style={{ color: severity == 'error' ? 'red' : 'inherit', whiteSpace: 'pre-wrap' }}>
          {severity == 'error' ? '❌' : '🎉'}&nbsp;{message}
        </Typography>
      </DialogContent>

      <DialogActions>
        <DezinerButton
          onClick={onClose}
          variant="text"
          color="secondary"
        >
          {'close'}
        </DezinerButton>
      </DialogActions>

    </Dialog>
  );
}


function CheckoutForm({
  disabled,
}: {
  disabled: boolean,
}) {

  const classes = useStyles();


  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false)

  const params = useParams();
  const designId = parseInt(params?.designId ?? 0);


  const [error, setError] = useState<any>(undefined);
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);

  async function submitStripeHandler(event: any) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const { error }: any = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/DesignView/${designId}`
      },
    });

    setIsLoading(false);
    setPaymentSubmitted(true);

    if (error) {
      setError(error);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.error(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }



  return (
    <>
      <PaymentStatusDialog
        open={paymentSubmitted}
        onClose={() => {
          setPaymentSubmitted(false);
        }}
        severity={'error'}
        title={'Payment Failure'}
        message={error?.message ?? 'Payment Failed! Please try another credit card instead.'}
      />
      <Grid container direction="column"
        style={{ maxWidth: '500px', margin: 'auto' }}
      >
        <PaymentElement />
        <VerticalSpacer />
        <VerticalSpacer />
        <DezinerButton
          color="primary"
          variant="contained"
          size="large"
          className={classes.payButton}
          disabled={disabled || isLoading}
          onClick={submitStripeHandler}
          loading={isLoading}
        >
          {'Pay Now'}
        </DezinerButton>
      </Grid>
    </>
  );
}



export default function DesignBuyPageContentInner() {

  const params = useParams();
  const designId = parseInt(params?.designId);
  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const navigate = useNavigate();

  const classes = useStyles();

  const queryParams = useSelector((state: RootState) => state.router.location.query);
  const priceId = parseInt(queryParams.price_id || '0')

  const isLimitedPeriod = Boolean(priceId);


  const {
    mutate: createPaymentIntent,
    data: paymentIntentResponse = { payment_intent_id: '', client_secret: '' },
    isLoading
  } = useCreateBuyIntent({
    designId,
    pricePeriodId: priceId,
    paidType: isLimitedPeriod ? 'Limited Period' : 'Lifetime',
  });

  useEffect(function savePaymentIntent() {
    sessionStorage.setItem('payment_intent_id', paymentIntentResponse.payment_intent_id);
    sessionStorage.setItem('client_secret', paymentIntentResponse.client_secret);
  }, [paymentIntentResponse]);

  const clientSecret = paymentIntentResponse.client_secret;


  const options = {
    // passing the client secret obtained in step 3
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };


  const [acceptTermsAndCondition, setAcceptTermsAndCondition] = useState(false);

  const targetPriceItem = isLimitedPeriod ? designAttributes?.design_prices?.find(price => price.id == priceId) : undefined;


  const basePrice = priceId ?
    (targetPriceItem?.amount ?? 0)
    :
    (designAttributes?.price ?? 0);

  const igst = basePrice * 18 / 100;
  const platformFee = basePrice * 12 / 100;


  const totalPrice = basePrice + igst + platformFee;



  const [showPayForm, setShowPayForm] = useState(false);

  const yearsCount = targetPriceItem?.year;
  const yearString = yearsCount ? `${yearsCount} Year(s)` : '';

  const monthsCount = targetPriceItem?.month
  const monthString = monthsCount ? `${monthsCount} Month(s)` : ''

  const andString = monthsCount && yearsCount ? 'and' : ''

  const buyForLimitedTimeString = `Buy for ${yearString} ${andString} ${monthString}`;


  function payNow1SubmitHandler() {

    createPaymentIntent({
      price: totalPrice,
      paidType: isLimitedPeriod ? 'Limited Period' : 'Lifetime',
      pricePeriodId: priceId,
      designId,
    }, {
      onSuccess: () => {
        setShowPayForm(true)
      }
    });
  }


  return (
    <div className={classes.pageWrapper}>


      <AppBar position="static" color="inherit" elevation={1}>
        <Toolbar style={{ justifyContent: 'space-between' }}>

          <img
            src={dezinerKnotLogo}
            alt="Deziner Knot"
            className={classes.logo}
          />

          <IconButton onClick={() => navigate(`/DesignView/${designId}`)}>
            <CloseIcon />
          </IconButton>

        </Toolbar>
      </AppBar>

      <VerticalSpacer />

      {
        showPayForm && clientSecret ?
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              disabled={!acceptTermsAndCondition}
            />
          </Elements>
          :
          <div className={classes.root}>
            <div className={classes.container} >

              <div>
                <Typography className={classes.orderSummary}>
                  {'Order summary'}
                </Typography>

                <DesignMainMedia
                  selectedMediaIndex={0}
                  designId={designId}
                />
              </div>


              <div>
                <VerticalSpacer />
                <VerticalSpacer />

                <Typography className={classes.designTitle}>
                  {designAttributes?.title}
                </Typography>

                <div className={classes.priceRow}>
                  <Typography>
                    {
                      priceId ?
                        buyForLimitedTimeString
                        :
                        'For a lifetime purchase'
                    }
                  </Typography>
                </div>
                <div className={classes.priceRow}>
                  <Typography>{'Price'}</Typography>
                  <Typography>{`₹ ${basePrice}`}</Typography>
                </div>

                <div className={classes.priceRow}>
                  <Typography>{'Platform fee'}</Typography>
                  <Typography>{`₹ ${platformFee}`}</Typography>
                </div>
                <div className={classes.priceRow}>
                  <Typography>{'IGST'}</Typography>
                  <Typography>{`₹ ${igst}`}</Typography>
                </div>
                <div className={clsx(classes.priceRow, classes.priceRowGray)}>
                  <Typography>{'Total'}</Typography>
                  <Typography>{`₹ ${totalPrice}`}</Typography>
                </div>

                <VerticalSpacer />
                <VerticalSpacer />


                <VerticalSpacer />

              </div>


            </div>

            <hr className={classes.line} />

            <div className={classes.payButtonContainer}>


              <div>
                <FormControlLabel
                  control={<Checkbox checked={acceptTermsAndCondition} onChange={() => setAcceptTermsAndCondition(x => !x)} color="primary" name="accept" />}
                  label={<Typography className={classes.grayText}>
                    {`By making this purchase you agree to our`}
                    <span className={clsx(classes.boldText, classes.link)}>
                      <Link to="/TermsOfService">
                        {'terms'}
                      </Link>
                    </span>
                    {`and`}
                    <span className={clsx(classes.boldText, classes.link)}>
                      <Link to="/PrivacyPolicy">
                        {'privacy policy'}
                      </Link>
                    </span>
                  </Typography>}
                />

                <Grid container alignItems="center">
                  <img src={secureIcon} style={{ marginRight: '6px' }} />
                  <Typography className={classes.grayText}>
                    {'Secured & encrypted checkout'}
                  </Typography>
                </Grid>
              </div>

              <DezinerButton
                color="primary"
                variant="contained"
                size="large"
                className={classes.payButton}
                disabled={!acceptTermsAndCondition || isLoading}
                onClick={payNow1SubmitHandler}
                loading={isLoading}
              >
                {'Pay Now'}
              </DezinerButton>



            </div>


          </div>
      }

    </div>
  );
}

/*
 *
 *


  const [showRedeemInput, setShowRedeemInput] = useState(false);
  const [redeemValue, setRedeemValue] = useState(0);

  const totalPriceBeforeRedeem = basePrice + igst + platformFee;

  const totalPrice = totalPriceBeforeRedeem - (showRedeemInput ? 0 : redeemValue);

  const isValidRedeem = redeemValue <= availableRedeem && redeemValue <= totalPriceBeforeRedeem;

Reddem jsx logic
                {/*
              showRedeemInput ?
                <div className={classes.priceRow} style={{ alignItems: 'flex-start' }}>
                  <div>
                    <Typography className={clsx(classes.grayText, classes.boldText, classes.meduimFont)}>
                      {`Enter your redeem value`}
                    </Typography>
                    {*//*
redeemValue && isValidRedeem ?
<Typography className={clsx(classes.grayText, classes.meduimFont)}>
{
availableRedeem - redeemValue == 0 ?
`You have no more to redeem` :
`You still have ₹ ${availableRedeem - redeemValue} available to Redeem`
}
</Typography>
:
null
*//*}
{
  redeemValue && !isValidRedeem ?
    <Typography className={clsx(classes.grayText, classes.italic)}>
      {'You have exceeded the max limit of your redemption value.'}
    </Typography>
    :
    null
}
</div>
<div style={{ display: 'flex', flexDirection: 'column' }}>
<DezinerTextField
  variant="outlined"
  value={redeemValue}
  type="number"
  error={!isValidRedeem}
  onChange={(event) => {
    let value = parseInt(event.target.value);
    if (!value || value >= 0)
      setRedeemValue(value)
  }}
  InputProps={{
    startAdornment: <span>{'₹'}</span>
  }}
  style={{
    maxWidth: '100px'
  }}
/>
<DezinerButton
  color="primary"
  disabled={!isValidRedeem}
  variant="contained"
  onClick={() => {
    if (isValidRedeem)
      setShowRedeemInput(false);
  }}>
  {'Redeem'}
</DezinerButton>
<DezinerButton
  color="primary"
  variant="text"
  onClick={() => {
      setShowRedeemInput(false);
  }}>
  {'cancel'}
</DezinerButton>
</div>
</div>
:
redeemValue ?
<>
<Typography className={clsx(classes.grayText, classes.meduimFont, classes.boldText)}>
  {'Congrats! Your loyalty points value has been applied to the cart.'}
</Typography>
<Typography className={clsx(classes.grayText, classes.meduimFont, classes.italic)}>
  {`Do you want to change your redemption value?`}
  <span style={{ padding: '0 5px' }} className={clsx(classes.boldText, classes.black, classes.pointer)} onClick={() => setShowRedeemInput(true)}>
    {`change`}
  </span>
  {'here'}
</Typography>
</>
:
<Typography className={clsx(classes.grayText, classes.meduimFont)}>
{`You have a Loyalty points value of `}
<span className={clsx(classes.boldText, classes.pointer, classes.black)} onClick={() => setShowRedeemInput(true)}>
  {`₹ ${availableRedeem}, Redeem`}
</span>
{` here`}
</Typography>
*//*}


*/

