import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

export function DezinerSpinner({noHeight,size}:{noHeight?:boolean,size?:number}) {
  return (
    <div
      data-testid="deziner-spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: noHeight? '10px' : "200px",
      }}
    >
      <CircularProgress size={size}/>
    </div>
  );
}
