import React from 'react';
import {
    useCometChatLatestConversationsList, useMyUID,
} from './hooks';
import ConversationList from './ConversationList';


export type OnConversationClick = (params: { uid: string, name: string, onlineStatus: string, chatType: 'group' | 'user' }) => void;

export default function ConversationsListContainer({
    onConversationClick
}: {
    onConversationClick: OnConversationClick
}) {


    const uid = useMyUID();
    let conversationsQuery = useCometChatLatestConversationsList({ uid });
    let conversations = conversationsQuery?.data ?? [];
    let loading = conversationsQuery?.isLoading;

    console.log('cometChat conversation....', conversations);

    let conversationListProps = { loading, conversations, onConversationClick };

    return (
        <ConversationList {...conversationListProps} />
    );
}
