import React from "react";
import { dezinerKnotLogo } from '../../landingpage/src/assets';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useLogoTitleHeaderStyles = makeStyles({
	header: {
		background: 'black',
		padding: '10px 50px',
		display: 'grid',
		gridTemplateColumns: '1fr 2fr 1fr',
		alignItems: 'center',
		['@media screen and (max-width: 768px)']: {
			gridTemplateColumns: '1fr 1fr',
		},
		['@media screen and (max-width: 500px)']: {
			gridTemplateColumns: '1fr',
			justifyItems: 'center',
		},
	},
	logo: {
		maxWidth: '190px'
	},
	title: {
		fontWeight: 'bold',
		fontSize: '1.5rem',
		color: 'white',
		textAlign: 'center',
	},
});

export default function LogoTitleHeader({ onLogoClick, title }: { onLogoClick: () => void, title: string }) {

	const classes = useLogoTitleHeaderStyles();

	return (
		<header className={classes.header}>
			<img
				src={dezinerKnotLogo}
				onClick={onLogoClick}
				className={clsx('white-svg', classes.logo)}
			/>
			<Typography variant="h1" className={classes.title}>
				{title}
			</Typography>
			<span />
		</header>
	)
}
