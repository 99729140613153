import {makeStyles} from '@material-ui/core/styles';

export const useClubsAndGroupCreateFromStyles = makeStyles({
    coverImageContainer: {
        background: '#f0f0f0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        minHeight: '250px',
        position: 'relative',
        ['@media screen and (max-width: 768px)']: {
            alignItems: 'flex-start'
        },
        '&>button': {
            margin: '20px'
        },
        '&>img': {
            position: 'absolute',

            width: '100%',
            maxWidth: '100%',
            height: '250px',
            objectFit: 'cover',
            zIndex: -1,
        }
    },
    trasparentBackground: {
        background: 'transparent',
    },
    camerIcon: {
        width: '25px',
        maxWidth: '25px',
    },
    uploadBtn: {
        fontSize: '1.1rem',
        fontWeight: 700,
        color: '#a9a9a9'
    },
    profileImageContainer: {
        padding: "10px",
        background: "white",
        borderRadius: "40%",
        width: "150px",
        height: "150px",
        margin: "auto",
        position: "relative",
        marginBottom: '-80px',
        top: "-70px",
        '&>img:hover': {
            border: '2px solid gray'
        },
        '&>img': {
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: '40%',
            objectFit: 'cover',
            width: "150px",
            height: "150px",
            cursor: "pointer",
        },
        '&>div:hover': {
            background: '#f5f5f5'
        },
        '&>div': {
            '& img': {
                margin: '10px 0'
            },
            border: "2px dashed #a9a9a9",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "40%",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            cursor: "pointer",

        }
    },
    formItemsContainer: {
        maxWidth: '350px',
        margin: '0 auto',
        padding: '10px'
    },
    submitContainer: {
        padding: '30px',
    },

});