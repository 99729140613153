import { CometChat } from "@cometchat-pro/chat";
import { useDispatch } from "react-redux";
import { useQuery } from '@tanstack/react-query';
import { useIsLoggedIn } from "../../../../components/src/hooks";
import { setUserOnlineStatus } from "../../../../components/src/store/ui/ui.actions";
import { ConversationWithUser } from "../types";
import { cometchatKeys } from "../query-keys";

export function useCometChatUserPresence({ uid }: { uid: string }) {

    const dispatch = useDispatch();

    if (uid) {
        CometChat.addUserListener(
            uid,
            new CometChat.UserListener({
                onUserOnline: (onlineUser: ConversationWithUser) => {
                    console.log("cometChat On User Online:", onlineUser.name);
                    dispatch(setUserOnlineStatus({ status: 'online', uid: onlineUser.uid }));
                },
                onUserOffline: (offlineUser: ConversationWithUser) => {
                    console.log("cometChat On User Offline:", offlineUser.name);
                    dispatch(setUserOnlineStatus({ status: 'offline', uid: offlineUser.uid }));
                },
            })
        );
    }

    return function unsubscribe() {
        if (uid) {
            CometChat.removeUserListener(uid)
        }
    }
}

export function useCometChatGroupMembers({ guid }: { guid: string }) {
    const isLoggedIn = useIsLoggedIn();

    function fetchGroupMembers() {
        const limit = 50;
        const groupMemberRequest = new CometChat.GroupMembersRequestBuilder(guid)
            .setLimit(limit)
            .build();
        return new Promise((resolve, reject) => {
            groupMemberRequest.fetchNext().then(
                groupMembers => {
                    resolve(groupMembers);
                    console.log("Group Member list fetched successfully:", groupMembers);
                }, error => {
                    console.log("Group Member list fetching failed with exception:", error);
                }
            );
        });
    }

    return useQuery(
        cometchatKeys.groupMembersList({ gid: guid }),
        fetchGroupMembers, {
        enabled: Boolean(guid && isLoggedIn),
    });
}