import { DesignCategory } from "../api-types";

export const clubsKeys = {
  fetchClubs: () => ['clubs'] as const,
  fetchSingleClubs: (params: { clubId: number }) => ['single-club', params] as const,

  fetchLabels: (params: { clubId: number }) => ['club-labels', params] as const,

  fetchCollections: (params: { clubId: number, labelId?: number }) => ['club-collections', params] as const,

  changeCover: (params: { clubId: number, }) => ['club-change-cover', params] as const,

  fetchDesignsByCategory: (params: {
    category: DesignCategory,
    labelId?: number,
    clubId: number,
  }) => ['clubs-designs', params] as const,


  fetchMembers: (params: { clubId: number }) => ['club-members', params] as const,
  addAsAdmin: (params: { clubId: number }) => ['add-as-admin', params] as const,
  removeAdminPermission: (params: { clubId: number }) => ['remove-admin-permission', params] as const,
  removeMember: (params: { clubId: number }) => ['remove-member', params] as const,

  createLabel: (params: { clubId: number }) => ['create-club-label', params] as const,
  createCollection: (params: { clubId: number }) => ['create-club-collection', params] as const,

  updateInfo: (params: { clubId: number }) => ['update-club-info', params] as const,
  updateSocialProfiles: (params: { clubId: number }) => ['update-club-social-profiles', params] as const,

  addMemberToClub: (params: { clubId: number }) => ['add-member', params] as const,
  fetchClubRoles: () => ['club-roles'] as const,
  createNewClub: () => ['create-new-club'] as const,

  fetchNonRespondingMembers: (params: { clubId: number }) => ['clubs-non-responding-members', params] as const,

  followClub: () => ['follow-club', ] as const,
  unfollowClub: () => ['unfollow-club', ] as const,
  clubFollowers: (params: { clubId: number }) => ['club-followers', params] as const,
  acceptInvitation: (params: { clubId: number }) => ['clubs-accept-invitation', params] as const,
  declineInvitation: (params: { clubId: number }) => ['clubs-decline-invitation', params] as const,
}
