import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { Club, ClubMember, CollectionHome, Design, DesignCategory, Label, PostedBy } from "../api-types";
import { clubsMessages, collectionsMessages, labelsMessages } from "../messages-generator";
import { clubsKeys } from "../query-keys/clubs";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";

export function useClubFollowers({ clubId }: { clubId: number }) {
  
  return useDezinerQuery<{ data: PostedBy[] }>({
    message: clubsMessages.fetchClubFollowers({ clubId }),
    queryKey: clubsKeys.clubFollowers({ clubId }),
  });
}

export function useUnfollowClub({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.unfollowClub>[0]>({
    messageGenerator: clubsMessages.unfollowClub,
    queryKey: clubsKeys.unfollowClub(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchSingleClubs({ clubId }));
        queryClient.invalidateQueries(clubsKeys.clubFollowers({ clubId }));
      }
    }
  });
}


export function useFollowClub({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.followClub>[0]>({
    messageGenerator: clubsMessages.followClub,
    queryKey: clubsKeys.followClub(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchSingleClubs({ clubId }));
        queryClient.invalidateQueries(clubsKeys.clubFollowers({ clubId }));
      },
    }
  });
}

export function useClubsNonRespondingMembers({ clubId }: { clubId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: ClubMember[] }>({
    message: clubsMessages.fetchNonResponsdingMembers({ clubId }),
    queryKey: clubsKeys.fetchNonRespondingMembers({ clubId }),
    options,
  });
}

export function useCreateClub() {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.createClub>[0]>({
    messageGenerator: clubsMessages.createClub,
    queryKey: clubsKeys.createNewClub(),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchClubs());
      },
    }
  });
}

export function useClubsRoles() {

  return useDezinerQuery<{ user_types: string[] }>({
    message: clubsMessages.fetchClubRoles(),
    queryKey: clubsKeys.fetchClubRoles(),
  });
}

export function useAddMemberToClub({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.addMemberToClub>[0]>({
    messageGenerator: clubsMessages.addMemberToClub,
    queryKey: clubsKeys.addMemberToClub({ clubId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchMembers({ clubId }));
        queryClient.invalidateQueries(clubsKeys.fetchNonRespondingMembers({ clubId }));
      },
    }
  });
}


export function useClubs() {

  return useDezinerQuery<{ data: Club[] }>({
    message: clubsMessages.fetchClubs(),
    queryKey: clubsKeys.fetchClubs(),
  });
}

export function useSingleClub({ clubId }: { clubId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Club }>({
    message: clubsMessages.fetchSingleClub({ clubId }),
    queryKey: clubsKeys.fetchSingleClubs({ clubId }),
    options,
  });
}

export function useClubLabels({ clubId }: { clubId: number }) {

  return useDezinerQuery<{ data: Label[] }>({
    message: labelsMessages.fetchLabels({ clubId }),
    queryKey: clubsKeys.fetchLabels({ clubId }),
  });
}

export function useClubsCollections(params: { labelId?: number, clubId: number }) {

  return useDezinerQuery<{ data: CollectionHome[] }>({
    message: collectionsMessages.fetchCollections(params),
    queryKey: clubsKeys.fetchCollections(params),
  });
}

export function useClubsDesignsByCategory(params: {
  category: DesignCategory,
  labelId?: number,
  clubId: number,
}, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: Design[] }>({
    message: clubsMessages.fetchClubsDesignsByCategory(params),
    queryKey: clubsKeys.fetchDesignsByCategory(params),
    options,
  });
}

export function useClubChangeCover({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.changeCover>[0]>({
    messageGenerator: clubsMessages.changeCover,
    queryKey: clubsKeys.changeCover({ clubId, }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchSingleClubs({ clubId }));
      }
    }
  });
}


export function useClubMembers({ clubId }: { clubId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: ClubMember[] }>({
    message: clubsMessages.fetchMembers({ clubId }),
    queryKey: clubsKeys.fetchMembers({ clubId }),
    options,
  });
}


export function useAddClubMemberAsAdmin({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.addAsAdmin>[0]>({
    messageGenerator: clubsMessages.addAsAdmin,
    queryKey: clubsKeys.addAsAdmin({ clubId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchMembers({ clubId }));
      }
    },
  });
}


export function useRemoveClubMemberAdminPermission({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.removeAdminPermission>[0]>({
    messageGenerator: clubsMessages.removeAdminPermission,
    queryKey: clubsKeys.removeAdminPermission({ clubId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchMembers({ clubId }));
      }
    },
  });

}


export function useRemoveClubMember({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.removeMember>[0]>({
    messageGenerator: clubsMessages.removeMember,
    queryKey: clubsKeys.removeMember({ clubId }),
    options: {
      onSuccess() {
        queryClient.invalidateQueries(clubsKeys.fetchMembers({ clubId }));
      }
    },
  });

}



export function useCreateClubLabel({ clubId, }: { clubId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof labelsMessages.createLabel>[0]>({
    queryKey: clubsKeys.createLabel({ clubId }),
    messageGenerator: labelsMessages.createLabel,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(clubsKeys.fetchLabels({ clubId }));
      },
    },
  });

}


export function useCreateClubCollection({ clubId, }: { clubId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.createCollection>[0]>({
    queryKey: clubsKeys.createCollection({ clubId }),
    messageGenerator: collectionsMessages.createCollection,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(clubsKeys.fetchCollections({ clubId }));
      },
    },
  });

}


export function useUpdateClubInfo({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.updateClubInfo>[0]>({
    queryKey: clubsKeys.updateInfo({ clubId }),
    messageGenerator: clubsMessages.updateClubInfo,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(clubsKeys.fetchSingleClubs({ clubId }));
      },
    },
  });
}



export function useUpdateClubSocialProfiles({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.updateClubSocialProfiles>[0]>({
    queryKey: clubsKeys.updateSocialProfiles({ clubId }),
    messageGenerator: clubsMessages.updateClubSocialProfiles,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(clubsKeys.fetchSingleClubs({ clubId }));
      },
    },
  });
}

export function useAcceptClubMemberInvitation({ clubId }: { clubId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof clubsMessages.acceptInvitation>[0]>({
    messageGenerator: clubsMessages.acceptInvitation,
    queryKey: clubsKeys.acceptInvitation({ clubId }),
    options: {
      onSuccess(){
        queryClient.invalidateQueries(clubsKeys.fetchMembers({ clubId }))
        queryClient.invalidateQueries(clubsKeys.fetchNonRespondingMembers({ clubId }));
      }
    }
  });
}

export function useDeclineClubMemberInvitation({ clubId }: { clubId: number }) {

  return useDezinerMutation<Parameters<typeof clubsMessages.declineInvitation>[0]>({
    messageGenerator: clubsMessages.declineInvitation,
    queryKey: clubsKeys.declineInvitation({ clubId }),
  });
}
