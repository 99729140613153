import { CometChat } from "@cometchat-pro/chat";
import { useRef } from "react";
import { useDispatch, } from "react-redux";
import { saveCometChatDeliveredMessage, saveCometChatMessage, saveCometChatReadMessage } from "../../../../components/src/store/cometchat/message/message.action";
import { incrementChatUnreadCount } from "../../../../components/src/store/ui/ui.actions";
import { CometChatAudioMessageUser, CometChatAudioMessageGroup, CometChatDeliveredMessage, CometChatMessage, CometChatReadMessage } from "../types";
import { useCometChatMarkAsDelivered } from "./read";
const messageSound = require('../../../../components/assets/message-sound.mp3');


function playMessageSound() {
  let aSound = document.createElement('audio');
  aSound.setAttribute('src', messageSound);
  aSound.play();
}


export function useCometchatReceiveListeners({ uid }: { uid: string, }) {

  const addedEventListener = useRef(false);
  const dispatch = useDispatch();
  const markAsDelivered = useCometChatMarkAsDelivered();

  if (uid && !addedEventListener.current) {

    addedEventListener.current = true;

    CometChat.addMessageListener(
      uid,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChatMessage) => {
          console.log('cometChat text message received successfully', textMessage)

          let uid = '';
          if (textMessage.receiverType == 'group')
            uid = textMessage.receiverId;
          else
            uid = textMessage.sender.uid;

          dispatch(saveCometChatMessage({ uid, message: textMessage, chatType: textMessage.receiverType }));
          dispatch(incrementChatUnreadCount({ uid }));

          markAsDelivered(textMessage);
          playMessageSound();
        },
        onMediaMessageReceived: (mediaMessage: CometChatAudioMessageUser | CometChatAudioMessageGroup) => {
          console.log("cometChat Media message received successfully", mediaMessage);

          let uid = '';
          if (mediaMessage.receiverType == 'group')
            if (mediaMessage.receiver.guid)
              uid = mediaMessage.receiver.guid;
            else
              uid = mediaMessage.sender.uid;

          dispatch(saveCometChatMessage({ uid, message: mediaMessage, chatType: mediaMessage.receiverType }));
          dispatch(incrementChatUnreadCount({ uid }));

          markAsDelivered(mediaMessage);
          playMessageSound();
        },
        onCustomMessageReceived: (customMessage: any) => {
          console.log("cometChat Custom message received successfully", customMessage);
        },
        onMessagesDelivered: (messageReceipt: CometChatDeliveredMessage) => {
          console.log("cometChat MessageDeliverd", { messageReceipt });
          dispatch(saveCometChatDeliveredMessage({ message: messageReceipt }));
          dispatch({ type: 'wow-deliveered' });
        },
        onMessagesRead: (messageReceipt: CometChatReadMessage) => {
          console.log("cometChat MessageRead", { messageReceipt });
          dispatch(saveCometChatReadMessage({ message: messageReceipt }));
          dispatch({ type: 'wow-read' });
        },
      })
    );
  }
}
