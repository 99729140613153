import React, { useRef } from 'react';
import { useFormikContext } from "formik";
import Dropzone from "react-dropzone";
import clsx from 'clsx';
import {
  UplodaDesignInitValues,
  isImageFile, isVideoFile, not, isImageBelowLimits, isVideoBelowLimits,
  FormMedia,
} from './UploadDesignForm.web';
import { Rnd } from "react-rnd";
import { blobToBase64 } from "../../../../../components/src/utils";
import { useUploadDesignFormStyles } from "./UploadDesignFormStyle.web";
import { useWatermarks } from "../../../../../components/src/hooks";
import { defaultToEmptyArray } from "../../../../../components/src/default-values";
import { Watermark } from "../../../../../components/src/api-types";
import { MainMediaPlaceholder } from "./main-media-placeholder";
import { HideContainer } from "../../../../../components/src/design-system";



export function MainDropzone({
  setMediaOffsetWidth,
  setMediaOffsetHeight,
}: {
  setMediaOffsetWidth: React.Dispatch<React.SetStateAction<number>>,
  setMediaOffsetHeight: React.Dispatch<React.SetStateAction<number>>,
}) {

  const mediaFilesRef = useRef<any>();

  const classes = useUploadDesignFormStyles();
  const formik = useFormikContext<UplodaDesignInitValues>();

  const watermarksQuery = useWatermarks();
  const watermarks = defaultToEmptyArray<Watermark>(watermarksQuery?.data?.data)


  let mediaElement = <span />;

  if (formik.values.media?.[0]?.design_type?.startsWith('image'))
    mediaElement = (<img
      src={formik.values.media?.[0]?.data}
      className={classes.mainImg}
      alt="main image"
      onLoad={({ target }: any) => {
        setMediaOffsetWidth(target.offsetWidth);
        setMediaOffsetHeight(target.offsetHeight);
      }}
    />);

  if (formik.values.media?.[0]?.design_type?.startsWith('video'))
    mediaElement = (<video
      controls
      className={classes.video}
      onLoadedData={({ target }: any) => {
        setMediaOffsetWidth(target.offsetWidth);
        setMediaOffsetHeight(target.offsetHeight);
      }}
    >
      <source src={formik.values.media?.[0]?.data} type={'video/mp4'} />
    </video>);

  return (
    <Dropzone
      multiple
      accept={{
        'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
        'video/*': ['.mp4', '.mkv', '.mov', '.wmv', '.avi']
      }}
      maxFiles={5 - formik.values.media.length}
      onDropRejected={(filesRejected) => {
        let rejectedLength = filesRejected.length;
        let extraFilesLength = Math.abs(5 - (formik.values.media.length + rejectedLength));
        console.error(`You can\'t upload more than 5 files, you uploaded ${extraFilesLength} more files `);
      }}
      onDrop={async (files: any[]) => {
        try {
          if (!files.length) return;

          let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));
          let videosHasExceededLimits = files.filter(isVideoFile).some(not(isVideoBelowLimits));

          if (imagesHasExceededLimits || videosHasExceededLimits)
            formik.setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
          else
            formik.setErrors({ 'media': '' })

          const filteredFiles =
            files
              .filter(file => {
                if (isImageFile(file))
                  return isImageBelowLimits(file);
                else if (isVideoFile(file))
                  return isVideoBelowLimits(file);
              })

          let filesBase64: string[] = await Promise.all(
            filteredFiles.map(blobToBase64)
          );

          let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
            file,
            data: filesBase64[index],
            design_type: file?.type,
            name: file?.name,
            file_size: file?.size,
          }));

          formik.setFieldValue('media', [...newFiles, ...formik.values.media]);

          // default select the first watermark
          if (watermarks.length && formik.values.watermarkId < 0) {
            formik.setFieldValue('watermarkId', watermarks?.[0]?.id);
          }
        } catch (error) {
          console.error(error);
        }
      }}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({ className: "dropzone" })}
          onClick={() => {
            if (!formik.values.media[0] && mediaFilesRef && mediaFilesRef.current)
              mediaFilesRef.current.click();
          }}
        >

          <div className={classes.dropzoneArea}>

            <HideContainer hideWhen={formik.values.media?.[0]}>
              <MainMediaPlaceholder />
            </HideContainer>

            {
              formik.values.watermarkId >= 0 && formik.values.media[0] ?
                <div className={clsx(classes.watermarkOverlay, {
                  [classes.watermarkOverlayForVideos]: formik.values.media?.[0]?.design_type?.startsWith('video') ?? false,
                })}>
                  <Rnd
                    lockAspectRatio={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "4px solid white",
                      position: "relative",
                    }}
                    maxHeight='100%'
                    maxWidth='100%'
                    position={{ x: '50%', y: '50%' } as any}
                    default={{ width: '50%', height: '50%' } as any}
                    size={{ height: formik.values.waterMarkHeight, width: formik.values.waterMarkWidth }}
                    minWidth={150}
                    minHeight={150}
                    onResizeStop={(e: any, direction: any, ref: any, delta: any, position: any) => {

                      let waterMarkWidth = ref.style.width?.replace('px', '');
                      let waterMarkHeight = ref.style.height?.replace('px', '');

                      formik.setFieldValue('waterMarkWidth', parseInt(waterMarkWidth));
                      formik.setFieldValue('waterMarkHeight', parseInt(waterMarkHeight));

                    }}
                    disableDragging={true}
                  >

                    <div className={classes.rndBorder1} />
                    <div className={classes.rndBorder2} />
                    <div className={classes.rndBorder3} />
                    <div className={classes.rndBorder4} />

                    <img
                      src={
                        watermarks
                          ?.find(item => item.attributes.id == formik.values.watermarkId)
                          ?.attributes
                          ?.watermark_url
                        ?? ''
                      }
                      style={{
                        width: '90%',
                        height: '90%',
                        opacity: '0.4',
                      }}
                    />
                  </Rnd>
                </div>
                :
                null
            }

            <HideContainer hideWhen={!formik.values.media?.[0]?.data}>
              {mediaElement}
            </HideContainer>

          </div>



          {/* @ts-ignore */}
          <input
            type="file"
            {...getInputProps()}
            ref={mediaFilesRef}
          />
        </div>
      )}
    </Dropzone>
  );
}
